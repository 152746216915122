"use strict";
/**
 * ============================ (c) 2020 Liz Informática ============================
 * @created on Mon Jun 15 2020
 * @author Emir Marques - emirdeliz@gmail.com
 * What is this file?
 * Esse arquivo é responsável por efetuar funções úteis para número
 * ==================================================================================
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @created on Mon Jun 15 2020
 * @author: Emir Marques - emirdeliz@gmail.com
 * Esse método serve para formatar numero em n digitos
 * @param n: número a ser formatado
 * @param decimalPlaces: número de casas decimais para ser formtado
 */

exports.formatNumber = function (n, decimalPlaces) {
  return isNaN(n) ? n : parseFloat(n.toFixed(decimalPlaces || 0));
};
/**
 * @created on Mon Jun 15 2020
 * @author: Emir Marques - emirdeliz@gmail.com
 * Esse método serve para formatar numero em dois digitos
 * @param n: número a ser formatado
 */


exports.formatNumberToTwoDigits = function (n) {
  var decimalPlaces = 2;
  return exports.formatNumber(n, decimalPlaces);
};
/**
 * @created on thu Jun 18 2020
 * @author: Emir Marques - emirdeliz@gmail.com
 * Esse método serve para verificar se um número tende ao infinito. Existe uma função no próprio js que
 * deveria fazer isso. Mais por algum motivo ela retorna true quando testado com valores simples, ex: 3, 45, 100
 * Para o método abaixo foram realizados os seguintes testes:
 *    - isFinite(NaN)            -> false
 *    - isFinite(3)              -> false
 *    - isFinite(227)            -> false
 *    - isFinite(34256754344333) -> false
 *    - isFinite(Infinity)       -> true
 * @param n: número a ser testado
 */


exports.isFinite = function (n) {
  return n == Number.POSITIVE_INFINITY || n == Number.NEGATIVE_INFINITY;
};