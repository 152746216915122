"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function positiveNumber(num) {
  if (typeof num !== 'number') return 0;
  if (num <= 0) return 0;
  if (num > 0) return num;
  if (isNaN(num)) return 0;
  return 0;
}

exports.positiveNumber = positiveNumber;