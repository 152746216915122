"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function ObjectIdIsValid(str) {
  var _a;

  return ((_a = str) === null || _a === void 0 ? void 0 : _a.match(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i)) ? true : false;
}

exports.ObjectIdIsValid = ObjectIdIsValid;