"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var modesPlacePositivation;

(function (modesPlacePositivation) {
  modesPlacePositivation["read_only"] = "read_only";
  modesPlacePositivation["edit"] = "edit";
})(modesPlacePositivation = exports.modesPlacePositivation || (exports.modesPlacePositivation = {}));

var kindPlacePositivation;

(function (kindPlacePositivation) {
  kindPlacePositivation["category"] = "category";
  kindPlacePositivation["place"] = "place";
})(kindPlacePositivation = exports.kindPlacePositivation || (exports.kindPlacePositivation = {}));