"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isStringNumber(number) {
  return !isNaN(number);
}

exports.isStringNumber = isStringNumber;