"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function onlyNumbers(val) {
  return val.replace(/\D/g, '');
}

exports.onlyNumbers = onlyNumbers;