"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var DigitalBrochureActionsProvider_1 = require("./DigitalBrochureActionsProvider");

var DigitalBrochureProvider_1 = require("./DigitalBrochureProvider");

var graphql_clientgen_1 = require("graphql-clientgen");

var DataProviders_1 = require("../../DataProviders/DataProviders");

exports.DataProviders = function (props) {
  var digitalBrochure = props.digitalBrochure;
  return React.createElement(DataProviders_1.ComponentsGraphqlStoreContext.Consumer, null, function (value) {
    return React.createElement(DigitalBrochureActionsProvider_1.DigitalBrochureActionsProvider, {
      store: value
    }, React.createElement(DigitalBrochureProvider_1.DigitalBrochureProvider, {
      digitalBrochure: digitalBrochure
    }, props.children));
  });
};

function useClientStore() {
  return React.useContext(DataProviders_1.ComponentsGraphqlStoreContext);
}

exports.useClientStore = useClientStore;
exports.useClient = graphql_clientgen_1.useClientFactory(useClientStore);