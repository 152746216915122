"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var graphql_clientgen_1 = require("graphql-clientgen");

exports.IncentiveCampaignActionsContext = React.createContext({});
var Provider = exports.IncentiveCampaignActionsContext.Provider;

exports.IncentiveCampaignActionsProvider = function (props) {
  var children = props.children,
      store = props.store;
  var deletePin = graphql_clientgen_1.fetchGraphql('PinRemoveOne', {}, store);
  var fetchPin = graphql_clientgen_1.fetchGraphql('PinCreateOne', {}, store);
  var fetchLike = graphql_clientgen_1.fetchGraphql('IncentiveCampaignLikeToggle', {}, store);
  var fetchShare = graphql_clientgen_1.fetchGraphql('ShareCreateOne', {}, store);
  var fetchSearchUser = graphql_clientgen_1.fetchGraphql('UserSearch', {}, store);

  var unPin = function unPin(record, cb) {
    return deletePin({
      variables: {
        filter: record
      }
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      window.location.reload();
      return ctx;
    });
  };

  var onPin = function onPin(record, cb) {
    return fetchPin({
      variables: {
        record: Object.assign({
          kind: 'incentive_campaign'
        }, record)
      }
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  var onLike = function onLike(record, cb) {
    return fetchLike({
      variables: Object.assign({}, record)
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  var onShare = function onShare(record, cb) {
    return fetchShare({
      variables: {
        record: Object.assign(Object.assign({}, record), {
          kind: 'incentiveCampaign'
        })
      }
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  var onSearchUser = function onSearchUser(variables, cb) {
    return fetchSearchUser({
      variables: variables,
      cache: true
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  return React.createElement(Provider, {
    value: {
      deletePin: unPin,
      pin: onPin,
      like: onLike,
      share: onShare,
      searchUser: onSearchUser
    }
  }, children);
};

function useIncentiveCampaignActions() {
  return React.useContext(exports.IncentiveCampaignActionsContext);
}

exports.useIncentiveCampaignActions = useIncentiveCampaignActions;