"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function linkWithHttp(val) {
  var isHttpLink = val.toLowerCase().startsWith('http');
  return isHttpLink ? val : "https://".concat(val);
}

exports.linkWithHttp = linkWithHttp;