"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var useHtmlDimensions_1 = require("./useHtmlDimensions");

exports.useDimensions = function () {
  var _useHtmlDimensions_1$ = useHtmlDimensions_1.useHtmlDimensions(),
      sizes = _useHtmlDimensions_1$.sizes;

  return {
    width: sizes.width,
    height: sizes.height,
    isMobile: sizes.isMobile,
    isInitial: sizes.isInitial
  };
};