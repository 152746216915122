"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validateCEP = function (CEP) {
  return (CEP || '').match(/^\d{5}-?\d{3}$/) ? undefined : 'CEP Inválido';
};

exports.sanitizeCEP = function (CEP) {
  return (CEP || '').replace(/\D/gim, '');
};