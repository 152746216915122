import * as React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

const PageWrapperWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export const PageWrapper = (props: TPageWrapperProps) => {
  const { className } = props;

  return (
    <PageWrapperWrapper
      className={cx(className, 'PageWrapper PageWrapper_Wrapper')}
    >
      {props.children}
    </PageWrapperWrapper>
  );
};

export type TPageWrapperProps = {
  className?: string;
  children?: React.ReactNode;
};
