"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/** O tipo de uso do material. Ex: "video_poster" */

var EnumEventFileKind;

(function (EnumEventFileKind) {
  EnumEventFileKind["CardThumb"] = "card_thumb";
  EnumEventFileKind["VideoMp4"] = "video_mp4";
  EnumEventFileKind["VideoThumb"] = "video_thumb";
  EnumEventFileKind["VideoBanner"] = "video_banner";
  EnumEventFileKind["Pdf"] = "pdf";
  EnumEventFileKind["Notification"] = "notification";
  EnumEventFileKind["Mailing"] = "mailing";
  EnumEventFileKind["NotificationImage"] = "notification_image";
  EnumEventFileKind["AnyImage"] = "any_image";
  EnumEventFileKind["AnyDoc"] = "any_doc";
})(EnumEventFileKind = exports.EnumEventFileKind || (exports.EnumEventFileKind = {}));