"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var BLACKLIST = ['00000000000', '11111111111', '22222222222', '33333333333', '44444444444', '55555555555', '66666666666', '77777777777', '88888888888', '99999999999', '12345678909'];
var STRICT_STRIP_REGEX = /[.-]/g;
var LOOSE_STRIP_REGEX = /[^\d]/g;

function verifierDigit(numbersStr) {
  var numbersArr = numbersStr.split('').map(function (number) {
    return parseInt(number, 10);
  });
  var modulus = numbersArr.length + 1;
  var multiplied = numbersArr.map(function (number, index) {
    return number * (modulus - index);
  });
  var mod = multiplied.reduce(function (buffer, number) {
    return buffer + number;
  }) % 11;
  return mod < 2 ? 0 : 11 - mod;
}

exports.verifierDigit = verifierDigit;

function format(number) {
  return stripCPF(number).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
}

exports.format = format;

function stripCPF(number, strict) {
  var regex = strict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
  return (number || '').toString().replace(regex, '');
}

exports.stripCPF = stripCPF;

function isValidCPF(number, strict) {
  var stripped = stripCPF(number, strict); // CPF must be defined

  if (!stripped) {
    return false;
  } // CPF must have 11 chars


  if (stripped.length !== 11) {
    return false;
  } // CPF can't be blacklisted


  if (BLACKLIST.indexOf(stripped) !== -1) {
    return false;
  }

  var numbers = stripped.substr(0, 9);
  numbers += verifierDigit(numbers);
  numbers += verifierDigit(numbers);
  return numbers.substr(-2) === stripped.substr(-2);
}

exports.isValidCPF = isValidCPF;

function generateCPF(formatted) {
  var numbers = '';

  for (var i = 0; i < 9; i += 1) {
    numbers += Math.floor(Math.random() * 9);
  }

  numbers += verifierDigit(numbers);
  numbers += verifierDigit(numbers);
  return formatted ? format(numbers) : numbers;
}

exports.generateCPF = generateCPF;