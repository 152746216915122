"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var MaterialActionsProvider_1 = require("./MaterialActionsProvider");

var registerServiceWorker_1 = require("./registerServiceWorker");

var graphql_clientgen_1 = require("graphql-clientgen");

var useCurrentUser_1 = require("./useCurrentUser");

var TablePriceActionsProvider_1 = require("./TablePriceActionsProvider");

var PlaceActionsProvider_1 = require("./PlaceActionsProvider");

var __1 = require("..");

exports.ComponentsGraphqlStoreContext = React.createContext({});

exports.DataProviders = function (props) {
  var store = props.store,
      uploadUrl = props.uploadUrl;
  React.useEffect(function () {
    registerServiceWorker_1.registerWebPushServiceWorker(store);
  }, []);
  return React.createElement(exports.ComponentsGraphqlStoreContext.Provider, {
    value: store
  }, React.createElement(MaterialActionsProvider_1.MaterialActionsProvider, {
    store: store,
    uploadUrl: uploadUrl
  }, React.createElement(PlaceActionsProvider_1.PlaceActionsProvider, {
    store: store
  }, React.createElement(TablePriceActionsProvider_1.TablePriceActionsProvider, {
    store: store
  }, React.createElement(useCurrentUser_1.UserProvider, {
    parseUserPermissions: props.parseUserPermissions
  }, React.createElement(__1.AnalyticsProvider, null, React.createElement(React.Fragment, null, React.createElement(__1.BlockIncompleteUser, null), props.children)))))));
};

function useClientStore() {
  return React.useContext(exports.ComponentsGraphqlStoreContext);
}

exports.useClientStore = useClientStore;
exports.useClient = graphql_clientgen_1.useClientFactory(useClientStore);