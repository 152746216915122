"use strict";
/**
 * ============================ (c) 2020 Liz Informática ============================
 * @created on Thu Aug 06 2020
 * @author Emir Marques - emirdeliz@gmail.com
 * What is this file?
 * Esse arquivo é responsável por efetuar funções úteis para cep
 * ==================================================================================
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @created on Thu Aug 06 2020
 * @author: Emir Marques - emirdeliz@gmail.com
 * Esse método serve para formatar o cep
 * @param value: número ou string a ser formatado
 */

exports.formatCep = function (value) {
  return String(value || '').replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, '$1.$2-$3');
};