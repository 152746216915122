"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validatePassword = function (password) {
  if (!password || typeof password !== 'string') return 'Senha inválida';
  if (password.length < 8) return 'A senha precisa ter no mínimo 8 caracteres';
  if (password.match(/^[0-9]+$/)) return 'A senha não contém nenhuma letra maiúscula e minúscula';
  if (!password.match(/\d/)) return 'A senha não contém nenhum número'; //if (!password.match(/[a-z]/gim)) return 'A senha não contém nenhuma letra minúscula';

  if (!password.match(/[a-z]/)) return 'A senha não contém nenhuma letra minúscula'; //if (!password.match(/[A-Z]/gim)) return 'A senha não contém nenhuma letra maiúscula'; /[A-Z]/

  if (!password.match(/[A-Z]/)) return 'A senha não contém nenhuma letra maiúscula';
};