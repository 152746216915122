"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.stripSlash = function (url) {
  return (url || '').replace(/\/$/, '').replace(/\/\?/, '?').replace(/\/#$/, '#');
};

function getProtocol(url) {
  var _a;

  return ((_a = (url || '').match(protocolRegex)) === null || _a === void 0 ? void 0 : _a[0]) || '';
}

var protocolRegex = /^([a-z]*:\/\/)/;

function getDomainUrl(url, defaultDomain) {
  if (!url) return url;
  return new URL(url, defaultDomain).origin;
}

function splitUrl(url, defaultDomain) {
  var domain = getDomainUrl(url, defaultDomain);
  var rest = url.split(domain).slice(1).join('');
  return {
    domain: domain,
    rest: rest
  };
}

function join(parts) {
  return parts.filter(Boolean).map(function (el) {
    return stripAllSlashes(el);
  }).filter(Boolean).join('/');
}

function stripAllSlashes(str) {
  return str.replace(/^\//, '').replace(/\/$/, '');
}

exports.UrlUtils = {
  stripSlash: exports.stripSlash,
  stripAllSlashes: stripAllSlashes,
  getDomainUrl: getDomainUrl,
  getProtocol: getProtocol,
  splitUrl: splitUrl,
  join: join
};