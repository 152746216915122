"use strict";
/**
 * ============================ (c) 2020 Liz Informática ============================
 * @created on Mon Jun 29 2020
 * @author Emir Marques - emirdeliz@gmail.com
 * What is this file?
 * Esse arquivo é responsável por efetuar funções úteis para cnpj
 * ==================================================================================
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @created on Mon Jun 29 2020
 * @author: Emir Marques - emirdeliz@gmail.com
 * Esse método serve para formatar o cnpj
 * @param value: número ou string a ser formatado
 */

exports.formatCnpj = function (value) {
  return String(value || '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
};