"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var __1 = require("..");

var MaterialActionButton_1 = require("../ActionButton/MaterialActionButton");

var S = __importStar(require("./StylesCardInterno"));

exports.CardInternoItem = function (props) {
  var material = props.material,
      category = props.category,
      title = props.title,
      date = props.date,
      imgBody = props.imgBody,
      defaultImage = props.defaultImage,
      actions = props.actions,
      extraInfo = props.extraInfo;
  var actionsObject = typeof actions == 'function' ? actions(material) : actions;
  return React.createElement(__1.MaterialProvider, {
    material: material
  }, React.createElement(S.CardItem, null, React.createElement("header", null, category && React.createElement("span", {
    className: "card-category"
  }, category), React.createElement("span", {
    className: "card-date"
  }, date)), React.createElement(S.CardItemContent, null, React.createElement("div", {
    className: "card-content-wrapper"
  }, React.createElement("h2", null, title), extraInfo && extraInfo()), React.createElement("img", {
    src: imgBody || defaultImage,
    alt: "".concat(title, " imagem")
  })), actionsObject && React.createElement(MaterialActionButton_1.ActionButtonsS2, Object.assign({}, actionsObject))));
};