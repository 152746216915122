"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var convertXlsxToJson_1 = require("./convertXlsxToJson");

function convertFileXlsxToJson(file, tab, callback, ignoreRows) {
  var reader = new FileReader();
  var rABS = !!reader.readAsBinaryString;

  reader.onload = function (e) {
    var bstr = e.target.result;
    var json = convertXlsxToJson_1.convertXlsxToJson(bstr, tab, ignoreRows);
    callback(json);
  };

  if (rABS) {
    reader.readAsBinaryString(file);
  } else {
    reader.readAsArrayBuffer(file);
  }
}

exports.convertFileXlsxToJson = convertFileXlsxToJson;