"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var hashFragment = '';
var observer = null;
var asyncTimerId = null;
var scrollFunction = null;

function reset() {
  hashFragment = '';
  if (observer !== null) observer.disconnect();

  if (asyncTimerId !== null) {
    window.clearTimeout(asyncTimerId);
    asyncTimerId = null;
  }
}

function getElAndScroll() {
  var element = document.getElementById(hashFragment);

  if (element && scrollFunction) {
    scrollFunction(element);
    reset();
    return true;
  }

  return false;
}

function hashLinkScroll() {
  // Push onto callback queue so it runs after the DOM is updated
  window.setTimeout(function () {
    if (getElAndScroll() === false) {
      if (observer === null) {
        observer = new MutationObserver(getElAndScroll);
      }

      observer.observe(document, {
        attributes: true,
        childList: true,
        subtree: true
      }); // if the element doesn't show up in 10 seconds, stop checking

      asyncTimerId = window.setTimeout(function () {
        reset();
      }, 10000);
    }
  }, 0);
}

function scrollToHashLink(link) {
  reset();
  hashFragment = link.split('#').slice(1).join('#');
  if (!hashFragment) return;

  scrollFunction = function scrollFunction(el) {
    try {
      el.scrollIntoView({
        behavior: 'smooth'
      });
    } catch (e) {
      el.scrollIntoView();
    }
  };

  hashLinkScroll();
}

exports.scrollToHashLink = scrollToHashLink;