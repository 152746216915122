"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var MAX_MOBILE = 480;
var MAX_TABLET = 1250;
var WINDOW_WIDTH = typeof window !== 'object' ? MAX_MOBILE : window.innerWidth;

function remSetMAX_MOBILE(value) {
  MAX_MOBILE = value;
}

exports.remSetMAX_MOBILE = remSetMAX_MOBILE;

function remSetMAX_TABLET(value) {
  MAX_TABLET = value;
}

exports.remSetMAX_TABLET = remSetMAX_TABLET;

function rem() {
  var mobile;
  var tablet;
  var desk;

  switch (arguments.length) {
    case 1:
      {
        desk = tablet = mobile = arguments.length <= 0 ? undefined : arguments[0];
        break;
      }

    case 2:
      {
        mobile = arguments.length <= 0 ? undefined : arguments[0];
        tablet = arguments.length <= 1 ? undefined : arguments[1];
        desk = tablet;
        break;
      }

    case 3:
      {
        mobile = arguments.length <= 0 ? undefined : arguments[0];
        tablet = arguments.length <= 1 ? undefined : arguments[1];
        desk = arguments.length <= 2 ? undefined : arguments[2];
        break;
      }

    default:
      {
        mobile = arguments.length <= 0 ? undefined : arguments[0];
        tablet = mobile;
        desk = mobile;
      }
  }

  if (WINDOW_WIDTH < MAX_MOBILE) return "".concat(mobile, "px");
  if (WINDOW_WIDTH < MAX_TABLET) return "".concat(tablet, "px");
  return "".concat(desk, "px");
}

exports.rem = rem;