"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var __1 = require("..");

exports.useAbrangencia = function () {
  var _a;

  var context = __1.useSelecionarAbrangencia();

  var removeVirtualRegions = function removeVirtualRegions(value) {
    return value.startsWith('_');
  };

  var availableAtRegions = (_a = context.state.regions) === null || _a === void 0 ? void 0 : _a.filter(removeVirtualRegions);
  return {
    availableAtRegions: availableAtRegions,
    locationType: context.state.locationType,
    sapCode: context.state.sapCode
  };
};