"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function ensureNumber(num) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : NaN;

  try {
    num = Number(num);
    if (isNaN(num)) return defaultValue;
    return num;
  } catch (e) {
    console.error(e);
  }

  return defaultValue;
}

exports.ensureNumber = ensureNumber;