"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function delay() {
  var time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1000;
  var fn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(fn());
    }, time);
  });
}

exports.delay = delay;