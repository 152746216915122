"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.DigitalBrochureContext = React.createContext({});
var Provider = exports.DigitalBrochureContext.Provider;

exports.DigitalBrochureProvider = function (props) {
  var _a;

  var children = props.children,
      digitalBrochure = props.digitalBrochure;

  if (!((_a = digitalBrochure) === null || _a === void 0 ? void 0 : _a._id)) {
    console.error("DigitalBrochureProvider: digitalBrochure not provided in props \"".concat(JSON.stringify(props, null, 2), "\""));
    return null;
  }

  return React.createElement(Provider, {
    value: {
      digitalBrochure: digitalBrochure
    }
  }, children);
};

function useDigitalBrochureContext() {
  var _a;

  var context = React.useContext(exports.DigitalBrochureContext);

  if (!((_a = context.digitalBrochure) === null || _a === void 0 ? void 0 : _a._id)) {
    console.error("useDigitalBrochureContext: invalid digitalBrochure in context:", context);
    return null;
  }

  return context;
}

exports.useDigitalBrochureContext = useDigitalBrochureContext;