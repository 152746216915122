import { Method, MethodsDict, MethodsInfoDict } from 'graphql-clientgen';

export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  MongoID: any;
  JSON: any;
};

export type _IdOperatorsFilterAnalyticsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyAnalyticsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyAreaLojaInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyBannerInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyBudgetInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyChannelInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyCommentInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyContractInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyDeliveryOrderInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyDeviceInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyDeviceNotificationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyDigitalBrochureAnalyticsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyDigitalBrochureInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyDownloadSensitiveFilesInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyEmailInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyEmailStatsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyEmailUnsubscribeInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyEventLiveInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyFileInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyFolderInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyFormatoInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyHomebannerInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyImportUsersPlanInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyInventoryItemInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyInventoryWithDrawInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyJobTitleInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyLayoutInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyLegalTextInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyMapaPdvInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyNotificationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyPecaMapaInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyPinInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyPlaceAnalyticsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyPlaceInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyPositivatorInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyPositivatorItemInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyPricingTableInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyProductInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyProductionInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyRecentsActivitiesInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyRegionalBudgetInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyRegionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyRegionItemInput = {
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  gt?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  gte?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  lt?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  lte?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  ne?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyRegraMapaInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyRoleGroupInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManySearchCountInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindManyShareInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyStructuralInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManySupplierDeliveryInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManySupplierInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyTablePriceInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyUserDeletedInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyUserHistoryBlockInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyUserInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindManyUserWordsBlackListInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneBannerInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneBudgetInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneChannelInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneCommentInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneContractInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneDeviceInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneDigitalBrochureAnalyticsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneDigitalBrochureInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneEmailInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneEmailStatsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneEmailUnsubscribeInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneEventLiveInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneInventoryItemInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneJobTitleInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneLayoutInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneMetadataInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneNotificationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOnePlaceAnalyticsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOnePlaceInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneRegionalBudgetInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneRegionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneRegionItemInput = {
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  gt?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  gte?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  lt?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  lte?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  ne?: Maybe<Scalars['String']>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneRoleGroupInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterFindOneStructuralInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneSupplierInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneTablePriceInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneUserContentPermissionInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneUserDeletedInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterFindOneUserInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterMaterialInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveManyFolderInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveManyPinInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveManyPositivatorInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveOneFolderInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveOnePinInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterRemoveOneRegionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterRemoveOneShareInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateManyPositivatorInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneBannerInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _IdOperatorsFilterUpdateOneCommentInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneContractInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneDeviceInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneDigitalBrochureInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneEventLiveInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneFolderInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneImportUsersPlanInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneLayoutInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneMetadataInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneNotificationInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOnePlaceInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOnePositivatorInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneProductInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneStructuralInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneSupplierInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneTablePriceInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type _IdOperatorsFilterUpdateOneUserInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type AcceptedTermsAnalytics = {
  __typename?: 'AcceptedTermsAnalytics';
  term?: Maybe<Scalars['String']>;
  acceptedAt?: Maybe<Scalars['Date']>;
};

export type AddressHelperPayload = {
  __typename?: 'AddressHelperPayload';
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<AddressHelperRegionItemOption>;
  state?: Maybe<AddressHelperRegionItemOption>;
  region?: Maybe<AddressHelperRegionItemOption>;
  regionDDDs?: Maybe<Array<Maybe<AddressHelperRegionItemOption>>>;
  neighborhood?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  IBGE?: Maybe<Scalars['Float']>;
};

export type AddressHelperRegionItemOption = {
  __typename?: 'AddressHelperRegionItemOption';
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
};

export type Analytics = {
  __typename?: 'Analytics';
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  day?: Maybe<Scalars['String']>;
  /** Nome da entidade que está recebendo a ação, pode exemplo "IncentiveCampaign", "Material" ou "User" */
  entityName?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  userId?: Maybe<Scalars['String']>;
  /** Nome do usuário que executou a ação */
  userName?: Maybe<Scalars['String']>;
  /** Id do Canal (context.user.channels[0]) do usuário que executou a ação */
  userChannel?: Maybe<Scalars['String']>;
  /** Id do RoleGroup (context.user.roleGroup._id) do usuário que executou a ação */
  userRoleGroup?: Maybe<Scalars['String']>;
  /** Id da Regional (context.user.region._id) do usuário que executou a ação */
  userRegion?: Maybe<Scalars['String']>;
  userGrupoEconomicoPDV?: Maybe<Scalars['String']>;
  userRazaoSocialPDV?: Maybe<Scalars['String']>;
  userResponsavelUOPDV?: Maybe<Scalars['String']>;
  userIdArea?: Maybe<Scalars['String']>;
  /** Titulo do material sobre a qual o evento ocorreu. Por exemplo: 'Campanha de incentivo abc' ou 'Tabela de preços XYZ' */
  materialTitle?: Maybe<Scalars['String']>;
  materialCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  event?: Maybe<EnumAnalyticsEvent>;
  /** Kind do documento que recebeu o evento: Ex: price_table */
  parentKind?: Maybe<EnumAnalyticsParentKind>;
  parentId?: Maybe<Scalars['String']>;
  /** Quando o evento é "search", label é o termo buscado; quando o event é "pageView" é o titulo da página; etc */
  label?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  /** route da página acessada quando o evento é do tipo pageView */
  route?: Maybe<Scalars['String']>;
  /** Guarda o header 'User-Agent' recebido na requisição que originou o evento */
  device?: Maybe<Scalars['String']>;
  /** Tipo do device do usuário (mobile, tablet, desktop or notFound). */
  deviceKind?: Maybe<EnumAnalyticsDeviceKind>;
  ip?: Maybe<Scalars['String']>;
  materialCreatedAt?: Maybe<Scalars['Date']>;
  materialPublishedAt?: Maybe<Scalars['Date']>;
  originalMaterial?: Maybe<Scalars['String']>;
  /** Salva o CPF do usuário que está tentando logar e tem o login mal sucedido */
  userDenied?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type AnalyticsComment = {
  __typename?: 'AnalyticsComment';
  _id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  materialTitle?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  likesCount?: Maybe<Scalars['Int']>;
  subCommentsCount?: Maybe<Scalars['Int']>;
  commentId?: Maybe<Scalars['String']>;
};

export type AnalyticsNotification = {
  __typename?: 'AnalyticsNotification';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type AnalyticsPagination = {
  __typename?: 'AnalyticsPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Analytics>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type AnalyticsPin = {
  __typename?: 'AnalyticsPin';
  userId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type AnalyticsPoints = {
  __typename?: 'AnalyticsPoints';
  userId?: Maybe<Scalars['String']>;
  points?: Maybe<Array<Maybe<PointsExpireType>>>;
};

export type AnalyticsReportFilterInput = {
  kind?: Maybe<Scalars['String']>;
  dateStart?: Maybe<Scalars['Date']>;
  dateEnd?: Maybe<Scalars['Date']>;
};

export type AnalyticsReportItem = {
  __typename?: 'AnalyticsReportItem';
  event?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userChannel?: Maybe<Scalars['String']>;
  userRoleGroup?: Maybe<Scalars['String']>;
  userRegion?: Maybe<Scalars['String']>;
  userGrupoEconomicoPDV?: Maybe<Scalars['String']>;
  userRazaoSocialPDV?: Maybe<Scalars['String']>;
  userResponsavelUOPDV?: Maybe<Scalars['String']>;
  userIdArea?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  date: Scalars['Date'];
  materialCreatedAt?: Maybe<Scalars['Date']>;
  materialPublishedAt?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  day: Scalars['String'];
  kind?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  deviceKind?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
};

export type AnalyticsUser = {
  __typename?: 'AnalyticsUser';
  _id?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  cargo?: Maybe<Scalars['String']>;
  canal?: Maybe<Scalars['String']>;
  regional?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  data_adesao?: Maybe<Scalars['String']>;
  ativo?: Maybe<Scalars['Boolean']>;
  matricula?: Maybe<Scalars['String']>;
  responsavelUOPDV?: Maybe<Scalars['String']>;
  matriculaResponsavelUOPDV?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  reconhece?: Maybe<ReconheceAnalytics>;
  acceptedTerms?: Maybe<Array<Maybe<AcceptedTermsAnalytics>>>;
  data_criacao?: Maybe<Scalars['String']>;
  data_atualizacao?: Maybe<Scalars['String']>;
  data_ultimo_login?: Maybe<Scalars['String']>;
};

export type AnalyticsUserNotification = {
  __typename?: 'AnalyticsUserNotification';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  read?: Maybe<Array<Maybe<AnalyticsNotification>>>;
  unread?: Maybe<Array<Maybe<AnalyticsNotification>>>;
};

export type AreaLoja = {
  __typename?: 'AreaLoja';
  position?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  image?: Maybe<File>;
  optionInfo?: Maybe<OptionInfo>;
  creationInfo?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type AreaLojaPagination = {
  __typename?: 'AreaLojaPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<AreaLoja>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Author = {
  __typename?: 'Author';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type AvailableAtChannelsOperatorsFilterFindManyBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindManyEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterUpdateOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterUpdateOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtChannelsOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyBannerInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyCategoryInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyEventLiveInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindManyMaterialInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneBannerInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneCategoryInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneEventLiveInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterFindOneMaterialInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterMaterialInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterRemoveOneMaterialInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneBannerInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneCategoryInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneEventLiveInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionalProfilesOperatorsFilterUpdateOneMaterialInput = {
  /** Limita pela combinação rolegroup + region de cada usuário */
  gt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  gte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lt?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  lte?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  ne?: Maybe<Scalars['String']>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterUpdateOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterUpdateOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRegionsOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterUpdateOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterUpdateOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRoleGroupsOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRolesOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRolesOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableAtRolesOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyBannerInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyCategoryInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyEventLiveInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindManyMaterialInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneBannerInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneCategoryInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneEventLiveInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterFindOneMaterialInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterMaterialInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterRemoveOneMaterialInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterUpdateOneBannerInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterUpdateOneCategoryInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterUpdateOneEventLiveInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AvailableForUsersOperatorsFilterUpdateOneMaterialInput = {
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  gte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lt?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  lte?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  ne?: Maybe<Scalars['String']>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AwaitingUsersApprover = {
  __typename?: 'AwaitingUsersApprover';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type Banner = {
  __typename?: 'Banner';
  owner?: Maybe<Scalars['String']>;
  validity?: Maybe<BannerValidity>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<BannerImages>>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedBy>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  user?: Maybe<BannerAuthor>;
};

export type BannerAuthor = {
  __typename?: 'BannerAuthor';
  name: Scalars['String'];
};

export type BannerChangedBy = {
  __typename?: 'BannerChangedBy';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type BannerChangedByInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type BannerImages = {
  __typename?: 'BannerImages';
  link?: Maybe<Scalars['String']>;
  display?: Maybe<EnumBannerImagesDisplay>;
  fileId?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type BannerImagesInput = {
  link?: Maybe<Scalars['String']>;
  display?: Maybe<EnumBannerImagesDisplay>;
  fileId?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type BannerInfoContent = {
  __typename?: 'BannerInfoContent';
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  canView?: Maybe<Array<Maybe<Scalars['String']>>>;
  link?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
};

export type BannerValidity = {
  __typename?: 'BannerValidity';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  /** Lista de emails para notificar quando a expiração estiver próxima */
  notifyEmailsBeforeExpire?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BannerValidityInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  /** Lista de emails para notificar quando a expiração estiver próxima */
  notifyEmailsBeforeExpire?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BaseDownload = {
  __typename?: 'BaseDownload';
  schema?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type BrochureUpdateThumbnailPayload = {
  __typename?: 'BrochureUpdateThumbnailPayload';
  url?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  _id: Scalars['String'];
};

export type Budget = {
  __typename?: 'Budget';
  status?: Maybe<EnumBudgetStatus>;
  /** Titulo da verba. Exemplo "Verba para campanha de natal" */
  title?: Maybe<Scalars['String']>;
  /** Valor total da verba a ser distribuida entre as regionais. */
  value?: Maybe<Scalars['Float']>;
  /** Quanto cada regional poderá usar para produzir. */
  regionalValues?: Maybe<Array<Maybe<BudgetRegionalValues>>>;
  allowedLayouts?: Maybe<Array<Maybe<BudgetAllowedLayouts>>>;
  /** se true os itens criados na entidade RegionalBudget serão apenas para registro. Regionais não fazem produção pelo portal */
  isRegionalProduction?: Maybe<Scalars['Boolean']>;
  /** Canais (tipos) de lojas que poderão receber os produtos dessa verba. */
  availableAtChannels?: Maybe<Array<Maybe<EnumBudgetAvailableAtChannels>>>;
  initialRegionalBudgets?: Maybe<Array<Maybe<BudgetInitialRegionalBudgets>>>;
  /** ID da producão gerada a partir dessa verba */
  usedInProductionId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** Produção criada a partir desta verba */
  usedInProduction?: Maybe<Production>;
  regionalBudgets?: Maybe<Array<Maybe<RegionalBudget>>>;
  layouts?: Maybe<Array<Maybe<Layout>>>;
  author?: Maybe<SocialUser>;
};

export type BudgetAllowedLayouts = {
  __typename?: 'BudgetAllowedLayouts';
  codeT?: Maybe<Scalars['String']>;
  baseQuantity?: Maybe<Scalars['Float']>;
};

export type BudgetAllowedLayoutsInput = {
  codeT?: Maybe<Scalars['String']>;
  baseQuantity?: Maybe<Scalars['Float']>;
};

export type BudgetInitialRegionalBudgets = {
  __typename?: 'BudgetInitialRegionalBudgets';
  parentBudgetId?: Maybe<Scalars['String']>;
  region?: Maybe<EnumBudgetInitialRegionalBudgetsRegion>;
  deadline?: Maybe<Scalars['Date']>;
  maxValue?: Maybe<Scalars['Float']>;
  usedValue?: Maybe<Scalars['Float']>;
  remainingValue?: Maybe<Scalars['Float']>;
  availableAtChannels?: Maybe<
    Array<Maybe<EnumBudgetInitialRegionalBudgetsAvailableAtChannels>>
  >;
  allowedLayouts?: Maybe<Array<Maybe<RegionalBudgetAllowedLayouts>>>;
  productionItems?: Maybe<Array<Maybe<RegionalBudgetProductionItems>>>;
  /** True se a regional preencheu os valores para que a HQ possa criar a produção  */
  filledByRegional?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumBudgetInitialRegionalBudgetsStatus>;
  views?: Maybe<Array<Maybe<RegionalBudgetViews>>>;
  /** Replica as informações do produto no momento em que o budget foi salvo */
  finalProductsPricing?: Maybe<
    Array<Maybe<RegionalBudgetFinalProductsPricing>>
  >;
};

export type BudgetInitialRegionalBudgetsInput = {
  parentBudgetId?: Maybe<Scalars['String']>;
  region?: Maybe<EnumBudgetInitialRegionalBudgetsRegion>;
  deadline?: Maybe<Scalars['Date']>;
  maxValue?: Maybe<Scalars['Float']>;
  usedValue?: Maybe<Scalars['Float']>;
  remainingValue?: Maybe<Scalars['Float']>;
  availableAtChannels?: Maybe<
    Array<Maybe<EnumBudgetInitialRegionalBudgetsAvailableAtChannels>>
  >;
  allowedLayouts?: Maybe<Array<Maybe<RegionalBudgetAllowedLayoutsInput>>>;
  productionItems?: Maybe<Array<Maybe<RegionalBudgetProductionItemsInput>>>;
  /** True se a regional preencheu os valores para que a HQ possa criar a produção  */
  filledByRegional?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumBudgetInitialRegionalBudgetsStatus>;
  views?: Maybe<Array<Maybe<RegionalBudgetViewsInput>>>;
  /** Replica as informações do produto no momento em que o budget foi salvo */
  finalProductsPricing?: Maybe<
    Array<Maybe<RegionalBudgetFinalProductsPricingInput>>
  >;
};

/** List of items with pagination. */
export type BudgetPagination = {
  __typename?: 'BudgetPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Budget>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum BudgetPermissionsEnum {
  BudgetViewModule = 'budget_viewModule',
  CreateBudget = 'create_budget',
  ReadBudget = 'read_budget',
  UpdateBudget = 'update_budget',
  DeleteBudget = 'delete_budget',
  UpdateStatusBudget = 'update_status_budget'
}

export type BudgetRegionalValues = {
  __typename?: 'BudgetRegionalValues';
  region?: Maybe<EnumBudgetRegionalValuesRegion>;
  /** Não é obrigatorio porque uma regional pode nao receber verba. */
  value?: Maybe<Scalars['Float']>;
  /** Data final para as regionais podem preencher as verbas regionais */
  deadline?: Maybe<Scalars['Date']>;
};

export type BudgetRegionalValuesInput = {
  region?: Maybe<EnumBudgetRegionalValuesRegion>;
  /** Não é obrigatorio porque uma regional pode nao receber verba. */
  value?: Maybe<Scalars['Float']>;
  /** Data final para as regionais podem preencher as verbas regionais */
  deadline?: Maybe<Scalars['Date']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  code?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  productType?: Maybe<EnumCampaignProductType>;
  releaseDate?: Maybe<Scalars['Date']>;
  archivingDone?: Maybe<Scalars['Boolean']>;
  archive?: Maybe<EnumCampaignArchive>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  layouts?: Maybe<Array<Maybe<Layout>>>;
  layoutsWithIntervalQty?: Maybe<Array<Maybe<Layout>>>;
};

/** List of items with pagination. */
export type CampaignPagination = {
  __typename?: 'CampaignPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Campaign>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum CampaignPermissionsEnum {
  CampaignViewModule = 'campaign_viewModule',
  CreateCampaign = 'create_campaign',
  ReadCampaign = 'read_campaign',
  UpdateCampaign = 'update_campaign',
  UpdateStatusCampaign = 'update_status_campaign',
  DeleteCampaign = 'delete_campaign'
}

export type CampaignSearchByTitleDescriptionOrStatus = {
  __typename?: 'CampaignSearchByTitleDescriptionOrStatus';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Campaign>>>;
};

export type CampaignSearchByTitleOrDescription = {
  __typename?: 'CampaignSearchByTitleOrDescription';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Campaign>>>;
};

export type Category = {
  __typename?: 'Category';
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Category>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  parentCategory?: Maybe<Category>;
  materials?: Maybe<MaterialPagination>;
};

export type CategorySubCategoriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyCategoryInput>;
};

export type CategoryParentCategoryArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneCategoryInput>;
};

export type CategoryMaterialsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  sort?: Maybe<SortFindManyMaterialInput>;
  kind: Array<Maybe<EnumMaterialKind>>;
};

export type Channel = {
  __typename?: 'Channel';
  _id: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  roleGroups?: Maybe<Array<Maybe<RoleGroup>>>;
  subChannels?: Maybe<Array<Maybe<Channel>>>;
};

export type ChannelRoleGroupsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRoleGroupInput>;
};

export type ChannelSubChannelsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyChannelInput>;
};

/** List of items with pagination. */
export type ChannelPagination = {
  __typename?: 'ChannelPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Channel>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type CnpjOperatorsFilterFindManyPlaceAnalyticsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CnpjOperatorsFilterFindManyPlaceInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CnpjOperatorsFilterFindOnePlaceAnalyticsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CnpjOperatorsFilterFindOnePlaceInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CnpjOperatorsFilterUpdateOnePlaceInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Code_TmOperatorsFilterFindManyProductInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Code_TmOperatorsFilterUpdateOneProductInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CodeTmOperatorsFilterFindManyLayoutInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CodeTmOperatorsFilterFindOneLayoutInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CodeTmOperatorsFilterUpdateOneLayoutInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Codigo_SapOperatorsFilterFindManyPlaceAnalyticsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Codigo_SapOperatorsFilterFindManyPlaceInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Codigo_SapOperatorsFilterFindOnePlaceAnalyticsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Codigo_SapOperatorsFilterFindOnePlaceInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Codigo_SapOperatorsFilterUpdateOnePlaceInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Comment = {
  __typename?: 'Comment';
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Likes>;
  content?: Maybe<Scalars['String']>;
  replyRecipientId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  subComments?: Maybe<Array<Maybe<Comment>>>;
  replyRecipient?: Maybe<User>;
  user?: Maybe<SocialUser>;
};

export type CommentSubCommentsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyCommentInput>;
};

/** List of items with pagination. */
export type CommentPagination = {
  __typename?: 'CommentPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Comment>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type CommentSortPaginationFilterInput = {
  parentId: Scalars['String'];
};

export enum CommunicationKindFilter {
  Email = 'email',
  Notification = 'notification'
}

export enum ContentPermissionsHelperKind {
  Region = 'region',
  RoleGroup = 'roleGroup',
  SiteContentType = 'siteContentType'
}

export type ContentPermissionsHelperOption = {
  __typename?: 'ContentPermissionsHelperOption';
  id: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
  pId?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  kind: ContentPermissionsHelperKind;
};

export type Contract = {
  __typename?: 'Contract';
  nr_clm?: Maybe<Scalars['String']>;
  nr_contrato?: Maybe<Scalars['String']>;
  inicio_vigencia?: Maybe<Scalars['Date']>;
  final_vigencia?: Maybe<Scalars['Date']>;
  valor_total?: Maybe<Scalars['Float']>;
  valor_utilizado?: Maybe<Scalars['Float']>;
  nr_contrato_derivado?: Maybe<Scalars['String']>;
  inicio_vigencia_derivado?: Maybe<Scalars['Date']>;
  final_vigencia_derivado?: Maybe<Scalars['Date']>;
  valor_total_derivado?: Maybe<Scalars['Float']>;
  valor_utilizado_derivado?: Maybe<Scalars['Float']>;
  margem_saldo_derivado?: Maybe<Scalars['Float']>;
  natureza_contrato?: Maybe<Scalars['String']>;
  natureza_verba?: Maybe<Scalars['String']>;
  nrs_cic?: Maybe<Array<Maybe<ContractNrs_Cic>>>;
  prazo_pagto?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type ContractNrs_Cic = {
  __typename?: 'ContractNrs_cic';
  nr_cic?: Maybe<Scalars['String']>;
  inicio_vigencia_cic?: Maybe<Scalars['Date']>;
  final_vigencia_cic?: Maybe<Scalars['Date']>;
  valor_cic?: Maybe<Scalars['Float']>;
};

export type ContractNrs_CicInput = {
  nr_cic?: Maybe<Scalars['String']>;
  inicio_vigencia_cic?: Maybe<Scalars['Date']>;
  final_vigencia_cic?: Maybe<Scalars['Date']>;
  valor_cic?: Maybe<Scalars['Float']>;
};

/** List of items with pagination. */
export type ContractPagination = {
  __typename?: 'ContractPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Contract>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type CreatedAtOperatorsFilterAnalyticsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type CreatedAtOperatorsFilterFindManyAnalyticsInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type CreatedByOperatorsFilterFindManyPricingTableInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateManyCategoryInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
};

export type CreateManyCategoryPayload = {
  __typename?: 'CreateManyCategoryPayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<Category>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateManyContractInput = {
  nr_clm: Scalars['String'];
  nr_contrato: Scalars['String'];
  inicio_vigencia: Scalars['Date'];
  final_vigencia: Scalars['Date'];
  valor_total: Scalars['Float'];
  valor_utilizado: Scalars['Float'];
  nr_contrato_derivado: Scalars['String'];
  inicio_vigencia_derivado: Scalars['Date'];
  final_vigencia_derivado: Scalars['Date'];
  valor_total_derivado: Scalars['Float'];
  valor_utilizado_derivado: Scalars['Float'];
  margem_saldo_derivado: Scalars['Float'];
  natureza_contrato: Scalars['String'];
  natureza_verba: Scalars['String'];
  nrs_cic?: Maybe<Array<Maybe<ContractNrs_CicInput>>>;
  prazo_pagto: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CreateManyContractPayload = {
  __typename?: 'CreateManyContractPayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<Contract>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateManyEmailStatsInput = {
  statsId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmailStatsStatus>;
};

export type CreateManyEmailStatsPayload = {
  __typename?: 'CreateManyEmailStatsPayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<EmailStats>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateManyEmailUnsubscribeInput = {
  email: Scalars['String'];
};

export type CreateManyEmailUnsubscribePayload = {
  __typename?: 'CreateManyEmailUnsubscribePayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<EmailUnsubscribe>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateManyLayoutInput = {
  campaign: Scalars['String'];
  lpu: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  suffix: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  codeT?: Maybe<Scalars['String']>;
  codeTM?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  thumbnailID?: Maybe<Scalars['String']>;
};

export type CreateManyLayoutPayload = {
  __typename?: 'CreateManyLayoutPayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<Layout>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateManyNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
};

export type CreateManyNotificationPayload = {
  __typename?: 'CreateManyNotificationPayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<Notification>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateManyProductInput = {
  nome_produto: Scalars['String'];
  preco_produto?: Maybe<Scalars['Float']>;
  categoria?: Maybe<EnumProductCategoria>;
  abreviacao_producao?: Maybe<Scalars['String']>;
  especificacao?: Maybe<Scalars['String']>;
  faixa_preco?: Maybe<Scalars['Boolean']>;
  especificacao_peso?: Maybe<Scalars['Float']>;
  especificacao_largura?: Maybe<Scalars['Float']>;
  especificacao_altura?: Maybe<Scalars['Float']>;
  especificacao_profundidade?: Maybe<Scalars['Float']>;
  especificacao_preco?: Maybe<Scalars['Float']>;
  intervalo_quantidade?: Maybe<Array<Maybe<ProductIntervalo_QuantidadeInput>>>;
  code_tm?: Maybe<Scalars['String']>;
  qtd_dias?: Maybe<Scalars['Float']>;
  contagem?: Maybe<Scalars['String']>;
  tipo_multiplicador?: Maybe<EnumProductTipo_Multiplicador>;
  multiplicador_pdv?: Maybe<Scalars['Float']>;
  multiplicador_mobiliario?: Maybe<Scalars['Float']>;
  multiplicador_posicao?: Maybe<Scalars['Float']>;
  ncm?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Codigo SAP do fornecedor. "numero_fornecedor_sap" na entidade Supplier */
  supplierSAPCode?: Maybe<Scalars['String']>;
};

export type CreateManyProductPayload = {
  __typename?: 'CreateManyProductPayload';
  /** Created document ID */
  recordIds: Array<Maybe<Scalars['MongoID']>>;
  /** Created documents */
  records: Array<Maybe<Product>>;
  /** Count of all documents created */
  createCount: Scalars['Int'];
};

export type CreateOneAreaLojaInput = {
  position?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CreateOneAreaLojaPayload = {
  __typename?: 'CreateOneAreaLojaPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<AreaLoja>;
};

export type CreateOneBannerInput = {
  validity?: Maybe<BannerValidityInput>;
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position: Scalars['String'];
  title: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images: Array<Maybe<BannerImagesInput>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedByInput>;
};

export type CreateOneBannerPayload = {
  __typename?: 'CreateOneBannerPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Banner>;
};

export type CreateOneBudgetInput = {
  status: EnumBudgetStatus;
  /** Titulo da verba. Exemplo "Verba para campanha de natal" */
  title: Scalars['String'];
  /** Valor total da verba a ser distribuida entre as regionais. */
  value: Scalars['Float'];
  /** Quanto cada regional poderá usar para produzir. */
  regionalValues?: Maybe<Array<Maybe<BudgetRegionalValuesInput>>>;
  allowedLayouts: Array<Maybe<BudgetAllowedLayoutsInput>>;
  /** se true os itens criados na entidade RegionalBudget serão apenas para registro. Regionais não fazem produção pelo portal */
  isRegionalProduction?: Maybe<Scalars['Boolean']>;
  /** Canais (tipos) de lojas que poderão receber os produtos dessa verba. */
  availableAtChannels: Array<Maybe<EnumBudgetAvailableAtChannels>>;
  /** ID da producão gerada a partir dessa verba */
  usedInProductionId?: Maybe<Scalars['String']>;
};

export type CreateOneBudgetPayload = {
  __typename?: 'CreateOneBudgetPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Budget>;
};

export type CreateOneCampaignInput = {
  code?: Maybe<Scalars['String']>;
  productType: EnumCampaignProductType;
  releaseDate?: Maybe<Scalars['Date']>;
  archivingDone?: Maybe<Scalars['Boolean']>;
  archive: EnumCampaignArchive;
  title: Scalars['String'];
  description: Scalars['String'];
};

export type CreateOneCampaignPayload = {
  __typename?: 'CreateOneCampaignPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Campaign>;
};

export type CreateOneCategoryInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
};

export type CreateOneCategoryPayload = {
  __typename?: 'CreateOneCategoryPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Category>;
};

export type CreateOneCommentInput = {
  parentId: Scalars['String'];
  roomId?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  content: Scalars['String'];
  replyRecipientId?: Maybe<Scalars['String']>;
};

export type CreateOneCommentPayload = {
  __typename?: 'CreateOneCommentPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Comment>;
};

export type CreateOneContractInput = {
  nr_clm: Scalars['String'];
  nr_contrato: Scalars['String'];
  inicio_vigencia: Scalars['Date'];
  final_vigencia: Scalars['Date'];
  valor_total: Scalars['Float'];
  valor_utilizado: Scalars['Float'];
  nr_contrato_derivado: Scalars['String'];
  inicio_vigencia_derivado: Scalars['Date'];
  final_vigencia_derivado: Scalars['Date'];
  valor_total_derivado: Scalars['Float'];
  valor_utilizado_derivado: Scalars['Float'];
  margem_saldo_derivado: Scalars['Float'];
  natureza_contrato: Scalars['String'];
  natureza_verba: Scalars['String'];
  nrs_cic?: Maybe<Array<Maybe<ContractNrs_CicInput>>>;
  prazo_pagto: Scalars['String'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CreateOneContractPayload = {
  __typename?: 'CreateOneContractPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Contract>;
};

export type CreateOneDeliveryOrderInput = {
  title?: Maybe<Scalars['String']>;
  status?: Maybe<EnumDeliveryOrderStatus>;
  groups: Array<Maybe<DeliveryOrderGroupsInput>>;
  records: Array<Maybe<DeliveryOrderRecordsInput>>;
  region?: Maybe<Scalars['String']>;
  origin: EnumDeliveryOrderOrigin;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  executedAt?: Maybe<Scalars['Date']>;
  executedById?: Maybe<Scalars['String']>;
};

export type CreateOneDeliveryOrderPayload = {
  __typename?: 'CreateOneDeliveryOrderPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<DeliveryOrder>;
};

export type CreateOneDeviceInput = {
  manufacturer: Scalars['String'];
  model: Scalars['String'];
  inclusion: Scalars['String'];
  plan?: Maybe<Scalars['String']>;
  pricingTable?: Maybe<Scalars['String']>;
  featured?: Maybe<Array<Maybe<Scalars['String']>>>;
  comments?: Maybe<Scalars['String']>;
  chip?: Maybe<Scalars['String']>;
  nfc?: Maybe<Scalars['JSON']>;
  offer_date?: Maybe<Scalars['Date']>;
  launch_date?: Maybe<Scalars['Date']>;
  /** Código do aparelho (cliente chama de código do fornecedor). Código retornado pelo device. Ex: "MWC22" */
  codeProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
  codeTim: Array<Maybe<Scalars['String']>>;
  releaseDateOf?: Maybe<Scalars['Date']>;
  availableColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  eSim?: Maybe<Scalars['Boolean']>;
  dualSim?: Maybe<Scalars['Boolean']>;
  connectionType5G?: Maybe<Scalars['Boolean']>;
  ram?: Maybe<Scalars['JSON']>;
  internalMemory?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  windowSize?: Maybe<Scalars['String']>;
  windowResolution?: Maybe<Scalars['String']>;
  cameraMegaPixels?: Maybe<Scalars['String']>;
  cameraFrontal?: Maybe<Scalars['JSON']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  legalText?: Maybe<Scalars['String']>;
  status?: Maybe<EnumDeviceStatus>;
  type?: Maybe<Scalars['String']>;
  devicePlan?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  flags?: Maybe<Scalars['String']>;
  operationalSystem?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  connectionType?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  expandableMemory?: Maybe<Scalars['String']>;
  pixelDensity?: Maybe<Scalars['JSON']>;
  colors?: Maybe<Scalars['String']>;
  cameraResolution?: Maybe<Scalars['String']>;
  cameraResolutionBy?: Maybe<Scalars['String']>;
  cameraOpeningCapacity?: Maybe<Scalars['JSON']>;
  cameraStabilization?: Maybe<Scalars['Boolean']>;
  cameraAutofocus?: Maybe<Scalars['Boolean']>;
  cameraOpticalZoom?: Maybe<Scalars['Boolean']>;
  cameraDigitalZoom?: Maybe<Scalars['Boolean']>;
  cameraFlash?: Maybe<Scalars['Boolean']>;
  cameraHdr?: Maybe<Scalars['Boolean']>;
  cameraLocation?: Maybe<Scalars['Boolean']>;
  cameraFacialDetection?: Maybe<Scalars['Boolean']>;
  videoResolution?: Maybe<Scalars['String']>;
  videoStabilization?: Maybe<Scalars['Boolean']>;
  videoAutofocus?: Maybe<Scalars['Boolean']>;
  videoSlowMotion?: Maybe<Scalars['Boolean']>;
  videoHdr?: Maybe<Scalars['Boolean']>;
  gps?: Maybe<Scalars['Boolean']>;
  compass?: Maybe<Scalars['Boolean']>;
  biometric?: Maybe<Scalars['Boolean']>;
  radio?: Maybe<Scalars['Boolean']>;
  tv?: Maybe<Scalars['Boolean']>;
  waterproof?: Maybe<Scalars['Boolean']>;
  coolText?: Maybe<Scalars['String']>;
};

export type CreateOneDevicePayload = {
  __typename?: 'CreateOneDevicePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Device>;
};

export type CreateOneDigitalBrochureAnalyticsInput = {
  ddd: Scalars['String'];
  service: Scalars['String'];
  visualizedBrochures?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOneDigitalBrochureAnalyticsPayload = {
  __typename?: 'CreateOneDigitalBrochureAnalyticsPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<DigitalBrochureAnalytics>;
};

export type CreateOneDigitalBrochureInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  title: Scalars['String'];
  service: Scalars['String'];
  file: Scalars['String'];
  validity: Scalars['Date'];
  link?: Maybe<Scalars['String']>;
  thumbnailQuality?: Maybe<Scalars['Float']>;
  isVisibleToPublic?: Maybe<Scalars['Boolean']>;
};

export type CreateOneDigitalBrochurePayload = {
  __typename?: 'CreateOneDigitalBrochurePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<DigitalBrochure>;
};

export type CreateOneEmailStatsInput = {
  statsId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmailStatsStatus>;
};

export type CreateOneEmailStatsPayload = {
  __typename?: 'CreateOneEmailStatsPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<EmailStats>;
};

export type CreateOneEmailUnsubscribeInput = {
  email: Scalars['String'];
};

export type CreateOneEmailUnsubscribePayload = {
  __typename?: 'CreateOneEmailUnsubscribePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<EmailUnsubscribe>;
};

export type CreateOneEventLiveInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate: Scalars['Date'];
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title: Scalars['String'];
  kind: EnumEventLiveKind;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<EventLiveChangedByInput>;
};

export type CreateOneEventLivePayload = {
  __typename?: 'CreateOneEventLivePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<EventLive>;
};

export type CreateOneFileInput = {
  origin?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  parentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O tipo de uso do material. Ex: "video_poster" */
  kind?: Maybe<EnumFileKind>;
  title: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  extension: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOneFilePayload = {
  __typename?: 'CreateOneFilePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<File>;
};

export type CreateOneFolderInput = {
  kind: EnumFolderKind;
  name?: Maybe<Scalars['String']>;
};

export type CreateOneFolderPayload = {
  __typename?: 'CreateOneFolderPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Folder>;
};

export type CreateOneFormatoInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CreateOneFormatoPayload = {
  __typename?: 'CreateOneFormatoPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Formato>;
};

export type CreateOneHomebannerInput = {
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<HomebannerContentInput>>>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type CreateOneHomebannerPayload = {
  __typename?: 'CreateOneHomebannerPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Homebanner>;
};

export type CreateOneImportUsersPlanInput = {
  fileId?: Maybe<Scalars['String']>;
  statusImportUser?: Maybe<EnumImportUsersPlanStatusImportUser>;
  origin?: Maybe<EnumImportUsersPlanOrigin>;
  errorsFile?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateOneImportUsersPlanPayload = {
  __typename?: 'CreateOneImportUsersPlanPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<ImportUsersPlan>;
};

export type CreateOneInventoryWithDrawInput = {
  gradeMode: EnumInventoryWithDrawGradeMode;
  productionId?: Maybe<Scalars['String']>;
  depositPath: Scalars['String'];
  destinationPlaceSAPCode: Scalars['String'];
  layoutCodeTM: Scalars['String'];
  quantity: Scalars['Float'];
  deliveryPlaceSAPCode: Scalars['String'];
  depositNumber: Scalars['String'];
  salesOrganization?: Maybe<Scalars['String']>;
  distributionChannel?: Maybe<Scalars['String']>;
  activitiesSector?: Maybe<Scalars['String']>;
  productionCode?: Maybe<Scalars['String']>;
  commercialOpStatus?: Maybe<Scalars['String']>;
  commercialOpReason?: Maybe<Scalars['String']>;
  orderZMP?: Maybe<Scalars['String']>;
  processingDate?: Maybe<Scalars['String']>;
};

export type CreateOneInventoryWithDrawPayload = {
  __typename?: 'CreateOneInventoryWithDrawPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<InventoryWithDraw>;
};

export type CreateOneLayoutInput = {
  campaign: Scalars['String'];
  lpu: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  suffix: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  codeT?: Maybe<Scalars['String']>;
  codeTM?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  thumbnailID?: Maybe<Scalars['String']>;
};

export type CreateOneLayoutPayload = {
  __typename?: 'CreateOneLayoutPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Layout>;
};

export type CreateOneLegalTextInput = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type CreateOneLegalTextPayload = {
  __typename?: 'CreateOneLegalTextPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<LegalText>;
};

export type CreateOneMapaPdvInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  obs?: Maybe<Scalars['String']>;
  hasObs?: Maybe<Scalars['Boolean']>;
  clusters?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterState?: Maybe<Scalars['JSON']>;
  hasPeriod?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  pecas?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
};

export type CreateOneMapaPdvPayload = {
  __typename?: 'CreateOneMapaPDVPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<MapaPdv>;
};

export type CreateOneMaterialInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  availableAtChannels: Array<Maybe<Scalars['String']>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind: EnumMaterialKind;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<MaterialEditorInput>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
};

export type CreateOneMaterialPayload = {
  __typename?: 'CreateOneMaterialPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Material>;
};

export type CreateOneMetadataInput = {
  title?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type CreateOneMetadataPayload = {
  __typename?: 'CreateOneMetadataPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Metadata>;
};

export type CreateOneNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
};

export type CreateOneNotificationPayload = {
  __typename?: 'CreateOneNotificationPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Notification>;
};

export type CreateOnePecaMapaInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['JSON']>;
  formats?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CreateOnePecaMapaPayload = {
  __typename?: 'CreateOnePecaMapaPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<PecaMapa>;
};

export type CreateOnePinInput = {
  /** ID do item que está sendo favoritado. */
  recordId: Scalars['String'];
  kind?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  otherData?: Maybe<Scalars['JSON']>;
};

export type CreateOnePinPayload = {
  __typename?: 'CreateOnePinPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Pin>;
};

export type CreateOnePlaceInput = {
  type: EnumPlaceType;
  disabled: Scalars['Boolean'];
  CNPJ?: Maybe<Scalars['String']>;
  inscricao_estadual?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia: Scalars['String'];
  custcode: Scalars['String'];
  codigo_sap: Scalars['String'];
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  region: Scalars['String'];
  DDDs: Array<Maybe<Scalars['String']>>;
  address: PlaceAddressInput;
  modelo_de_loja?: Maybe<EnumPlaceModelo_De_Loja>;
  tipo_de_loja?: Maybe<EnumPlaceTipo_De_Loja>;
  tecnologia_de_rede?: Maybe<Scalars['String']>;
  produtos_comercializados?: Maybe<
    Array<Maybe<EnumPlaceProdutos_Comercializados>>
  >;
  foco_de_comunicacao?: Maybe<EnumPlaceFoco_De_Comunicacao>;
  cluster?: Maybe<EnumPlaceCluster>;
  canal_distribuicao?: Maybe<Scalars['String']>;
  organizacao_vendas?: Maybe<Scalars['String']>;
  setor_atividade?: Maybe<Scalars['String']>;
  quantidade_de_funcionarios?: Maybe<Scalars['Float']>;
  localizacao?: Maybe<EnumPlaceLocalizacao>;
  nome_da_galeria?: Maybe<Scalars['String']>;
  nome_do_shopping?: Maybe<Scalars['String']>;
  parcelamento?: Maybe<Scalars['Float']>;
  estrutura_de_loja?: Maybe<Array<Maybe<PlaceEstrutura_De_LojaInput>>>;
  inicio_da_operacao_mes?: Maybe<Scalars['Float']>;
  inicio_da_operacao_ano?: Maybe<Scalars['Float']>;
  ultima_modificacao?: Maybe<EnumPlaceUltima_Modificacao>;
  data_de_modificacao_mes?: Maybe<Scalars['Float']>;
  data_de_modificacao_ano?: Maybe<Scalars['Float']>;
  metragem?: Maybe<PlaceMetragemInput>;
  tipo_de_fachada?: Maybe<EnumPlaceTipo_De_Fachada>;
  revestimento_externo?: Maybe<EnumPlaceRevestimento_Externo>;
  posicoes_de_atendimento?: Maybe<Scalars['Float']>;
  posicoes_de_pos_venda?: Maybe<Scalars['Float']>;
  sala_de_gerente?: Maybe<Scalars['Boolean']>;
  back_office?: Maybe<Scalars['Boolean']>;
  wifi_clientes?: Maybe<Scalars['Boolean']>;
  sinal_live?: Maybe<Scalars['Boolean']>;
  display_de_senha?: Maybe<Scalars['Boolean']>;
  sistema_de_som?: Maybe<Scalars['Boolean']>;
  video_wall?: Maybe<Array<Maybe<PlaceVideo_WallInput>>>;
  adminInput?: Maybe<PlaceAdminInputInput>;
  adminId?: Maybe<Scalars['String']>;
};

export type CreateOnePlacePayload = {
  __typename?: 'CreateOnePlacePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Place>;
};

export type CreateOnePositivatorInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  isSubcategoryActive?: Maybe<Scalars['Boolean']>;
  isPositivatorActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  /** Enum no formato "PlaceType_X_ModeloDeLoja"' */
  channels: Array<Maybe<EnumPositivatorChannels>>;
  cnpjs?: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CreateOnePositivatorItemInput = {
  title?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  positivator?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUpload?: Maybe<Scalars['String']>;
  status?: Maybe<EnumPositivatorItemStatus>;
  place?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CreateOnePositivatorItemPayload = {
  __typename?: 'CreateOnePositivatorItemPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<PositivatorItem>;
};

export type CreateOnePositivatorPayload = {
  __typename?: 'CreateOnePositivatorPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Positivator>;
};

export type CreateOnePricingTableInput = {
  status?: Maybe<EnumPricingTableStatus>;
  description?: Maybe<Scalars['String']>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  disabled?: Maybe<Scalars['Boolean']>;
  xlsxId: Scalars['String'];
  activeSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  planPre?: Maybe<Scalars['String']>;
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  legalText?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  incidents?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  previous?: Maybe<Scalars['String']>;
  plansDevice?: Maybe<Scalars['JSON']>;
  devicePrices?: Maybe<Array<Maybe<PricingTableDevicePricesInput>>>;
  gadgetPrices?: Maybe<Array<Maybe<PricingTableGadgetPricesInput>>>;
};

export type CreateOnePricingTablePayload = {
  __typename?: 'CreateOnePricingTablePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<PricingTable>;
};

export type CreateOneProductInput = {
  nome_produto: Scalars['String'];
  preco_produto?: Maybe<Scalars['Float']>;
  categoria?: Maybe<EnumProductCategoria>;
  abreviacao_producao?: Maybe<Scalars['String']>;
  especificacao?: Maybe<Scalars['String']>;
  faixa_preco?: Maybe<Scalars['Boolean']>;
  especificacao_peso?: Maybe<Scalars['Float']>;
  especificacao_largura?: Maybe<Scalars['Float']>;
  especificacao_altura?: Maybe<Scalars['Float']>;
  especificacao_profundidade?: Maybe<Scalars['Float']>;
  especificacao_preco?: Maybe<Scalars['Float']>;
  intervalo_quantidade?: Maybe<Array<Maybe<ProductIntervalo_QuantidadeInput>>>;
  code_tm?: Maybe<Scalars['String']>;
  qtd_dias?: Maybe<Scalars['Float']>;
  contagem?: Maybe<Scalars['String']>;
  tipo_multiplicador?: Maybe<EnumProductTipo_Multiplicador>;
  multiplicador_pdv?: Maybe<Scalars['Float']>;
  multiplicador_mobiliario?: Maybe<Scalars['Float']>;
  multiplicador_posicao?: Maybe<Scalars['Float']>;
  ncm?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Codigo SAP do fornecedor. "numero_fornecedor_sap" na entidade Supplier */
  supplierSAPCode?: Maybe<Scalars['String']>;
};

export type CreateOneProductionInput = {
  title: Scalars['String'];
  /** lista de locais para receber os produtos mais os filtros
   * utilizados para obter a lista de locais
   */
  productionItems?: Maybe<Array<Maybe<ProductionProductionItemsInput>>>;
  /** quando uma producão é criada a partir de uma verba
   * salvamos o _id da verba
   */
  originBudgetId?: Maybe<Scalars['String']>;
  status: EnumProductionStatus;
};

export type CreateOneProductionPayload = {
  __typename?: 'CreateOneProductionPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Production>;
};

export type CreateOneProductPayload = {
  __typename?: 'CreateOneProductPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Product>;
};

export type CreateOneRecentsActivitiesInput = {
  parentId?: Maybe<Scalars['String']>;
  viewsHistory?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type CreateOneRecentsActivitiesPayload = {
  __typename?: 'CreateOneRecentsActivitiesPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<RecentsActivities>;
};

export type CreateOneRegraMapaInput = {
  area?: Maybe<Scalars['String']>;
  cluster?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CreateOneRegraMapaPayload = {
  __typename?: 'CreateOneRegraMapaPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<RegraMapa>;
};

export type CreateOneShareInput = {
  kind?: Maybe<EnumShareKind>;
  title?: Maybe<Scalars['String']>;
  /** O id do item compartilhado */
  recordId: Scalars['String'];
  userIds: Array<Maybe<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
};

export type CreateOneSharePayload = {
  __typename?: 'CreateOneSharePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Share>;
};

export type CreateOneStructuralInput = {
  cnpj?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tipo_modificacao?: Maybe<Scalars['String']>;
  tipo_loja?: Maybe<Scalars['String']>;
  financial?: Maybe<Array<Maybe<StructuralFinancialInput>>>;
  canal?: Maybe<Scalars['String']>;
  classificacao_pdv?: Maybe<Scalars['String']>;
  ponto_de_cessao?: Maybe<Scalars['String']>;
  loja_sap?: Maybe<Scalars['String']>;
  patrimonio_tombado?: Maybe<Scalars['String']>;
  reembolso_obra?: Maybe<Scalars['String']>;
  local?: Maybe<Scalars['String']>;
  ddd?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  escritorio_vt?: Maybe<Scalars['String']>;
  escritorio_resp?: Maybe<Scalars['String']>;
  status?: Maybe<EnumStructuralStatus>;
  stage?: Maybe<EnumStructuralStage>;
  projectSLA?: Maybe<Scalars['JSON']>;
  stageDATA?: Maybe<Scalars['JSON']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CreateOneStructuralPayload = {
  __typename?: 'CreateOneStructuralPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Structural>;
};

export type CreateOneSupplierInput = {
  numero_fornecedor_sap: Scalars['String'];
  CNPJ: Scalars['String'];
  razao_social: Scalars['String'];
  nome_fantasia: Scalars['String'];
  address: Scalars['JSON'];
  regionais_ufs_atendidas: Array<Maybe<Scalars['String']>>;
  contatos: Array<Maybe<Scalars['String']>>;
  nome: Scalars['String'];
  telefone: Scalars['String'];
  matricula: Scalars['String'];
  email: Scalars['String'];
  nivel_aprovacao?: Maybe<Scalars['String']>;
  valor_qualificacao?: Maybe<Scalars['Float']>;
  validade_qualificacao?: Maybe<Scalars['Date']>;
  contratos?: Maybe<Array<Maybe<Scalars['String']>>>;
  produtos_unitarios?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CreateOneSupplierPayload = {
  __typename?: 'CreateOneSupplierPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<Supplier>;
};

export type CreateOneTablePriceInput = {
  availableAtRegions: Array<Maybe<Scalars['String']>>;
  locationType: Array<Maybe<Scalars['String']>>;
  description: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;
  upload?: Maybe<Scalars['String']>;
  sapCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  planPre: Scalars['String'];
  planControl: Scalars['String'];
  planPos: Scalars['String'];
  coolText?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  plansDevice?: Maybe<Array<Maybe<TablePricePlansDeviceInput>>>;
};

export type CreateOneTablePricePayload = {
  __typename?: 'CreateOneTablePricePayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<TablePrice>;
};

export type CreateOneUserHistoryBlockInput = {
  /** Usuário bloqueado */
  userId?: Maybe<Scalars['String']>;
  /** Conteúdo que originou o bloqueio do usuário */
  parentId?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
};

export type CreateOneUserHistoryBlockPayload = {
  __typename?: 'CreateOneUserHistoryBlockPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<UserHistoryBlock>;
};

export type CreateOneUserWordsBlackListInput = {
  word?: Maybe<Scalars['String']>;
};

export type CreateOneUserWordsBlackListPayload = {
  __typename?: 'CreateOneUserWordsBlackListPayload';
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<UserWordsBlackList>;
};

export type DateRange = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type DayOperatorsFilterAnalyticsInput = {
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  gt?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  gte?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  lt?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  lte?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  ne?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DayOperatorsFilterFindManyAnalyticsInput = {
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  gt?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  gte?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  lt?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  lte?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  ne?: Maybe<Scalars['String']>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DeliveryOrder = {
  __typename?: 'DeliveryOrder';
  title?: Maybe<Scalars['String']>;
  status?: Maybe<EnumDeliveryOrderStatus>;
  groups?: Maybe<Array<Maybe<DeliveryOrderGroups>>>;
  records?: Maybe<Array<Maybe<DeliveryOrderRecords>>>;
  origin?: Maybe<EnumDeliveryOrderOrigin>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  executedAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
  createdBy?: Maybe<User>;
  updatedBy?: Maybe<User>;
  executedBy?: Maybe<User>;
  qntPDVs?: Maybe<Scalars['Float']>;
  qntItems?: Maybe<Scalars['Float']>;
};

export type DeliveryOrderGroups = {
  __typename?: 'DeliveryOrderGroups';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  itemCodeTMs?: Maybe<Array<Maybe<Scalars['String']>>>;
  places?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type DeliveryOrderGroupsInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  itemCodeTMs?: Maybe<Array<Maybe<Scalars['String']>>>;
  places?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

/** List of items with pagination. */
export type DeliveryOrderPagination = {
  __typename?: 'DeliveryOrderPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<DeliveryOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type DeliveryOrderRecords = {
  __typename?: 'DeliveryOrderRecords';
  groupID?: Maybe<Scalars['String']>;
  depositPath?: Maybe<Scalars['String']>;
  originId?: Maybe<Scalars['String']>;
  originSapCode?: Maybe<Scalars['String']>;
  destinyId?: Maybe<Scalars['String']>;
  destinySapCode?: Maybe<Scalars['String']>;
  destinyName?: Maybe<Scalars['String']>;
  destinyActivitieSector?: Maybe<Scalars['String']>;
  destinyDistributionChannel?: Maybe<Scalars['String']>;
  destinySalesOrganization?: Maybe<Scalars['String']>;
  itemCodeTM?: Maybe<Scalars['String']>;
  itemDescription?: Maybe<Scalars['String']>;
  qnt?: Maybe<Scalars['Float']>;
  executedQnt?: Maybe<Scalars['Float']>;
};

export type DeliveryOrderRecordsInput = {
  groupID?: Maybe<Scalars['String']>;
  depositPath?: Maybe<Scalars['String']>;
  originId?: Maybe<Scalars['String']>;
  originSapCode?: Maybe<Scalars['String']>;
  destinyId?: Maybe<Scalars['String']>;
  destinySapCode?: Maybe<Scalars['String']>;
  destinyName?: Maybe<Scalars['String']>;
  destinyActivitieSector?: Maybe<Scalars['String']>;
  destinyDistributionChannel?: Maybe<Scalars['String']>;
  destinySalesOrganization?: Maybe<Scalars['String']>;
  itemCodeTM?: Maybe<Scalars['String']>;
  itemDescription?: Maybe<Scalars['String']>;
  qnt?: Maybe<Scalars['Float']>;
  executedQnt?: Maybe<Scalars['Float']>;
};

export type DeliveryOrderValidatedReport = {
  __typename?: 'DeliveryOrderValidatedReport';
  isValid?: Maybe<Scalars['Boolean']>;
  validatedRecords?: Maybe<Array<Maybe<ValidatedDeliveryRecord>>>;
  storageReport?: Maybe<Array<Maybe<StorageReport>>>;
};

export type DepositNumberOperatorsFilterFindManyInventoryItemInput = {
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  gt?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  gte?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  lt?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  lte?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  ne?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DepositNumberOperatorsFilterFindOneInventoryItemInput = {
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  gt?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  gte?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  lt?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  lte?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  ne?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DescriptionTmOperatorsFilterFindManyInventoryItemInput = {
  /** Descrição do produto. usado para busca por texto. */
  gt?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  gte?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  lt?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  lte?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  ne?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Descrição do produto. usado para busca por texto. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DescriptionTmOperatorsFilterFindOneInventoryItemInput = {
  /** Descrição do produto. usado para busca por texto. */
  gt?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  gte?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  lt?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  lte?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  ne?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Descrição do produto. usado para busca por texto. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyBannerInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyCategoryInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyEventLiveInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindManyMaterialInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneBannerInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneCategoryInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneEventLiveInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterFindOneMaterialInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterMaterialInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterRemoveOneMaterialInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterUpdateOneBannerInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterUpdateOneCategoryInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterUpdateOneEventLiveInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DestinationEmailsFromMailingFileOperatorsFilterUpdateOneMaterialInput = {
  /** Utilizado para envio de notificações e informativos */
  gt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  gte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lt?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  lte?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  ne?: Maybe<Scalars['String']>;
  /** Utilizado para envio de notificações e informativos */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Device = {
  __typename?: 'Device';
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  inclusion?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  pricingTable?: Maybe<Scalars['String']>;
  featured?: Maybe<Array<Maybe<Scalars['String']>>>;
  comments?: Maybe<Scalars['String']>;
  chip?: Maybe<Scalars['String']>;
  nfc?: Maybe<Scalars['JSON']>;
  offer_date?: Maybe<Scalars['Date']>;
  launch_date?: Maybe<Scalars['Date']>;
  /** Código do aparelho (cliente chama de código do fornecedor). Código retornado pelo device. Ex: "MWC22" */
  codeProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
  codeTim?: Maybe<Array<Maybe<Scalars['String']>>>;
  releaseDateOf?: Maybe<Scalars['Date']>;
  availableColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  eSim?: Maybe<Scalars['Boolean']>;
  dualSim?: Maybe<Scalars['Boolean']>;
  connectionType5G?: Maybe<Scalars['Boolean']>;
  ram?: Maybe<Scalars['JSON']>;
  internalMemory?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  windowSize?: Maybe<Scalars['String']>;
  windowResolution?: Maybe<Scalars['String']>;
  cameraMegaPixels?: Maybe<Scalars['String']>;
  cameraFrontal?: Maybe<Scalars['JSON']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  legalText?: Maybe<Scalars['String']>;
  status?: Maybe<EnumDeviceStatus>;
  type?: Maybe<Scalars['String']>;
  devicePlan?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  flags?: Maybe<Scalars['String']>;
  operationalSystem?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  connectionType?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  expandableMemory?: Maybe<Scalars['String']>;
  pixelDensity?: Maybe<Scalars['JSON']>;
  colors?: Maybe<Scalars['String']>;
  cameraResolution?: Maybe<Scalars['String']>;
  cameraResolutionBy?: Maybe<Scalars['String']>;
  cameraOpeningCapacity?: Maybe<Scalars['JSON']>;
  cameraStabilization?: Maybe<Scalars['Boolean']>;
  cameraAutofocus?: Maybe<Scalars['Boolean']>;
  cameraOpticalZoom?: Maybe<Scalars['Boolean']>;
  cameraDigitalZoom?: Maybe<Scalars['Boolean']>;
  cameraFlash?: Maybe<Scalars['Boolean']>;
  cameraHdr?: Maybe<Scalars['Boolean']>;
  cameraLocation?: Maybe<Scalars['Boolean']>;
  cameraFacialDetection?: Maybe<Scalars['Boolean']>;
  videoResolution?: Maybe<Scalars['String']>;
  videoStabilization?: Maybe<Scalars['Boolean']>;
  videoAutofocus?: Maybe<Scalars['Boolean']>;
  videoSlowMotion?: Maybe<Scalars['Boolean']>;
  videoHdr?: Maybe<Scalars['Boolean']>;
  gps?: Maybe<Scalars['Boolean']>;
  compass?: Maybe<Scalars['Boolean']>;
  biometric?: Maybe<Scalars['Boolean']>;
  radio?: Maybe<Scalars['Boolean']>;
  tv?: Maybe<Scalars['Boolean']>;
  waterproof?: Maybe<Scalars['Boolean']>;
  coolText?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  imagesContent?: Maybe<Array<Maybe<File>>>;
  prices?: Maybe<DevicePricePayload>;
};

export type DeviceImagesContentArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type DeviceBaseDownloadPayload = {
  __typename?: 'DeviceBaseDownloadPayload';
  schema?: Maybe<Scalars['JSON']>;
  data?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type DeviceFindManufacturers = {
  __typename?: 'DeviceFindManufacturers';
  name: Scalars['String'];
};

export type DeviceNotification = {
  __typename?: 'DeviceNotification';
  codeProvider?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  notifiedEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  notifiedUserIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  solved?: Maybe<Scalars['Boolean']>;
  debuggerInfo?: Maybe<DeviceNotificationDebuggerInfo>;
  name?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
  sapCode?: Maybe<Scalars['String']>;
  /** Informações do device vindas dos headers http */
  httpRequestDeviceInfo?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  foundDevice?: Maybe<Device>;
};

export type DeviceNotificationDebuggerInfo = {
  __typename?: 'DeviceNotificationDebuggerInfo';
  device?: Maybe<Scalars['String']>;
  hardware?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  systemVersion?: Maybe<Scalars['String']>;
  systemName?: Maybe<Scalars['String']>;
  identifierForVendor?: Maybe<Scalars['String']>;
  localizedModel?: Maybe<Scalars['String']>;
};

export type DeviceNotificationDebuggerInfoInput = {
  device?: Maybe<Scalars['String']>;
  hardware?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  systemVersion?: Maybe<Scalars['String']>;
  systemName?: Maybe<Scalars['String']>;
  identifierForVendor?: Maybe<Scalars['String']>;
  localizedModel?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type DeviceNotificationPagination = {
  __typename?: 'DeviceNotificationPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<DeviceNotification>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

/** List of items with pagination. */
export type DevicePagination = {
  __typename?: 'DevicePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Device>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum DevicePermissionsEnum {
  DevicesViewModule = 'devices_view_module',
  ReadDevice = 'read_device',
  CreateDevice = 'create_device',
  UpdateDevice = 'update_device',
  DeleteDevice = 'delete_device',
  UpdateStatusDevice = 'update_status_device'
}

export type DevicePlans = {
  __typename?: 'DevicePlans';
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  planPre?: Maybe<Scalars['String']>;
};

export type DevicePricePayload = {
  __typename?: 'DevicePricePayload';
  legalText: Scalars['String'];
  price?: Maybe<PricePayload>;
};

export type DevicePrices = {
  __typename?: 'DevicePrices';
  pre?: Maybe<Scalars['Int']>;
  pos?: Maybe<Scalars['Int']>;
  control?: Maybe<Scalars['Int']>;
};

export type DeviceSearch = {
  __typename?: 'DeviceSearch';
  _id: Scalars['String'];
  model: Scalars['String'];
  codeTim: Array<Maybe<Scalars['String']>>;
  codeProvider: Array<Maybe<Scalars['String']>>;
};

export type DigitalBrochure = {
  __typename?: 'DigitalBrochure';
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  thumbnailURL?: Maybe<Scalars['String']>;
  thumbnailHDURL?: Maybe<Scalars['String']>;
  /** URL do SVG do QRCode gerado no .save() do documento e enviado ao S3 */
  qrCodeSVGURL?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['Date']>;
  link?: Maybe<Scalars['String']>;
  thumbnailQuality?: Maybe<Scalars['Float']>;
  isVisibleToPublic?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  fileContent?: Maybe<File>;
};

export type DigitalBrochureAnalytics = {
  __typename?: 'DigitalBrochureAnalytics';
  ddd?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  visualizedBrochures?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type DigitalBrochureAnalyticsPagination = {
  __typename?: 'DigitalBrochureAnalyticsPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<DigitalBrochureAnalytics>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum DigitalBrochureAnalyticsPermissionsEnum {
  CreateDigitalbrochureAnalytics = 'create_digitalbrochure_analytics',
  ReadDigitalbrochureAnalytics = 'read_digitalbrochure_analytics',
  UpdateDigitalbrochureAnalytics = 'update_digitalbrochure_analytics',
  DeleteDigitalbrochureAnalytics = 'delete_digitalbrochure_analytics',
  UpdateStatusDigitalbrochureAnalytics = 'update_status_digitalbrochure_analytics'
}

/** List of items with pagination. */
export type DigitalBrochurePagination = {
  __typename?: 'DigitalBrochurePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<DigitalBrochure>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum DigitalBrochurePermissionsEnum {
  DigitalBrochureViewModule = 'digitalBrochure_viewModule',
  CreateDigitalbrochure = 'create_digitalbrochure',
  ReadDigitalbrochure = 'read_digitalbrochure',
  UpdateDigitalbrochure = 'update_digitalbrochure',
  DeleteDigitalbrochure = 'delete_digitalbrochure',
  UpdateStatusDigitalbrochure = 'update_status_digitalbrochure'
}

export type DigitalBrochureSearchByDddAndService = {
  __typename?: 'DigitalBrochureSearchByDDDAndService';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<DigitalBrochure>>>;
};

export type DownloadAcceptedTerms = {
  __typename?: 'DownloadAcceptedTerms';
  url?: Maybe<Scalars['String']>;
};

export type DownloadSensitiveFiles = {
  __typename?: 'DownloadSensitiveFiles';
  owner?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  file?: Maybe<File>;
  user?: Maybe<User>;
};

/** List of items with pagination. */
export type DownloadSensitiveFilesPagination = {
  __typename?: 'DownloadSensitiveFilesPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<DownloadSensitiveFiles>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type DownloadUsersAcceptedPoints = {
  __typename?: 'DownloadUsersAcceptedPoints';
  url?: Maybe<Scalars['String']>;
};

export type DownloadUsersInfo = {
  __typename?: 'DownloadUsersInfo';
  url?: Maybe<Scalars['String']>;
};

export type DownloadUsersWithPoints = {
  __typename?: 'DownloadUsersWithPoints';
  url?: Maybe<Scalars['String']>;
};

export type Email = {
  __typename?: 'Email';
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  /** Props usadas para montar o layout do email */
  templateProps?: Maybe<Scalars['JSON']>;
  templateName?: Maybe<EnumEmailTemplateName>;
  status?: Maybe<EnumEmailStatus>;
  sent?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['Date']>;
  /** O corpo do email que foi enviado */
  sentBody?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Lista de emails que clicaram no link de confirmar leitura */
  readBy?: Maybe<Array<Maybe<EmailReadBy>>>;
  notifyCollectionOnRead?: Maybe<EmailNotifyCollectionOnRead>;
  statsId?: Maybe<Scalars['String']>;
  plataform?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  images?: Maybe<EmailImages>;
  attachments?: Maybe<Array<Maybe<File>>>;
  html?: Maybe<EmailHtml>;
};

export type EmailAttachmentsArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type EmailHtml = {
  __typename?: 'EmailHtml';
  body?: Maybe<Scalars['String']>;
};

export type EmailImages = {
  __typename?: 'EmailImages';
  IMAGE_SRC_1?: Maybe<Scalars['String']>;
  IMAGE_SRC_2?: Maybe<Scalars['String']>;
};

export type EmailNotifyCollectionOnRead = {
  __typename?: 'EmailNotifyCollectionOnRead';
  modelToUpdate?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  documentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EmailNotifyCollectionOnReadInput = {
  modelToUpdate?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  documentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EmailOperatorsFilterFindManyUserInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EmailOperatorsFilterFindOneUserInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EmailOperatorsFilterUpdateOneUserInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** List of items with pagination. */
export type EmailPagination = {
  __typename?: 'EmailPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Email>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type EmailReadBy = {
  __typename?: 'EmailReadBy';
  email?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type EmailReadByInput = {
  email?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type EmailStats = {
  __typename?: 'EmailStats';
  statsId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmailStatsStatus>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export enum EmailTemplateNames {
  Template1 = 'template1',
  Template2 = 'template2',
  Template3 = 'template3',
  Template4 = 'template4'
}

export type EmailUnsubscribe = {
  __typename?: 'EmailUnsubscribe';
  email?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type EmailUnsubscribeActionPayload = {
  __typename?: 'EmailUnsubscribeActionPayload';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** Tipo do device do usuário (mobile, tablet, desktop or notFound). */
export enum EnumAnalyticsDeviceKind {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
  NotFound = 'notFound'
}

export enum EnumAnalyticsEvent {
  Login = 'login',
  LoginDenied = 'login_denied',
  Logout = 'logout',
  LoginPcs = 'login_pcs',
  LoginAzureAd = 'login_azure_ad',
  LoginDeniedPcs = 'login_denied_pcs',
  LogoutPcs = 'logout_pcs',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  View = 'view',
  PageView = 'pageView',
  Share = 'share',
  Like = 'like',
  Download = 'download',
  DownloadDenied = 'download_denied',
  Search = 'search',
  RecoverPoints = 'recover_points',
  DownloadRegulation = 'download_regulation',
  DownloadPresentation = 'download_presentation'
}

/** Kind do documento que recebeu o evento: Ex: price_table */
export enum EnumAnalyticsParentKind {
  Live = 'live',
  User = 'user',
  Search = 'search',
  PageView = 'pageView',
  IncentiveCampaign = 'incentiveCampaign',
  PriceTable = 'price_table',
  Material = 'material',
  Video = 'video',
  Communication = 'communication',
  Regulation = 'regulation',
  Tanamao = 'tanamao',
  OfferMap = 'offer_map',
  Process = 'process',
  SalesArgument = 'sales_argument',
  DevicesComparator = 'devices_comparator',
  PartsMap = 'parts_map',
  SupportMaterials = 'support_materials',
  LinksUteis = 'links_uteis',
  News = 'news',
  Document = 'document',
  CompaniesMaterials = 'companies_materials',
  SeeMoreChannel = 'see_more_channel',
  SeeMoreRegion = 'see_more_region',
  SocialFeed = 'social_feed',
  CommunicationBackup = 'communication_backup',
  Pilulas = 'pilulas',
  ComunicacaoParaOCanal = 'comunicacao_para_o_canal',
  Matinais = 'matinais',
  MateriaisParaOPdv = 'materiais_para_o_pdv',
  PdvMap = 'pdv_map',
  Planogram = 'planogram',
  PdvPieces = 'pdv_pieces',
  PocketGuide = 'pocket_guide'
}

export enum EnumBannerImagesDisplay {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Both = 'both'
}

export enum EnumBannerStatus {
  Cancelled = 'cancelled',
  Published = 'published',
  Expired = 'expired'
}

export enum EnumBudgetAvailableAtChannels {
  LojaPropria = 'loja_propria',
  Revenda = 'revenda',
  Master = 'master',
  Varejo = 'varejo',
  Distribuicao = 'distribuicao'
}

export enum EnumBudgetInitialRegionalBudgetsAvailableAtChannels {
  LojaPropria = 'loja_propria',
  Revenda = 'revenda',
  Master = 'master',
  Varejo = 'varejo',
  Distribuicao = 'distribuicao'
}

export enum EnumBudgetInitialRegionalBudgetsRegion {
  Tsl = 'TSL',
  Tse = 'TSE',
  Tcn = 'TCN',
  Tsp = 'TSP',
  Tne = 'TNE'
}

export enum EnumBudgetInitialRegionalBudgetsStatus {
  Filled = 'filled',
  Viewed = 'viewed',
  Initial = 'initial',
  Finished = 'finished'
}

export enum EnumBudgetRegionalValuesRegion {
  Tsl = 'TSL',
  Tse = 'TSE',
  Tcn = 'TCN',
  Tsp = 'TSP',
  Tne = 'TNE'
}

export enum EnumBudgetStatus {
  Draft = 'draft',
  SentToRegionals = 'sentToRegionals',
  Finished = 'finished'
}

export enum EnumCampaignArchive {
  Naoarquivar = 'naoarquivar',
  Ummes = 'ummes',
  Tresmeses = 'tresmeses',
  Seismeses = 'seismeses',
  Umano = 'umano'
}

export enum EnumCampaignProductType {
  Pre = 'pre',
  Pos = 'pos',
  Controle = 'controle',
  Fixo = 'fixo',
  Live = 'live',
  Wttx = 'wttx',
  Smb = 'smb',
  Institucional = 'institucional',
  Outros = 'outros'
}

export enum EnumDeliveryOrderOrigin {
  Independent = 'independent',
  Production = 'production'
}

export enum EnumDeliveryOrderStatus {
  Open = 'open',
  Finished = 'finished'
}

export enum EnumDeviceStatus {
  Active = 'active',
  Deleted = 'deleted'
}

export enum EnumEmailStatsStatus {
  Open = 'open',
  Spam = 'spam',
  Delivered = 'delivered',
  Undelivered = 'undelivered'
}

export enum EnumEmailStatus {
  Scheduled = 'scheduled',
  Sent = 'sent',
  Draft = 'draft',
  Error = 'error',
  Sending = 'sending'
}

export enum EnumEmailTemplateName {
  Template1 = 'template1',
  Template2 = 'template2',
  Template3 = 'template3',
  Template4 = 'template4',
  Template5 = 'template5',
  TemplateConsumer01 = 'templateConsumer01',
  TemplateConsumer02 = 'templateConsumer02',
  TemplateConsumer03 = 'templateConsumer03',
  TemplateConsumer04 = 'templateConsumer04',
  TemplateConsumer05 = 'templateConsumer05',
  TemplateCorp01 = 'templateCorp01',
  TemplateCorp02 = 'templateCorp02',
  TemplateCorp03 = 'templateCorp03',
  TemplateCorp04 = 'templateCorp04',
  TemplateCorp05 = 'templateCorp05',
  TemplateCorp06 = 'templateCorp06',
  TemplateCorp07 = 'templateCorp07',
  TemplateCorp08 = 'templateCorp08',
  TemplateCorp09 = 'templateCorp09',
  TemplateCorp10 = 'templateCorp10',
  PlainHtml = 'plainHTML'
}

export enum EnumEventLiveKind {
  Live = 'live'
}

export enum EnumEventLiveStatus {
  Editing = 'editing',
  WaitingApproval = 'waiting_approval',
  Approved = 'approved',
  Disapproved = 'disapproved',
  Cancelled = 'cancelled',
  Published = 'published',
  PublishNow = 'publish_now',
  Expired = 'expired'
}

/** O tipo de uso do material. Ex: "video_poster" */
export enum EnumFileKind {
  CardThumb = 'card_thumb',
  VideoMp4 = 'video_mp4',
  VideoThumb = 'video_thumb',
  VideoBanner = 'video_banner',
  Pdf = 'pdf',
  Notification = 'notification',
  Mailing = 'mailing',
  NotificationImage = 'notification_image',
  EmailAsset = 'email_asset',
  BannerImage = 'banner_image',
  AnyImage = 'any_image',
  AnyDoc = 'any_doc',
  ProfileImage = 'profile_image',
  Xlsx = 'xlsx',
  BannerMaterialMobile = 'banner_material_mobile',
  BannerMaterialDesktop = 'banner_material_desktop',
  SocialFeedImage = 'social_feed_image',
  SocialFeedVideo = 'social_feed_video',
  SensitiveFile = 'sensitive_file',
  Places = 'places',
  SmbFtp = 'smb_ftp',
  UserCreditOperation = 'user_credit_operation',
  StructuralFiles = 'structural_files',
  Cad = 'cad',
  StructuralImages = 'structural_images'
}

export enum EnumFolderKind {
  Favorite = 'favorite'
}

export enum EnumFutureOperationOperation {
  Credit = 'credit',
  Reserve = 'reserve',
  Debit = 'debit',
  Release = 'release',
  Chargeback = 'chargeback',
  Reverse = 'reverse',
  Expired = 'expired',
  DisabledExpired = 'disabledExpired',
  ManualCredit = 'manualCredit',
  ManualRevertReserved = 'manualRevertReserved'
}

export enum EnumImportUsersPlanOrigin {
  Sigtim = 'SIGTIM',
  Radar = 'RADAR',
  Import = 'IMPORT',
  Basevarejo = 'BASEVAREJO'
}

export enum EnumImportUsersPlanStatusImportUser {
  Pending = 'pending',
  Processed = 'processed',
  Processing = 'processing',
  Error = 'error'
}

export enum EnumInventoryWithDrawGradeMode {
  FromProduction = 'fromProduction',
  FromProductionWithCustomQuantity = 'fromProductionWithCustomQuantity',
  Independent = 'independent'
}

export enum EnumMaterialCommunicationItemsConfigKind {
  Email = 'email',
  Notification = 'notification',
  Bulletin = 'bulletin'
}

export enum EnumMaterialEditorKind {
  Html = 'html',
  Link = 'link',
  Table = 'table'
}

export enum EnumMaterialKind {
  PriceTable = 'price_table',
  Material = 'material',
  Video = 'video',
  Communication = 'communication',
  Regulation = 'regulation',
  Tanamao = 'tanamao',
  OfferMap = 'offer_map',
  Process = 'process',
  SalesArgument = 'sales_argument',
  DevicesComparator = 'devices_comparator',
  PartsMap = 'parts_map',
  SupportMaterials = 'support_materials',
  LinksUteis = 'links_uteis',
  News = 'news',
  Document = 'document',
  CompaniesMaterials = 'companies_materials',
  SeeMoreChannel = 'see_more_channel',
  SeeMoreRegion = 'see_more_region',
  SocialFeed = 'social_feed',
  CommunicationBackup = 'communication_backup',
  Pilulas = 'pilulas',
  Matinais = 'matinais',
  ComunicacaoParaOCanal = 'comunicacao_para_o_canal',
  MateriaisParaOPdv = 'materiais_para_o_pdv',
  PdvMap = 'pdv_map',
  Planogram = 'planogram',
  PdvPieces = 'pdv_pieces',
  PocketGuide = 'pocket_guide'
}

export enum EnumMaterialPreviousStatus {
  Editing = 'editing',
  WaitingApproval = 'waiting_approval',
  Approved = 'approved',
  Disapproved = 'disapproved',
  Cancelled = 'cancelled',
  Published = 'published',
  PublishNow = 'publish_now',
  Expired = 'expired'
}

export enum EnumMaterialStatus {
  Editing = 'editing',
  WaitingApproval = 'waiting_approval',
  Approved = 'approved',
  Disapproved = 'disapproved',
  Cancelled = 'cancelled',
  Published = 'published',
  PublishNow = 'publish_now',
  Expired = 'expired'
}

export enum EnumPlaceAnalyticsCluster {
  A = 'A',
  B = 'B',
  C = 'C',
  Conceito = 'conceito',
  Prime = 'prime',
  Standard = 'standard',
  CurvaA = 'curva_a',
  CurvaB = 'curva_b',
  CurvaC = 'curva_c',
  CurvaD = 'curva_d',
  CurvaE = 'curva_e'
}

export enum EnumPlaceAnalyticsFoco_De_Comunicacao {
  Controle = 'controle',
  Pos = 'pos',
  PosPuroAltoValor = 'pos_puro_alto_valor',
  PosPuroControle = 'pos_puro_controle'
}

export enum EnumPlaceAnalyticsLocalizacao {
  Rua = 'rua',
  Galeria = 'galeria',
  Shopping = 'shopping'
}

export enum EnumPlaceAnalyticsLogType {
  Login = 'LOGIN'
}

export enum EnumPlaceAnalyticsModelo_De_Loja {
  LojaAntiga = 'loja_antiga',
  Compacta = 'compacta',
  Experiencia = 'experiencia',
  Store = 'store',
  LojaHighDigital = 'loja_high_digital',
  LojaPrimeDigital = 'loja_prime_digital',
  QuiosqueFechado = 'quiosque_fechado',
  QuiosqueAberto = 'quiosque_aberto',
  Lounge = 'lounge',
  RevendaAntiga = 'revenda_antiga',
  RevendaAntigaFacelift = 'revenda_antiga_facelift',
  RevendaHigh = 'revenda_high',
  RevendaHighDigital = 'revenda_high_digital',
  RevendaExpress = 'revenda_express',
  ModeloFuturo = 'modelo_futuro'
}

export enum EnumPlaceAnalyticsPlatformName {
  PwaPrecificador = 'PWA_PRECIFICADOR',
  PwaPositivador = 'PWA_POSITIVADOR',
  ComparadorAparelho = 'COMPARADOR_APARELHO',
  NaoDisponibilizado = 'NAO_DISPONIBILIZADO'
}

export enum EnumPlaceAnalyticsProdutos_Comercializados {
  Pre = 'pre',
  Pos = 'pos',
  Controle = 'controle',
  Fixo = 'fixo',
  Live = 'live',
  Wttx = 'wttx',
  Smb = 'smb',
  Seguro = 'seguro',
  TradeIn = 'trade_in',
  Acessorios = 'acessorios'
}

export enum EnumPlaceAnalyticsRevestimento_Externo {
  AcmCinza = 'acm_cinza',
  AcmBranca = 'acm_branca',
  AcmAzul = 'acm_azul',
  Porcelanato = 'porcelanato',
  Marmorato = 'marmorato',
  Pintura = 'pintura'
}

export enum EnumPlaceAnalyticsTipo_De_Fachada {
  FlutuanteTransparente = 'flutuante_transparente',
  FlutuanteAzul = 'flutuante_azul',
  Acm = 'acm',
  Nicho = 'nicho',
  Pescador = 'pescador'
}

export enum EnumPlaceAnalyticsTipo_De_Loja {
  Cessao = 'cessao',
  SapSetorDeAtendimentoPresencial = 'sap__setor_de_atendimento_presencial'
}

export enum EnumPlaceAnalyticsType {
  EscritorioRegional = 'escritorio_regional',
  CentroDeDistribuicaoLogistico = 'centro_de_distribuicao_logistico',
  LojaPropria = 'loja_propria',
  Revenda = 'revenda',
  Master = 'master',
  Varejo = 'varejo',
  Distribuicao = 'distribuicao'
}

export enum EnumPlaceAnalyticsUltima_Modificacao {
  Abertura = 'abertura',
  Reforma = 'reforma',
  ReformaParcial = 'reforma_parcial',
  TrocaDePonto = 'troca_de_ponto',
  Expansao = 'expansao',
  Facelift = 'facelift'
}

export enum EnumPlaceAnalyticsVideo_WallKind {
  StandAlone = 'stand_alone',
  Testeira = 'testeira',
  PainelInterno = 'painel_interno',
  Portico = 'portico',
  TotemExterno = 'totem_externo',
  TotemInterno = 'totem_interno',
  VitrineEmbutido = 'vitrine_embutido',
  VitrineAquario = 'vitrine_aquario'
}

export enum EnumPlaceAnalyticsVideo_WallOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum EnumPlaceCluster {
  A = 'A',
  B = 'B',
  C = 'C',
  Conceito = 'conceito',
  Prime = 'prime',
  Standard = 'standard',
  CurvaA = 'curva_a',
  CurvaB = 'curva_b',
  CurvaC = 'curva_c',
  CurvaD = 'curva_d',
  CurvaE = 'curva_e'
}

export enum EnumPlaceFoco_De_Comunicacao {
  Controle = 'controle',
  Pos = 'pos',
  PosPuroAltoValor = 'pos_puro_alto_valor',
  PosPuroControle = 'pos_puro_controle'
}

export enum EnumPlaceLocalizacao {
  Rua = 'rua',
  Galeria = 'galeria',
  Shopping = 'shopping'
}

export enum EnumPlaceModelo_De_Loja {
  LojaAntiga = 'loja_antiga',
  Compacta = 'compacta',
  Experiencia = 'experiencia',
  Store = 'store',
  LojaHighDigital = 'loja_high_digital',
  LojaPrimeDigital = 'loja_prime_digital',
  QuiosqueFechado = 'quiosque_fechado',
  QuiosqueAberto = 'quiosque_aberto',
  Lounge = 'lounge',
  RevendaAntiga = 'revenda_antiga',
  RevendaAntigaFacelift = 'revenda_antiga_facelift',
  RevendaHigh = 'revenda_high',
  RevendaHighDigital = 'revenda_high_digital',
  RevendaExpress = 'revenda_express',
  ModeloFuturo = 'modelo_futuro'
}

export enum EnumPlaceProdutos_Comercializados {
  Pre = 'pre',
  Pos = 'pos',
  Controle = 'controle',
  Fixo = 'fixo',
  Live = 'live',
  Wttx = 'wttx',
  Smb = 'smb',
  Seguro = 'seguro',
  TradeIn = 'trade_in',
  Acessorios = 'acessorios'
}

export enum EnumPlaceRevestimento_Externo {
  AcmCinza = 'acm_cinza',
  AcmBranca = 'acm_branca',
  AcmAzul = 'acm_azul',
  Porcelanato = 'porcelanato',
  Marmorato = 'marmorato',
  Pintura = 'pintura'
}

export enum EnumPlaceTipo_De_Fachada {
  FlutuanteTransparente = 'flutuante_transparente',
  FlutuanteAzul = 'flutuante_azul',
  Acm = 'acm',
  Nicho = 'nicho',
  Pescador = 'pescador'
}

export enum EnumPlaceTipo_De_Loja {
  Cessao = 'cessao',
  SapSetorDeAtendimentoPresencial = 'sap__setor_de_atendimento_presencial'
}

export enum EnumPlaceType {
  EscritorioRegional = 'escritorio_regional',
  CentroDeDistribuicaoLogistico = 'centro_de_distribuicao_logistico',
  LojaPropria = 'loja_propria',
  Revenda = 'revenda',
  Master = 'master',
  Varejo = 'varejo',
  Distribuicao = 'distribuicao'
}

export enum EnumPlaceUltima_Modificacao {
  Abertura = 'abertura',
  Reforma = 'reforma',
  ReformaParcial = 'reforma_parcial',
  TrocaDePonto = 'troca_de_ponto',
  Expansao = 'expansao',
  Facelift = 'facelift'
}

export enum EnumPlaceVideo_WallKind {
  StandAlone = 'stand_alone',
  Testeira = 'testeira',
  PainelInterno = 'painel_interno',
  Portico = 'portico',
  TotemExterno = 'totem_externo',
  TotemInterno = 'totem_interno',
  VitrineEmbutido = 'vitrine_embutido',
  VitrineAquario = 'vitrine_aquario'
}

export enum EnumPlaceVideo_WallOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export enum EnumPositivatorChannels {
  LojaPropriaXLojaAntiga = 'loja_propria_X_loja_antiga',
  LojaPropriaXCompacta = 'loja_propria_X_compacta',
  LojaPropriaXExperiencia = 'loja_propria_X_experiencia',
  LojaPropriaXStore = 'loja_propria_X_store',
  LojaPropriaXLojaHighDigital = 'loja_propria_X_loja_high_digital',
  LojaPropriaXLojaPrimeDigital = 'loja_propria_X_loja_prime_digital',
  LojaPropriaXQuiosqueFechado = 'loja_propria_X_quiosque_fechado',
  LojaPropriaXQuiosqueAberto = 'loja_propria_X_quiosque_aberto',
  LojaPropriaXLounge = 'loja_propria_X_lounge',
  RevendaXRevendaAntiga = 'revenda_X_revenda_antiga',
  RevendaXRevendaAntigaFacelift = 'revenda_X_revenda_antiga_facelift',
  RevendaXRevendaHigh = 'revenda_X_revenda_high',
  RevendaXRevendaHighDigital = 'revenda_X_revenda_high_digital',
  RevendaXRevendaExpress = 'revenda_X_revenda_express',
  RevendaXQuiosqueFechado = 'revenda_X_quiosque_fechado',
  RevendaXQuiosqueAberto = 'revenda_X_quiosque_aberto',
  RevendaXLounge = 'revenda_X_lounge',
  Master = 'master',
  Varejo = 'varejo',
  Distribuicao = 'distribuicao'
}

export enum EnumPositivatorItemStatus {
  Pending = 'pending',
  Positivated = 'positivated',
  Removed = 'removed'
}

export enum EnumPricingTableStatus {
  Active = 'active',
  Inactive = 'inactive',
  Scheduled = 'scheduled',
  Deprecated = 'deprecated'
}

export enum EnumProductCategoria {
  Estrutural = 'estrutural',
  Merchandising = 'merchandising'
}

export enum EnumProductionItemQuantityInputMode {
  ByProduct = 'byProduct',
  ByDestination = 'byDestination'
}

export enum EnumProductionItemSupplierDeliveryDestinationType {
  CentroDistribuicaoLogistica = 'centro_distribuicao_logistica',
  EscritorioRegional = 'escritorio_regional',
  Distribuicao = 'distribuicao',
  AgenciaVarejo = 'agencia_varejo',
  Pdv = 'pdv'
}

export enum EnumProductionParsedItemsQuantityInputMode {
  ByProduct = 'byProduct',
  ByDestination = 'byDestination'
}

export enum EnumProductionParsedItemsSupplierDeliveryDestinationType {
  CentroDistribuicaoLogistica = 'centro_distribuicao_logistica',
  EscritorioRegional = 'escritorio_regional',
  Distribuicao = 'distribuicao',
  AgenciaVarejo = 'agencia_varejo',
  Pdv = 'pdv'
}

export enum EnumProductionProductionItemsQuantityInputMode {
  ByProduct = 'byProduct',
  ByDestination = 'byDestination'
}

export enum EnumProductionProductionItemsSupplierDeliveryDestinationType {
  CentroDistribuicaoLogistica = 'centro_distribuicao_logistica',
  EscritorioRegional = 'escritorio_regional',
  Distribuicao = 'distribuicao',
  AgenciaVarejo = 'agencia_varejo',
  Pdv = 'pdv'
}

export enum EnumProductionStatus {
  Draft = 'draft',
  Sent = 'sent',
  Grouped = 'grouped',
  Canceled = 'canceled'
}

export enum EnumProductTipo_Multiplicador {
  Pdv = 'pdv',
  Mobiliario = 'mobiliario',
  Posicao = 'posicao',
  Nulo = 'nulo'
}

export enum EnumRegionalBudgetAvailableAtChannels {
  LojaPropria = 'loja_propria',
  Revenda = 'revenda',
  Master = 'master',
  Varejo = 'varejo',
  Distribuicao = 'distribuicao'
}

export enum EnumRegionalBudgetParseItemsPayloadByDestinationDeliveryPlaceType {
  CentroDistribuicaoLogistica = 'centro_distribuicao_logistica',
  EscritorioRegional = 'escritorio_regional',
  Distribuicao = 'distribuicao',
  AgenciaVarejo = 'agencia_varejo',
  Pdv = 'pdv'
}

export enum EnumRegionalBudgetProductionItemsQuantityByDestinationOutputDeliveryPlaceType {
  CentroDistribuicaoLogistica = 'centro_distribuicao_logistica',
  EscritorioRegional = 'escritorio_regional',
  Distribuicao = 'distribuicao',
  AgenciaVarejo = 'agencia_varejo',
  Pdv = 'pdv'
}

export enum EnumRegionalBudgetProductionItemsQuantityInputMode {
  ByProduct = 'byProduct',
  ByDestination = 'byDestination'
}

export enum EnumRegionalBudgetProductionItemsSupplierDeliveryDestinationType {
  CentroDistribuicaoLogistica = 'centro_distribuicao_logistica',
  EscritorioRegional = 'escritorio_regional',
  Distribuicao = 'distribuicao',
  AgenciaVarejo = 'agencia_varejo',
  Pdv = 'pdv'
}

export enum EnumRegionalBudgetRegion {
  Tsl = 'TSL',
  Tse = 'TSE',
  Tcn = 'TCN',
  Tsp = 'TSP',
  Tne = 'TNE'
}

export enum EnumRegionalBudgetStatus {
  Filled = 'filled',
  Viewed = 'viewed',
  Initial = 'initial',
  Finished = 'finished'
}

export enum EnumRoleGroupIdmType {
  Region = 'region',
  Uf = 'uf'
}

export enum EnumShareKind {
  Material = 'material',
  IncentiveCampaign = 'incentiveCampaign'
}

export enum EnumStructuralStage {
  AprovacaoProjetoCriacao = 'APROVACAO_PROJETO_CRIACAO',
  Briefing = 'BRIEFING',
  AgendamentoLevantamentoCadastral = 'AGENDAMENTO_LEVANTAMENTO_CADASTRAL',
  LevantamentoCadastral = 'LEVANTAMENTO_CADASTRAL',
  EstudoLayout = 'ESTUDO_LAYOUT',
  ProjetoExecutivo = 'PROJETO_EXECUTIVO',
  Rrt = 'RRT',
  ThreeDs = 'THREE_DS',
  ProjetoComplementar = 'PROJETO_COMPLEMENTAR',
  AgendamentoVistoriaMeioObra = 'AGENDAMENTO_VISTORIA_MEIO_OBRA',
  RelatorioMeioObra = 'RELATORIO_MEIO_OBRA',
  AgendamentoVistoriaFinalObra = 'AGENDAMENTO_VISTORIA_FINAL_OBRA',
  RelatorioFinalObra = 'RELATORIO_FINAL_OBRA',
  Aprovacao = 'APROVACAO'
}

export enum EnumStructuralStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Finished = 'FINISHED'
}

export type EstruturaDeLojaWithProduct = {
  __typename?: 'EstruturaDeLojaWithProduct';
  productId: Scalars['String'];
  codeTM: Scalars['String'];
  productName: Scalars['String'];
  amount: Scalars['Int'];
  product: Product;
};

export type EventDateOperatorsFilterFindManyEventLiveInput = {
  /** Data do evento */
  gt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  gte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  ne?: Maybe<Scalars['Date']>;
  /** Data do evento */
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  /** Data do evento */
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type EventDateOperatorsFilterFindOneEventLiveInput = {
  /** Data do evento */
  gt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  gte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  ne?: Maybe<Scalars['Date']>;
  /** Data do evento */
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  /** Data do evento */
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type EventDateOperatorsFilterUpdateOneEventLiveInput = {
  /** Data do evento */
  gt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  gte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lt?: Maybe<Scalars['Date']>;
  /** Data do evento */
  lte?: Maybe<Scalars['Date']>;
  /** Data do evento */
  ne?: Maybe<Scalars['Date']>;
  /** Data do evento */
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  /** Data do evento */
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type EventLive = {
  __typename?: 'EventLive';
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories: Array<Maybe<Category>>;
  owner?: Maybe<Scalars['String']>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate?: Maybe<Scalars['Date']>;
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumEventLiveKind>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  likes?: Maybe<Likes>;
  changedBy?: Maybe<EventLiveChangedBy>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** Retorna true se o item estiver salvo como Pin */
  pinned: Scalars['Boolean'];
  files?: Maybe<Array<Maybe<File>>>;
  user?: Maybe<LiveAuthor>;
  views?: Maybe<MaterialViews>;
  sameCategoryItems?: Maybe<MaterialPagination>;
};

export type EventLiveFilesArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type EventLiveSameCategoryItemsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyMaterialInput>;
  sort?: Maybe<SortFindManyMaterialInput>;
};

export type EventLiveChangedBy = {
  __typename?: 'EventLiveChangedBy';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type EventLiveChangedByInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type EventLivePagination = {
  __typename?: 'EventLivePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<EventLive>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type EventOperatorsFilterAnalyticsInput = {
  gt?: Maybe<EnumAnalyticsEvent>;
  gte?: Maybe<EnumAnalyticsEvent>;
  lt?: Maybe<EnumAnalyticsEvent>;
  lte?: Maybe<EnumAnalyticsEvent>;
  ne?: Maybe<EnumAnalyticsEvent>;
  in?: Maybe<Array<Maybe<EnumAnalyticsEvent>>>;
  nin?: Maybe<Array<Maybe<EnumAnalyticsEvent>>>;
};

export type EventOperatorsFilterFindManyAnalyticsInput = {
  gt?: Maybe<EnumAnalyticsEvent>;
  gte?: Maybe<EnumAnalyticsEvent>;
  lt?: Maybe<EnumAnalyticsEvent>;
  lte?: Maybe<EnumAnalyticsEvent>;
  ne?: Maybe<EnumAnalyticsEvent>;
  in?: Maybe<Array<Maybe<EnumAnalyticsEvent>>>;
  nin?: Maybe<Array<Maybe<EnumAnalyticsEvent>>>;
};

export type File = {
  __typename?: 'File';
  origin?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  parentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O tipo de uso do material. Ex: "video_poster" */
  kind?: Maybe<EnumFileKind>;
  title?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  /** A url original do arquivo, sem token de autorização. */
  url?: Maybe<Scalars['String']>;
  destinationKey?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  sizeDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  imageInfo?: Maybe<FileImageInfo>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  ownerContent?: Maybe<User>;
  /** A URL do arquivo assinada com um token de autorização */
  signedUrl?: Maybe<Scalars['String']>;
  /** A URL do arquivo assinada com um token de autorização */
  downloadUrl?: Maybe<Scalars['String']>;
};

export type FileImageInfo = {
  __typename?: 'FileImageInfo';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type FileImageInfoInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

/** List of items with pagination. */
export type FilePagination = {
  __typename?: 'FilePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<File>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Filter = {
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  validityEnum?: Maybe<ValidityEnum>;
  availableAtRegions?: Maybe<Scalars['String']>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
  parentId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  nome_fantasia?: Maybe<Scalars['String']>;
  codigo_sap?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  razao_social?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  hasImage?: Maybe<Scalars['Boolean']>;
  hasLegalText?: Maybe<Scalars['Boolean']>;
  valid?: Maybe<Scalars['String']>;
  /** Remove da listagem os items já entregues que o usuário "arquivou" */
  ignoreArchived?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inDepositPath?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allDepositPath?: Maybe<Array<Maybe<Scalars['String']>>>;
  placeType?: Maybe<Array<Maybe<EnumPlaceType>>>;
  nome?: Maybe<Scalars['String']>;
  escritorio_resp?: Maybe<Scalars['String']>;
};

export type FilterAnalyticsInput = {
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  day?: Maybe<Scalars['String']>;
  /** Nome da entidade que está recebendo a ação, pode exemplo "IncentiveCampaign", "Material" ou "User" */
  entityName?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  userId?: Maybe<Scalars['String']>;
  /** Nome do usuário que executou a ação */
  userName?: Maybe<Scalars['String']>;
  /** Id do Canal (context.user.channels[0]) do usuário que executou a ação */
  userChannel?: Maybe<Scalars['String']>;
  /** Id do RoleGroup (context.user.roleGroup._id) do usuário que executou a ação */
  userRoleGroup?: Maybe<Scalars['String']>;
  /** Id da Regional (context.user.region._id) do usuário que executou a ação */
  userRegion?: Maybe<Scalars['String']>;
  userGrupoEconomicoPDV?: Maybe<Scalars['String']>;
  userRazaoSocialPDV?: Maybe<Scalars['String']>;
  userResponsavelUOPDV?: Maybe<Scalars['String']>;
  userIdArea?: Maybe<Scalars['String']>;
  /** Titulo do material sobre a qual o evento ocorreu. Por exemplo: 'Campanha de incentivo abc' ou 'Tabela de preços XYZ' */
  materialTitle?: Maybe<Scalars['String']>;
  materialCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  event?: Maybe<EnumAnalyticsEvent>;
  /** Kind do documento que recebeu o evento: Ex: price_table */
  parentKind?: Maybe<EnumAnalyticsParentKind>;
  parentId?: Maybe<Scalars['String']>;
  /** Quando o evento é "search", label é o termo buscado; quando o event é "pageView" é o titulo da página; etc */
  label?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  /** route da página acessada quando o evento é do tipo pageView */
  route?: Maybe<Scalars['String']>;
  /** Guarda o header 'User-Agent' recebido na requisição que originou o evento */
  device?: Maybe<Scalars['String']>;
  /** Tipo do device do usuário (mobile, tablet, desktop or notFound). */
  deviceKind?: Maybe<EnumAnalyticsDeviceKind>;
  ip?: Maybe<Scalars['String']>;
  materialCreatedAt?: Maybe<Scalars['Date']>;
  materialPublishedAt?: Maybe<Scalars['Date']>;
  originalMaterial?: Maybe<Scalars['String']>;
  /** Salva o CPF do usuário que está tentando logar e tem o login mal sucedido */
  userDenied?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterAnalyticsInput>;
  OR?: Maybe<Array<FilterAnalyticsInput>>;
  AND?: Maybe<Array<FilterAnalyticsInput>>;
};

export type FilterAuthorInput = {
  channel?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type FilterFindManyAnalyticsInput = {
  /** O dia que o evento ocorreu, no formato YYYY-MM-DD */
  day?: Maybe<Scalars['String']>;
  /** Nome da entidade que está recebendo a ação, pode exemplo "IncentiveCampaign", "Material" ou "User" */
  entityName?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  userId?: Maybe<Scalars['String']>;
  /** Nome do usuário que executou a ação */
  userName?: Maybe<Scalars['String']>;
  /** Id do Canal (context.user.channels[0]) do usuário que executou a ação */
  userChannel?: Maybe<Scalars['String']>;
  /** Id do RoleGroup (context.user.roleGroup._id) do usuário que executou a ação */
  userRoleGroup?: Maybe<Scalars['String']>;
  /** Id da Regional (context.user.region._id) do usuário que executou a ação */
  userRegion?: Maybe<Scalars['String']>;
  userGrupoEconomicoPDV?: Maybe<Scalars['String']>;
  userRazaoSocialPDV?: Maybe<Scalars['String']>;
  userResponsavelUOPDV?: Maybe<Scalars['String']>;
  userIdArea?: Maybe<Scalars['String']>;
  /** Titulo do material sobre a qual o evento ocorreu. Por exemplo: 'Campanha de incentivo abc' ou 'Tabela de preços XYZ' */
  materialTitle?: Maybe<Scalars['String']>;
  materialCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  event?: Maybe<EnumAnalyticsEvent>;
  /** Kind do documento que recebeu o evento: Ex: price_table */
  parentKind?: Maybe<EnumAnalyticsParentKind>;
  parentId?: Maybe<Scalars['String']>;
  /** Quando o evento é "search", label é o termo buscado; quando o event é "pageView" é o titulo da página; etc */
  label?: Maybe<Scalars['String']>;
  referer?: Maybe<Scalars['String']>;
  /** route da página acessada quando o evento é do tipo pageView */
  route?: Maybe<Scalars['String']>;
  /** Guarda o header 'User-Agent' recebido na requisição que originou o evento */
  device?: Maybe<Scalars['String']>;
  /** Tipo do device do usuário (mobile, tablet, desktop or notFound). */
  deviceKind?: Maybe<EnumAnalyticsDeviceKind>;
  ip?: Maybe<Scalars['String']>;
  materialCreatedAt?: Maybe<Scalars['Date']>;
  materialPublishedAt?: Maybe<Scalars['Date']>;
  originalMaterial?: Maybe<Scalars['String']>;
  /** Salva o CPF do usuário que está tentando logar e tem o login mal sucedido */
  userDenied?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyAnalyticsInput>;
  OR?: Maybe<Array<FilterFindManyAnalyticsInput>>;
  AND?: Maybe<Array<FilterFindManyAnalyticsInput>>;
};

export type FilterFindManyAreaLojaInput = {
  position?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyAreaLojaInput>;
  OR?: Maybe<Array<FilterFindManyAreaLojaInput>>;
  AND?: Maybe<Array<FilterFindManyAreaLojaInput>>;
};

export type FilterFindManyBannerInput = {
  owner?: Maybe<Scalars['String']>;
  validity?: Maybe<BannerValidityInput>;
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<BannerImagesInput>>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyBannerInput>;
  OR?: Maybe<Array<FilterFindManyBannerInput>>;
  AND?: Maybe<Array<FilterFindManyBannerInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterFindManyBudgetInput = {
  status?: Maybe<EnumBudgetStatus>;
  /** Titulo da verba. Exemplo "Verba para campanha de natal" */
  title?: Maybe<Scalars['String']>;
  /** Valor total da verba a ser distribuida entre as regionais. */
  value?: Maybe<Scalars['Float']>;
  /** Quanto cada regional poderá usar para produzir. */
  regionalValues?: Maybe<Array<Maybe<BudgetRegionalValuesInput>>>;
  allowedLayouts?: Maybe<Array<Maybe<BudgetAllowedLayoutsInput>>>;
  /** se true os itens criados na entidade RegionalBudget serão apenas para registro. Regionais não fazem produção pelo portal */
  isRegionalProduction?: Maybe<Scalars['Boolean']>;
  /** Canais (tipos) de lojas que poderão receber os produtos dessa verba. */
  availableAtChannels?: Maybe<Array<Maybe<EnumBudgetAvailableAtChannels>>>;
  initialRegionalBudgets?: Maybe<
    Array<Maybe<BudgetInitialRegionalBudgetsInput>>
  >;
  /** ID da producão gerada a partir dessa verba */
  usedInProductionId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyBudgetInput>;
  OR?: Maybe<Array<FilterFindManyBudgetInput>>;
  AND?: Maybe<Array<FilterFindManyBudgetInput>>;
};

export type FilterFindManyCampaignInput = {
  code?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  productType?: Maybe<EnumCampaignProductType>;
  releaseDate?: Maybe<Scalars['Date']>;
  archivingDone?: Maybe<Scalars['Boolean']>;
  archive?: Maybe<EnumCampaignArchive>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCampaignInput>;
  OR?: Maybe<Array<FilterFindManyCampaignInput>>;
  AND?: Maybe<Array<FilterFindManyCampaignInput>>;
};

export type FilterFindManyCategoryInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCategoryInput>;
  OR?: Maybe<Array<FilterFindManyCategoryInput>>;
  AND?: Maybe<Array<FilterFindManyCategoryInput>>;
};

export type FilterFindManyChannelInput = {
  _id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyChannelInput>;
  OR?: Maybe<Array<FilterFindManyChannelInput>>;
  AND?: Maybe<Array<FilterFindManyChannelInput>>;
};

export type FilterFindManyCommentInput = {
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  replyRecipientId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCommentInput>;
  OR?: Maybe<Array<FilterFindManyCommentInput>>;
  AND?: Maybe<Array<FilterFindManyCommentInput>>;
};

export type FilterFindManyContractInput = {
  nr_clm?: Maybe<Scalars['String']>;
  nr_contrato?: Maybe<Scalars['String']>;
  inicio_vigencia?: Maybe<Scalars['Date']>;
  final_vigencia?: Maybe<Scalars['Date']>;
  valor_total?: Maybe<Scalars['Float']>;
  valor_utilizado?: Maybe<Scalars['Float']>;
  nr_contrato_derivado?: Maybe<Scalars['String']>;
  inicio_vigencia_derivado?: Maybe<Scalars['Date']>;
  final_vigencia_derivado?: Maybe<Scalars['Date']>;
  valor_total_derivado?: Maybe<Scalars['Float']>;
  valor_utilizado_derivado?: Maybe<Scalars['Float']>;
  margem_saldo_derivado?: Maybe<Scalars['Float']>;
  natureza_contrato?: Maybe<Scalars['String']>;
  natureza_verba?: Maybe<Scalars['String']>;
  nrs_cic?: Maybe<Array<Maybe<ContractNrs_CicInput>>>;
  prazo_pagto?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyContractInput>;
  OR?: Maybe<Array<FilterFindManyContractInput>>;
  AND?: Maybe<Array<FilterFindManyContractInput>>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeliveryOrderInput = {
  title?: Maybe<Scalars['String']>;
  status?: Maybe<EnumDeliveryOrderStatus>;
  groups?: Maybe<Array<Maybe<DeliveryOrderGroupsInput>>>;
  records?: Maybe<Array<Maybe<DeliveryOrderRecordsInput>>>;
  region?: Maybe<Scalars['String']>;
  origin?: Maybe<EnumDeliveryOrderOrigin>;
  createdAt?: Maybe<Scalars['Date']>;
  createdById?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedById?: Maybe<Scalars['String']>;
  executedAt?: Maybe<Scalars['Date']>;
  executedById?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyDeliveryOrderInput>;
  OR?: Maybe<Array<FilterFindManyDeliveryOrderInput>>;
  AND?: Maybe<Array<FilterFindManyDeliveryOrderInput>>;
};

export type FilterFindManyDeviceInput = {
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  inclusion?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  pricingTable?: Maybe<Scalars['String']>;
  featured?: Maybe<Array<Maybe<Scalars['String']>>>;
  comments?: Maybe<Scalars['String']>;
  chip?: Maybe<Scalars['String']>;
  nfc?: Maybe<Scalars['JSON']>;
  offer_date?: Maybe<Scalars['Date']>;
  launch_date?: Maybe<Scalars['Date']>;
  /** Código do aparelho (cliente chama de código do fornecedor). Código retornado pelo device. Ex: "MWC22" */
  codeProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
  codeTim?: Maybe<Array<Maybe<Scalars['String']>>>;
  releaseDateOf?: Maybe<Scalars['Date']>;
  availableColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  eSim?: Maybe<Scalars['Boolean']>;
  dualSim?: Maybe<Scalars['Boolean']>;
  connectionType5G?: Maybe<Scalars['Boolean']>;
  ram?: Maybe<Scalars['JSON']>;
  internalMemory?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  windowSize?: Maybe<Scalars['String']>;
  windowResolution?: Maybe<Scalars['String']>;
  cameraMegaPixels?: Maybe<Scalars['String']>;
  cameraFrontal?: Maybe<Scalars['JSON']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  legalText?: Maybe<Scalars['String']>;
  status?: Maybe<EnumDeviceStatus>;
  type?: Maybe<Scalars['String']>;
  devicePlan?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  flags?: Maybe<Scalars['String']>;
  operationalSystem?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  connectionType?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  expandableMemory?: Maybe<Scalars['String']>;
  pixelDensity?: Maybe<Scalars['JSON']>;
  colors?: Maybe<Scalars['String']>;
  cameraResolution?: Maybe<Scalars['String']>;
  cameraResolutionBy?: Maybe<Scalars['String']>;
  cameraOpeningCapacity?: Maybe<Scalars['JSON']>;
  cameraStabilization?: Maybe<Scalars['Boolean']>;
  cameraAutofocus?: Maybe<Scalars['Boolean']>;
  cameraOpticalZoom?: Maybe<Scalars['Boolean']>;
  cameraDigitalZoom?: Maybe<Scalars['Boolean']>;
  cameraFlash?: Maybe<Scalars['Boolean']>;
  cameraHdr?: Maybe<Scalars['Boolean']>;
  cameraLocation?: Maybe<Scalars['Boolean']>;
  cameraFacialDetection?: Maybe<Scalars['Boolean']>;
  videoResolution?: Maybe<Scalars['String']>;
  videoStabilization?: Maybe<Scalars['Boolean']>;
  videoAutofocus?: Maybe<Scalars['Boolean']>;
  videoSlowMotion?: Maybe<Scalars['Boolean']>;
  videoHdr?: Maybe<Scalars['Boolean']>;
  gps?: Maybe<Scalars['Boolean']>;
  compass?: Maybe<Scalars['Boolean']>;
  biometric?: Maybe<Scalars['Boolean']>;
  radio?: Maybe<Scalars['Boolean']>;
  tv?: Maybe<Scalars['Boolean']>;
  waterproof?: Maybe<Scalars['Boolean']>;
  coolText?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyDeviceInput>;
  OR?: Maybe<Array<FilterFindManyDeviceInput>>;
  AND?: Maybe<Array<FilterFindManyDeviceInput>>;
  hasImage?: Maybe<Scalars['Boolean']>;
  hasLegalText?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDeviceNotificationInput = {
  codeProvider?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  notifiedEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  notifiedUserIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  solved?: Maybe<Scalars['Boolean']>;
  debuggerInfo?: Maybe<DeviceNotificationDebuggerInfoInput>;
  name?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
  sapCode?: Maybe<Scalars['String']>;
  /** Informações do device vindas dos headers http */
  httpRequestDeviceInfo?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyDeviceNotificationInput>;
  OR?: Maybe<Array<FilterFindManyDeviceNotificationInput>>;
  AND?: Maybe<Array<FilterFindManyDeviceNotificationInput>>;
  includeSolved?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyDigitalBrochureAnalyticsInput = {
  ddd?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  visualizedBrochures?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyDigitalBrochureAnalyticsInput>;
  OR?: Maybe<Array<FilterFindManyDigitalBrochureAnalyticsInput>>;
  AND?: Maybe<Array<FilterFindManyDigitalBrochureAnalyticsInput>>;
};

export type FilterFindManyDigitalBrochureInput = {
  title?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  /** URL do thumbnail do PDF gerado no .save() do documento e enviado ao S3 */
  thumbnailURL?: Maybe<Scalars['String']>;
  /** URL do thumbnail em HD do PDF gerado no .save() do documento e enviado ao S3 */
  thumbnailHDURL?: Maybe<Scalars['String']>;
  /** URL do SVG do QRCode gerado no .save() do documento e enviado ao S3 */
  qrCodeSVGURL?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['Date']>;
  link?: Maybe<Scalars['String']>;
  thumbnailQuality?: Maybe<Scalars['Float']>;
  isVisibleToPublic?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyDigitalBrochureInput>;
  OR?: Maybe<Array<FilterFindManyDigitalBrochureInput>>;
  AND?: Maybe<Array<FilterFindManyDigitalBrochureInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inAvailableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allAvailableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  valid?: Maybe<Scalars['String']>;
};

export type FilterFindManyDownloadSensitiveFilesInput = {
  owner?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyDownloadSensitiveFilesInput>;
  OR?: Maybe<Array<FilterFindManyDownloadSensitiveFilesInput>>;
  AND?: Maybe<Array<FilterFindManyDownloadSensitiveFilesInput>>;
};

export type FilterFindManyEmailInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  /** Props usadas para montar o layout do email */
  templateProps?: Maybe<Scalars['JSON']>;
  templateName?: Maybe<EnumEmailTemplateName>;
  status?: Maybe<EnumEmailStatus>;
  sent?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['Date']>;
  /** O corpo do email que foi enviado */
  sentBody?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Lista de emails que clicaram no link de confirmar leitura */
  readBy?: Maybe<Array<Maybe<EmailReadByInput>>>;
  notifyCollectionOnRead?: Maybe<EmailNotifyCollectionOnReadInput>;
  statsId?: Maybe<Scalars['String']>;
  plataform?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyEmailInput>;
  OR?: Maybe<Array<FilterFindManyEmailInput>>;
  AND?: Maybe<Array<FilterFindManyEmailInput>>;
};

export type FilterFindManyEmailStatsInput = {
  statsId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmailStatsStatus>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyEmailStatsInput>;
  OR?: Maybe<Array<FilterFindManyEmailStatsInput>>;
  AND?: Maybe<Array<FilterFindManyEmailStatsInput>>;
};

export type FilterFindManyEmailUnsubscribeInput = {
  email?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyEmailUnsubscribeInput>;
  OR?: Maybe<Array<FilterFindManyEmailUnsubscribeInput>>;
  AND?: Maybe<Array<FilterFindManyEmailUnsubscribeInput>>;
};

export type FilterFindManyEventLiveInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate?: Maybe<Scalars['Date']>;
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumEventLiveKind>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<EventLiveChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyEventLiveInput>;
  OR?: Maybe<Array<FilterFindManyEventLiveInput>>;
  AND?: Maybe<Array<FilterFindManyEventLiveInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterFindManyFileInput = {
  origin?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  parentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O tipo de uso do material. Ex: "video_poster" */
  kind?: Maybe<EnumFileKind>;
  title?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  /** A url original do arquivo, sem token de autorização. */
  url?: Maybe<Scalars['String']>;
  destinationKey?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  sizeDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  imageInfo?: Maybe<FileImageInfoInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyFileInput>;
  OR?: Maybe<Array<FilterFindManyFileInput>>;
  AND?: Maybe<Array<FilterFindManyFileInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  in_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  all_id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindManyFolderInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyFolderInput>;
  OR?: Maybe<Array<FilterFindManyFolderInput>>;
  AND?: Maybe<Array<FilterFindManyFolderInput>>;
};

export type FilterFindManyFormatoInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyFormatoInput>;
  OR?: Maybe<Array<FilterFindManyFormatoInput>>;
  AND?: Maybe<Array<FilterFindManyFormatoInput>>;
};

export type FilterFindManyHomebannerInput = {
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<HomebannerContentInput>>>;
  visible?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyHomebannerInput>;
  OR?: Maybe<Array<FilterFindManyHomebannerInput>>;
  AND?: Maybe<Array<FilterFindManyHomebannerInput>>;
};

export type FilterFindManyImportUsersPlanInput = {
  fileId?: Maybe<Scalars['String']>;
  statusImportUser?: Maybe<EnumImportUsersPlanStatusImportUser>;
  origin?: Maybe<EnumImportUsersPlanOrigin>;
  errorsFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyImportUsersPlanInput>;
  OR?: Maybe<Array<FilterFindManyImportUsersPlanInput>>;
  AND?: Maybe<Array<FilterFindManyImportUsersPlanInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  validityEnum?: Maybe<ValidityEnum>;
  availableAtRegions?: Maybe<Scalars['String']>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
};

export type FilterFindManyInventoryItemInput = {
  layoutCodeTM?: Maybe<Scalars['String']>;
  /** A quantidade de produtos nesse local. */
  quantity?: Maybe<Scalars['Float']>;
  /** Código SAP do CDL */
  locationSAPCode?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  depositNumber?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  descriptionTM?: Maybe<Scalars['String']>;
  searchNGrams?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadedAt?: Maybe<Scalars['Date']>;
  /** O dado da sincronização que deu origin a este item (uma linha do xlsx). */
  originData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyInventoryItemInput>;
  OR?: Maybe<Array<FilterFindManyInventoryItemInput>>;
  AND?: Maybe<Array<FilterFindManyInventoryItemInput>>;
  search?: Maybe<Scalars['String']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inDepositPath?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allDepositPath?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindManyInventoryWithDrawInput = {
  author?: Maybe<Scalars['String']>;
  gradeMode?: Maybe<EnumInventoryWithDrawGradeMode>;
  productionId?: Maybe<Scalars['String']>;
  depositPath?: Maybe<Scalars['String']>;
  /** A quantidade em estoque no CDL quando a retirada foi feita */
  quantityInStock?: Maybe<Scalars['Float']>;
  destinationPlaceSAPCode?: Maybe<Scalars['String']>;
  layoutCodeTM?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  depositNumber?: Maybe<Scalars['String']>;
  salesOrganization?: Maybe<Scalars['String']>;
  distributionChannel?: Maybe<Scalars['String']>;
  activitiesSector?: Maybe<Scalars['String']>;
  productionCode?: Maybe<Scalars['String']>;
  commercialOpStatus?: Maybe<Scalars['String']>;
  commercialOpReason?: Maybe<Scalars['String']>;
  orderZMP?: Maybe<Scalars['String']>;
  processingDate?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyInventoryWithDrawInput>;
  OR?: Maybe<Array<FilterFindManyInventoryWithDrawInput>>;
  AND?: Maybe<Array<FilterFindManyInventoryWithDrawInput>>;
};

export type FilterFindManyJobTitleInput = {
  _id?: Maybe<Scalars['String']>;
  /** Nome do perfil. Ex: fornecedor, Usuário Regional */
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyJobTitleInput>;
  OR?: Maybe<Array<FilterFindManyJobTitleInput>>;
  AND?: Maybe<Array<FilterFindManyJobTitleInput>>;
};

export type FilterFindManyLayoutInput = {
  campaign?: Maybe<Scalars['String']>;
  lpu?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  codeTM?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnailID?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyLayoutInput>;
  OR?: Maybe<Array<FilterFindManyLayoutInput>>;
  AND?: Maybe<Array<FilterFindManyLayoutInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCodeT?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCodeT?: Maybe<Array<Maybe<Scalars['String']>>>;
  isMissingCodeTM?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyLegalTextInput = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyLegalTextInput>;
  OR?: Maybe<Array<FilterFindManyLegalTextInput>>;
  AND?: Maybe<Array<FilterFindManyLegalTextInput>>;
};

export type FilterFindManyMapaPdvInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  obs?: Maybe<Scalars['String']>;
  hasObs?: Maybe<Scalars['Boolean']>;
  clusters?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterState?: Maybe<Scalars['JSON']>;
  hasPeriod?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  pecas?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyMapaPdvInput>;
  OR?: Maybe<Array<FilterFindManyMapaPdvInput>>;
  AND?: Maybe<Array<FilterFindManyMapaPdvInput>>;
};

export type FilterFindManyMaterialInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<MaterialEditorInput>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyMaterialInput>;
  OR?: Maybe<Array<FilterFindManyMaterialInput>>;
  AND?: Maybe<Array<FilterFindManyMaterialInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
};

export type FilterFindManyNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyNotificationInput>;
  OR?: Maybe<Array<FilterFindManyNotificationInput>>;
  AND?: Maybe<Array<FilterFindManyNotificationInput>>;
};

export type FilterFindManyPecaMapaInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['JSON']>;
  formats?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyPecaMapaInput>;
  OR?: Maybe<Array<FilterFindManyPecaMapaInput>>;
  AND?: Maybe<Array<FilterFindManyPecaMapaInput>>;
};

export type FilterFindManyPinInput = {
  owner?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  recordId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  otherData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyPinInput>;
  OR?: Maybe<Array<FilterFindManyPinInput>>;
  AND?: Maybe<Array<FilterFindManyPinInput>>;
};

export type FilterFindManyPlaceAnalyticsInput = {
  userId?: Maybe<Scalars['String']>;
  logType?: Maybe<EnumPlaceAnalyticsLogType>;
  platformName?: Maybe<EnumPlaceAnalyticsPlatformName>;
  type?: Maybe<EnumPlaceAnalyticsType>;
  disabled?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  inscricao_estadual?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  custcode?: Maybe<Scalars['String']>;
  codigo_sap?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<PlaceAnalyticsAddressInput>;
  modelo_de_loja?: Maybe<EnumPlaceAnalyticsModelo_De_Loja>;
  tipo_de_loja?: Maybe<EnumPlaceAnalyticsTipo_De_Loja>;
  tecnologia_de_rede?: Maybe<Scalars['String']>;
  produtos_comercializados?: Maybe<
    Array<Maybe<EnumPlaceAnalyticsProdutos_Comercializados>>
  >;
  foco_de_comunicacao?: Maybe<EnumPlaceAnalyticsFoco_De_Comunicacao>;
  cluster?: Maybe<EnumPlaceAnalyticsCluster>;
  canal_distribuicao?: Maybe<Scalars['String']>;
  organizacao_vendas?: Maybe<Scalars['String']>;
  setor_atividade?: Maybe<Scalars['String']>;
  quantidade_de_funcionarios?: Maybe<Scalars['Float']>;
  localizacao?: Maybe<EnumPlaceAnalyticsLocalizacao>;
  nome_da_galeria?: Maybe<Scalars['String']>;
  nome_do_shopping?: Maybe<Scalars['String']>;
  parcelamento?: Maybe<Scalars['Float']>;
  estrutura_de_loja?: Maybe<Array<Maybe<PlaceAnalyticsEstrutura_De_LojaInput>>>;
  inicio_da_operacao_mes?: Maybe<Scalars['Float']>;
  inicio_da_operacao_ano?: Maybe<Scalars['Float']>;
  ultima_modificacao?: Maybe<EnumPlaceAnalyticsUltima_Modificacao>;
  data_de_modificacao_mes?: Maybe<Scalars['Float']>;
  data_de_modificacao_ano?: Maybe<Scalars['Float']>;
  metragem?: Maybe<PlaceAnalyticsMetragemInput>;
  tipo_de_fachada?: Maybe<EnumPlaceAnalyticsTipo_De_Fachada>;
  revestimento_externo?: Maybe<EnumPlaceAnalyticsRevestimento_Externo>;
  posicoes_de_atendimento?: Maybe<Scalars['Float']>;
  posicoes_de_pos_venda?: Maybe<Scalars['Float']>;
  sala_de_gerente?: Maybe<Scalars['Boolean']>;
  back_office?: Maybe<Scalars['Boolean']>;
  wifi_clientes?: Maybe<Scalars['Boolean']>;
  sinal_live?: Maybe<Scalars['Boolean']>;
  display_de_senha?: Maybe<Scalars['Boolean']>;
  sistema_de_som?: Maybe<Scalars['Boolean']>;
  video_wall?: Maybe<Array<Maybe<PlaceAnalyticsVideo_WallInput>>>;
  adminInput?: Maybe<PlaceAnalyticsAdminInputInput>;
  adminId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyPlaceAnalyticsInput>;
  OR?: Maybe<Array<FilterFindManyPlaceAnalyticsInput>>;
  AND?: Maybe<Array<FilterFindManyPlaceAnalyticsInput>>;
};

export type FilterFindManyPlaceInput = {
  type?: Maybe<EnumPlaceType>;
  disabled?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  inscricao_estadual?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  custcode?: Maybe<Scalars['String']>;
  codigo_sap?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<PlaceAddressInput>;
  modelo_de_loja?: Maybe<EnumPlaceModelo_De_Loja>;
  tipo_de_loja?: Maybe<EnumPlaceTipo_De_Loja>;
  tecnologia_de_rede?: Maybe<Scalars['String']>;
  produtos_comercializados?: Maybe<
    Array<Maybe<EnumPlaceProdutos_Comercializados>>
  >;
  foco_de_comunicacao?: Maybe<EnumPlaceFoco_De_Comunicacao>;
  cluster?: Maybe<EnumPlaceCluster>;
  canal_distribuicao?: Maybe<Scalars['String']>;
  organizacao_vendas?: Maybe<Scalars['String']>;
  setor_atividade?: Maybe<Scalars['String']>;
  quantidade_de_funcionarios?: Maybe<Scalars['Float']>;
  localizacao?: Maybe<EnumPlaceLocalizacao>;
  nome_da_galeria?: Maybe<Scalars['String']>;
  nome_do_shopping?: Maybe<Scalars['String']>;
  parcelamento?: Maybe<Scalars['Float']>;
  estrutura_de_loja?: Maybe<Array<Maybe<PlaceEstrutura_De_LojaInput>>>;
  inicio_da_operacao_mes?: Maybe<Scalars['Float']>;
  inicio_da_operacao_ano?: Maybe<Scalars['Float']>;
  ultima_modificacao?: Maybe<EnumPlaceUltima_Modificacao>;
  data_de_modificacao_mes?: Maybe<Scalars['Float']>;
  data_de_modificacao_ano?: Maybe<Scalars['Float']>;
  metragem?: Maybe<PlaceMetragemInput>;
  tipo_de_fachada?: Maybe<EnumPlaceTipo_De_Fachada>;
  revestimento_externo?: Maybe<EnumPlaceRevestimento_Externo>;
  posicoes_de_atendimento?: Maybe<Scalars['Float']>;
  posicoes_de_pos_venda?: Maybe<Scalars['Float']>;
  sala_de_gerente?: Maybe<Scalars['Boolean']>;
  back_office?: Maybe<Scalars['Boolean']>;
  wifi_clientes?: Maybe<Scalars['Boolean']>;
  sinal_live?: Maybe<Scalars['Boolean']>;
  display_de_senha?: Maybe<Scalars['Boolean']>;
  sistema_de_som?: Maybe<Scalars['Boolean']>;
  video_wall?: Maybe<Array<Maybe<PlaceVideo_WallInput>>>;
  adminInput?: Maybe<PlaceAdminInputInput>;
  adminId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyPlaceInput>;
  OR?: Maybe<Array<FilterFindManyPlaceInput>>;
  AND?: Maybe<Array<FilterFindManyPlaceInput>>;
};

export type FilterFindManyPositivatorInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  isSubcategoryActive?: Maybe<Scalars['Boolean']>;
  isPositivatorActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  /** Enum no formato "PlaceType_X_ModeloDeLoja"' */
  channels?: Maybe<Array<Maybe<EnumPositivatorChannels>>>;
  cnpjs?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyPositivatorInput>;
  OR?: Maybe<Array<FilterFindManyPositivatorInput>>;
  AND?: Maybe<Array<FilterFindManyPositivatorInput>>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  placeType?: Maybe<Array<Maybe<EnumPlaceType>>>;
};

export type FilterFindManyPositivatorItemInput = {
  title?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUpload?: Maybe<Scalars['String']>;
  status?: Maybe<EnumPositivatorItemStatus>;
  place?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyPositivatorItemInput>;
  OR?: Maybe<Array<FilterFindManyPositivatorItemInput>>;
  AND?: Maybe<Array<FilterFindManyPositivatorItemInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inPositivator?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allPositivator?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindManyPricingTableInput = {
  status?: Maybe<EnumPricingTableStatus>;
  description?: Maybe<Scalars['String']>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  disabled?: Maybe<Scalars['Boolean']>;
  xlsxId?: Maybe<Scalars['String']>;
  activeSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  planPre?: Maybe<Scalars['String']>;
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  legalText?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['String']>;
  incidents?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  previous?: Maybe<Scalars['String']>;
  plansDevice?: Maybe<Scalars['JSON']>;
  devicePrices?: Maybe<Array<Maybe<PricingTableDevicePricesInput>>>;
  gadgetPrices?: Maybe<Array<Maybe<PricingTableGadgetPricesInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyPricingTableInput>;
  OR?: Maybe<Array<FilterFindManyPricingTableInput>>;
  AND?: Maybe<Array<FilterFindManyPricingTableInput>>;
};

export type FilterFindManyProductInput = {
  nome_produto?: Maybe<Scalars['String']>;
  preco_produto?: Maybe<Scalars['Float']>;
  categoria?: Maybe<Scalars['String']>;
  abreviacao_producao?: Maybe<Scalars['String']>;
  especificacao?: Maybe<Scalars['String']>;
  faixa_preco?: Maybe<Scalars['Boolean']>;
  especificacao_peso?: Maybe<Scalars['Float']>;
  especificacao_largura?: Maybe<Scalars['Float']>;
  especificacao_altura?: Maybe<Scalars['Float']>;
  especificacao_profundidade?: Maybe<Scalars['Float']>;
  especificacao_preco?: Maybe<Scalars['Float']>;
  intervalo_quantidade?: Maybe<Array<Maybe<ProductIntervalo_QuantidadeInput>>>;
  code_tm?: Maybe<Scalars['String']>;
  qtd_dias?: Maybe<Scalars['Float']>;
  contagem?: Maybe<Scalars['String']>;
  tipo_multiplicador?: Maybe<EnumProductTipo_Multiplicador>;
  multiplicador_pdv?: Maybe<Scalars['Float']>;
  multiplicador_mobiliario?: Maybe<Scalars['Float']>;
  multiplicador_posicao?: Maybe<Scalars['Float']>;
  ncm?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Codigo SAP do fornecedor. "numero_fornecedor_sap" na entidade Supplier */
  supplierSAPCode?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyProductInput>;
  OR?: Maybe<Array<FilterFindManyProductInput>>;
  AND?: Maybe<Array<FilterFindManyProductInput>>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
};

export type FilterFindManyProductionInput = {
  /** código da produção, criado dinamicamente. */
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** lista de locais para receber os produtos mais os filtros
   * utilizados para obter a lista de locais
   */
  productionItems?: Maybe<Array<Maybe<ProductionProductionItemsInput>>>;
  /** Replica as informações do produto no momento em que a produção foi salva */
  finalProductsPricing?: Maybe<
    Array<Maybe<ProductionFinalProductsPricingInput>>
  >;
  /** quando uma producão é criada a partir de uma verba
   * salvamos o _id da verba
   */
  originBudgetId?: Maybe<Scalars['String']>;
  status?: Maybe<EnumProductionStatus>;
  owner?: Maybe<Scalars['String']>;
  /** quando uma producão é concluida, enviamos emails aos fornecedores
   * com os itens a serem produzidos
   */
  sentEmails?: Maybe<Array<Maybe<ProductionSentEmailsInput>>>;
  /** Resumo dos dados para iniciar o processo de entrega ao enviar uma produção */
  deliverySummary?: Maybe<Array<Maybe<ProductionDeliverySummaryInput>>>;
  readEmails?: Maybe<Array<Maybe<ProductionReadEmailsInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyProductionInput>;
  OR?: Maybe<Array<FilterFindManyProductionInput>>;
  AND?: Maybe<Array<FilterFindManyProductionInput>>;
  /** Remove da listagem os items já entregues que o usuário "arquivou" */
  ignoreArchived?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManyRecentsActivitiesInput = {
  owner?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  viewsHistory?: Maybe<Array<Maybe<Scalars['Date']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRecentsActivitiesInput>;
  OR?: Maybe<Array<FilterFindManyRecentsActivitiesInput>>;
  AND?: Maybe<Array<FilterFindManyRecentsActivitiesInput>>;
};

export type FilterFindManyRegionalBudgetInput = {
  parentBudgetId?: Maybe<Scalars['String']>;
  region?: Maybe<EnumRegionalBudgetRegion>;
  deadline?: Maybe<Scalars['Date']>;
  maxValue?: Maybe<Scalars['Float']>;
  usedValue?: Maybe<Scalars['Float']>;
  remainingValue?: Maybe<Scalars['Float']>;
  availableAtChannels?: Maybe<
    Array<Maybe<EnumRegionalBudgetAvailableAtChannels>>
  >;
  allowedLayouts?: Maybe<Array<Maybe<RegionalBudgetAllowedLayoutsInput>>>;
  productionItems?: Maybe<Array<Maybe<RegionalBudgetProductionItemsInput>>>;
  /** True se a regional preencheu os valores para que a HQ possa criar a produção  */
  filledByRegional?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumRegionalBudgetStatus>;
  views?: Maybe<Array<Maybe<RegionalBudgetViewsInput>>>;
  /** Replica as informações do produto no momento em que o budget foi salvo */
  finalProductsPricing?: Maybe<
    Array<Maybe<RegionalBudgetFinalProductsPricingInput>>
  >;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRegionalBudgetInput>;
  OR?: Maybe<Array<FilterFindManyRegionalBudgetInput>>;
  AND?: Maybe<Array<FilterFindManyRegionalBudgetInput>>;
};

export type FilterFindManyRegionInput = {
  _id?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  name?: Maybe<Scalars['String']>;
  itemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRegionInput>;
  OR?: Maybe<Array<FilterFindManyRegionInput>>;
  AND?: Maybe<Array<FilterFindManyRegionInput>>;
};

export type FilterFindManyRegionItemInput = {
  name?: Maybe<Scalars['String']>;
  /** sigla do local, quando aplicavel. ex: BR, SP, AL. Ou o nome se não aplicável. */
  abbreviation?: Maybe<Scalars['String']>;
  /** O nivel de uma area, 0 para pais, 1 para estado, 3 DDD (area), 4 para cidades, etc */
  level?: Maybe<Scalars['Float']>;
  UF?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  /** código IBGE */
  IBGE?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Boolean']>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRegionItemInput>;
  OR?: Maybe<Array<FilterFindManyRegionItemInput>>;
  AND?: Maybe<Array<FilterFindManyRegionItemInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  in_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  all_id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindManyRegraMapaInput = {
  area?: Maybe<Scalars['String']>;
  cluster?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRegraMapaInput>;
  OR?: Maybe<Array<FilterFindManyRegraMapaInput>>;
  AND?: Maybe<Array<FilterFindManyRegraMapaInput>>;
};

export type FilterFindManyRoleGroupInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Quando aplicavel será o nome do canal pai */
  channelName?: Maybe<Scalars['String']>;
  /** Uma lista de permissoes incluidas no perfil. */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Scalars['Float']>;
  /** O Nivel de acesso hierarquico deste perfil. Usado para limitar por hierarquia as acoes de um perfil sobre outro. */
  accessLevel?: Maybe<Scalars['Float']>;
  /** ID do canal `parent`; caso o perfil esteja atrelado a um canal. */
  parentId?: Maybe<Scalars['String']>;
  idm?: Maybe<RoleGroupIdmInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyRoleGroupInput>;
  OR?: Maybe<Array<FilterFindManyRoleGroupInput>>;
  AND?: Maybe<Array<FilterFindManyRoleGroupInput>>;
};

export type FilterFindManySearchCountInput = {
  _id?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManySearchCountInput>;
  OR?: Maybe<Array<FilterFindManySearchCountInput>>;
  AND?: Maybe<Array<FilterFindManySearchCountInput>>;
};

export type FilterFindManyShareInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumShareKind>;
  title?: Maybe<Scalars['String']>;
  /** O id do item compartilhado */
  recordId?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyShareInput>;
  OR?: Maybe<Array<FilterFindManyShareInput>>;
  AND?: Maybe<Array<FilterFindManyShareInput>>;
};

export type FilterFindManyStructuralInput = {
  cnpj?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tipo_modificacao?: Maybe<Scalars['String']>;
  tipo_loja?: Maybe<Scalars['String']>;
  financial?: Maybe<Array<Maybe<StructuralFinancialInput>>>;
  canal?: Maybe<Scalars['String']>;
  classificacao_pdv?: Maybe<Scalars['String']>;
  ponto_de_cessao?: Maybe<Scalars['String']>;
  loja_sap?: Maybe<Scalars['String']>;
  patrimonio_tombado?: Maybe<Scalars['String']>;
  reembolso_obra?: Maybe<Scalars['String']>;
  local?: Maybe<Scalars['String']>;
  ddd?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  escritorio_vt?: Maybe<Scalars['String']>;
  escritorio_resp?: Maybe<Scalars['String']>;
  status?: Maybe<EnumStructuralStatus>;
  stage?: Maybe<EnumStructuralStage>;
  projectSLA?: Maybe<Scalars['JSON']>;
  stageDATA?: Maybe<Scalars['JSON']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyStructuralInput>;
  OR?: Maybe<Array<FilterFindManyStructuralInput>>;
  AND?: Maybe<Array<FilterFindManyStructuralInput>>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManySupplierDeliveryInput = {
  /** Codigo sap do fornecedor. numero_fornecedor_sap na entidade Supplier. */
  supplierSAPCode?: Maybe<Scalars['String']>;
  /** Codigo T do layout */
  layoutCodeT?: Maybe<Scalars['String']>;
  /** Codigo TM do layout */
  layoutCodeTM?: Maybe<Scalars['String']>;
  productionId?: Maybe<Scalars['String']>;
  productionTitle?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['Boolean']>;
  expectedDeliveryDate?: Maybe<Scalars['Date']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  sentEmailIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalNotes?: Maybe<Scalars['String']>;
  placeRegion?: Maybe<Scalars['String']>;
  depositPath?: Maybe<Scalars['String']>;
  depositNumber?: Maybe<Scalars['String']>;
  deliveryPlaceId?: Maybe<Scalars['String']>;
  deliveryPlaceType?: Maybe<Scalars['String']>;
  deliveryPlaceTypeName?: Maybe<Scalars['String']>;
  deliveryPlaceName?: Maybe<Scalars['String']>;
  deliveryPlaceCNPJ?: Maybe<Scalars['String']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  deliveryPlaceRazaoSocial?: Maybe<Scalars['String']>;
  deliveryPlaceNomeFantasia?: Maybe<Scalars['String']>;
  deliveryPlaceAddress?: Maybe<Scalars['String']>;
  deliveryPlaceNumber?: Maybe<Scalars['String']>;
  deliveryPlaceComplement?: Maybe<Scalars['String']>;
  deliveryPlaceZipCode?: Maybe<Scalars['String']>;
  deliveryPlaceNeighborhood?: Maybe<Scalars['String']>;
  deliveryPlaceCity?: Maybe<Scalars['String']>;
  deliveryPlaceState?: Maybe<Scalars['String']>;
  deliveryPlaceIsRegional?: Maybe<Scalars['Boolean']>;
  quantityToDelivery?: Maybe<Scalars['Float']>;
  quantityDelivered?: Maybe<Scalars['Float']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  receiverPersonalName?: Maybe<Scalars['String']>;
  receiverPersonalDocument?: Maybe<Scalars['String']>;
  archivedToUserIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sumario da producão que deu origem as este LogisticItem */
  originSummary?: Maybe<SupplierDeliveryOriginSummaryInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManySupplierDeliveryInput>;
  OR?: Maybe<Array<FilterFindManySupplierDeliveryInput>>;
  AND?: Maybe<Array<FilterFindManySupplierDeliveryInput>>;
  /** Remove da listagem os items já entregues que o usuário "arquivou" */
  ignoreArchived?: Maybe<Scalars['Boolean']>;
};

export type FilterFindManySupplierInput = {
  numero_fornecedor_sap?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  regionais_ufs_atendidas?: Maybe<Array<Maybe<Scalars['String']>>>;
  contatos?: Maybe<Array<Maybe<Scalars['String']>>>;
  nome?: Maybe<Scalars['String']>;
  telefone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nivel_aprovacao?: Maybe<Scalars['String']>;
  valor_qualificacao?: Maybe<Scalars['Float']>;
  validade_qualificacao?: Maybe<Scalars['Date']>;
  contratos?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManySupplierInput>;
  OR?: Maybe<Array<FilterFindManySupplierInput>>;
  AND?: Maybe<Array<FilterFindManySupplierInput>>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inProdutos_unitarios?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allProdutos_unitarios?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindManyTablePriceInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  upload?: Maybe<Scalars['String']>;
  sapCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  planPre?: Maybe<Scalars['String']>;
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  coolText?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  plansDevice?: Maybe<Array<Maybe<TablePricePlansDeviceInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyTablePriceInput>;
  OR?: Maybe<Array<FilterFindManyTablePriceInput>>;
  AND?: Maybe<Array<FilterFindManyTablePriceInput>>;
};

export type FilterFindManyUserDeletedInput = {
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  CNPJ?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  optInDate?: Maybe<Scalars['Date']>;
  optIn?: Maybe<Scalars['Boolean']>;
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  notifications?: Maybe<UserDeletedNotificationsInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUserDeletedInput>;
  OR?: Maybe<Array<FilterFindManyUserDeletedInput>>;
  AND?: Maybe<Array<FilterFindManyUserDeletedInput>>;
};

export type FilterFindManyUserHistoryBlockInput = {
  /** Responsável pelo bloqueio */
  owner?: Maybe<Scalars['String']>;
  /** Usuário bloqueado */
  userId?: Maybe<Scalars['String']>;
  /** Conteúdo que originou o bloqueio do usuário */
  parentId?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUserHistoryBlockInput>;
  OR?: Maybe<Array<FilterFindManyUserHistoryBlockInput>>;
  AND?: Maybe<Array<FilterFindManyUserHistoryBlockInput>>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
};

export type FilterFindManyUserInput = {
  email?: Maybe<Scalars['String']>;
  loggedAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledWithPoints?: Maybe<Scalars['Boolean']>;
  disabledAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  acceptedTerms?: Maybe<Array<Maybe<UserAcceptedTermsInput>>>;
  oldPasswords?: Maybe<Array<Maybe<UserOldPasswordsInput>>>;
  isApprover?: Maybe<Scalars['Boolean']>;
  /** Lista de DDDs aos quais a pessoa tem acesso - proveniente do GU. */
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Permissões do usuário. */
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** É true se o usuário recebeu alguma contentPermission */
  isContentTypeAdmin?: Maybe<Scalars['Boolean']>;
  /** ID do Pefil do usuario */
  roleGroup?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  isTempCPF?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  /** O id da imagem do perfil do usuário. */
  avatarId?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['Date']>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
  blockedByFailedAttempts?: Maybe<Scalars['Boolean']>;
  isBeta?: Maybe<Scalars['Boolean']>;
  failedAtempts?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUserInput>;
  OR?: Maybe<Array<FilterFindManyUserInput>>;
  AND?: Maybe<Array<FilterFindManyUserInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inApproverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allApproverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que NÃO derem match em um dos elementos do array informado */
  ninChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que NÃO derem match em um dos elementos do array informado */
  nin_id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindManyUserWordsBlackListInput = {
  owner?: Maybe<Scalars['String']>;
  word?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUserWordsBlackListInput>;
  OR?: Maybe<Array<FilterFindManyUserWordsBlackListInput>>;
  AND?: Maybe<Array<FilterFindManyUserWordsBlackListInput>>;
};

export type FilterFindOneBannerInput = {
  owner?: Maybe<Scalars['String']>;
  validity?: Maybe<BannerValidityInput>;
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<BannerImagesInput>>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneBannerInput>;
  OR?: Maybe<Array<FilterFindOneBannerInput>>;
  AND?: Maybe<Array<FilterFindOneBannerInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterFindOneBudgetInput = {
  status?: Maybe<EnumBudgetStatus>;
  /** Titulo da verba. Exemplo "Verba para campanha de natal" */
  title?: Maybe<Scalars['String']>;
  /** Valor total da verba a ser distribuida entre as regionais. */
  value?: Maybe<Scalars['Float']>;
  /** Quanto cada regional poderá usar para produzir. */
  regionalValues?: Maybe<Array<Maybe<BudgetRegionalValuesInput>>>;
  allowedLayouts?: Maybe<Array<Maybe<BudgetAllowedLayoutsInput>>>;
  /** se true os itens criados na entidade RegionalBudget serão apenas para registro. Regionais não fazem produção pelo portal */
  isRegionalProduction?: Maybe<Scalars['Boolean']>;
  /** Canais (tipos) de lojas que poderão receber os produtos dessa verba. */
  availableAtChannels?: Maybe<Array<Maybe<EnumBudgetAvailableAtChannels>>>;
  initialRegionalBudgets?: Maybe<
    Array<Maybe<BudgetInitialRegionalBudgetsInput>>
  >;
  /** ID da producão gerada a partir dessa verba */
  usedInProductionId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneBudgetInput>;
  OR?: Maybe<Array<FilterFindOneBudgetInput>>;
  AND?: Maybe<Array<FilterFindOneBudgetInput>>;
};

export type FilterFindOneCampaignInput = {
  code?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  productType?: Maybe<EnumCampaignProductType>;
  releaseDate?: Maybe<Scalars['Date']>;
  archivingDone?: Maybe<Scalars['Boolean']>;
  archive?: Maybe<EnumCampaignArchive>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCampaignInput>;
  OR?: Maybe<Array<FilterFindOneCampaignInput>>;
  AND?: Maybe<Array<FilterFindOneCampaignInput>>;
};

export type FilterFindOneCategoryInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCategoryInput>;
  OR?: Maybe<Array<FilterFindOneCategoryInput>>;
  AND?: Maybe<Array<FilterFindOneCategoryInput>>;
};

export type FilterFindOneChannelInput = {
  _id?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneChannelInput>;
  OR?: Maybe<Array<FilterFindOneChannelInput>>;
  AND?: Maybe<Array<FilterFindOneChannelInput>>;
};

export type FilterFindOneCommentInput = {
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  replyRecipientId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCommentInput>;
  OR?: Maybe<Array<FilterFindOneCommentInput>>;
  AND?: Maybe<Array<FilterFindOneCommentInput>>;
};

export type FilterFindOneContractInput = {
  nr_clm?: Maybe<Scalars['String']>;
  nr_contrato?: Maybe<Scalars['String']>;
  inicio_vigencia?: Maybe<Scalars['Date']>;
  final_vigencia?: Maybe<Scalars['Date']>;
  valor_total?: Maybe<Scalars['Float']>;
  valor_utilizado?: Maybe<Scalars['Float']>;
  nr_contrato_derivado?: Maybe<Scalars['String']>;
  inicio_vigencia_derivado?: Maybe<Scalars['Date']>;
  final_vigencia_derivado?: Maybe<Scalars['Date']>;
  valor_total_derivado?: Maybe<Scalars['Float']>;
  valor_utilizado_derivado?: Maybe<Scalars['Float']>;
  margem_saldo_derivado?: Maybe<Scalars['Float']>;
  natureza_contrato?: Maybe<Scalars['String']>;
  natureza_verba?: Maybe<Scalars['String']>;
  nrs_cic?: Maybe<Array<Maybe<ContractNrs_CicInput>>>;
  prazo_pagto?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneContractInput>;
  OR?: Maybe<Array<FilterFindOneContractInput>>;
  AND?: Maybe<Array<FilterFindOneContractInput>>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDeviceInput = {
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  inclusion?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  pricingTable?: Maybe<Scalars['String']>;
  featured?: Maybe<Array<Maybe<Scalars['String']>>>;
  comments?: Maybe<Scalars['String']>;
  chip?: Maybe<Scalars['String']>;
  nfc?: Maybe<Scalars['JSON']>;
  offer_date?: Maybe<Scalars['Date']>;
  launch_date?: Maybe<Scalars['Date']>;
  /** Código do aparelho (cliente chama de código do fornecedor). Código retornado pelo device. Ex: "MWC22" */
  codeProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
  codeTim?: Maybe<Array<Maybe<Scalars['String']>>>;
  releaseDateOf?: Maybe<Scalars['Date']>;
  availableColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  eSim?: Maybe<Scalars['Boolean']>;
  dualSim?: Maybe<Scalars['Boolean']>;
  connectionType5G?: Maybe<Scalars['Boolean']>;
  ram?: Maybe<Scalars['JSON']>;
  internalMemory?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  windowSize?: Maybe<Scalars['String']>;
  windowResolution?: Maybe<Scalars['String']>;
  cameraMegaPixels?: Maybe<Scalars['String']>;
  cameraFrontal?: Maybe<Scalars['JSON']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  legalText?: Maybe<Scalars['String']>;
  status?: Maybe<EnumDeviceStatus>;
  type?: Maybe<Scalars['String']>;
  devicePlan?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  flags?: Maybe<Scalars['String']>;
  operationalSystem?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  connectionType?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  expandableMemory?: Maybe<Scalars['String']>;
  pixelDensity?: Maybe<Scalars['JSON']>;
  colors?: Maybe<Scalars['String']>;
  cameraResolution?: Maybe<Scalars['String']>;
  cameraResolutionBy?: Maybe<Scalars['String']>;
  cameraOpeningCapacity?: Maybe<Scalars['JSON']>;
  cameraStabilization?: Maybe<Scalars['Boolean']>;
  cameraAutofocus?: Maybe<Scalars['Boolean']>;
  cameraOpticalZoom?: Maybe<Scalars['Boolean']>;
  cameraDigitalZoom?: Maybe<Scalars['Boolean']>;
  cameraFlash?: Maybe<Scalars['Boolean']>;
  cameraHdr?: Maybe<Scalars['Boolean']>;
  cameraLocation?: Maybe<Scalars['Boolean']>;
  cameraFacialDetection?: Maybe<Scalars['Boolean']>;
  videoResolution?: Maybe<Scalars['String']>;
  videoStabilization?: Maybe<Scalars['Boolean']>;
  videoAutofocus?: Maybe<Scalars['Boolean']>;
  videoSlowMotion?: Maybe<Scalars['Boolean']>;
  videoHdr?: Maybe<Scalars['Boolean']>;
  gps?: Maybe<Scalars['Boolean']>;
  compass?: Maybe<Scalars['Boolean']>;
  biometric?: Maybe<Scalars['Boolean']>;
  radio?: Maybe<Scalars['Boolean']>;
  tv?: Maybe<Scalars['Boolean']>;
  waterproof?: Maybe<Scalars['Boolean']>;
  coolText?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneDeviceInput>;
  OR?: Maybe<Array<FilterFindOneDeviceInput>>;
  AND?: Maybe<Array<FilterFindOneDeviceInput>>;
  hasImage?: Maybe<Scalars['Boolean']>;
  hasLegalText?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneDigitalBrochureAnalyticsInput = {
  ddd?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  visualizedBrochures?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneDigitalBrochureAnalyticsInput>;
  OR?: Maybe<Array<FilterFindOneDigitalBrochureAnalyticsInput>>;
  AND?: Maybe<Array<FilterFindOneDigitalBrochureAnalyticsInput>>;
};

export type FilterFindOneDigitalBrochureInput = {
  title?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  /** URL do thumbnail do PDF gerado no .save() do documento e enviado ao S3 */
  thumbnailURL?: Maybe<Scalars['String']>;
  /** URL do thumbnail em HD do PDF gerado no .save() do documento e enviado ao S3 */
  thumbnailHDURL?: Maybe<Scalars['String']>;
  /** URL do SVG do QRCode gerado no .save() do documento e enviado ao S3 */
  qrCodeSVGURL?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['Date']>;
  link?: Maybe<Scalars['String']>;
  thumbnailQuality?: Maybe<Scalars['Float']>;
  isVisibleToPublic?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneDigitalBrochureInput>;
  OR?: Maybe<Array<FilterFindOneDigitalBrochureInput>>;
  AND?: Maybe<Array<FilterFindOneDigitalBrochureInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inAvailableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allAvailableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  valid?: Maybe<Scalars['String']>;
};

export type FilterFindOneEmailInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  /** Props usadas para montar o layout do email */
  templateProps?: Maybe<Scalars['JSON']>;
  templateName?: Maybe<EnumEmailTemplateName>;
  status?: Maybe<EnumEmailStatus>;
  sent?: Maybe<Scalars['Boolean']>;
  sentAt?: Maybe<Scalars['Date']>;
  /** O corpo do email que foi enviado */
  sentBody?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Lista de emails que clicaram no link de confirmar leitura */
  readBy?: Maybe<Array<Maybe<EmailReadByInput>>>;
  notifyCollectionOnRead?: Maybe<EmailNotifyCollectionOnReadInput>;
  statsId?: Maybe<Scalars['String']>;
  plataform?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneEmailInput>;
  OR?: Maybe<Array<FilterFindOneEmailInput>>;
  AND?: Maybe<Array<FilterFindOneEmailInput>>;
};

export type FilterFindOneEmailStatsInput = {
  statsId?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEmailStatsStatus>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneEmailStatsInput>;
  OR?: Maybe<Array<FilterFindOneEmailStatsInput>>;
  AND?: Maybe<Array<FilterFindOneEmailStatsInput>>;
};

export type FilterFindOneEmailUnsubscribeInput = {
  email?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneEmailUnsubscribeInput>;
  OR?: Maybe<Array<FilterFindOneEmailUnsubscribeInput>>;
  AND?: Maybe<Array<FilterFindOneEmailUnsubscribeInput>>;
};

export type FilterFindOneEventLiveInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate?: Maybe<Scalars['Date']>;
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumEventLiveKind>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<EventLiveChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneEventLiveInput>;
  OR?: Maybe<Array<FilterFindOneEventLiveInput>>;
  AND?: Maybe<Array<FilterFindOneEventLiveInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterFindOneInventoryItemInput = {
  layoutCodeTM?: Maybe<Scalars['String']>;
  /** A quantidade de produtos nesse local. */
  quantity?: Maybe<Scalars['Float']>;
  /** Código SAP do CDL */
  locationSAPCode?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  depositNumber?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  descriptionTM?: Maybe<Scalars['String']>;
  searchNGrams?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadedAt?: Maybe<Scalars['Date']>;
  /** O dado da sincronização que deu origin a este item (uma linha do xlsx). */
  originData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneInventoryItemInput>;
  OR?: Maybe<Array<FilterFindOneInventoryItemInput>>;
  AND?: Maybe<Array<FilterFindOneInventoryItemInput>>;
  search?: Maybe<Scalars['String']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inDepositPath?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allDepositPath?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindOneJobTitleInput = {
  _id?: Maybe<Scalars['String']>;
  /** Nome do perfil. Ex: fornecedor, Usuário Regional */
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneJobTitleInput>;
  OR?: Maybe<Array<FilterFindOneJobTitleInput>>;
  AND?: Maybe<Array<FilterFindOneJobTitleInput>>;
};

export type FilterFindOneLayoutInput = {
  campaign?: Maybe<Scalars['String']>;
  lpu?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  codeTM?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnailID?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneLayoutInput>;
  OR?: Maybe<Array<FilterFindOneLayoutInput>>;
  AND?: Maybe<Array<FilterFindOneLayoutInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCodeT?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCodeT?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindOneMaterialInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<MaterialEditorInput>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneMaterialInput>;
  OR?: Maybe<Array<FilterFindOneMaterialInput>>;
  AND?: Maybe<Array<FilterFindOneMaterialInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindOneMetadataInput = {
  title?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneMetadataInput>;
  OR?: Maybe<Array<FilterFindOneMetadataInput>>;
  AND?: Maybe<Array<FilterFindOneMetadataInput>>;
};

export type FilterFindOneNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneNotificationInput>;
  OR?: Maybe<Array<FilterFindOneNotificationInput>>;
  AND?: Maybe<Array<FilterFindOneNotificationInput>>;
};

export type FilterFindOnePlaceAnalyticsInput = {
  userId?: Maybe<Scalars['String']>;
  logType?: Maybe<EnumPlaceAnalyticsLogType>;
  platformName?: Maybe<EnumPlaceAnalyticsPlatformName>;
  type?: Maybe<EnumPlaceAnalyticsType>;
  disabled?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  inscricao_estadual?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  custcode?: Maybe<Scalars['String']>;
  codigo_sap?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<PlaceAnalyticsAddressInput>;
  modelo_de_loja?: Maybe<EnumPlaceAnalyticsModelo_De_Loja>;
  tipo_de_loja?: Maybe<EnumPlaceAnalyticsTipo_De_Loja>;
  tecnologia_de_rede?: Maybe<Scalars['String']>;
  produtos_comercializados?: Maybe<
    Array<Maybe<EnumPlaceAnalyticsProdutos_Comercializados>>
  >;
  foco_de_comunicacao?: Maybe<EnumPlaceAnalyticsFoco_De_Comunicacao>;
  cluster?: Maybe<EnumPlaceAnalyticsCluster>;
  canal_distribuicao?: Maybe<Scalars['String']>;
  organizacao_vendas?: Maybe<Scalars['String']>;
  setor_atividade?: Maybe<Scalars['String']>;
  quantidade_de_funcionarios?: Maybe<Scalars['Float']>;
  localizacao?: Maybe<EnumPlaceAnalyticsLocalizacao>;
  nome_da_galeria?: Maybe<Scalars['String']>;
  nome_do_shopping?: Maybe<Scalars['String']>;
  parcelamento?: Maybe<Scalars['Float']>;
  estrutura_de_loja?: Maybe<Array<Maybe<PlaceAnalyticsEstrutura_De_LojaInput>>>;
  inicio_da_operacao_mes?: Maybe<Scalars['Float']>;
  inicio_da_operacao_ano?: Maybe<Scalars['Float']>;
  ultima_modificacao?: Maybe<EnumPlaceAnalyticsUltima_Modificacao>;
  data_de_modificacao_mes?: Maybe<Scalars['Float']>;
  data_de_modificacao_ano?: Maybe<Scalars['Float']>;
  metragem?: Maybe<PlaceAnalyticsMetragemInput>;
  tipo_de_fachada?: Maybe<EnumPlaceAnalyticsTipo_De_Fachada>;
  revestimento_externo?: Maybe<EnumPlaceAnalyticsRevestimento_Externo>;
  posicoes_de_atendimento?: Maybe<Scalars['Float']>;
  posicoes_de_pos_venda?: Maybe<Scalars['Float']>;
  sala_de_gerente?: Maybe<Scalars['Boolean']>;
  back_office?: Maybe<Scalars['Boolean']>;
  wifi_clientes?: Maybe<Scalars['Boolean']>;
  sinal_live?: Maybe<Scalars['Boolean']>;
  display_de_senha?: Maybe<Scalars['Boolean']>;
  sistema_de_som?: Maybe<Scalars['Boolean']>;
  video_wall?: Maybe<Array<Maybe<PlaceAnalyticsVideo_WallInput>>>;
  adminInput?: Maybe<PlaceAnalyticsAdminInputInput>;
  adminId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOnePlaceAnalyticsInput>;
  OR?: Maybe<Array<FilterFindOnePlaceAnalyticsInput>>;
  AND?: Maybe<Array<FilterFindOnePlaceAnalyticsInput>>;
};

export type FilterFindOnePlaceInput = {
  type?: Maybe<EnumPlaceType>;
  disabled?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  inscricao_estadual?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  custcode?: Maybe<Scalars['String']>;
  codigo_sap?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<PlaceAddressInput>;
  modelo_de_loja?: Maybe<EnumPlaceModelo_De_Loja>;
  tipo_de_loja?: Maybe<EnumPlaceTipo_De_Loja>;
  tecnologia_de_rede?: Maybe<Scalars['String']>;
  produtos_comercializados?: Maybe<
    Array<Maybe<EnumPlaceProdutos_Comercializados>>
  >;
  foco_de_comunicacao?: Maybe<EnumPlaceFoco_De_Comunicacao>;
  cluster?: Maybe<EnumPlaceCluster>;
  canal_distribuicao?: Maybe<Scalars['String']>;
  organizacao_vendas?: Maybe<Scalars['String']>;
  setor_atividade?: Maybe<Scalars['String']>;
  quantidade_de_funcionarios?: Maybe<Scalars['Float']>;
  localizacao?: Maybe<EnumPlaceLocalizacao>;
  nome_da_galeria?: Maybe<Scalars['String']>;
  nome_do_shopping?: Maybe<Scalars['String']>;
  parcelamento?: Maybe<Scalars['Float']>;
  estrutura_de_loja?: Maybe<Array<Maybe<PlaceEstrutura_De_LojaInput>>>;
  inicio_da_operacao_mes?: Maybe<Scalars['Float']>;
  inicio_da_operacao_ano?: Maybe<Scalars['Float']>;
  ultima_modificacao?: Maybe<EnumPlaceUltima_Modificacao>;
  data_de_modificacao_mes?: Maybe<Scalars['Float']>;
  data_de_modificacao_ano?: Maybe<Scalars['Float']>;
  metragem?: Maybe<PlaceMetragemInput>;
  tipo_de_fachada?: Maybe<EnumPlaceTipo_De_Fachada>;
  revestimento_externo?: Maybe<EnumPlaceRevestimento_Externo>;
  posicoes_de_atendimento?: Maybe<Scalars['Float']>;
  posicoes_de_pos_venda?: Maybe<Scalars['Float']>;
  sala_de_gerente?: Maybe<Scalars['Boolean']>;
  back_office?: Maybe<Scalars['Boolean']>;
  wifi_clientes?: Maybe<Scalars['Boolean']>;
  sinal_live?: Maybe<Scalars['Boolean']>;
  display_de_senha?: Maybe<Scalars['Boolean']>;
  sistema_de_som?: Maybe<Scalars['Boolean']>;
  video_wall?: Maybe<Array<Maybe<PlaceVideo_WallInput>>>;
  adminInput?: Maybe<PlaceAdminInputInput>;
  adminId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOnePlaceInput>;
  OR?: Maybe<Array<FilterFindOnePlaceInput>>;
  AND?: Maybe<Array<FilterFindOnePlaceInput>>;
};

export type FilterFindOneRegionalBudgetInput = {
  parentBudgetId?: Maybe<Scalars['String']>;
  region?: Maybe<EnumRegionalBudgetRegion>;
  deadline?: Maybe<Scalars['Date']>;
  maxValue?: Maybe<Scalars['Float']>;
  usedValue?: Maybe<Scalars['Float']>;
  remainingValue?: Maybe<Scalars['Float']>;
  availableAtChannels?: Maybe<
    Array<Maybe<EnumRegionalBudgetAvailableAtChannels>>
  >;
  allowedLayouts?: Maybe<Array<Maybe<RegionalBudgetAllowedLayoutsInput>>>;
  productionItems?: Maybe<Array<Maybe<RegionalBudgetProductionItemsInput>>>;
  /** True se a regional preencheu os valores para que a HQ possa criar a produção  */
  filledByRegional?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumRegionalBudgetStatus>;
  views?: Maybe<Array<Maybe<RegionalBudgetViewsInput>>>;
  /** Replica as informações do produto no momento em que o budget foi salvo */
  finalProductsPricing?: Maybe<
    Array<Maybe<RegionalBudgetFinalProductsPricingInput>>
  >;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneRegionalBudgetInput>;
  OR?: Maybe<Array<FilterFindOneRegionalBudgetInput>>;
  AND?: Maybe<Array<FilterFindOneRegionalBudgetInput>>;
};

export type FilterFindOneRegionInput = {
  _id?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  name?: Maybe<Scalars['String']>;
  itemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneRegionInput>;
  OR?: Maybe<Array<FilterFindOneRegionInput>>;
  AND?: Maybe<Array<FilterFindOneRegionInput>>;
};

export type FilterFindOneRegionItemInput = {
  name?: Maybe<Scalars['String']>;
  /** sigla do local, quando aplicavel. ex: BR, SP, AL. Ou o nome se não aplicável. */
  abbreviation?: Maybe<Scalars['String']>;
  /** O nivel de uma area, 0 para pais, 1 para estado, 3 DDD (area), 4 para cidades, etc */
  level?: Maybe<Scalars['Float']>;
  UF?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  /** código IBGE */
  IBGE?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Boolean']>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneRegionItemInput>;
  OR?: Maybe<Array<FilterFindOneRegionItemInput>>;
  AND?: Maybe<Array<FilterFindOneRegionItemInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  in_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  all_id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindOneRoleGroupInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** Quando aplicavel será o nome do canal pai */
  channelName?: Maybe<Scalars['String']>;
  /** Uma lista de permissoes incluidas no perfil. */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Scalars['Float']>;
  /** O Nivel de acesso hierarquico deste perfil. Usado para limitar por hierarquia as acoes de um perfil sobre outro. */
  accessLevel?: Maybe<Scalars['Float']>;
  /** ID do canal `parent`; caso o perfil esteja atrelado a um canal. */
  parentId?: Maybe<Scalars['String']>;
  idm?: Maybe<RoleGroupIdmInput>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneRoleGroupInput>;
  OR?: Maybe<Array<FilterFindOneRoleGroupInput>>;
  AND?: Maybe<Array<FilterFindOneRoleGroupInput>>;
};

export type FilterFindOneStructuralInput = {
  cnpj?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tipo_modificacao?: Maybe<Scalars['String']>;
  tipo_loja?: Maybe<Scalars['String']>;
  financial?: Maybe<Array<Maybe<StructuralFinancialInput>>>;
  canal?: Maybe<Scalars['String']>;
  classificacao_pdv?: Maybe<Scalars['String']>;
  ponto_de_cessao?: Maybe<Scalars['String']>;
  loja_sap?: Maybe<Scalars['String']>;
  patrimonio_tombado?: Maybe<Scalars['String']>;
  reembolso_obra?: Maybe<Scalars['String']>;
  local?: Maybe<Scalars['String']>;
  ddd?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  escritorio_vt?: Maybe<Scalars['String']>;
  escritorio_resp?: Maybe<Scalars['String']>;
  status?: Maybe<EnumStructuralStatus>;
  stage?: Maybe<EnumStructuralStage>;
  projectSLA?: Maybe<Scalars['JSON']>;
  stageDATA?: Maybe<Scalars['JSON']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneStructuralInput>;
  OR?: Maybe<Array<FilterFindOneStructuralInput>>;
  AND?: Maybe<Array<FilterFindOneStructuralInput>>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
};

export type FilterFindOneSupplierInput = {
  numero_fornecedor_sap?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  regionais_ufs_atendidas?: Maybe<Array<Maybe<Scalars['String']>>>;
  contatos?: Maybe<Array<Maybe<Scalars['String']>>>;
  nome?: Maybe<Scalars['String']>;
  telefone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nivel_aprovacao?: Maybe<Scalars['String']>;
  valor_qualificacao?: Maybe<Scalars['Float']>;
  validade_qualificacao?: Maybe<Scalars['Date']>;
  contratos?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneSupplierInput>;
  OR?: Maybe<Array<FilterFindOneSupplierInput>>;
  AND?: Maybe<Array<FilterFindOneSupplierInput>>;
  isNotDeleted?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inProdutos_unitarios?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allProdutos_unitarios?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterFindOneTablePriceInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  upload?: Maybe<Scalars['String']>;
  sapCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  planPre?: Maybe<Scalars['String']>;
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  coolText?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  plansDevice?: Maybe<Array<Maybe<TablePricePlansDeviceInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneTablePriceInput>;
  OR?: Maybe<Array<FilterFindOneTablePriceInput>>;
  AND?: Maybe<Array<FilterFindOneTablePriceInput>>;
};

export type FilterFindOneUserContentPermissionInput = {
  userId?: Maybe<Scalars['String']>;
  roleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteContentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneUserContentPermissionInput>;
  OR?: Maybe<Array<FilterFindOneUserContentPermissionInput>>;
  AND?: Maybe<Array<FilterFindOneUserContentPermissionInput>>;
};

export type FilterFindOneUserDeletedInput = {
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  CNPJ?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  optInDate?: Maybe<Scalars['Date']>;
  optIn?: Maybe<Scalars['Boolean']>;
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  notifications?: Maybe<UserDeletedNotificationsInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneUserDeletedInput>;
  OR?: Maybe<Array<FilterFindOneUserDeletedInput>>;
  AND?: Maybe<Array<FilterFindOneUserDeletedInput>>;
};

export type FilterFindOneUserInput = {
  email?: Maybe<Scalars['String']>;
  loggedAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledWithPoints?: Maybe<Scalars['Boolean']>;
  disabledAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  acceptedTerms?: Maybe<Array<Maybe<UserAcceptedTermsInput>>>;
  oldPasswords?: Maybe<Array<Maybe<UserOldPasswordsInput>>>;
  isApprover?: Maybe<Scalars['Boolean']>;
  /** Lista de DDDs aos quais a pessoa tem acesso - proveniente do GU. */
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Permissões do usuário. */
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** É true se o usuário recebeu alguma contentPermission */
  isContentTypeAdmin?: Maybe<Scalars['Boolean']>;
  /** ID do Pefil do usuario */
  roleGroup?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  isTempCPF?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  /** O id da imagem do perfil do usuário. */
  avatarId?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['Date']>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
  blockedByFailedAttempts?: Maybe<Scalars['Boolean']>;
  isBeta?: Maybe<Scalars['Boolean']>;
  failedAtempts?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneUserInput>;
  OR?: Maybe<Array<FilterFindOneUserInput>>;
  AND?: Maybe<Array<FilterFindOneUserInput>>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inApproverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allApproverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que NÃO derem match em um dos elementos do array informado */
  ninChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterMaterialInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<MaterialEditorInput>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterMaterialInput>;
  OR?: Maybe<Array<FilterMaterialInput>>;
  AND?: Maybe<Array<FilterMaterialInput>>;
  includeDrafts?: Maybe<Scalars['Boolean']>;
  /** Filtra os items que derem match em um dos elementos do array informado */
  inCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filtra os items que derem match emtodos os elementos do array informado */
  allCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca customizada geral por _id, titulo, tags, ou numericId */
  search?: Maybe<Scalars['String']>;
  /** Busca customizada pelo owner (autor) do material */
  filterAuthor?: Maybe<FilterAuthorInput>;
  /** Busca por um range de datas no  */
  createdAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  updatedAtRange?: Maybe<DateRange>;
  /** Busca por um range de datas no  */
  publishedAtRange?: Maybe<DateRange>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterRemoveManyFolderInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveManyFolderInput>;
  OR?: Maybe<Array<FilterRemoveManyFolderInput>>;
  AND?: Maybe<Array<FilterRemoveManyFolderInput>>;
};

export type FilterRemoveManyPinInput = {
  owner?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  recordId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  otherData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveManyPinInput>;
  OR?: Maybe<Array<FilterRemoveManyPinInput>>;
  AND?: Maybe<Array<FilterRemoveManyPinInput>>;
};

export type FilterRemoveManyPositivatorInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  isSubcategoryActive?: Maybe<Scalars['Boolean']>;
  isPositivatorActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  /** Enum no formato "PlaceType_X_ModeloDeLoja"' */
  channels?: Maybe<Array<Maybe<EnumPositivatorChannels>>>;
  cnpjs?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveManyPositivatorInput>;
  OR?: Maybe<Array<FilterRemoveManyPositivatorInput>>;
  AND?: Maybe<Array<FilterRemoveManyPositivatorInput>>;
};

export type FilterRemoveOneFolderInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveOneFolderInput>;
  OR?: Maybe<Array<FilterRemoveOneFolderInput>>;
  AND?: Maybe<Array<FilterRemoveOneFolderInput>>;
};

export type FilterRemoveOneMaterialInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<MaterialEditorInput>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveOneMaterialInput>;
  OR?: Maybe<Array<FilterRemoveOneMaterialInput>>;
  AND?: Maybe<Array<FilterRemoveOneMaterialInput>>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterRemoveOnePinInput = {
  owner?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  recordId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  otherData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveOnePinInput>;
  OR?: Maybe<Array<FilterRemoveOnePinInput>>;
  AND?: Maybe<Array<FilterRemoveOnePinInput>>;
};

export type FilterRemoveOneRegionInput = {
  _id?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  name?: Maybe<Scalars['String']>;
  itemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveOneRegionInput>;
  OR?: Maybe<Array<FilterRemoveOneRegionInput>>;
  AND?: Maybe<Array<FilterRemoveOneRegionInput>>;
};

export type FilterRemoveOneShareInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumShareKind>;
  title?: Maybe<Scalars['String']>;
  /** O id do item compartilhado */
  recordId?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterRemoveOneShareInput>;
  OR?: Maybe<Array<FilterRemoveOneShareInput>>;
  AND?: Maybe<Array<FilterRemoveOneShareInput>>;
};

export type FilterUpdateManyPositivatorInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  isSubcategoryActive?: Maybe<Scalars['Boolean']>;
  isPositivatorActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  /** Enum no formato "PlaceType_X_ModeloDeLoja"' */
  channels?: Maybe<Array<Maybe<EnumPositivatorChannels>>>;
  cnpjs?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyPositivatorInput>;
  OR?: Maybe<Array<FilterUpdateManyPositivatorInput>>;
  AND?: Maybe<Array<FilterUpdateManyPositivatorInput>>;
};

export type FilterUpdateOneBannerInput = {
  owner?: Maybe<Scalars['String']>;
  validity?: Maybe<BannerValidityInput>;
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<BannerImagesInput>>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneBannerInput>;
  OR?: Maybe<Array<FilterUpdateOneBannerInput>>;
  AND?: Maybe<Array<FilterUpdateOneBannerInput>>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterUpdateOneCampaignInput = {
  code?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  productType?: Maybe<EnumCampaignProductType>;
  releaseDate?: Maybe<Scalars['Date']>;
  archivingDone?: Maybe<Scalars['Boolean']>;
  archive?: Maybe<EnumCampaignArchive>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCampaignInput>;
  OR?: Maybe<Array<FilterUpdateOneCampaignInput>>;
  AND?: Maybe<Array<FilterUpdateOneCampaignInput>>;
};

export type FilterUpdateOneCategoryInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCategoryInput>;
  OR?: Maybe<Array<FilterUpdateOneCategoryInput>>;
  AND?: Maybe<Array<FilterUpdateOneCategoryInput>>;
};

export type FilterUpdateOneCommentInput = {
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  replyRecipientId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCommentInput>;
  OR?: Maybe<Array<FilterUpdateOneCommentInput>>;
  AND?: Maybe<Array<FilterUpdateOneCommentInput>>;
};

export type FilterUpdateOneContractInput = {
  nr_clm?: Maybe<Scalars['String']>;
  nr_contrato?: Maybe<Scalars['String']>;
  inicio_vigencia?: Maybe<Scalars['Date']>;
  final_vigencia?: Maybe<Scalars['Date']>;
  valor_total?: Maybe<Scalars['Float']>;
  valor_utilizado?: Maybe<Scalars['Float']>;
  nr_contrato_derivado?: Maybe<Scalars['String']>;
  inicio_vigencia_derivado?: Maybe<Scalars['Date']>;
  final_vigencia_derivado?: Maybe<Scalars['Date']>;
  valor_total_derivado?: Maybe<Scalars['Float']>;
  valor_utilizado_derivado?: Maybe<Scalars['Float']>;
  margem_saldo_derivado?: Maybe<Scalars['Float']>;
  natureza_contrato?: Maybe<Scalars['String']>;
  natureza_verba?: Maybe<Scalars['String']>;
  nrs_cic?: Maybe<Array<Maybe<ContractNrs_CicInput>>>;
  prazo_pagto?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneContractInput>;
  OR?: Maybe<Array<FilterUpdateOneContractInput>>;
  AND?: Maybe<Array<FilterUpdateOneContractInput>>;
};

export type FilterUpdateOneDeviceInput = {
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  inclusion?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  pricingTable?: Maybe<Scalars['String']>;
  featured?: Maybe<Array<Maybe<Scalars['String']>>>;
  comments?: Maybe<Scalars['String']>;
  chip?: Maybe<Scalars['String']>;
  nfc?: Maybe<Scalars['JSON']>;
  offer_date?: Maybe<Scalars['Date']>;
  launch_date?: Maybe<Scalars['Date']>;
  /** Código do aparelho (cliente chama de código do fornecedor). Código retornado pelo device. Ex: "MWC22" */
  codeProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
  codeTim?: Maybe<Array<Maybe<Scalars['String']>>>;
  releaseDateOf?: Maybe<Scalars['Date']>;
  availableColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  eSim?: Maybe<Scalars['Boolean']>;
  dualSim?: Maybe<Scalars['Boolean']>;
  connectionType5G?: Maybe<Scalars['Boolean']>;
  ram?: Maybe<Scalars['JSON']>;
  internalMemory?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  windowSize?: Maybe<Scalars['String']>;
  windowResolution?: Maybe<Scalars['String']>;
  cameraMegaPixels?: Maybe<Scalars['String']>;
  cameraFrontal?: Maybe<Scalars['JSON']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  legalText?: Maybe<Scalars['String']>;
  status?: Maybe<EnumDeviceStatus>;
  type?: Maybe<Scalars['String']>;
  devicePlan?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  flags?: Maybe<Scalars['String']>;
  operationalSystem?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  connectionType?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  expandableMemory?: Maybe<Scalars['String']>;
  pixelDensity?: Maybe<Scalars['JSON']>;
  colors?: Maybe<Scalars['String']>;
  cameraResolution?: Maybe<Scalars['String']>;
  cameraResolutionBy?: Maybe<Scalars['String']>;
  cameraOpeningCapacity?: Maybe<Scalars['JSON']>;
  cameraStabilization?: Maybe<Scalars['Boolean']>;
  cameraAutofocus?: Maybe<Scalars['Boolean']>;
  cameraOpticalZoom?: Maybe<Scalars['Boolean']>;
  cameraDigitalZoom?: Maybe<Scalars['Boolean']>;
  cameraFlash?: Maybe<Scalars['Boolean']>;
  cameraHdr?: Maybe<Scalars['Boolean']>;
  cameraLocation?: Maybe<Scalars['Boolean']>;
  cameraFacialDetection?: Maybe<Scalars['Boolean']>;
  videoResolution?: Maybe<Scalars['String']>;
  videoStabilization?: Maybe<Scalars['Boolean']>;
  videoAutofocus?: Maybe<Scalars['Boolean']>;
  videoSlowMotion?: Maybe<Scalars['Boolean']>;
  videoHdr?: Maybe<Scalars['Boolean']>;
  gps?: Maybe<Scalars['Boolean']>;
  compass?: Maybe<Scalars['Boolean']>;
  biometric?: Maybe<Scalars['Boolean']>;
  radio?: Maybe<Scalars['Boolean']>;
  tv?: Maybe<Scalars['Boolean']>;
  waterproof?: Maybe<Scalars['Boolean']>;
  coolText?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneDeviceInput>;
  OR?: Maybe<Array<FilterUpdateOneDeviceInput>>;
  AND?: Maybe<Array<FilterUpdateOneDeviceInput>>;
};

export type FilterUpdateOneDigitalBrochureInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  /** URL do thumbnail do PDF gerado no .save() do documento e enviado ao S3 */
  thumbnailURL?: Maybe<Scalars['String']>;
  /** URL do thumbnail em HD do PDF gerado no .save() do documento e enviado ao S3 */
  thumbnailHDURL?: Maybe<Scalars['String']>;
  /** URL do SVG do QRCode gerado no .save() do documento e enviado ao S3 */
  qrCodeSVGURL?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['Date']>;
  link?: Maybe<Scalars['String']>;
  thumbnailQuality?: Maybe<Scalars['Float']>;
  isVisibleToPublic?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneDigitalBrochureInput>;
  OR?: Maybe<Array<FilterUpdateOneDigitalBrochureInput>>;
  AND?: Maybe<Array<FilterUpdateOneDigitalBrochureInput>>;
};

export type FilterUpdateOneEventLiveInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate?: Maybe<Scalars['Date']>;
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumEventLiveKind>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<EventLiveChangedByInput>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneEventLiveInput>;
  OR?: Maybe<Array<FilterUpdateOneEventLiveInput>>;
  AND?: Maybe<Array<FilterUpdateOneEventLiveInput>>;
  validityEnum?: Maybe<ValidityEnum>;
};

export type FilterUpdateOneFolderInput = {
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneFolderInput>;
  OR?: Maybe<Array<FilterUpdateOneFolderInput>>;
  AND?: Maybe<Array<FilterUpdateOneFolderInput>>;
};

export type FilterUpdateOneImportUsersPlanInput = {
  fileId?: Maybe<Scalars['String']>;
  statusImportUser?: Maybe<EnumImportUsersPlanStatusImportUser>;
  origin?: Maybe<EnumImportUsersPlanOrigin>;
  errorsFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneImportUsersPlanInput>;
  OR?: Maybe<Array<FilterUpdateOneImportUsersPlanInput>>;
  AND?: Maybe<Array<FilterUpdateOneImportUsersPlanInput>>;
  validityEnum?: Maybe<ValidityEnum>;
  availableAtRegions?: Maybe<Scalars['String']>;
};

export type FilterUpdateOneLayoutInput = {
  campaign?: Maybe<Scalars['String']>;
  lpu?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  codeT?: Maybe<Scalars['String']>;
  codeTM?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnailID?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneLayoutInput>;
  OR?: Maybe<Array<FilterUpdateOneLayoutInput>>;
  AND?: Maybe<Array<FilterUpdateOneLayoutInput>>;
};

export type FilterUpdateOneMaterialInput = {
  availableAtRegions?: Maybe<Scalars['String']>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<MaterialEditorInput>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneMaterialInput>;
  OR?: Maybe<Array<FilterUpdateOneMaterialInput>>;
  AND?: Maybe<Array<FilterUpdateOneMaterialInput>>;
  validityEnum?: Maybe<ValidityEnum>;
  /** Busca por CommunicationItemsConfig */
  communicationKind?: Maybe<CommunicationKindFilter>;
  /** Busca por CommunicationItemsConfig */
  communicationKindNE?: Maybe<CommunicationKindFilter>;
  /** Busca por usuários aprovadores do material */
  usersToApproveIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que os usuários precisam aprovar */
  awaitingApproversIN?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Busca os materiais que estão com categorias adicionais */
  inAdditionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterUpdateOneMetadataInput = {
  title?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneMetadataInput>;
  OR?: Maybe<Array<FilterUpdateOneMetadataInput>>;
  AND?: Maybe<Array<FilterUpdateOneMetadataInput>>;
};

export type FilterUpdateOneNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
  _id?: Maybe<Scalars['MongoID']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneNotificationInput>;
  OR?: Maybe<Array<FilterUpdateOneNotificationInput>>;
  AND?: Maybe<Array<FilterUpdateOneNotificationInput>>;
};

export type FilterUpdateOnePlaceInput = {
  type?: Maybe<EnumPlaceType>;
  disabled?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  inscricao_estadual?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  custcode?: Maybe<Scalars['String']>;
  codigo_sap?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<PlaceAddressInput>;
  modelo_de_loja?: Maybe<EnumPlaceModelo_De_Loja>;
  tipo_de_loja?: Maybe<EnumPlaceTipo_De_Loja>;
  tecnologia_de_rede?: Maybe<Scalars['String']>;
  produtos_comercializados?: Maybe<
    Array<Maybe<EnumPlaceProdutos_Comercializados>>
  >;
  foco_de_comunicacao?: Maybe<EnumPlaceFoco_De_Comunicacao>;
  cluster?: Maybe<EnumPlaceCluster>;
  canal_distribuicao?: Maybe<Scalars['String']>;
  organizacao_vendas?: Maybe<Scalars['String']>;
  setor_atividade?: Maybe<Scalars['String']>;
  quantidade_de_funcionarios?: Maybe<Scalars['Float']>;
  localizacao?: Maybe<EnumPlaceLocalizacao>;
  nome_da_galeria?: Maybe<Scalars['String']>;
  nome_do_shopping?: Maybe<Scalars['String']>;
  parcelamento?: Maybe<Scalars['Float']>;
  estrutura_de_loja?: Maybe<Array<Maybe<PlaceEstrutura_De_LojaInput>>>;
  inicio_da_operacao_mes?: Maybe<Scalars['Float']>;
  inicio_da_operacao_ano?: Maybe<Scalars['Float']>;
  ultima_modificacao?: Maybe<EnumPlaceUltima_Modificacao>;
  data_de_modificacao_mes?: Maybe<Scalars['Float']>;
  data_de_modificacao_ano?: Maybe<Scalars['Float']>;
  metragem?: Maybe<PlaceMetragemInput>;
  tipo_de_fachada?: Maybe<EnumPlaceTipo_De_Fachada>;
  revestimento_externo?: Maybe<EnumPlaceRevestimento_Externo>;
  posicoes_de_atendimento?: Maybe<Scalars['Float']>;
  posicoes_de_pos_venda?: Maybe<Scalars['Float']>;
  sala_de_gerente?: Maybe<Scalars['Boolean']>;
  back_office?: Maybe<Scalars['Boolean']>;
  wifi_clientes?: Maybe<Scalars['Boolean']>;
  sinal_live?: Maybe<Scalars['Boolean']>;
  display_de_senha?: Maybe<Scalars['Boolean']>;
  sistema_de_som?: Maybe<Scalars['Boolean']>;
  video_wall?: Maybe<Array<Maybe<PlaceVideo_WallInput>>>;
  adminInput?: Maybe<PlaceAdminInputInput>;
  adminId?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOnePlaceInput>;
  OR?: Maybe<Array<FilterUpdateOnePlaceInput>>;
  AND?: Maybe<Array<FilterUpdateOnePlaceInput>>;
};

export type FilterUpdateOnePositivatorInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  isSubcategoryActive?: Maybe<Scalars['Boolean']>;
  isPositivatorActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  /** Enum no formato "PlaceType_X_ModeloDeLoja"' */
  channels?: Maybe<Array<Maybe<EnumPositivatorChannels>>>;
  cnpjs?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOnePositivatorInput>;
  OR?: Maybe<Array<FilterUpdateOnePositivatorInput>>;
  AND?: Maybe<Array<FilterUpdateOnePositivatorInput>>;
};

export type FilterUpdateOneProductInput = {
  nome_produto?: Maybe<Scalars['String']>;
  preco_produto?: Maybe<Scalars['Float']>;
  categoria?: Maybe<EnumProductCategoria>;
  abreviacao_producao?: Maybe<Scalars['String']>;
  especificacao?: Maybe<Scalars['String']>;
  faixa_preco?: Maybe<Scalars['Boolean']>;
  especificacao_peso?: Maybe<Scalars['Float']>;
  especificacao_largura?: Maybe<Scalars['Float']>;
  especificacao_altura?: Maybe<Scalars['Float']>;
  especificacao_profundidade?: Maybe<Scalars['Float']>;
  especificacao_preco?: Maybe<Scalars['Float']>;
  intervalo_quantidade?: Maybe<Array<Maybe<ProductIntervalo_QuantidadeInput>>>;
  code_tm?: Maybe<Scalars['String']>;
  qtd_dias?: Maybe<Scalars['Float']>;
  contagem?: Maybe<Scalars['String']>;
  tipo_multiplicador?: Maybe<EnumProductTipo_Multiplicador>;
  multiplicador_pdv?: Maybe<Scalars['Float']>;
  multiplicador_mobiliario?: Maybe<Scalars['Float']>;
  multiplicador_posicao?: Maybe<Scalars['Float']>;
  ncm?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Codigo SAP do fornecedor. "numero_fornecedor_sap" na entidade Supplier */
  supplierSAPCode?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneProductInput>;
  OR?: Maybe<Array<FilterUpdateOneProductInput>>;
  AND?: Maybe<Array<FilterUpdateOneProductInput>>;
};

export type FilterUpdateOneStructuralInput = {
  cnpj?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tipo_modificacao?: Maybe<Scalars['String']>;
  tipo_loja?: Maybe<Scalars['String']>;
  financial?: Maybe<Array<Maybe<StructuralFinancialInput>>>;
  canal?: Maybe<Scalars['String']>;
  classificacao_pdv?: Maybe<Scalars['String']>;
  ponto_de_cessao?: Maybe<Scalars['String']>;
  loja_sap?: Maybe<Scalars['String']>;
  patrimonio_tombado?: Maybe<Scalars['String']>;
  reembolso_obra?: Maybe<Scalars['String']>;
  local?: Maybe<Scalars['String']>;
  ddd?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  escritorio_vt?: Maybe<Scalars['String']>;
  escritorio_resp?: Maybe<Scalars['String']>;
  status?: Maybe<EnumStructuralStatus>;
  stage?: Maybe<EnumStructuralStage>;
  projectSLA?: Maybe<Scalars['JSON']>;
  stageDATA?: Maybe<Scalars['JSON']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneStructuralInput>;
  OR?: Maybe<Array<FilterUpdateOneStructuralInput>>;
  AND?: Maybe<Array<FilterUpdateOneStructuralInput>>;
};

export type FilterUpdateOneSupplierInput = {
  numero_fornecedor_sap?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  regionais_ufs_atendidas?: Maybe<Array<Maybe<Scalars['String']>>>;
  contatos?: Maybe<Array<Maybe<Scalars['String']>>>;
  nome?: Maybe<Scalars['String']>;
  telefone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nivel_aprovacao?: Maybe<Scalars['String']>;
  valor_qualificacao?: Maybe<Scalars['Float']>;
  validade_qualificacao?: Maybe<Scalars['Date']>;
  contratos?: Maybe<Array<Maybe<Scalars['String']>>>;
  produtos_unitarios?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneSupplierInput>;
  OR?: Maybe<Array<FilterUpdateOneSupplierInput>>;
  AND?: Maybe<Array<FilterUpdateOneSupplierInput>>;
};

export type FilterUpdateOneTablePriceInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  upload?: Maybe<Scalars['String']>;
  sapCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  planPre?: Maybe<Scalars['String']>;
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  coolText?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  plansDevice?: Maybe<Array<Maybe<TablePricePlansDeviceInput>>>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneTablePriceInput>;
  OR?: Maybe<Array<FilterUpdateOneTablePriceInput>>;
  AND?: Maybe<Array<FilterUpdateOneTablePriceInput>>;
};

export type FilterUpdateOneUserInput = {
  email?: Maybe<Scalars['String']>;
  loggedAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledWithPoints?: Maybe<Scalars['Boolean']>;
  disabledAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  acceptedTerms?: Maybe<Array<Maybe<UserAcceptedTermsInput>>>;
  oldPasswords?: Maybe<Array<Maybe<UserOldPasswordsInput>>>;
  approverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  isApprover?: Maybe<Scalars['Boolean']>;
  /** Lista de DDDs aos quais a pessoa tem acesso - proveniente do GU. */
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Permissões do usuário. */
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** É true se o usuário recebeu alguma contentPermission */
  isContentTypeAdmin?: Maybe<Scalars['Boolean']>;
  /** ID do Pefil do usuario */
  roleGroup?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  isTempCPF?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  /** O id da imagem do perfil do usuário. */
  avatarId?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['Date']>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
  blockedByFailedAttempts?: Maybe<Scalars['Boolean']>;
  isBeta?: Maybe<Scalars['Boolean']>;
  failedAtempts?: Maybe<Scalars['Float']>;
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneUserInput>;
  OR?: Maybe<Array<FilterUpdateOneUserInput>>;
  AND?: Maybe<Array<FilterUpdateOneUserInput>>;
};

export type Folder = {
  __typename?: 'Folder';
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type FolderPagination = {
  __typename?: 'FolderPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Folder>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Formato = {
  __typename?: 'Formato';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  image?: Maybe<File>;
  creationInfo?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type FormatoPagination = {
  __typename?: 'FormatoPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Formato>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type FutureOperation = {
  __typename?: 'FutureOperation';
  userCreditOperationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  operation?: Maybe<EnumFutureOperationOperation>;
  /** Referências da collection de userCreditOperation para saber quais os débitos foram usados para zerar o valor de pointsLeft */
  userDebitOperations?: Maybe<Array<Maybe<FutureOperationUserDebitOperations>>>;
  pointsLeft?: Maybe<Scalars['Float']>;
  expiresAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type FutureOperationUserDebitOperations = {
  __typename?: 'FutureOperationUserDebitOperations';
  debitedPoints?: Maybe<Scalars['Float']>;
  userIdCreditOperation?: Maybe<Scalars['String']>;
};

export type History = {
  __typename?: 'History';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<HistoryFact>>>;
};

export type HistoryFact = {
  __typename?: 'HistoryFact';
  date?: Maybe<Scalars['Date']>;
  author?: Maybe<Author>;
  updates?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type Homebanner = {
  __typename?: 'Homebanner';
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<HomebannerContent>>>;
  visible?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  bannerImage?: Maybe<File>;
  contentData?: Maybe<Array<Maybe<BannerInfoContent>>>;
};

export type HomebannerContent = {
  __typename?: 'HomebannerContent';
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  canView?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HomebannerContentInput = {
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  canView?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ImportUsersPlan = {
  __typename?: 'ImportUsersPlan';
  fileId?: Maybe<Scalars['String']>;
  statusImportUser?: Maybe<EnumImportUsersPlanStatusImportUser>;
  origin?: Maybe<EnumImportUsersPlanOrigin>;
  errorsFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  file?: Maybe<File>;
};

/** List of items with pagination. */
export type ImportUsersPlanPagination = {
  __typename?: 'ImportUsersPlanPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<ImportUsersPlan>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  layoutCodeTM?: Maybe<Scalars['String']>;
  /** A quantidade de produtos nesse local. */
  quantity?: Maybe<Scalars['Float']>;
  /** Código SAP do CDL */
  locationSAPCode?: Maybe<Scalars['String']>;
  /** Código deposito. Hoje é 51 para TSE e 31 o restante */
  depositNumber?: Maybe<Scalars['String']>;
  depositPath?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  /** Descrição do produto. usado para busca por texto. */
  descriptionTM?: Maybe<Scalars['String']>;
  uploadedAt?: Maybe<Scalars['Date']>;
  /** O dado da sincronização que deu origin a este item (uma linha do xlsx). */
  originData?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  layout?: Maybe<Layout>;
  location?: Maybe<Place>;
};

/** List of items with pagination. */
export type InventoryItemPagination = {
  __typename?: 'InventoryItemPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<InventoryItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type InventorySyncProduct = {
  quantity: Scalars['Int'];
  layoutCodeTM: Scalars['String'];
  locationSAPCode: Scalars['String'];
  descriptionTM: Scalars['String'];
  depositNumber: Scalars['String'];
  originData: Scalars['JSON'];
};

export type InventoryWithDraw = {
  __typename?: 'InventoryWithDraw';
  author?: Maybe<Scalars['String']>;
  gradeMode?: Maybe<EnumInventoryWithDrawGradeMode>;
  productionId?: Maybe<Scalars['String']>;
  depositPath?: Maybe<Scalars['String']>;
  /** A quantidade em estoque no CDL quando a retirada foi feita */
  quantityInStock?: Maybe<Scalars['Float']>;
  destinationPlaceSAPCode?: Maybe<Scalars['String']>;
  layoutCodeTM?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  depositNumber?: Maybe<Scalars['String']>;
  salesOrganization?: Maybe<Scalars['String']>;
  distributionChannel?: Maybe<Scalars['String']>;
  activitiesSector?: Maybe<Scalars['String']>;
  productionCode?: Maybe<Scalars['String']>;
  commercialOpStatus?: Maybe<Scalars['String']>;
  commercialOpReason?: Maybe<Scalars['String']>;
  orderZMP?: Maybe<Scalars['String']>;
  processingDate?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type InventoryWithDrawPagination = {
  __typename?: 'InventoryWithDrawPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<InventoryWithDraw>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type JobTitle = {
  __typename?: 'JobTitle';
  _id: Scalars['String'];
  /** Nome do perfil. Ex: fornecedor, Usuário Regional */
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type KindOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<EnumMaterialKind>;
  gte?: Maybe<EnumMaterialKind>;
  lt?: Maybe<EnumMaterialKind>;
  lte?: Maybe<EnumMaterialKind>;
  ne?: Maybe<EnumMaterialKind>;
  in?: Maybe<Array<Maybe<EnumMaterialKind>>>;
  nin?: Maybe<Array<Maybe<EnumMaterialKind>>>;
};

export type KindOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<EnumMaterialKind>;
  gte?: Maybe<EnumMaterialKind>;
  lt?: Maybe<EnumMaterialKind>;
  lte?: Maybe<EnumMaterialKind>;
  ne?: Maybe<EnumMaterialKind>;
  in?: Maybe<Array<Maybe<EnumMaterialKind>>>;
  nin?: Maybe<Array<Maybe<EnumMaterialKind>>>;
};

export type KindOperatorsFilterMaterialInput = {
  gt?: Maybe<EnumMaterialKind>;
  gte?: Maybe<EnumMaterialKind>;
  lt?: Maybe<EnumMaterialKind>;
  lte?: Maybe<EnumMaterialKind>;
  ne?: Maybe<EnumMaterialKind>;
  in?: Maybe<Array<Maybe<EnumMaterialKind>>>;
  nin?: Maybe<Array<Maybe<EnumMaterialKind>>>;
};

export type KindOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<EnumMaterialKind>;
  gte?: Maybe<EnumMaterialKind>;
  lt?: Maybe<EnumMaterialKind>;
  lte?: Maybe<EnumMaterialKind>;
  ne?: Maybe<EnumMaterialKind>;
  in?: Maybe<Array<Maybe<EnumMaterialKind>>>;
  nin?: Maybe<Array<Maybe<EnumMaterialKind>>>;
};

export type KindOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<EnumMaterialKind>;
  gte?: Maybe<EnumMaterialKind>;
  lt?: Maybe<EnumMaterialKind>;
  lte?: Maybe<EnumMaterialKind>;
  ne?: Maybe<EnumMaterialKind>;
  in?: Maybe<Array<Maybe<EnumMaterialKind>>>;
  nin?: Maybe<Array<Maybe<EnumMaterialKind>>>;
};

export type Layout = {
  __typename?: 'Layout';
  campaign?: Maybe<Scalars['String']>;
  lpu?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  codeT?: Maybe<Scalars['String']>;
  codeTM?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnailID?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  descriptionTM?: Maybe<Scalars['String']>;
  campaignContent?: Maybe<Campaign>;
  lpuContent?: Maybe<Product>;
  thumbnail?: Maybe<File>;
};

export type LayoutCampaignContentArgs = {
  sort?: Maybe<SortCampaignEnum>;
};

export type LayoutLpuContentArgs = {
  sort?: Maybe<SortProductEnum>;
  filter?: Maybe<Filter>;
};

export type LayoutAssociateCodeItemInput = {
  codeT?: Maybe<Scalars['String']>;
  codeTM?: Maybe<Scalars['String']>;
};

export type LayoutCodeTmOperatorsFilterFindManyInventoryItemInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LayoutCodeTmOperatorsFilterFindOneInventoryItemInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LayoutContent = {
  __typename?: 'LayoutContent';
  _id?: Maybe<Scalars['String']>;
  data?: Maybe<LayoutData>;
  layout?: Maybe<Layout>;
  supplier?: Maybe<Supplier>;
  product?: Maybe<Product>;
  placesByInventoryAndLpuStructural?: Maybe<Array<Maybe<Place>>>;
};

export type LayoutContentListPayload = {
  __typename?: 'LayoutContentListPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<LayoutContent>>>;
};

export type LayoutData = {
  __typename?: 'LayoutData';
  layout?: Maybe<Scalars['String']>;
  factorial?: Maybe<Scalars['Boolean']>;
  multiplier?: Maybe<Scalars['Float']>;
  extraValueByCDL?: Maybe<Scalars['Float']>;
  extraPercentage?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  deliveryLocation?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
};

/** List of items with pagination. */
export type LayoutPagination = {
  __typename?: 'LayoutPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Layout>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum LayoutPermissionsEnum {
  LayoutViewModule = 'layout_viewModule',
  CreateLayout = 'create_layout',
  ReadLayout = 'read_layout',
  UpdateLayout = 'update_layout',
  DeleteLayout = 'delete_layout',
  UpdateStatusLayout = 'update_status_layout'
}

export type LayoutSearchByName = {
  __typename?: 'LayoutSearchByName';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Layout>>>;
};

export type LayoutSearchByNameAndContainsLpuIntervalQty = {
  __typename?: 'LayoutSearchByNameAndContainsLpuIntervalQty';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Layout>>>;
};

export type LegalText = {
  __typename?: 'LegalText';
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type LegalTextPagination = {
  __typename?: 'LegalTextPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<LegalText>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Likes = {
  __typename?: 'Likes';
  liked: Scalars['Boolean'];
  count: Scalars['Int'];
};

export type ListCodes = {
  __typename?: 'listCodes';
  list?: Maybe<Array<Maybe<Scalars['String']>>>;
  tableId?: Maybe<Scalars['String']>;
};

export type LiveAuthor = {
  __typename?: 'LiveAuthor';
  name: Scalars['String'];
};

export type LocationOptionsPayload = {
  __typename?: 'LocationOptionsPayload';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<RegionPayload>>>;
};

export type LocationSapCodeOperatorsFilterFindManyInventoryItemInput = {
  /** Código SAP do CDL */
  gt?: Maybe<Scalars['String']>;
  /** Código SAP do CDL */
  gte?: Maybe<Scalars['String']>;
  /** Código SAP do CDL */
  lt?: Maybe<Scalars['String']>;
  /** Código SAP do CDL */
  lte?: Maybe<Scalars['String']>;
  /** Código SAP do CDL */
  ne?: Maybe<Scalars['String']>;
  /** Código SAP do CDL */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Código SAP do CDL */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LocationSapCodeOperatorsFilterFindOneInventoryItemInput = {
  /** Código SAP do CDL */
  gt?: Maybe<Scalars['String']>;
  /** Código SAP do CDL */
  gte?: Maybe<Scalars['String']>;
  /** Código SAP do CDL */
  lt?: Maybe<Scalars['String']>;
  /** Código SAP do CDL */
  lte?: Maybe<Scalars['String']>;
  /** Código SAP do CDL */
  ne?: Maybe<Scalars['String']>;
  /** Código SAP do CDL */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Código SAP do CDL */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LoginToken = {
  __typename?: 'LoginToken';
  token: Scalars['String'];
  iat: Scalars['Float'];
};

export enum LogisticsPermissionsEnum {
  LogisticsViewModule = 'logistics_viewModule',
  LogisticsViewDelivery = 'logistics_viewDelivery',
  LogisticsUpdateDeliveryTime = 'logistics_updateDeliveryTime',
  LogisticsArchive = 'logistics_archive',
  LogisticsUpdateNf = 'logistics_updateNF',
  LogisticsUpdateDeliveryStatus = 'logistics_updateDeliveryStatus',
  LogisticsUpdateInventory = 'logistics_updateInventory',
  LogisticsCreateGrade = 'logistics_createGrade',
  LogisticsUploadDistribution = 'logistics_uploadDistribution'
}

export type MapaItemsPayload = {
  __typename?: 'MapaItemsPayload';
  peca?: Maybe<PecaMapa>;
  formato?: Maybe<Formato>;
};

export type MapaPdv = {
  __typename?: 'MapaPDV';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  obs?: Maybe<Scalars['String']>;
  hasObs?: Maybe<Scalars['Boolean']>;
  clusters?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterState?: Maybe<Scalars['JSON']>;
  hasPeriod?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  pecas?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  image?: Maybe<File>;
  creationInfo?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type MapaPdvPagination = {
  __typename?: 'MapaPDVPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<MapaPdv>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Material = {
  __typename?: 'Material';
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidity>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories: Array<Maybe<Category>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<MaterialStatusOption>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfig>>
  >;
  /** Data em que o material passou para categoria "approved" */
  publishedAt?: Maybe<Scalars['Date']>;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<MaterialEditor>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  likes?: Maybe<Likes>;
  changedBy?: Maybe<MaterialChangedBy>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  /** Ids dos materiais que serão listados no boletim */
  bulletinMaterialsId?: Maybe<Array<Maybe<Material>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  title_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_fuzzy?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericId?: Maybe<Scalars['Float']>;
  sameCategoryItems?: Maybe<MaterialPagination>;
  validityEnum?: Maybe<ValidityEnum>;
  kindName?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<File>>>;
  /** Retorna true se o item estiver salvo como Pin */
  pinned: Scalars['Boolean'];
  awaitingUsersApprover?: Maybe<Array<Maybe<AwaitingUsersApprover>>>;
  /** Retorna true se o usuário atual é o criador do mesmo */
  isOwner: Scalars['Boolean'];
  mainCategory?: Maybe<Category>;
  /** O usuário que criou o material (o mesmo que solicitante) */
  author?: Maybe<Author>;
  email?: Maybe<Email>;
  sentEmail?: Maybe<SentEmailHtml>;
  views?: Maybe<MaterialViews>;
  /** Array de modificações no formato http://jsonpatch.com/#operations */
  history?: Maybe<History>;
};

export type MaterialSameCategoryItemsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyMaterialInput>;
  sort?: Maybe<SortFindManyMaterialInput>;
};

export type MaterialFilesArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type MaterialMainCategoryArgs = {
  sort?: Maybe<SortMenuEnum>;
  filter?: Maybe<Filter>;
};

export type MaterialEmailArgs = {
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEmailInput>;
};

export type MaterialChangedBy = {
  __typename?: 'MaterialChangedBy';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type MaterialChangedByInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dateText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type MaterialCommunicationItemsConfig = {
  __typename?: 'MaterialCommunicationItemsConfig';
  kind?: Maybe<EnumMaterialCommunicationItemsConfigKind>;
  /** O id do email agendado */
  emailId?: Maybe<Scalars['String']>;
  /** O id da notificação agendada */
  notificationId?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  templateProps?: Maybe<MaterialCommunicationItemsConfigTemplateProps>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MaterialCommunicationItemsConfigInput = {
  kind?: Maybe<EnumMaterialCommunicationItemsConfigKind>;
  /** O id do email agendado */
  emailId?: Maybe<Scalars['String']>;
  /** O id da notificação agendada */
  notificationId?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  templateProps?: Maybe<MaterialCommunicationItemsConfigTemplatePropsInput>;
  attachmentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MaterialCommunicationItemsConfigTemplateProps = {
  __typename?: 'MaterialCommunicationItemsConfigTemplateProps';
  html?: Maybe<Scalars['String']>;
  TITLE_1?: Maybe<Scalars['String']>;
  TITLE_2?: Maybe<Scalars['String']>;
  TITLE_3?: Maybe<Scalars['String']>;
  TEXT_1?: Maybe<Scalars['String']>;
  TEXT_2?: Maybe<Scalars['String']>;
  TEXT_3?: Maybe<Scalars['String']>;
  TEXT_4?: Maybe<Scalars['String']>;
  BUTTON_TITLE?: Maybe<Scalars['String']>;
  BUTTON_LINK?: Maybe<Scalars['String']>;
  IMAGE_SRC_1?: Maybe<Scalars['String']>;
  IMAGE_SRC_2?: Maybe<Scalars['String']>;
  HIDE_BUTTON?: Maybe<Scalars['Boolean']>;
};

export type MaterialCommunicationItemsConfigTemplatePropsInput = {
  html?: Maybe<Scalars['String']>;
  TITLE_1?: Maybe<Scalars['String']>;
  TITLE_2?: Maybe<Scalars['String']>;
  TITLE_3?: Maybe<Scalars['String']>;
  TEXT_1?: Maybe<Scalars['String']>;
  TEXT_2?: Maybe<Scalars['String']>;
  TEXT_3?: Maybe<Scalars['String']>;
  TEXT_4?: Maybe<Scalars['String']>;
  BUTTON_TITLE?: Maybe<Scalars['String']>;
  BUTTON_LINK?: Maybe<Scalars['String']>;
  IMAGE_SRC_1?: Maybe<Scalars['String']>;
  IMAGE_SRC_2?: Maybe<Scalars['String']>;
  HIDE_BUTTON?: Maybe<Scalars['Boolean']>;
};

export type MaterialEditor = {
  __typename?: 'MaterialEditor';
  _id?: Maybe<Scalars['Float']>;
  parent?: Maybe<Scalars['Float']>;
  kind?: Maybe<EnumMaterialEditorKind>;
  data?: Maybe<MaterialEditorData>;
};

export type MaterialEditorData = {
  __typename?: 'MaterialEditorData';
  title?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  cols?: Maybe<Array<Maybe<MaterialEditorDataCols>>>;
  rows?: Maybe<Array<Maybe<Array<Maybe<Scalars['JSON']>>>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MaterialEditorDataCols = {
  __typename?: 'MaterialEditorDataCols';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MaterialEditorDataColsInput = {
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MaterialEditorDataInput = {
  title?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  cols?: Maybe<Array<Maybe<MaterialEditorDataColsInput>>>;
  rows?: Maybe<Array<Maybe<Array<Maybe<Scalars['JSON']>>>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type MaterialEditorInput = {
  _id?: Maybe<Scalars['Float']>;
  parent?: Maybe<Scalars['Float']>;
  kind?: Maybe<EnumMaterialEditorKind>;
  data?: Maybe<MaterialEditorDataInput>;
};

/** List of items with pagination. */
export type MaterialPagination = {
  __typename?: 'MaterialPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Material>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type MaterialSearch = {
  __typename?: 'MaterialSearch';
  pageInfo: PaginationInfo;
  items?: Maybe<Array<Maybe<Material>>>;
  count?: Maybe<Scalars['Int']>;
  autocomplete?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MaterialSearchOwners = {
  __typename?: 'MaterialSearchOwners';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type MaterialStatusOption = {
  __typename?: 'MaterialStatusOption';
  label: Scalars['String'];
  _id: Scalars['String'];
  value: EnumMaterialStatus;
  description: Scalars['String'];
};

export type MaterialValidity = {
  __typename?: 'MaterialValidity';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  /** Lista de emails para notificar quando a expiração estiver próxima */
  notifyEmailsBeforeExpire?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MaterialValidityInput = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  /** Lista de emails para notificar quando a expiração estiver próxima */
  notifyEmailsBeforeExpire?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MaterialViews = {
  __typename?: 'MaterialViews';
  count: Scalars['Int'];
};

export type MerchanUserCheck = {
  __typename?: 'MerchanUserCheck';
  active: Scalars['Boolean'];
  firstAccess: Scalars['Boolean'];
  tempPassword: Scalars['Boolean'];
  blockedByAtempts: Scalars['Boolean'];
  maskedEmail: Scalars['String'];
};

export type Metadata = {
  __typename?: 'Metadata';
  title?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  RegionRemoveOne?: Maybe<RemoveOneRegionPayload>;
  UserContentPermissionToggle?: Maybe<Scalars['Boolean']>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  UserUpdateOne?: Maybe<UpdateOneUserPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  UserRemoveById?: Maybe<RemoveByIdUserPayload>;
  UserAcceptTerm?: Maybe<Scalars['Boolean']>;
  UserLoginWithPassword?: Maybe<User>;
  UserLoginWithToken?: Maybe<User>;
  UserLoginRenewToken?: Maybe<User>;
  UserSaveLogoutAnalytics?: Maybe<Scalars['Boolean']>;
  UserSaveLoginAnalyticsPCS?: Maybe<Scalars['Boolean']>;
  UserFillEmail?: Maybe<Scalars['Boolean']>;
  UserFillAvatarId?: Maybe<Scalars['Boolean']>;
  UserOptIn?: Maybe<Scalars['Boolean']>;
  UserMarkNotificationAsRead?: Maybe<Scalars['Boolean']>;
  UserDeleteNotification?: Maybe<Scalars['String']>;
  UserDeleteManyNotification?: Maybe<Array<Maybe<Scalars['String']>>>;
  UserRegistration?: Maybe<UserRegistrationPayload>;
  UserBlockComments?: Maybe<Scalars['Boolean']>;
  ChangeToUserTaNarede?: Maybe<UserTaNaRede>;
  UserChangePassword?: Maybe<Scalars['Boolean']>;
  UserResetPassword?: Maybe<Scalars['Boolean']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  FileCreateOne?: Maybe<CreateOneFilePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  FileRemoveById?: Maybe<RemoveByIdFilePayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  FileUpdateById?: Maybe<UpdateByIdFilePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  MaterialCreateOne?: Maybe<CreateOneMaterialPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  MaterialUpdateOne?: Maybe<UpdateOneMaterialPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  MaterialUpdateById?: Maybe<UpdateByIdMaterialPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  MaterialRemoveOne?: Maybe<RemoveOneMaterialPayload>;
  CommunicationCreateOne?: Maybe<CreateOneMaterialPayload>;
  CommunicationUpdateOne?: Maybe<UpdateOneMaterialPayload>;
  CommunicationApproveChannel?: Maybe<Scalars['Boolean']>;
  CommunicationDisapproveChannel?: Maybe<Scalars['Boolean']>;
  CommunicationRemoveApprover?: Maybe<Scalars['Boolean']>;
  EmailFillTemplate: Scalars['String'];
  /** Marca um email como lido pelo destinatario informado pelo token recebido no e-mail */
  EmailMarkAsRead?: Maybe<Scalars['Boolean']>;
  SendEmailFront?: Maybe<Scalars['Boolean']>;
  MaterialSendTestEmail: Scalars['Boolean'];
  MaterialLikeToggle?: Maybe<Scalars['Boolean']>;
  UpdateDeteachedAndLatestVideo?: Maybe<Scalars['Boolean']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  NotificationCreateOne?: Maybe<CreateOneNotificationPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  NotificationCreateMany?: Maybe<CreateManyNotificationPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  NotificationUpdateOne?: Maybe<UpdateOneNotificationPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  NotificationRemoveById?: Maybe<RemoveByIdNotificationPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  CategoryCreateOne?: Maybe<CreateOneCategoryPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  CategoryUpdateOne?: Maybe<UpdateOneCategoryPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  CategoryRemoveById?: Maybe<RemoveByIdCategoryPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  CategoryCreateMany?: Maybe<CreateManyCategoryPayload>;
  /** Adiciona uma "PushSubscriptionJSON" ao cadastro do usuario. */
  WebPushSubscribe?: Maybe<Scalars['Boolean']>;
  /** Envia uma web push notification para uma lista de userIds */
  WebPushSendNotification?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  PinCreateOne?: Maybe<CreateOnePinPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  PinRemoveOne?: Maybe<RemoveOnePinPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  PinRemoveMany?: Maybe<RemoveManyPinPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  ShareCreateOne?: Maybe<CreateOneSharePayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  ShareRemoveOne?: Maybe<RemoveOneSharePayload>;
  /** Remove o usuário atual de um compartilhamento. */
  ShareRemoveMe: Scalars['Boolean'];
  /** Create one document with mongoose defaults, setters, hooks and validation */
  CommentCreateOne?: Maybe<CreateOneCommentPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  CommentUpdateOne?: Maybe<UpdateOneCommentPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  CommentRemoveById?: Maybe<RemoveByIdCommentPayload>;
  CommentLikeToggle?: Maybe<Scalars['Boolean']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  EventLiveCreateOne?: Maybe<CreateOneEventLivePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  EventLiveRemoveById?: Maybe<RemoveByIdEventLivePayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  EventLiveUpdateOne?: Maybe<UpdateOneEventLivePayload>;
  EventLiveCloseRoom?: Maybe<Scalars['Boolean']>;
  EventLiveLikeToggle?: Maybe<Scalars['Boolean']>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  BannerRemoveById?: Maybe<RemoveByIdBannerPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  BannerCreateOne?: Maybe<CreateOneBannerPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  BannerUpdateOne?: Maybe<UpdateOneBannerPayload>;
  AnalyticsPageView?: Maybe<Scalars['Boolean']>;
  AnalyticsTrackEvent?: Maybe<Scalars['Boolean']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  FolderCreateOne?: Maybe<CreateOneFolderPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  FolderRemoveOne?: Maybe<RemoveOneFolderPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  FolderRemoveMany?: Maybe<RemoveManyFolderPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  FolderUpdateOne?: Maybe<UpdateOneFolderPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  RecentsActivitiesCreateOne?: Maybe<CreateOneRecentsActivitiesPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  UserWordsBlackListCreateOne?: Maybe<CreateOneUserWordsBlackListPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  ImportUsersPlanCreateOne?: Maybe<CreateOneImportUsersPlanPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  ImportUsersPlanUpdateOne?: Maybe<UpdateOneImportUsersPlanPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  UserHistoryBlockCreateOne?: Maybe<CreateOneUserHistoryBlockPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  EmailStatsCreateMany?: Maybe<CreateManyEmailStatsPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  EmailStatsCreateOne?: Maybe<CreateOneEmailStatsPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  EmailUnsubscribeCreateMany?: Maybe<CreateManyEmailUnsubscribePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  EmailUnsubscribeCreateOne?: Maybe<CreateOneEmailUnsubscribePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  HomebannerCreateOne?: Maybe<CreateOneHomebannerPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  HomebannerUpdateById?: Maybe<UpdateByIdHomebannerPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  PlaceRemoveById?: Maybe<RemoveByIdPlacePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  PlaceCreateOne?: Maybe<CreateOnePlacePayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  PlaceUpdateById?: Maybe<UpdateByIdPlacePayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  PlaceUpdateOne?: Maybe<UpdateOnePlacePayload>;
  PlacesBaseUpload?: Maybe<Scalars['JSON']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  ContractCreateOne?: Maybe<CreateOneContractPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  ContractCreateMany?: Maybe<CreateManyContractPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  ContractUpdateOne?: Maybe<UpdateOneContractPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  ContractUpdateById?: Maybe<UpdateByIdContractPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  ContractRemoveById?: Maybe<RemoveByIdContractPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  ProductCreateOne?: Maybe<CreateOneProductPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  ProductUpdateOne?: Maybe<UpdateOneProductPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  ProductCreateMany?: Maybe<CreateManyProductPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  ProductUpdateById?: Maybe<UpdateByIdProductPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  ProductRemoveById?: Maybe<RemoveByIdProductPayload>;
  ProductBaseUpload?: Maybe<Scalars['JSON']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  SupplierCreateOne?: Maybe<CreateOneSupplierPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  SupplierUpdateOne?: Maybe<UpdateOneSupplierPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  SupplierUpdateById?: Maybe<UpdateByIdSupplierPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  SupplierRemoveById?: Maybe<RemoveByIdSupplierPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  TablePriceCreateOne?: Maybe<CreateOneTablePricePayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  TablePriceUpdateOne?: Maybe<UpdateOneTablePricePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  TablePriceRemoveById?: Maybe<RemoveByIdTablePricePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  PricingTableCreateOne?: Maybe<CreateOnePricingTablePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  PricingTableRemoveById?: Maybe<RemoveByIdPricingTablePayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  PricingTableUpdateById?: Maybe<UpdateByIdPricingTablePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  LegalTextCreateOne?: Maybe<CreateOneLegalTextPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  LegalTextUpdateById?: Maybe<UpdateByIdLegalTextPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  LegalTextRemoveById?: Maybe<RemoveByIdLegalTextPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  DeviceCreateOne?: Maybe<CreateOneDevicePayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  DeviceUpdateOne?: Maybe<UpdateOneDevicePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  DeviceRemoveById?: Maybe<RemoveByIdDevicePayload>;
  /** Marca um device como deletado */
  DeleteDevice?: Maybe<Device>;
  DeviceBaseUpload?: Maybe<Scalars['JSON']>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  DigitalBrochureCreateOne?: Maybe<CreateOneDigitalBrochurePayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  DigitalBrochureUpdateOne?: Maybe<UpdateOneDigitalBrochurePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  DigitalBrochureRemoveById?: Maybe<RemoveByIdDigitalBrochurePayload>;
  BrochureUpdateThumbnail: Array<Maybe<BrochureUpdateThumbnailPayload>>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  CampaignCreateOne?: Maybe<CreateOneCampaignPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  CampaignUpdateOne?: Maybe<UpdateOneCampaignPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  CampaignRemoveById?: Maybe<RemoveByIdCampaignPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  LayoutCreateOne?: Maybe<CreateOneLayoutPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  LayoutCreateMany?: Maybe<CreateManyLayoutPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  LayoutUpdateOne?: Maybe<UpdateOneLayoutPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  LayoutUpdateById?: Maybe<UpdateByIdLayoutPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  LayoutRemoveById?: Maybe<RemoveByIdLayoutPayload>;
  LayoutAssociateCodeTM?: Maybe<Scalars['Boolean']>;
  RegionalBudgetMarkAsViewed?: Maybe<Scalars['Boolean']>;
  BudgetMarkAsFinished?: Maybe<Scalars['Boolean']>;
  RegionalBudgetParseItems?: Maybe<RegionalBudgetParseItemsPayload>;
  RegionalBudgetUpdateProductionItems?: Maybe<RegionalBudget>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  ProductionCreateOne?: Maybe<CreateOneProductionPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  ProductionUpdateById?: Maybe<UpdateByIdProductionPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  ProductionRemoveById?: Maybe<RemoveByIdProductionPayload>;
  /** Marca um item como arquivado para que nao apareca mais na listagem na pagina 'Logística' para o fornecedor */
  ProductionArchiveItem?: Maybe<Production>;
  ProductionParseItems?: Maybe<ProductionParseItemsPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  BudgetCreateOne?: Maybe<CreateOneBudgetPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  BudgetUpdateById?: Maybe<UpdateByIdBudgetPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  BudgetRemoveById?: Maybe<RemoveByIdBudgetPayload>;
  SupplierDeliveryUpdateStatus?: Maybe<Scalars['Boolean']>;
  /** Sincroniza o estoque com nos dados vindos do xlsx (parse do xlsx feito no
   * front). Remove todo o estoque existente no local informado e sincroniza.
   */
  InventorySync?: Maybe<Array<Maybe<InventoryItem>>>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  InventoryWithDrawCreateOne?: Maybe<CreateOneInventoryWithDrawPayload>;
  InventoryCreateWithDraw?: Maybe<Array<Maybe<InventoryWithDraw>>>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  DeliveryOrderCreateOne?: Maybe<CreateOneDeliveryOrderPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  DeliveryOrderUpdateById?: Maybe<UpdateByIdDeliveryOrderPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  DeliveryOrderRemoveById?: Maybe<RemoveByIdDeliveryOrderPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  MetadataCreateOne?: Maybe<CreateOneMetadataPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  MetadataUpdateOne?: Maybe<UpdateOneMetadataPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  MetadataRemoveById?: Maybe<RemoveByIdMetadataPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  MetadataUpdateById?: Maybe<UpdateByIdMetadataPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  PositivatorCreateOne?: Maybe<CreateOnePositivatorPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  PositivatorUpdateOne?: Maybe<UpdateOnePositivatorPayload>;
  /** Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  PositivatorUpdateMany?: Maybe<UpdateManyPositivatorPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  PositivatorRemoveById?: Maybe<RemoveByIdPositivatorPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  PositivatorRemoveMany?: Maybe<RemoveManyPositivatorPayload>;
  PositivatorNotificationCreateOne?: Maybe<Notification>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  PositivatorItemCreateOne?: Maybe<CreateOnePositivatorItemPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  PositivatorItemUpdateById?: Maybe<UpdateByIdPositivatorItemPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  PositivatorItemRemoveById?: Maybe<RemoveByIdPositivatorItemPayload>;
  DeviceNotificationMarkAsRead?: Maybe<DeviceNotification>;
  DeviceNotificationMarkAsSolved?: Maybe<DeviceNotification>;
  MerchanRequestTempPassword: Scalars['Boolean'];
  MerchanVerifyTempCode: Scalars['Boolean'];
  MerchanChangePassword: Scalars['Boolean'];
  /** Create one document with mongoose defaults, setters, hooks and validation */
  StructuralCreateOne?: Maybe<CreateOneStructuralPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  StructuralUpdateOne?: Maybe<UpdateOneStructuralPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  StructuralRemoveById?: Maybe<RemoveByIdStructuralPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  StructuralUpdateById?: Maybe<UpdateByIdStructuralPayload>;
  StructuralEditStatus?: Maybe<StructuralEditStatusPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  DigitalBrochureAnalyticsCreateOne?: Maybe<
    CreateOneDigitalBrochureAnalyticsPayload
  >;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  AreaLojaCreateOne?: Maybe<CreateOneAreaLojaPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  AreaLojaUpdateById?: Maybe<UpdateByIdAreaLojaPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  AreaLojaRemoveById?: Maybe<RemoveByIdAreaLojaPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  RegraMapaCreateOne?: Maybe<CreateOneRegraMapaPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  RegraMapaUpdateById?: Maybe<UpdateByIdRegraMapaPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  RegraMapaRemoveById?: Maybe<RemoveByIdRegraMapaPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  FormatoCreateOne?: Maybe<CreateOneFormatoPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  FormatoUpdateById?: Maybe<UpdateByIdFormatoPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  FormatoRemoveById?: Maybe<RemoveByIdFormatoPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  PecaMapaCreateOne?: Maybe<CreateOnePecaMapaPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  PecaMapaUpdateById?: Maybe<UpdateByIdPecaMapaPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  PecaMapaRemoveById?: Maybe<RemoveByIdPecaMapaPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  MapaPDVCreateOne?: Maybe<CreateOneMapaPdvPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  MapaPDVUpdateById?: Maybe<UpdateByIdMapaPdvPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  MapaPDVRemoveById?: Maybe<RemoveByIdMapaPdvPayload>;
};

export type MutationRegionRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOneRegionInput>;
  sort?: Maybe<SortRemoveOneRegionInput>;
};

export type MutationUserContentPermissionToggleArgs = {
  userId: Scalars['String'];
  roleGroups: Array<Maybe<Scalars['String']>>;
  regions: Array<Maybe<Scalars['String']>>;
  contentTypes: Array<Maybe<Scalars['String']>>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
};

export type MutationUserUpdateOneArgs = {
  record: UpdateOneUserInput;
  filter?: Maybe<FilterUpdateOneUserInput>;
  sort?: Maybe<SortUpdateOneUserInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationUserRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationUserAcceptTermArgs = {
  term: Scalars['String'];
};

export type MutationUserLoginWithPasswordArgs = {
  user: Scalars['String'];
  password: Scalars['String'];
  platformName?: Maybe<PlatformNamesEnum>;
};

export type MutationUserLoginWithTokenArgs = {
  token: Scalars['String'];
  platformName?: Maybe<PlatformNamesEnum>;
};

export type MutationUserLoginRenewTokenArgs = {
  token: Scalars['String'];
};

export type MutationUserFillEmailArgs = {
  email: Scalars['String'];
};

export type MutationUserFillAvatarIdArgs = {
  avatarId: Scalars['String'];
};

export type MutationUserOptInArgs = {
  accept: Scalars['Boolean'];
};

export type MutationUserMarkNotificationAsReadArgs = {
  isRead: Scalars['Boolean'];
  notifications: Array<Maybe<Scalars['String']>>;
};

export type MutationUserDeleteNotificationArgs = {
  notificationId: Scalars['String'];
};

export type MutationUserDeleteManyNotificationArgs = {
  notificationIds?: Maybe<Array<Scalars['String']>>;
};

export type MutationUserRegistrationArgs = {
  record: UserRegistrationTypeInput;
};

export type MutationUserBlockCommentsArgs = {
  userId: Scalars['String'];
  isBlocked?: Maybe<Scalars['Boolean']>;
  materialId?: Maybe<Scalars['String']>;
};

export type MutationUserChangePasswordArgs = {
  newPassword: Scalars['String'];
};

export type MutationUserResetPasswordArgs = {
  user: Scalars['String'];
  domain?: Maybe<Scalars['String']>;
};

export type MutationFileCreateOneArgs = {
  record: CreateOneFileInput;
};

export type MutationFileRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationFileUpdateByIdArgs = {
  record: UpdateByIdFileInput;
};

export type MutationMaterialCreateOneArgs = {
  record: CreateOneMaterialInput;
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type MutationMaterialUpdateOneArgs = {
  record: UpdateOneMaterialInput;
  filter?: Maybe<FilterUpdateOneMaterialInput>;
  sort?: Maybe<SortUpdateOneMaterialInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationMaterialUpdateByIdArgs = {
  record: UpdateByIdMaterialInput;
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type MutationMaterialRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOneMaterialInput>;
  sort?: Maybe<SortRemoveOneMaterialInput>;
};

export type MutationCommunicationCreateOneArgs = {
  record: CreateOneMaterialInput;
};

export type MutationCommunicationUpdateOneArgs = {
  communicationId: Scalars['String'];
  record?: Maybe<UpdateOneMaterialInput>;
};

export type MutationCommunicationApproveChannelArgs = {
  recordId: Scalars['String'];
};

export type MutationCommunicationDisapproveChannelArgs = {
  recordId: Scalars['String'];
  message: Scalars['String'];
};

export type MutationCommunicationRemoveApproverArgs = {
  communicationId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationEmailFillTemplateArgs = {
  template?: Maybe<EmailTemplateNames>;
  props?: Maybe<MaterialCommunicationItemsConfigTemplatePropsInput>;
};

export type MutationEmailMarkAsReadArgs = {
  token: Scalars['String'];
};

export type MutationSendEmailFrontArgs = {
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  attach?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationMaterialSendTestEmailArgs = {
  recordId: Scalars['String'];
};

export type MutationMaterialLikeToggleArgs = {
  recordId: Scalars['String'];
};

export type MutationUpdateDeteachedAndLatestVideoArgs = {
  idDetachedVideo?: Maybe<Scalars['String']>;
  idLatestVideo1?: Maybe<Scalars['String']>;
  idLatestVideo2?: Maybe<Scalars['String']>;
  idLatestVideo3?: Maybe<Scalars['String']>;
};

export type MutationNotificationCreateOneArgs = {
  record: CreateOneNotificationInput;
};

export type MutationNotificationCreateManyArgs = {
  records: Array<CreateManyNotificationInput>;
};

export type MutationNotificationUpdateOneArgs = {
  record: UpdateOneNotificationInput;
  filter?: Maybe<FilterUpdateOneNotificationInput>;
  sort?: Maybe<SortUpdateOneNotificationInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationNotificationRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationCategoryCreateOneArgs = {
  record: CreateOneCategoryInput;
  filter?: Maybe<Filter>;
};

export type MutationCategoryUpdateOneArgs = {
  record: UpdateOneCategoryInput;
  filter?: Maybe<FilterUpdateOneCategoryInput>;
  sort?: Maybe<SortUpdateOneCategoryInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationCategoryRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type MutationCategoryCreateManyArgs = {
  records: Array<CreateManyCategoryInput>;
  filter?: Maybe<Filter>;
};

export type MutationWebPushSubscribeArgs = {
  subscription: Scalars['JSON'];
};

export type MutationWebPushSendNotificationArgs = {
  userIds: Array<Maybe<Scalars['String']>>;
  notificationOptions: Scalars['JSON'];
  sync?: Maybe<Scalars['Boolean']>;
};

export type MutationPinCreateOneArgs = {
  record: CreateOnePinInput;
};

export type MutationPinRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOnePinInput>;
  sort?: Maybe<SortRemoveOnePinInput>;
};

export type MutationPinRemoveManyArgs = {
  filter: FilterRemoveManyPinInput;
};

export type MutationShareCreateOneArgs = {
  record: CreateOneShareInput;
};

export type MutationShareRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOneShareInput>;
  sort?: Maybe<SortRemoveOneShareInput>;
};

export type MutationShareRemoveMeArgs = {
  shareId?: Maybe<Scalars['String']>;
};

export type MutationCommentCreateOneArgs = {
  record: CreateOneCommentInput;
};

export type MutationCommentUpdateOneArgs = {
  record: UpdateOneCommentInput;
  filter?: Maybe<FilterUpdateOneCommentInput>;
  sort?: Maybe<SortUpdateOneCommentInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationCommentRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationCommentLikeToggleArgs = {
  recordId: Scalars['String'];
};

export type MutationEventLiveCreateOneArgs = {
  record: CreateOneEventLiveInput;
  filter?: Maybe<Filter>;
};

export type MutationEventLiveRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type MutationEventLiveUpdateOneArgs = {
  record: UpdateOneEventLiveInput;
  filter?: Maybe<FilterUpdateOneEventLiveInput>;
  sort?: Maybe<SortUpdateOneEventLiveInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationEventLiveCloseRoomArgs = {
  eventId: Scalars['String'];
};

export type MutationEventLiveLikeToggleArgs = {
  recordId: Scalars['String'];
};

export type MutationBannerRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type MutationBannerCreateOneArgs = {
  record: CreateOneBannerInput;
  filter?: Maybe<Filter>;
};

export type MutationBannerUpdateOneArgs = {
  record: UpdateOneBannerInput;
  filter?: Maybe<FilterUpdateOneBannerInput>;
  sort?: Maybe<SortUpdateOneBannerInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationAnalyticsPageViewArgs = {
  title: Scalars['String'];
  host?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
};

export type MutationAnalyticsTrackEventArgs = {
  event: EnumAnalyticsEvent;
  kind: EnumAnalyticsParentKind;
  recordId?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type MutationFolderCreateOneArgs = {
  record: CreateOneFolderInput;
};

export type MutationFolderRemoveOneArgs = {
  filter?: Maybe<FilterRemoveOneFolderInput>;
  sort?: Maybe<SortRemoveOneFolderInput>;
};

export type MutationFolderRemoveManyArgs = {
  filter: FilterRemoveManyFolderInput;
};

export type MutationFolderUpdateOneArgs = {
  record: UpdateOneFolderInput;
  filter?: Maybe<FilterUpdateOneFolderInput>;
  sort?: Maybe<SortUpdateOneFolderInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationRecentsActivitiesCreateOneArgs = {
  record: CreateOneRecentsActivitiesInput;
};

export type MutationUserWordsBlackListCreateOneArgs = {
  record: CreateOneUserWordsBlackListInput;
};

export type MutationImportUsersPlanCreateOneArgs = {
  record: CreateOneImportUsersPlanInput;
  filter?: Maybe<Filter>;
};

export type MutationImportUsersPlanUpdateOneArgs = {
  record: UpdateOneImportUsersPlanInput;
  filter?: Maybe<FilterUpdateOneImportUsersPlanInput>;
  sort?: Maybe<SortUpdateOneImportUsersPlanInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationUserHistoryBlockCreateOneArgs = {
  record: CreateOneUserHistoryBlockInput;
  sort?: Maybe<Sort>;
};

export type MutationEmailStatsCreateManyArgs = {
  records: Array<CreateManyEmailStatsInput>;
};

export type MutationEmailStatsCreateOneArgs = {
  record: CreateOneEmailStatsInput;
};

export type MutationEmailUnsubscribeCreateManyArgs = {
  records: Array<CreateManyEmailUnsubscribeInput>;
};

export type MutationEmailUnsubscribeCreateOneArgs = {
  record: CreateOneEmailUnsubscribeInput;
};

export type MutationHomebannerCreateOneArgs = {
  record: CreateOneHomebannerInput;
};

export type MutationHomebannerUpdateByIdArgs = {
  record: UpdateByIdHomebannerInput;
};

export type MutationPlaceRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type MutationPlaceCreateOneArgs = {
  record: CreateOnePlaceInput;
  filter?: Maybe<Filter>;
};

export type MutationPlaceUpdateByIdArgs = {
  record: UpdateByIdPlaceInput;
  filter?: Maybe<Filter>;
};

export type MutationPlaceUpdateOneArgs = {
  record: UpdateOnePlaceInput;
  filter?: Maybe<FilterUpdateOnePlaceInput>;
  sort?: Maybe<SortUpdateOnePlaceInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationPlacesBaseUploadArgs = {
  fileId: Scalars['String'];
  operations: Array<Scalars['String']>;
};

export type MutationContractCreateOneArgs = {
  record: CreateOneContractInput;
};

export type MutationContractCreateManyArgs = {
  records: Array<CreateManyContractInput>;
};

export type MutationContractUpdateOneArgs = {
  record: UpdateOneContractInput;
  filter?: Maybe<FilterUpdateOneContractInput>;
  sort?: Maybe<SortUpdateOneContractInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationContractUpdateByIdArgs = {
  record: UpdateByIdContractInput;
};

export type MutationContractRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationProductCreateOneArgs = {
  record: CreateOneProductInput;
};

export type MutationProductUpdateOneArgs = {
  record: UpdateOneProductInput;
  filter?: Maybe<FilterUpdateOneProductInput>;
  sort?: Maybe<SortUpdateOneProductInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationProductCreateManyArgs = {
  records: Array<CreateManyProductInput>;
};

export type MutationProductUpdateByIdArgs = {
  record: UpdateByIdProductInput;
};

export type MutationProductRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationProductBaseUploadArgs = {
  fileId: Scalars['String'];
  operations: Array<Scalars['String']>;
};

export type MutationSupplierCreateOneArgs = {
  record: CreateOneSupplierInput;
  filter?: Maybe<Filter>;
};

export type MutationSupplierUpdateOneArgs = {
  record: UpdateOneSupplierInput;
  filter?: Maybe<FilterUpdateOneSupplierInput>;
  sort?: Maybe<SortUpdateOneSupplierInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationSupplierUpdateByIdArgs = {
  record: UpdateByIdSupplierInput;
  filter?: Maybe<Filter>;
};

export type MutationSupplierRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type MutationTablePriceCreateOneArgs = {
  record: CreateOneTablePriceInput;
};

export type MutationTablePriceUpdateOneArgs = {
  record: UpdateOneTablePriceInput;
  filter?: Maybe<FilterUpdateOneTablePriceInput>;
  sort?: Maybe<SortUpdateOneTablePriceInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationTablePriceRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationPricingTableCreateOneArgs = {
  record: CreateOnePricingTableInput;
};

export type MutationPricingTableRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationPricingTableUpdateByIdArgs = {
  record: UpdateByIdPricingTableInput;
};

export type MutationLegalTextCreateOneArgs = {
  record: CreateOneLegalTextInput;
};

export type MutationLegalTextUpdateByIdArgs = {
  record: UpdateByIdLegalTextInput;
};

export type MutationLegalTextRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationDeviceCreateOneArgs = {
  record: CreateOneDeviceInput;
  filter?: Maybe<Filter>;
};

export type MutationDeviceUpdateOneArgs = {
  record: UpdateOneDeviceInput;
  filter?: Maybe<FilterUpdateOneDeviceInput>;
  sort?: Maybe<SortUpdateOneDeviceInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationDeviceRemoveByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type MutationDeleteDeviceArgs = {
  recordId: Scalars['String'];
};

export type MutationDeviceBaseUploadArgs = {
  fileId: Scalars['String'];
};

export type MutationDigitalBrochureCreateOneArgs = {
  record: CreateOneDigitalBrochureInput;
};

export type MutationDigitalBrochureUpdateOneArgs = {
  record: UpdateOneDigitalBrochureInput;
  filter?: Maybe<FilterUpdateOneDigitalBrochureInput>;
  sort?: Maybe<SortUpdateOneDigitalBrochureInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationDigitalBrochureRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationBrochureUpdateThumbnailArgs = {
  filter: FilterFindManyDigitalBrochureInput;
  density: Scalars['Int'];
};

export type MutationCampaignCreateOneArgs = {
  record: CreateOneCampaignInput;
};

export type MutationCampaignUpdateOneArgs = {
  record: UpdateOneCampaignInput;
  filter?: Maybe<FilterUpdateOneCampaignInput>;
  sort?: Maybe<SortUpdateOneCampaignInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationCampaignRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationLayoutCreateOneArgs = {
  record: CreateOneLayoutInput;
};

export type MutationLayoutCreateManyArgs = {
  records: Array<CreateManyLayoutInput>;
};

export type MutationLayoutUpdateOneArgs = {
  record: UpdateOneLayoutInput;
  filter?: Maybe<FilterUpdateOneLayoutInput>;
  sort?: Maybe<SortUpdateOneLayoutInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationLayoutUpdateByIdArgs = {
  record: UpdateByIdLayoutInput;
};

export type MutationLayoutRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationLayoutAssociateCodeTmArgs = {
  items?: Maybe<Array<Maybe<LayoutAssociateCodeItemInput>>>;
};

export type MutationRegionalBudgetMarkAsViewedArgs = {
  regionalBudgetId: Scalars['String'];
};

export type MutationBudgetMarkAsFinishedArgs = {
  budgetId: Scalars['String'];
};

export type MutationRegionalBudgetParseItemsArgs = {
  regionalBudgetId: Scalars['String'];
  parentBudgetId: Scalars['String'];
  items?: Maybe<Array<Maybe<ProductionParsedItemsInput>>>;
};

export type MutationRegionalBudgetUpdateProductionItemsArgs = {
  regionalBudgetId: Scalars['String'];
  productionItems?: Maybe<Array<Maybe<ProductionItemInput>>>;
};

export type MutationProductionCreateOneArgs = {
  record: CreateOneProductionInput;
};

export type MutationProductionUpdateByIdArgs = {
  record: UpdateByIdProductionInput;
};

export type MutationProductionRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationProductionArchiveItemArgs = {
  recordId: Scalars['String'];
  archive: Scalars['Boolean'];
};

export type MutationProductionParseItemsArgs = {
  items?: Maybe<Array<Maybe<ProductionParsedItemsInput>>>;
};

export type MutationBudgetCreateOneArgs = {
  record: CreateOneBudgetInput;
};

export type MutationBudgetUpdateByIdArgs = {
  record: UpdateByIdBudgetInput;
};

export type MutationBudgetRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationSupplierDeliveryUpdateStatusArgs = {
  productionId: Scalars['String'];
  records: Array<Maybe<SupplierDeliveryUpdateStatusInput>>;
};

export type MutationInventorySyncArgs = {
  depositPaths: Array<Maybe<Scalars['String']>>;
  records: Array<Maybe<InventorySyncProduct>>;
};

export type MutationInventoryWithDrawCreateOneArgs = {
  record: CreateOneInventoryWithDrawInput;
};

export type MutationInventoryCreateWithDrawArgs = {
  records?: Maybe<Array<Maybe<CreateOneInventoryWithDrawInput>>>;
};

export type MutationDeliveryOrderCreateOneArgs = {
  record: CreateOneDeliveryOrderInput;
};

export type MutationDeliveryOrderUpdateByIdArgs = {
  record: UpdateByIdDeliveryOrderInput;
};

export type MutationDeliveryOrderRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationMetadataCreateOneArgs = {
  record: CreateOneMetadataInput;
};

export type MutationMetadataUpdateOneArgs = {
  record: UpdateOneMetadataInput;
  filter?: Maybe<FilterUpdateOneMetadataInput>;
  sort?: Maybe<SortUpdateOneMetadataInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationMetadataRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationMetadataUpdateByIdArgs = {
  record: UpdateByIdMetadataInput;
};

export type MutationPositivatorCreateOneArgs = {
  record: CreateOnePositivatorInput;
};

export type MutationPositivatorUpdateOneArgs = {
  record: UpdateOnePositivatorInput;
  filter?: Maybe<FilterUpdateOnePositivatorInput>;
  sort?: Maybe<SortUpdateOnePositivatorInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationPositivatorUpdateManyArgs = {
  record: UpdateManyPositivatorInput;
  filter?: Maybe<FilterUpdateManyPositivatorInput>;
  sort?: Maybe<SortUpdateManyPositivatorInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
};

export type MutationPositivatorRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationPositivatorRemoveManyArgs = {
  filter: FilterRemoveManyPositivatorInput;
};

export type MutationPositivatorNotificationCreateOneArgs = {
  positivedPercentageInterval?: Maybe<PositivatorNumberIntervalInput>;
  regionItems: Array<Maybe<Scalars['String']>>;
  channels: Array<Maybe<EnumPlaceType>>;
  text: Scalars['String'];
  schedule: Scalars['Date'];
};

export type MutationPositivatorItemCreateOneArgs = {
  record: CreateOnePositivatorItemInput;
};

export type MutationPositivatorItemUpdateByIdArgs = {
  record: UpdateByIdPositivatorItemInput;
};

export type MutationPositivatorItemRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationDeviceNotificationMarkAsReadArgs = {
  _id: Scalars['String'];
};

export type MutationDeviceNotificationMarkAsSolvedArgs = {
  notificationId: Scalars['String'];
  devicesIds: Array<Scalars['String']>;
};

export type MutationMerchanRequestTempPasswordArgs = {
  email: Scalars['String'];
  CPF?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
};

export type MutationMerchanVerifyTempCodeArgs = {
  code: Scalars['String'];
  CPF?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
};

export type MutationMerchanChangePasswordArgs = {
  CPF?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationStructuralCreateOneArgs = {
  record: CreateOneStructuralInput;
};

export type MutationStructuralUpdateOneArgs = {
  record: UpdateOneStructuralInput;
  filter?: Maybe<FilterUpdateOneStructuralInput>;
  sort?: Maybe<SortUpdateOneStructuralInput>;
  skip?: Maybe<Scalars['Int']>;
};

export type MutationStructuralRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationStructuralUpdateByIdArgs = {
  record: UpdateByIdStructuralInput;
};

export type MutationStructuralEditStatusArgs = {
  projectId?: Maybe<Scalars['String']>;
  stage?: Maybe<EnumStructuralStage>;
  status?: Maybe<ProjectStageStatus>;
  data?: Maybe<Scalars['JSON']>;
  isComment?: Maybe<Scalars['Boolean']>;
  isFile?: Maybe<Scalars['Boolean']>;
};

export type MutationDigitalBrochureAnalyticsCreateOneArgs = {
  record: CreateOneDigitalBrochureAnalyticsInput;
};

export type MutationAreaLojaCreateOneArgs = {
  record: CreateOneAreaLojaInput;
};

export type MutationAreaLojaUpdateByIdArgs = {
  record: UpdateByIdAreaLojaInput;
};

export type MutationAreaLojaRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationRegraMapaCreateOneArgs = {
  record: CreateOneRegraMapaInput;
};

export type MutationRegraMapaUpdateByIdArgs = {
  record: UpdateByIdRegraMapaInput;
};

export type MutationRegraMapaRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationFormatoCreateOneArgs = {
  record: CreateOneFormatoInput;
};

export type MutationFormatoUpdateByIdArgs = {
  record: UpdateByIdFormatoInput;
};

export type MutationFormatoRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationPecaMapaCreateOneArgs = {
  record: CreateOnePecaMapaInput;
};

export type MutationPecaMapaUpdateByIdArgs = {
  record: UpdateByIdPecaMapaInput;
};

export type MutationPecaMapaRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MutationMapaPdvCreateOneArgs = {
  record: CreateOneMapaPdvInput;
};

export type MutationMapaPdvUpdateByIdArgs = {
  record: UpdateByIdMapaPdvInput;
};

export type MutationMapaPdvRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};

export type MyMapaPayload = {
  __typename?: 'MyMapaPayload';
  key?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<MapaItemsPayload>>>;
};

export type MyMapaPdvPayload = {
  __typename?: 'MyMapaPDVPayload';
  areas?: Maybe<Array<Maybe<AreaLoja>>>;
  data?: Maybe<Array<Maybe<MyMapaPayload>>>;
};

export type NameOperatorsFilterFindManyChannelInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindManyRegionInput = {
  /** Nome da regiao. Ex: Sudeste */
  gt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  gte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  ne?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Nome da regiao. Ex: Sudeste */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindManyRoleGroupInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindManyUserInput = {
  /** O nome completo do usuario. */
  gt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  gte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  ne?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindOneChannelInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindOneRegionInput = {
  /** Nome da regiao. Ex: Sudeste */
  gt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  gte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  ne?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Nome da regiao. Ex: Sudeste */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindOneRoleGroupInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterFindOneUserInput = {
  /** O nome completo do usuario. */
  gt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  gte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  ne?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterRemoveOneRegionInput = {
  /** Nome da regiao. Ex: Sudeste */
  gt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  gte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lt?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  lte?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  ne?: Maybe<Scalars['String']>;
  /** Nome da regiao. Ex: Sudeste */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Nome da regiao. Ex: Sudeste */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NameOperatorsFilterUpdateOneUserInput = {
  /** O nome completo do usuario. */
  gt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  gte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lt?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  lte?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  ne?: Maybe<Scalars['String']>;
  /** O nome completo do usuario. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Notification = {
  __typename?: 'Notification';
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
  createdAt?: Maybe<Scalars['Date']>;
  owner?: Maybe<SocialUser>;
  event?: Maybe<EventLive>;
  material?: Maybe<Material>;
  wasRead?: Maybe<Scalars['Boolean']>;
};

export type NotificationEventArgs = {
  filter?: Maybe<Filter>;
};

export type NotificationMaterialArgs = {
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

/** List of items with pagination. */
export type NotificationPagination = {
  __typename?: 'NotificationPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Notification>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Notifications = {
  __typename?: 'Notifications';
  unreadIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  readIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OldPassword = {
  __typename?: 'OldPassword';
  password: Scalars['String'];
  createdAt: Scalars['Date'];
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterAnalyticsInput = {
  day?: Maybe<DayOperatorsFilterAnalyticsInput>;
  userId?: Maybe<UserIdOperatorsFilterAnalyticsInput>;
  event?: Maybe<EventOperatorsFilterAnalyticsInput>;
  _id?: Maybe<_IdOperatorsFilterAnalyticsInput>;
  createdAt?: Maybe<CreatedAtOperatorsFilterAnalyticsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyAnalyticsInput = {
  day?: Maybe<DayOperatorsFilterFindManyAnalyticsInput>;
  userId?: Maybe<UserIdOperatorsFilterFindManyAnalyticsInput>;
  event?: Maybe<EventOperatorsFilterFindManyAnalyticsInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyAnalyticsInput>;
  createdAt?: Maybe<CreatedAtOperatorsFilterFindManyAnalyticsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyAreaLojaInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyAreaLojaInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyBannerInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyBannerInput>;
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyBannerInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyBannerInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyBannerInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyBannerInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyBannerInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyBannerInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindManyBannerInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyBudgetInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyBudgetInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCampaignInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyCampaignInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyCampaignInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCategoryInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyCategoryInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyCategoryInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyCategoryInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyCategoryInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyCategoryInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyCategoryInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindManyCategoryInput>;
  availableAtRoles?: Maybe<
    AvailableAtRolesOperatorsFilterFindManyCategoryInput
  >;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyChannelInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyChannelInput>;
  name?: Maybe<NameOperatorsFilterFindManyChannelInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCommentInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyCommentInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyCommentInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyContractInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyContractInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyDeliveryOrderInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyDeliveryOrderInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyDeviceInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyDeviceInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyDeviceNotificationInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyDeviceNotificationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyDigitalBrochureAnalyticsInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyDigitalBrochureAnalyticsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyDigitalBrochureInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyDigitalBrochureInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyDownloadSensitiveFilesInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyDownloadSensitiveFilesInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyDownloadSensitiveFilesInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyEmailInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyEmailInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyEmailStatsInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyEmailStatsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyEmailUnsubscribeInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyEmailUnsubscribeInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyEventLiveInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyEventLiveInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyEventLiveInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyEventLiveInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyEventLiveInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyEventLiveInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyEventLiveInput
  >;
  owner?: Maybe<OwnerOperatorsFilterFindManyEventLiveInput>;
  eventDate?: Maybe<EventDateOperatorsFilterFindManyEventLiveInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyEventLiveInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyFileInput = {
  parentIds?: Maybe<ParentIdsOperatorsFilterFindManyFileInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyFileInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyFolderInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyFolderInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyFolderInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyFormatoInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyFormatoInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyHomebannerInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyHomebannerInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyImportUsersPlanInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyImportUsersPlanInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyInventoryItemInput = {
  layoutCodeTM?: Maybe<LayoutCodeTmOperatorsFilterFindManyInventoryItemInput>;
  locationSAPCode?: Maybe<
    LocationSapCodeOperatorsFilterFindManyInventoryItemInput
  >;
  depositNumber?: Maybe<DepositNumberOperatorsFilterFindManyInventoryItemInput>;
  region?: Maybe<RegionOperatorsFilterFindManyInventoryItemInput>;
  descriptionTM?: Maybe<DescriptionTmOperatorsFilterFindManyInventoryItemInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyInventoryItemInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyInventoryWithDrawInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyInventoryWithDrawInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyJobTitleInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyJobTitleInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyLayoutInput = {
  codeTM?: Maybe<CodeTmOperatorsFilterFindManyLayoutInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyLayoutInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyLegalTextInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyLegalTextInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyMapaPdvInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyMapaPdvInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyMaterialInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindManyMaterialInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindManyMaterialInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindManyMaterialInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindManyMaterialInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindManyMaterialInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindManyMaterialInput
  >;
  kind?: Maybe<KindOperatorsFilterFindManyMaterialInput>;
  owner?: Maybe<OwnerOperatorsFilterFindManyMaterialInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyMaterialInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyNotificationInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyNotificationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyPecaMapaInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyPecaMapaInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyPinInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyPinInput>;
  recordId?: Maybe<RecordIdOperatorsFilterFindManyPinInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyPinInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyPlaceAnalyticsInput = {
  userId?: Maybe<UserIdOperatorsFilterFindManyPlaceAnalyticsInput>;
  CNPJ?: Maybe<CnpjOperatorsFilterFindManyPlaceAnalyticsInput>;
  codigo_sap?: Maybe<Codigo_SapOperatorsFilterFindManyPlaceAnalyticsInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyPlaceAnalyticsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyPlaceInput = {
  CNPJ?: Maybe<CnpjOperatorsFilterFindManyPlaceInput>;
  codigo_sap?: Maybe<Codigo_SapOperatorsFilterFindManyPlaceInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyPlaceInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyPositivatorInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyPositivatorInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyPositivatorItemInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyPositivatorItemInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyPricingTableInput = {
  createdBy?: Maybe<CreatedByOperatorsFilterFindManyPricingTableInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyPricingTableInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyProductInput = {
  code_tm?: Maybe<Code_TmOperatorsFilterFindManyProductInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyProductInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyProductionInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyProductionInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyProductionInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRecentsActivitiesInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyRecentsActivitiesInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyRecentsActivitiesInput>;
  updatedAt?: Maybe<UpdatedAtOperatorsFilterFindManyRecentsActivitiesInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRegionalBudgetInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyRegionalBudgetInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRegionInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyRegionInput>;
  name?: Maybe<NameOperatorsFilterFindManyRegionInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRegionItemInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyRegionItemInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRegraMapaInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyRegraMapaInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyRoleGroupInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyRoleGroupInput>;
  name?: Maybe<NameOperatorsFilterFindManyRoleGroupInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManySearchCountInput = {
  _id?: Maybe<_IdOperatorsFilterFindManySearchCountInput>;
  term?: Maybe<TermOperatorsFilterFindManySearchCountInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyShareInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyShareInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyShareInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyStructuralInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyStructuralInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManySupplierDeliveryInput = {
  _id?: Maybe<_IdOperatorsFilterFindManySupplierDeliveryInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManySupplierInput = {
  _id?: Maybe<_IdOperatorsFilterFindManySupplierInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyTablePriceInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyTablePriceInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUserDeletedInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyUserDeletedInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUserHistoryBlockInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyUserHistoryBlockInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyUserHistoryBlockInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUserInput = {
  email?: Maybe<EmailOperatorsFilterFindManyUserInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyUserInput>;
  name?: Maybe<NameOperatorsFilterFindManyUserInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUserWordsBlackListInput = {
  owner?: Maybe<OwnerOperatorsFilterFindManyUserWordsBlackListInput>;
  _id?: Maybe<_IdOperatorsFilterFindManyUserWordsBlackListInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneBannerInput = {
  owner?: Maybe<OwnerOperatorsFilterFindOneBannerInput>;
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneBannerInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneBannerInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneBannerInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneBannerInput
  >;
  availableForUsers?: Maybe<AvailableForUsersOperatorsFilterFindOneBannerInput>;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneBannerInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindOneBannerInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneBudgetInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneBudgetInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCampaignInput = {
  owner?: Maybe<OwnerOperatorsFilterFindOneCampaignInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneCampaignInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCategoryInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneCategoryInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneCategoryInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneCategoryInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneCategoryInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindOneCategoryInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneCategoryInput
  >;
  _id?: Maybe<_IdOperatorsFilterFindOneCategoryInput>;
  availableAtRoles?: Maybe<AvailableAtRolesOperatorsFilterFindOneCategoryInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneChannelInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneChannelInput>;
  name?: Maybe<NameOperatorsFilterFindOneChannelInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCommentInput = {
  owner?: Maybe<OwnerOperatorsFilterFindOneCommentInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneCommentInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneContractInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneContractInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneDeviceInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneDeviceInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneDigitalBrochureAnalyticsInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneDigitalBrochureAnalyticsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneDigitalBrochureInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneDigitalBrochureInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneEmailInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneEmailInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneEmailStatsInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneEmailStatsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneEmailUnsubscribeInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneEmailUnsubscribeInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneEventLiveInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneEventLiveInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneEventLiveInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneEventLiveInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneEventLiveInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindOneEventLiveInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneEventLiveInput
  >;
  owner?: Maybe<OwnerOperatorsFilterFindOneEventLiveInput>;
  eventDate?: Maybe<EventDateOperatorsFilterFindOneEventLiveInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneEventLiveInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneInventoryItemInput = {
  layoutCodeTM?: Maybe<LayoutCodeTmOperatorsFilterFindOneInventoryItemInput>;
  locationSAPCode?: Maybe<
    LocationSapCodeOperatorsFilterFindOneInventoryItemInput
  >;
  depositNumber?: Maybe<DepositNumberOperatorsFilterFindOneInventoryItemInput>;
  region?: Maybe<RegionOperatorsFilterFindOneInventoryItemInput>;
  descriptionTM?: Maybe<DescriptionTmOperatorsFilterFindOneInventoryItemInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneInventoryItemInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneJobTitleInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneJobTitleInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneLayoutInput = {
  codeTM?: Maybe<CodeTmOperatorsFilterFindOneLayoutInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneLayoutInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneMaterialInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterFindOneMaterialInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterFindOneMaterialInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterFindOneMaterialInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterFindOneMaterialInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterFindOneMaterialInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterFindOneMaterialInput
  >;
  kind?: Maybe<KindOperatorsFilterFindOneMaterialInput>;
  owner?: Maybe<OwnerOperatorsFilterFindOneMaterialInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneMaterialInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneMetadataInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneMetadataInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneNotificationInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneNotificationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOnePlaceAnalyticsInput = {
  userId?: Maybe<UserIdOperatorsFilterFindOnePlaceAnalyticsInput>;
  CNPJ?: Maybe<CnpjOperatorsFilterFindOnePlaceAnalyticsInput>;
  codigo_sap?: Maybe<Codigo_SapOperatorsFilterFindOnePlaceAnalyticsInput>;
  _id?: Maybe<_IdOperatorsFilterFindOnePlaceAnalyticsInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOnePlaceInput = {
  CNPJ?: Maybe<CnpjOperatorsFilterFindOnePlaceInput>;
  codigo_sap?: Maybe<Codigo_SapOperatorsFilterFindOnePlaceInput>;
  _id?: Maybe<_IdOperatorsFilterFindOnePlaceInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneRegionalBudgetInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneRegionalBudgetInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneRegionInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneRegionInput>;
  name?: Maybe<NameOperatorsFilterFindOneRegionInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneRegionItemInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneRegionItemInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneRoleGroupInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneRoleGroupInput>;
  name?: Maybe<NameOperatorsFilterFindOneRoleGroupInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneStructuralInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneStructuralInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneSupplierInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneSupplierInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneTablePriceInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneTablePriceInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneUserContentPermissionInput = {
  userId?: Maybe<UserIdOperatorsFilterFindOneUserContentPermissionInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneUserContentPermissionInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneUserDeletedInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneUserDeletedInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneUserInput = {
  email?: Maybe<EmailOperatorsFilterFindOneUserInput>;
  _id?: Maybe<_IdOperatorsFilterFindOneUserInput>;
  name?: Maybe<NameOperatorsFilterFindOneUserInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterMaterialInput = {
  availableAtRegions?: Maybe<AvailableAtRegionsOperatorsFilterMaterialInput>;
  availableAtChannels?: Maybe<AvailableAtChannelsOperatorsFilterMaterialInput>;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterMaterialInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterMaterialInput
  >;
  availableForUsers?: Maybe<AvailableForUsersOperatorsFilterMaterialInput>;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterMaterialInput
  >;
  kind?: Maybe<KindOperatorsFilterMaterialInput>;
  owner?: Maybe<OwnerOperatorsFilterMaterialInput>;
  _id?: Maybe<_IdOperatorsFilterMaterialInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveManyFolderInput = {
  owner?: Maybe<OwnerOperatorsFilterRemoveManyFolderInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveManyFolderInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveManyPinInput = {
  owner?: Maybe<OwnerOperatorsFilterRemoveManyPinInput>;
  recordId?: Maybe<RecordIdOperatorsFilterRemoveManyPinInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveManyPinInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveManyPositivatorInput = {
  _id?: Maybe<_IdOperatorsFilterRemoveManyPositivatorInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveOneFolderInput = {
  owner?: Maybe<OwnerOperatorsFilterRemoveOneFolderInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveOneFolderInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveOneMaterialInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterRemoveOneMaterialInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterRemoveOneMaterialInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterRemoveOneMaterialInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterRemoveOneMaterialInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterRemoveOneMaterialInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterRemoveOneMaterialInput
  >;
  kind?: Maybe<KindOperatorsFilterRemoveOneMaterialInput>;
  owner?: Maybe<OwnerOperatorsFilterRemoveOneMaterialInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveOneMaterialInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveOnePinInput = {
  owner?: Maybe<OwnerOperatorsFilterRemoveOnePinInput>;
  recordId?: Maybe<RecordIdOperatorsFilterRemoveOnePinInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveOnePinInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveOneRegionInput = {
  _id?: Maybe<_IdOperatorsFilterRemoveOneRegionInput>;
  name?: Maybe<NameOperatorsFilterRemoveOneRegionInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterRemoveOneShareInput = {
  owner?: Maybe<OwnerOperatorsFilterRemoveOneShareInput>;
  _id?: Maybe<_IdOperatorsFilterRemoveOneShareInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyPositivatorInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateManyPositivatorInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneBannerInput = {
  owner?: Maybe<OwnerOperatorsFilterUpdateOneBannerInput>;
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterUpdateOneBannerInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterUpdateOneBannerInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterUpdateOneBannerInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneBannerInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterUpdateOneBannerInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterUpdateOneBannerInput
  >;
  _id?: Maybe<_IdOperatorsFilterUpdateOneBannerInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCampaignInput = {
  owner?: Maybe<OwnerOperatorsFilterUpdateOneCampaignInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneCampaignInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCategoryInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterUpdateOneCategoryInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterUpdateOneCategoryInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterUpdateOneCategoryInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneCategoryInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterUpdateOneCategoryInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterUpdateOneCategoryInput
  >;
  _id?: Maybe<_IdOperatorsFilterUpdateOneCategoryInput>;
  availableAtRoles?: Maybe<
    AvailableAtRolesOperatorsFilterUpdateOneCategoryInput
  >;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCommentInput = {
  owner?: Maybe<OwnerOperatorsFilterUpdateOneCommentInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneCommentInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneContractInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneContractInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneDeviceInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneDeviceInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneDigitalBrochureInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneDigitalBrochureInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneEventLiveInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterUpdateOneEventLiveInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterUpdateOneEventLiveInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterUpdateOneEventLiveInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneEventLiveInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterUpdateOneEventLiveInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterUpdateOneEventLiveInput
  >;
  owner?: Maybe<OwnerOperatorsFilterUpdateOneEventLiveInput>;
  eventDate?: Maybe<EventDateOperatorsFilterUpdateOneEventLiveInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneEventLiveInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneFolderInput = {
  owner?: Maybe<OwnerOperatorsFilterUpdateOneFolderInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneFolderInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneImportUsersPlanInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneImportUsersPlanInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneLayoutInput = {
  codeTM?: Maybe<CodeTmOperatorsFilterUpdateOneLayoutInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneLayoutInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneMaterialInput = {
  availableAtRegions?: Maybe<
    AvailableAtRegionsOperatorsFilterUpdateOneMaterialInput
  >;
  availableAtChannels?: Maybe<
    AvailableAtChannelsOperatorsFilterUpdateOneMaterialInput
  >;
  availableAtRoleGroups?: Maybe<
    AvailableAtRoleGroupsOperatorsFilterUpdateOneMaterialInput
  >;
  availableAtRegionalProfiles?: Maybe<
    AvailableAtRegionalProfilesOperatorsFilterUpdateOneMaterialInput
  >;
  availableForUsers?: Maybe<
    AvailableForUsersOperatorsFilterUpdateOneMaterialInput
  >;
  destinationEmailsFromMailingFile?: Maybe<
    DestinationEmailsFromMailingFileOperatorsFilterUpdateOneMaterialInput
  >;
  kind?: Maybe<KindOperatorsFilterUpdateOneMaterialInput>;
  owner?: Maybe<OwnerOperatorsFilterUpdateOneMaterialInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneMaterialInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneMetadataInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneMetadataInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneNotificationInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneNotificationInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOnePlaceInput = {
  CNPJ?: Maybe<CnpjOperatorsFilterUpdateOnePlaceInput>;
  codigo_sap?: Maybe<Codigo_SapOperatorsFilterUpdateOnePlaceInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOnePlaceInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOnePositivatorInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOnePositivatorInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneProductInput = {
  code_tm?: Maybe<Code_TmOperatorsFilterUpdateOneProductInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneProductInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneStructuralInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneStructuralInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneSupplierInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneSupplierInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneTablePriceInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneTablePriceInput>;
};

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneUserInput = {
  email?: Maybe<EmailOperatorsFilterUpdateOneUserInput>;
  _id?: Maybe<_IdOperatorsFilterUpdateOneUserInput>;
  name?: Maybe<NameOperatorsFilterUpdateOneUserInput>;
};

export type OptionInfo = {
  __typename?: 'OptionInfo';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type OwnerOperatorsFilterFindManyBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyCommentInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyDownloadSensitiveFilesInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyFolderInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyPinInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyProductionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyRecentsActivitiesInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyShareInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyUserHistoryBlockInput = {
  /** Responsável pelo bloqueio */
  gt?: Maybe<Scalars['String']>;
  /** Responsável pelo bloqueio */
  gte?: Maybe<Scalars['String']>;
  /** Responsável pelo bloqueio */
  lt?: Maybe<Scalars['String']>;
  /** Responsável pelo bloqueio */
  lte?: Maybe<Scalars['String']>;
  /** Responsável pelo bloqueio */
  ne?: Maybe<Scalars['String']>;
  /** Responsável pelo bloqueio */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Responsável pelo bloqueio */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindManyUserWordsBlackListInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneCommentInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterFindOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveManyFolderInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveManyPinInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveOneFolderInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveOnePinInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterRemoveOneShareInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneBannerInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneCampaignInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneCommentInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneEventLiveInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneFolderInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OwnerOperatorsFilterUpdateOneMaterialInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  currentPage: Scalars['Int'];
  perPage: Scalars['Int'];
  pageCount?: Maybe<Scalars['Int']>;
  itemCount?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type ParentIdsOperatorsFilterFindManyFileInput = {
  /** O _id dos materiais que usam este arquivo */
  gt?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  gte?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  lt?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  lte?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  ne?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O _id dos materiais que usam este arquivo */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PecaMapa = {
  __typename?: 'PecaMapa';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['JSON']>;
  formats?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  image?: Maybe<File>;
  creationInfo?: Maybe<Scalars['String']>;
  formatsInfo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

/** List of items with pagination. */
export type PecaMapaPagination = {
  __typename?: 'PecaMapaPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<PecaMapa>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Pin = {
  __typename?: 'Pin';
  owner?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  recordId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  otherData?: Maybe<Scalars['JSON']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  folder?: Maybe<Folder>;
  material?: Maybe<Material>;
};

export type PinMaterialArgs = {
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

/** List of items with pagination. */
export type PinPagination = {
  __typename?: 'PinPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Pin>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Place = {
  __typename?: 'Place';
  type?: Maybe<EnumPlaceType>;
  disabled?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  inscricao_estadual?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  custcode?: Maybe<Scalars['String']>;
  codigo_sap?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<PlaceAddress>;
  modelo_de_loja?: Maybe<EnumPlaceModelo_De_Loja>;
  tipo_de_loja?: Maybe<EnumPlaceTipo_De_Loja>;
  tecnologia_de_rede?: Maybe<Scalars['String']>;
  produtos_comercializados?: Maybe<
    Array<Maybe<EnumPlaceProdutos_Comercializados>>
  >;
  foco_de_comunicacao?: Maybe<EnumPlaceFoco_De_Comunicacao>;
  cluster?: Maybe<EnumPlaceCluster>;
  canal_distribuicao?: Maybe<Scalars['String']>;
  organizacao_vendas?: Maybe<Scalars['String']>;
  setor_atividade?: Maybe<Scalars['String']>;
  quantidade_de_funcionarios?: Maybe<Scalars['Float']>;
  localizacao?: Maybe<EnumPlaceLocalizacao>;
  nome_da_galeria?: Maybe<Scalars['String']>;
  nome_do_shopping?: Maybe<Scalars['String']>;
  parcelamento?: Maybe<Scalars['Float']>;
  /** A estrutura de loja com adição do campo `name` */
  estrutura_de_loja?: Maybe<Array<Maybe<EstruturaDeLojaWithProduct>>>;
  inicio_da_operacao_mes?: Maybe<Scalars['Float']>;
  inicio_da_operacao_ano?: Maybe<Scalars['Float']>;
  ultima_modificacao?: Maybe<EnumPlaceUltima_Modificacao>;
  data_de_modificacao_mes?: Maybe<Scalars['Float']>;
  data_de_modificacao_ano?: Maybe<Scalars['Float']>;
  metragem?: Maybe<PlaceMetragem>;
  tipo_de_fachada?: Maybe<EnumPlaceTipo_De_Fachada>;
  revestimento_externo?: Maybe<EnumPlaceRevestimento_Externo>;
  posicoes_de_atendimento?: Maybe<Scalars['Float']>;
  posicoes_de_pos_venda?: Maybe<Scalars['Float']>;
  sala_de_gerente?: Maybe<Scalars['Boolean']>;
  back_office?: Maybe<Scalars['Boolean']>;
  wifi_clientes?: Maybe<Scalars['Boolean']>;
  sinal_live?: Maybe<Scalars['Boolean']>;
  display_de_senha?: Maybe<Scalars['Boolean']>;
  sistema_de_som?: Maybe<Scalars['Boolean']>;
  video_wall?: Maybe<Array<Maybe<PlaceVideo_Wall>>>;
  adminInput?: Maybe<PlaceAdminInput>;
  adminId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** Array de modificações no formato http://jsonpatch.com/#operations */
  history?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  isActive: Scalars['Boolean'];
  /** Admin <> Places relation */
  admin?: Maybe<User>;
  CDL?: Maybe<PlaceCdlInfo>;
};

export type PlaceAddress = {
  __typename?: 'PlaceAddress';
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  IBGE?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Boolean']>;
};

export type PlaceAddressInput = {
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  IBGE?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Boolean']>;
};

export type PlaceAdminInput = {
  __typename?: 'PlaceAdminInput';
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type PlaceAdminInputInput = {
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type PlaceAnalytics = {
  __typename?: 'PlaceAnalytics';
  userId?: Maybe<Scalars['String']>;
  logType?: Maybe<EnumPlaceAnalyticsLogType>;
  platformName?: Maybe<EnumPlaceAnalyticsPlatformName>;
  type?: Maybe<EnumPlaceAnalyticsType>;
  disabled?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  inscricao_estadual?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  custcode?: Maybe<Scalars['String']>;
  codigo_sap?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<PlaceAnalyticsAddress>;
  modelo_de_loja?: Maybe<EnumPlaceAnalyticsModelo_De_Loja>;
  tipo_de_loja?: Maybe<EnumPlaceAnalyticsTipo_De_Loja>;
  tecnologia_de_rede?: Maybe<Scalars['String']>;
  produtos_comercializados?: Maybe<
    Array<Maybe<EnumPlaceAnalyticsProdutos_Comercializados>>
  >;
  foco_de_comunicacao?: Maybe<EnumPlaceAnalyticsFoco_De_Comunicacao>;
  cluster?: Maybe<EnumPlaceAnalyticsCluster>;
  canal_distribuicao?: Maybe<Scalars['String']>;
  organizacao_vendas?: Maybe<Scalars['String']>;
  setor_atividade?: Maybe<Scalars['String']>;
  quantidade_de_funcionarios?: Maybe<Scalars['Float']>;
  localizacao?: Maybe<EnumPlaceAnalyticsLocalizacao>;
  nome_da_galeria?: Maybe<Scalars['String']>;
  nome_do_shopping?: Maybe<Scalars['String']>;
  parcelamento?: Maybe<Scalars['Float']>;
  estrutura_de_loja?: Maybe<Array<Maybe<PlaceAnalyticsEstrutura_De_Loja>>>;
  inicio_da_operacao_mes?: Maybe<Scalars['Float']>;
  inicio_da_operacao_ano?: Maybe<Scalars['Float']>;
  ultima_modificacao?: Maybe<EnumPlaceAnalyticsUltima_Modificacao>;
  data_de_modificacao_mes?: Maybe<Scalars['Float']>;
  data_de_modificacao_ano?: Maybe<Scalars['Float']>;
  metragem?: Maybe<PlaceAnalyticsMetragem>;
  tipo_de_fachada?: Maybe<EnumPlaceAnalyticsTipo_De_Fachada>;
  revestimento_externo?: Maybe<EnumPlaceAnalyticsRevestimento_Externo>;
  posicoes_de_atendimento?: Maybe<Scalars['Float']>;
  posicoes_de_pos_venda?: Maybe<Scalars['Float']>;
  sala_de_gerente?: Maybe<Scalars['Boolean']>;
  back_office?: Maybe<Scalars['Boolean']>;
  wifi_clientes?: Maybe<Scalars['Boolean']>;
  sinal_live?: Maybe<Scalars['Boolean']>;
  display_de_senha?: Maybe<Scalars['Boolean']>;
  sistema_de_som?: Maybe<Scalars['Boolean']>;
  video_wall?: Maybe<Array<Maybe<PlaceAnalyticsVideo_Wall>>>;
  adminInput?: Maybe<PlaceAnalyticsAdminInput>;
  adminId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type PlaceAnalyticsAddress = {
  __typename?: 'PlaceAnalyticsAddress';
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  IBGE?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Boolean']>;
};

export type PlaceAnalyticsAddressInput = {
  zipCode?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  cityName?: Maybe<Scalars['String']>;
  IBGE?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Boolean']>;
};

export type PlaceAnalyticsAdminInput = {
  __typename?: 'PlaceAnalyticsAdminInput';
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type PlaceAnalyticsAdminInputInput = {
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type PlaceAnalyticsEstrutura_De_Loja = {
  __typename?: 'PlaceAnalyticsEstrutura_de_loja';
  codeTM?: Maybe<Scalars['String']>;
  /** Replicado automaticamente do cadastro de produtos ao atualizar um local */
  productName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type PlaceAnalyticsEstrutura_De_LojaInput = {
  codeTM?: Maybe<Scalars['String']>;
  /** Replicado automaticamente do cadastro de produtos ao atualizar um local */
  productName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type PlaceAnalyticsMetragem = {
  __typename?: 'PlaceAnalyticsMetragem';
  terreo?: Maybe<Scalars['Float']>;
  mezanino?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type PlaceAnalyticsMetragemInput = {
  terreo?: Maybe<Scalars['Float']>;
  mezanino?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** List of items with pagination. */
export type PlaceAnalyticsPagination = {
  __typename?: 'PlaceAnalyticsPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<PlaceAnalytics>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type PlaceAnalyticsVideo_Wall = {
  __typename?: 'PlaceAnalyticsVideo_wall';
  kind?: Maybe<EnumPlaceAnalyticsVideo_WallKind>;
  label?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  orientation?: Maybe<EnumPlaceAnalyticsVideo_WallOrientation>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type PlaceAnalyticsVideo_WallInput = {
  kind?: Maybe<EnumPlaceAnalyticsVideo_WallKind>;
  label?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  orientation?: Maybe<EnumPlaceAnalyticsVideo_WallOrientation>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type PlaceCdlInfo = {
  __typename?: 'PlaceCDLInfo';
  UF: Scalars['String'];
  REGIONAL: Scalars['String'];
  CODIGO_SAP: Scalars['String'];
  CNPJ: Scalars['String'];
};

export type PlaceEstrutura_De_LojaInput = {
  codeTM?: Maybe<Scalars['String']>;
  /** Replicado automaticamente do cadastro de produtos ao atualizar um local */
  productName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type PlaceMetragem = {
  __typename?: 'PlaceMetragem';
  terreo?: Maybe<Scalars['Float']>;
  mezanino?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type PlaceMetragemInput = {
  terreo?: Maybe<Scalars['Float']>;
  mezanino?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** List of items with pagination. */
export type PlacePagination = {
  __typename?: 'PlacePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Place>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type PlacePaginationCustom = {
  __typename?: 'PlacePaginationCustom';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Place>>>;
  pageInfo: PaginationInfo;
};

export type PlacePositivationItem = {
  __typename?: 'PlacePositivationItem';
  _id: Scalars['String'];
  CNPJ: Scalars['String'];
  codigo_sap: Scalars['String'];
  nome_fantasia: Scalars['String'];
  type: Scalars['String'];
  UF: Scalars['String'];
  region: Scalars['String'];
  totalPointsInPlace?: Maybe<Scalars['Int']>;
  totalPositivated?: Maybe<Scalars['Int']>;
  positivatedPercent?: Maybe<Scalars['Float']>;
  allItems?: Maybe<Array<Scalars['String']>>;
};

export type PlacePositivationPagination = {
  __typename?: 'PlacePositivationPagination';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<PlacePositivationItem>>>;
  pageInfo: PaginationInfo;
};

export type PlacePositivatorsPayload = {
  __typename?: 'PlacePositivatorsPayload';
  notPositivatedCount?: Maybe<Scalars['Int']>;
  positivators?: Maybe<Array<Maybe<PositivatorItem>>>;
  pendingPositivators?: Maybe<Array<Maybe<PositivatorItem>>>;
  positivedPositivators?: Maybe<Array<Maybe<PositivatorItem>>>;
  removedPositivators?: Maybe<Array<Maybe<PositivatorItem>>>;
};

export type PlaceSearch = {
  __typename?: 'PlaceSearch';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Place>>>;
};

export type PlaceSearchByChannelSapCodeOrPlaceAndRegion = {
  __typename?: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Place>>>;
};

export type PlaceSearchByIdsLpuCategoryAndContainsInventory = {
  __typename?: 'PlaceSearchByIdsLpuCategoryAndContainsInventory';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Place>>>;
};

export type PlaceSearchByIdsTypeAndName = {
  __typename?: 'PlaceSearchByIdsTypeAndName';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Place>>>;
};

export enum PlacesPermissionsEnum {
  PlacesViewModule = 'places_viewModule',
  CreatePlace = 'create_place',
  ReadPlace = 'read_place',
  UpdatePlace = 'update_place',
  UpdateStatusPlace = 'update_status_place',
  DeletePlace = 'delete_place',
  PlacesValidatePlacesXml = 'places_validatePlacesXML'
}

export type PlaceVideo_Wall = {
  __typename?: 'PlaceVideo_wall';
  kind?: Maybe<EnumPlaceVideo_WallKind>;
  label?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  orientation?: Maybe<EnumPlaceVideo_WallOrientation>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type PlaceVideo_WallInput = {
  kind?: Maybe<EnumPlaceVideo_WallKind>;
  label?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  orientation?: Maybe<EnumPlaceVideo_WallOrientation>;
  _id?: Maybe<Scalars['MongoID']>;
};

export enum PlatformNamesEnum {
  PwaPrecificador = 'PWA_PRECIFICADOR',
  PwaPositivador = 'PWA_POSITIVADOR',
  ComparadorAparelho = 'COMPARADOR_APARELHO'
}

export type PointsExpireType = {
  __typename?: 'PointsExpireType';
  value?: Maybe<Scalars['Int']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export type Positivator = {
  __typename?: 'Positivator';
  isActive?: Maybe<Scalars['Boolean']>;
  isSubcategoryActive?: Maybe<Scalars['Boolean']>;
  isPositivatorActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  /** Enum no formato "PlaceType_X_ModeloDeLoja"' */
  channels?: Maybe<Array<Maybe<EnumPositivatorChannels>>>;
  cnpjs?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  imageContent?: Maybe<File>;
};

export type PositivatorItem = {
  __typename?: 'PositivatorItem';
  title?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  positivator?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUpload?: Maybe<Scalars['String']>;
  status?: Maybe<EnumPositivatorItemStatus>;
  place?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  imageContent?: Maybe<File>;
  imageUploadContent?: Maybe<File>;
};

/** List of items with pagination. */
export type PositivatorItemPagination = {
  __typename?: 'PositivatorItemPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<PositivatorItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type PositivatorNumberIntervalInput = {
  start: Scalars['Float'];
  end: Scalars['Float'];
};

/** List of items with pagination. */
export type PositivatorPagination = {
  __typename?: 'PositivatorPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Positivator>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum PositivatorPermissionsEnum {
  PositivatorViewModule = 'positivator_viewModule',
  CreatePositivator = 'create_positivator',
  ReadPositivator = 'read_positivator',
  UpdatePositivator = 'update_positivator',
  DeletePositivator = 'delete_positivator',
  UpdateStatusPositivator = 'update_status_positivator',
  CreatePositivatorplace = 'create_positivatorplace',
  ReadPositivatorplace = 'read_positivatorplace',
  UpdatePositivatorplace = 'update_positivatorplace',
  DeletePositivatorplace = 'delete_positivatorplace',
  UpdateStatusPositivatorplace = 'update_status_positivatorplace',
  CreateNotification = 'create_notification',
  ReadNotification = 'read_notification',
  UpdateNotification = 'update_notification',
  DeleteNotification = 'delete_notification',
  UpdateStatusNotification = 'update_status_notification'
}

export type PricePayload = {
  __typename?: 'PricePayload';
  plans?: Maybe<Array<Maybe<TablePlansPayload>>>;
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  planPre?: Maybe<Scalars['String']>;
  pre?: Maybe<Scalars['Int']>;
  pos?: Maybe<Scalars['Int']>;
  control?: Maybe<Scalars['Int']>;
};

export type PricingTable = {
  __typename?: 'PricingTable';
  status?: Maybe<EnumPricingTableStatus>;
  description?: Maybe<Scalars['String']>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  disabled?: Maybe<Scalars['Boolean']>;
  xlsxId?: Maybe<Scalars['String']>;
  activeSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  planPre?: Maybe<Scalars['String']>;
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  legalText?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['String']>;
  incidents?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  previous?: Maybe<Scalars['String']>;
  plansDevice?: Maybe<Scalars['JSON']>;
  devicePrices?: Maybe<Array<Maybe<PricingTableDevicePrices>>>;
  gadgetPrices?: Maybe<Array<Maybe<PricingTableGadgetPrices>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  xlsxFileContent?: Maybe<File>;
  creatorContent?: Maybe<User>;
  legalTextContent?: Maybe<LegalText>;
};

export type PricingTableDevicePrices = {
  __typename?: 'PricingTableDevicePrices';
  category?: Maybe<Scalars['String']>;
  codeTim?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  sapDescription?: Maybe<Scalars['String']>;
  planPre?: Maybe<Scalars['Float']>;
  planPos?: Maybe<Scalars['Float']>;
  planControl?: Maybe<Scalars['Float']>;
  tablePlans?: Maybe<Scalars['JSON']>;
  endDate?: Maybe<Scalars['String']>;
};

export type PricingTableDevicePricesInput = {
  category?: Maybe<Scalars['String']>;
  codeTim?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  sapDescription?: Maybe<Scalars['String']>;
  planPre?: Maybe<Scalars['Float']>;
  planPos?: Maybe<Scalars['Float']>;
  planControl?: Maybe<Scalars['Float']>;
  tablePlans?: Maybe<Scalars['JSON']>;
  endDate?: Maybe<Scalars['String']>;
};

export type PricingTableGadgetPrices = {
  __typename?: 'PricingTableGadgetPrices';
  category?: Maybe<Scalars['String']>;
  codeTim?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  sapDescription?: Maybe<Scalars['String']>;
  pre?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
};

export type PricingTableGadgetPricesInput = {
  category?: Maybe<Scalars['String']>;
  codeTim?: Maybe<Scalars['String']>;
  marketDescription?: Maybe<Scalars['String']>;
  sapDescription?: Maybe<Scalars['String']>;
  pre?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type PricingTablePagination = {
  __typename?: 'PricingTablePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<PricingTable>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type Product = {
  __typename?: 'Product';
  nome_produto?: Maybe<Scalars['String']>;
  preco_produto?: Maybe<Scalars['Float']>;
  categoria?: Maybe<EnumProductCategoria>;
  abreviacao_producao?: Maybe<Scalars['String']>;
  especificacao?: Maybe<Scalars['String']>;
  faixa_preco?: Maybe<Scalars['Boolean']>;
  especificacao_peso?: Maybe<Scalars['Float']>;
  especificacao_largura?: Maybe<Scalars['Float']>;
  especificacao_altura?: Maybe<Scalars['Float']>;
  especificacao_profundidade?: Maybe<Scalars['Float']>;
  especificacao_preco?: Maybe<Scalars['Float']>;
  intervalo_quantidade?: Maybe<Array<Maybe<ProductIntervalo_Quantidade>>>;
  code_tm?: Maybe<Scalars['String']>;
  qtd_dias?: Maybe<Scalars['Float']>;
  contagem?: Maybe<Scalars['String']>;
  tipo_multiplicador?: Maybe<EnumProductTipo_Multiplicador>;
  multiplicador_pdv?: Maybe<Scalars['Float']>;
  multiplicador_mobiliario?: Maybe<Scalars['Float']>;
  multiplicador_posicao?: Maybe<Scalars['Float']>;
  ncm?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Codigo SAP do fornecedor. "numero_fornecedor_sap" na entidade Supplier */
  supplierSAPCode?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  supplier?: Maybe<Supplier>;
  relatedToContent?: Maybe<Product>;
  pv?: Maybe<Array<Maybe<Supplier>>>;
  pl?: Maybe<Array<Maybe<Place>>>;
};

export type ProductIntervalo_Quantidade = {
  __typename?: 'ProductIntervalo_quantidade';
  quantidade_inicial?: Maybe<Scalars['Float']>;
  quantidade_final?: Maybe<Scalars['Float']>;
  preco?: Maybe<Scalars['Float']>;
};

export type ProductIntervalo_QuantidadeInput = {
  quantidade_inicial?: Maybe<Scalars['Float']>;
  quantidade_final?: Maybe<Scalars['Float']>;
  preco?: Maybe<Scalars['Float']>;
};

export type Production = {
  __typename?: 'Production';
  /** código da produção, criado dinamicamente. */
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  /** lista de locais para receber os produtos mais os filtros
   * utilizados para obter a lista de locais
   */
  productionItems?: Maybe<Array<Maybe<ProductionProductionItems>>>;
  /** Replica as informações do produto no momento em que a produção foi salva */
  finalProductsPricing?: Maybe<Array<Maybe<ProductionFinalProductsPricing>>>;
  /** quando uma producão é criada a partir de uma verba
   * salvamos o _id da verba
   */
  originBudgetId?: Maybe<Scalars['String']>;
  status?: Maybe<EnumProductionStatus>;
  owner?: Maybe<Scalars['String']>;
  /** quando uma producão é concluida, enviamos emails aos fornecedores
   * com os itens a serem produzidos
   */
  sentEmails?: Maybe<Array<Maybe<ProductionSentEmails>>>;
  /** Resumo dos dados para iniciar o processo de entrega ao enviar uma produção */
  deliverySummary?: Maybe<Array<Maybe<ProductionDeliverySummary>>>;
  readEmails?: Maybe<Array<Maybe<ProductionReadEmails>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  originBudget?: Maybe<Budget>;
  author?: Maybe<SocialUser>;
  delivery?: Maybe<Array<Maybe<SupplierDelivery>>>;
  /** Para cada produto sendo produzido retorna os dados do produto,  estado do
   * estoque, local de entrega para o fornecedor e lista de destinos finais em que
   * a TIM deve entregar (PDVs) depois que os produtos chegarem ao CDL (quando o
   * fornecedor entregar)
   */
  inventory?: Maybe<Array<Maybe<ProductionInventoryItem>>>;
  deliveryStatus?: Maybe<ProductionDeliveryStatus>;
  sentEmailStatus?: Maybe<Array<Maybe<ProductionEmailStatus>>>;
};

export type ProductionDeliveryArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManySupplierDeliveryInput>;
};

export type ProductionDeliveryStatus = {
  __typename?: 'ProductionDeliveryStatus';
  count: Scalars['Int'];
  layoutsCodeTMissingCodeTM: Array<Maybe<Scalars['String']>>;
  percentDelivered: Scalars['Float'];
  allDeliveryFilledCodeTM: Scalars['Boolean'];
};

export type ProductionDeliverySummary = {
  __typename?: 'ProductionDeliverySummary';
  productionCode?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierSAPCode?: Maybe<Scalars['String']>;
  productionId?: Maybe<Scalars['String']>;
  productionTitle?: Maybe<Scalars['String']>;
  layoutId?: Maybe<Scalars['String']>;
  layoutTitle?: Maybe<Scalars['String']>;
  layoutPassword?: Maybe<Scalars['String']>;
  layoutLink?: Maybe<Scalars['String']>;
  deliveryPlaceId?: Maybe<Scalars['String']>;
  deliveryPlaceType?: Maybe<Scalars['String']>;
  deliveryPlaceName?: Maybe<Scalars['String']>;
  deliveryPlaceTypeName?: Maybe<Scalars['String']>;
  deliveryPlaceCNPJ?: Maybe<Scalars['String']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  deliveryPlaceRazaoSocial?: Maybe<Scalars['String']>;
  deliveryPlaceNomeFantasia?: Maybe<Scalars['String']>;
  deliveryPlaceAddress?: Maybe<Scalars['String']>;
  deliveryPlaceNumber?: Maybe<Scalars['String']>;
  deliveryPlaceComplement?: Maybe<Scalars['String']>;
  deliveryPlaceZipCode?: Maybe<Scalars['String']>;
  deliveryPlaceNeighborhood?: Maybe<Scalars['String']>;
  deliveryPlaceCity?: Maybe<Scalars['String']>;
  deliveryPlaceState?: Maybe<Scalars['String']>;
  deliveryPlaceIsRegional?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['String']>;
  placeCustCode?: Maybe<Scalars['String']>;
  placeCNPJ?: Maybe<Scalars['String']>;
  placeRazaoSocial?: Maybe<Scalars['String']>;
  placeNomeFantasia?: Maybe<Scalars['String']>;
  placeRegion?: Maybe<Scalars['String']>;
  placeCity?: Maybe<Scalars['String']>;
  placeNeighborhood?: Maybe<Scalars['String']>;
  placeZipCode?: Maybe<Scalars['String']>;
  placeState?: Maybe<Scalars['String']>;
  placeAreaCode?: Maybe<Scalars['String']>;
  placeSAPCode?: Maybe<Scalars['String']>;
  placeCluster?: Maybe<Scalars['String']>;
  placeCommunicationFocus?: Maybe<Scalars['String']>;
  placeStoreModel?: Maybe<Scalars['String']>;
  placeNetworkTech?: Maybe<Scalars['String']>;
  placeInCapital?: Maybe<Scalars['Boolean']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  layoutCodeTM?: Maybe<Scalars['String']>;
  layoutDescription?: Maybe<Scalars['String']>;
  layoutExtraValueByCDL?: Maybe<Scalars['Float']>;
  layoutFinalCountAtSameDeliveryPlace?: Maybe<Scalars['Float']>;
  layoutProductionCount?: Maybe<Scalars['Float']>;
  destinationPlaceQuantity?: Maybe<Scalars['Float']>;
  layoutProductionUnitPrice?: Maybe<Scalars['Float']>;
  layoutProductionUnitPriceFormatted?: Maybe<Scalars['String']>;
  layoutProductionTotalPrice?: Maybe<Scalars['Float']>;
  layoutProductionTotalPriceFormatted?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type ProductionDeliverySummaryInput = {
  productionCode?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierSAPCode?: Maybe<Scalars['String']>;
  productionId?: Maybe<Scalars['String']>;
  productionTitle?: Maybe<Scalars['String']>;
  layoutId?: Maybe<Scalars['String']>;
  layoutTitle?: Maybe<Scalars['String']>;
  layoutPassword?: Maybe<Scalars['String']>;
  layoutLink?: Maybe<Scalars['String']>;
  deliveryPlaceId?: Maybe<Scalars['String']>;
  deliveryPlaceType?: Maybe<Scalars['String']>;
  deliveryPlaceName?: Maybe<Scalars['String']>;
  deliveryPlaceTypeName?: Maybe<Scalars['String']>;
  deliveryPlaceCNPJ?: Maybe<Scalars['String']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  deliveryPlaceRazaoSocial?: Maybe<Scalars['String']>;
  deliveryPlaceNomeFantasia?: Maybe<Scalars['String']>;
  deliveryPlaceAddress?: Maybe<Scalars['String']>;
  deliveryPlaceNumber?: Maybe<Scalars['String']>;
  deliveryPlaceComplement?: Maybe<Scalars['String']>;
  deliveryPlaceZipCode?: Maybe<Scalars['String']>;
  deliveryPlaceNeighborhood?: Maybe<Scalars['String']>;
  deliveryPlaceCity?: Maybe<Scalars['String']>;
  deliveryPlaceState?: Maybe<Scalars['String']>;
  deliveryPlaceIsRegional?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['String']>;
  placeCustCode?: Maybe<Scalars['String']>;
  placeCNPJ?: Maybe<Scalars['String']>;
  placeRazaoSocial?: Maybe<Scalars['String']>;
  placeNomeFantasia?: Maybe<Scalars['String']>;
  placeRegion?: Maybe<Scalars['String']>;
  placeCity?: Maybe<Scalars['String']>;
  placeNeighborhood?: Maybe<Scalars['String']>;
  placeZipCode?: Maybe<Scalars['String']>;
  placeState?: Maybe<Scalars['String']>;
  placeAreaCode?: Maybe<Scalars['String']>;
  placeSAPCode?: Maybe<Scalars['String']>;
  placeCluster?: Maybe<Scalars['String']>;
  placeCommunicationFocus?: Maybe<Scalars['String']>;
  placeStoreModel?: Maybe<Scalars['String']>;
  placeNetworkTech?: Maybe<Scalars['String']>;
  placeInCapital?: Maybe<Scalars['Boolean']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  layoutCodeTM?: Maybe<Scalars['String']>;
  layoutDescription?: Maybe<Scalars['String']>;
  layoutExtraValueByCDL?: Maybe<Scalars['Float']>;
  layoutFinalCountAtSameDeliveryPlace?: Maybe<Scalars['Float']>;
  layoutProductionCount?: Maybe<Scalars['Float']>;
  destinationPlaceQuantity?: Maybe<Scalars['Float']>;
  layoutProductionUnitPrice?: Maybe<Scalars['Float']>;
  layoutProductionUnitPriceFormatted?: Maybe<Scalars['String']>;
  layoutProductionTotalPrice?: Maybe<Scalars['Float']>;
  layoutProductionTotalPriceFormatted?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type ProductionEmailStatus = {
  __typename?: 'ProductionEmailStatus';
  supplierId?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  emailId?: Maybe<Scalars['String']>;
};

export type ProductionFinalProductsPricing = {
  __typename?: 'ProductionFinalProductsPricing';
  layoutId?: Maybe<Scalars['String']>;
  destinationsCount?: Maybe<Scalars['Float']>;
  layoutTitle?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  /** Intervalo de preços */
  productPriceInterval?: Maybe<
    Array<Maybe<ProductionFinalProductsPricingProductPriceInterval>>
  >;
  productFinalPriceByItem?: Maybe<Scalars['Float']>;
  productTotalPrice?: Maybe<Scalars['Float']>;
  productAmount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productTitle?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierCNPJ?: Maybe<Scalars['String']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignTitle?: Maybe<Scalars['String']>;
};

export type ProductionFinalProductsPricingInput = {
  layoutId?: Maybe<Scalars['String']>;
  destinationsCount?: Maybe<Scalars['Float']>;
  layoutTitle?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  /** Intervalo de preços */
  productPriceInterval?: Maybe<
    Array<Maybe<ProductionFinalProductsPricingProductPriceIntervalInput>>
  >;
  productFinalPriceByItem?: Maybe<Scalars['Float']>;
  productTotalPrice?: Maybe<Scalars['Float']>;
  productAmount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productTitle?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierCNPJ?: Maybe<Scalars['String']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignTitle?: Maybe<Scalars['String']>;
};

export type ProductionFinalProductsPricingProductPriceInterval = {
  __typename?: 'ProductionFinalProductsPricingProductPriceInterval';
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type ProductionFinalProductsPricingProductPriceIntervalInput = {
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type ProductionInventoryItem = {
  __typename?: 'ProductionInventoryItem';
  quantityInStock?: Maybe<Scalars['Int']>;
  layoutDescriptionTM?: Maybe<Scalars['String']>;
  supplierDelivery?: Maybe<SupplierDelivery>;
  destinationPlace?: Maybe<Place>;
  depositPath?: Maybe<Scalars['String']>;
};

export type ProductionItemInput = {
  frontendGroupId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  quantityInputMode?: Maybe<EnumProductionItemQuantityInputMode>;
  destinationsFilters?: Maybe<
    RegionalBudgetProductionItemsDestinationsFiltersInput
  >;
  /** Códigos SAP dos locais (Lojas, Escriotorios, etc) encontrados a partir do filtro informado no campo "destinationsFilters" */
  foundDestinationsSAPCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  quantityByProductInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductInputInput>>
  >;
  quantityByProductOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductOutputInput>>
  >;
  quantityByDestinationInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationInputInput>>
  >;
  /** processado no backend a partir do quantityByProductInput ou do quantityByDestinationInput dependendo do quantityInputMode */
  quantityByDestinationOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationOutputInput>>
  >;
  supplierDeliveryDestinationType?: Maybe<
    EnumProductionItemSupplierDeliveryDestinationType
  >;
  extraItemsOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsExtraItemsOutputInput>>
  >;
};

/** List of items with pagination. */
export type ProductionPagination = {
  __typename?: 'ProductionPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Production>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type ProductionParsedItemsInput = {
  frontendGroupId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  quantityInputMode?: Maybe<EnumProductionParsedItemsQuantityInputMode>;
  destinationsFilters?: Maybe<
    RegionalBudgetProductionItemsDestinationsFiltersInput
  >;
  /** Códigos SAP dos locais (Lojas, Escriotorios, etc) encontrados a partir do filtro informado no campo "destinationsFilters" */
  foundDestinationsSAPCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  quantityByProductInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductInputInput>>
  >;
  quantityByProductOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductOutputInput>>
  >;
  quantityByDestinationInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationInputInput>>
  >;
  /** processado no backend a partir do quantityByProductInput ou do quantityByDestinationInput dependendo do quantityInputMode */
  quantityByDestinationOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationOutputInput>>
  >;
  supplierDeliveryDestinationType?: Maybe<
    EnumProductionParsedItemsSupplierDeliveryDestinationType
  >;
  extraItemsOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsExtraItemsOutputInput>>
  >;
};

export type ProductionParseItemsPayload = {
  __typename?: 'ProductionParseItemsPayload';
  count?: Maybe<Scalars['Float']>;
  byDestination?: Maybe<
    Array<Maybe<RegionalBudgetParseItemsPayloadByDestination>>
  >;
  byProduct?: Maybe<Array<Maybe<RegionalBudgetParseItemsPayloadByProduct>>>;
};

export enum ProductionPermissionsEnum {
  ProductionViewModule = 'production_viewModule',
  CreateProduction = 'create_production',
  ReadProduction = 'read_production',
  UpdateProduction = 'update_production',
  DeleteProduction = 'delete_production',
  UpdateStatusProduction = 'update_status_production'
}

export type ProductionProductionItems = {
  __typename?: 'ProductionProductionItems';
  frontendGroupId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  quantityInputMode?: Maybe<EnumProductionProductionItemsQuantityInputMode>;
  destinationsFilters?: Maybe<RegionalBudgetProductionItemsDestinationsFilters>;
  /** Códigos SAP dos locais (Lojas, Escriotorios, etc) encontrados a partir do filtro informado no campo "destinationsFilters" */
  foundDestinationsSAPCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  quantityByProductInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductInput>>
  >;
  quantityByProductOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductOutput>>
  >;
  quantityByDestinationInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationInput>>
  >;
  /** processado no backend a partir do quantityByProductInput ou do quantityByDestinationInput dependendo do quantityInputMode */
  quantityByDestinationOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationOutput>>
  >;
  supplierDeliveryDestinationType?: Maybe<
    EnumProductionProductionItemsSupplierDeliveryDestinationType
  >;
  extraItemsOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsExtraItemsOutput>>
  >;
};

export type ProductionProductionItemsInput = {
  frontendGroupId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  quantityInputMode?: Maybe<EnumProductionProductionItemsQuantityInputMode>;
  destinationsFilters?: Maybe<
    RegionalBudgetProductionItemsDestinationsFiltersInput
  >;
  /** Códigos SAP dos locais (Lojas, Escriotorios, etc) encontrados a partir do filtro informado no campo "destinationsFilters" */
  foundDestinationsSAPCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  quantityByProductInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductInputInput>>
  >;
  quantityByProductOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductOutputInput>>
  >;
  quantityByDestinationInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationInputInput>>
  >;
  /** processado no backend a partir do quantityByProductInput ou do quantityByDestinationInput dependendo do quantityInputMode */
  quantityByDestinationOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationOutputInput>>
  >;
  supplierDeliveryDestinationType?: Maybe<
    EnumProductionProductionItemsSupplierDeliveryDestinationType
  >;
  extraItemsOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsExtraItemsOutputInput>>
  >;
};

export type ProductionReadEmails = {
  __typename?: 'ProductionReadEmails';
  emailId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type ProductionReadEmailsInput = {
  emailId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type ProductionReportPayload = {
  __typename?: 'ProductionReportPayload';
  productionTitle: Scalars['String'];
  rows?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type ProductionSentEmails = {
  __typename?: 'ProductionSentEmails';
  /** Id do fornecedor que recebeu o email com relatorio da produção */
  supplierId?: Maybe<Scalars['String']>;
  /** CNPJ do fornecedor que recebeu o email com relatorio da produção */
  supplierCNPJ?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  /** Id do email enviado ao fornecedor com o relatorio da produção */
  emailId?: Maybe<Scalars['String']>;
  /** Endereço do email do contato do fornecedor cadastrado no fornecedor */
  emailAddress?: Maybe<Scalars['String']>;
};

export type ProductionSentEmailsInput = {
  /** Id do fornecedor que recebeu o email com relatorio da produção */
  supplierId?: Maybe<Scalars['String']>;
  /** CNPJ do fornecedor que recebeu o email com relatorio da produção */
  supplierCNPJ?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  /** Id do email enviado ao fornecedor com o relatorio da produção */
  emailId?: Maybe<Scalars['String']>;
  /** Endereço do email do contato do fornecedor cadastrado no fornecedor */
  emailAddress?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type ProductPagination = {
  __typename?: 'ProductPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type ProductSearchByNameOrSupplier = {
  __typename?: 'ProductSearchByNameOrSupplier';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Product>>>;
  pageInfo?: Maybe<PaginationInfo>;
};

export type ProductSearchByNameOrSupplierAndNotContainsSomeIds = {
  __typename?: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Product>>>;
};

export type ProductSearchBySupplierZipCodeAndContainsInventory = {
  __typename?: 'ProductSearchBySupplierZipCodeAndContainsInventory';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Product>>>;
};

export type ProjectSlaPayload = {
  __typename?: 'ProjectSLAPayload';
  creation?: Maybe<Scalars['String']>;
  aprovacaoProjeto?: Maybe<Scalars['String']>;
  briefing?: Maybe<Scalars['String']>;
  agendamentoLevantamentoCadastral?: Maybe<Scalars['String']>;
  levantamentoCadastral?: Maybe<Scalars['String']>;
  estudoLayout?: Maybe<Scalars['String']>;
  projetoExecutivo?: Maybe<Scalars['String']>;
  rrt?: Maybe<Scalars['String']>;
  threeDS?: Maybe<Scalars['String']>;
  projetoComplementar?: Maybe<Scalars['String']>;
};

export enum ProjectStageStatus {
  Rascunho = 'RASCUNHO',
  Analise = 'ANALISE',
  AnaliseHq = 'ANALISE_HQ',
  AnaliseRegional = 'ANALISE_REGIONAL',
  Validado = 'VALIDADO',
  Ajuste = 'AJUSTE',
  Pendente = 'PENDENTE',
  Aprovado = 'APROVADO',
  Reprovado = 'REPROVADO',
  NaoAplica = 'NAO_APLICA',
  Enviado = 'ENVIADO',
  AguardandoSolicitacao = 'AGUARDANDO_SOLICITACAO'
}

export type Query = {
  __typename?: 'Query';
  RegionFindMany?: Maybe<Array<Maybe<Region>>>;
  RegionFindOne?: Maybe<Region>;
  RegionPagination?: Maybe<RegionPagination>;
  RegionItemFindMany?: Maybe<Array<Maybe<RegionItem>>>;
  RegionItemFindOne?: Maybe<RegionItem>;
  RegionItemPagination?: Maybe<RegionItemPagination>;
  /** Executa a busca dos itens de região */
  RegionItemDistinctDDD?: Maybe<RegionItemDistinctDdd>;
  /** Executa a busca dos itens distintos por regional e UF (não inclui THQ para isso será necessário adicionar um filtro) */
  RegionItemDistinctRegionAndUF?: Maybe<RegionItemDistinctRegionAndUf>;
  JobTitleFindOne?: Maybe<JobTitle>;
  JobTitleFindMany?: Maybe<Array<Maybe<JobTitle>>>;
  RoleGroupFindOne?: Maybe<RoleGroup>;
  RoleGroupFindMany?: Maybe<Array<Maybe<RoleGroup>>>;
  RoleGroupPagination?: Maybe<RoleGroupPagination>;
  ChannelFindMany?: Maybe<Array<Maybe<Channel>>>;
  ChannelFindOne?: Maybe<Channel>;
  ChannelPagination?: Maybe<ChannelPagination>;
  UserContentPermissionFindOne?: Maybe<UserContentPermission>;
  /** Lista de possiveis content-permissions que os usuários podem ter. */
  ContentPermissionsHelper?: Maybe<
    Array<Maybe<ContentPermissionsHelperOption>>
  >;
  UserPagination?: Maybe<UserPagination>;
  UserFindByIds?: Maybe<Array<Maybe<User>>>;
  UserFindById?: Maybe<User>;
  UserFindOne?: Maybe<User>;
  UserFindMany?: Maybe<Array<Maybe<User>>>;
  User?: Maybe<User>;
  /** Executa a busca do usuario */
  UserSearch?: Maybe<Array<Maybe<UserSearch>>>;
  UserSearchPagination?: Maybe<UserPagination>;
  TextFindBlackList?: Maybe<TextFindBlackList>;
  /** Executa a busca do usuario */
  UserFindByEmails?: Maybe<Array<Maybe<UserSimpleResult>>>;
  UserFindByMatricula?: Maybe<Array<Maybe<User>>>;
  FileFindByIds?: Maybe<Array<Maybe<File>>>;
  FileFindById?: Maybe<File>;
  FilePagination?: Maybe<FilePagination>;
  MaterialFindById?: Maybe<Material>;
  MaterialFindMany?: Maybe<Array<Maybe<Material>>>;
  MaterialFindOne?: Maybe<Material>;
  MaterialPagination?: Maybe<MaterialPagination>;
  MaterialCount?: Maybe<Scalars['Int']>;
  MaterialStatusOptions?: Maybe<Array<Maybe<MaterialStatusOption>>>;
  MaterialSearchOwners?: Maybe<Array<Maybe<MaterialSearchOwners>>>;
  EmailFindMany?: Maybe<Array<Maybe<Email>>>;
  EmailPagination?: Maybe<EmailPagination>;
  EmailFindOne?: Maybe<Email>;
  /** Retorna true se o email foi marcado como lido pelo destinatario informado */
  EmailCheckRead?: Maybe<Scalars['Boolean']>;
  MaterialSearch?: Maybe<MaterialSearch>;
  MaterialFindPinned?: Maybe<MaterialPagination>;
  NotificationFindMany?: Maybe<Array<Maybe<Notification>>>;
  NotificationFindOne?: Maybe<Notification>;
  NotificationPagination?: Maybe<NotificationPagination>;
  HelperAddress?: Maybe<AddressHelperPayload>;
  CategoryFindOne?: Maybe<Category>;
  CategoryFindById?: Maybe<Category>;
  CategoryFindMany?: Maybe<Array<Maybe<Category>>>;
  CategoryFindByIds?: Maybe<Array<Maybe<Category>>>;
  /** Retorna a chave publica (VAPID key) pra a subscription de web push notifications */
  WebPushPublicKey: Scalars['String'];
  PinFindMany?: Maybe<Array<Maybe<Pin>>>;
  PinPagination?: Maybe<PinPagination>;
  ShareFindMany?: Maybe<Array<Maybe<Share>>>;
  SharePagination?: Maybe<SharePagination>;
  CommentPagination?: Maybe<CommentPagination>;
  CommentFindOne?: Maybe<Comment>;
  CommentFindById?: Maybe<Comment>;
  CommentFindMany?: Maybe<Array<Maybe<Comment>>>;
  CommentSortPagination?: Maybe<CommentPagination>;
  EventLivePagination?: Maybe<EventLivePagination>;
  EventLiveFindOne?: Maybe<EventLive>;
  EventLiveFindById?: Maybe<EventLive>;
  BannerFindMany?: Maybe<Array<Maybe<Banner>>>;
  BannerFindOne?: Maybe<Banner>;
  AnalyticsCount?: Maybe<Scalars['Int']>;
  AnalyticsPagination?: Maybe<AnalyticsPagination>;
  AnalyticsUsersReport?: Maybe<Array<Maybe<AnalyticsUser>>>;
  AnalyticsFindReport?: Maybe<Array<Maybe<AnalyticsReportItem>>>;
  AnalyticsUserNotification?: Maybe<Array<Maybe<AnalyticsUserNotification>>>;
  AnalyticsPin?: Maybe<Array<Maybe<AnalyticsPin>>>;
  AnalyticsComment?: Maybe<Array<Maybe<AnalyticsComment>>>;
  AnalyticsPoints?: Maybe<Array<Maybe<AnalyticsPoints>>>;
  AnalyticsDownloads?: Maybe<Scalars['Boolean']>;
  SearchCountFindMany?: Maybe<Array<Maybe<SearchCount>>>;
  SearchCountPagination?: Maybe<SearchCountPagination>;
  FolderFindMany?: Maybe<Array<Maybe<Folder>>>;
  FolderFindById?: Maybe<Folder>;
  FolderPagination?: Maybe<FolderPagination>;
  RecentsActivitiesPagination?: Maybe<RecentsActivitiesPagination>;
  UserWordsBlackListFindMany?: Maybe<Array<Maybe<UserWordsBlackList>>>;
  UserWordsBlackListFindById?: Maybe<UserWordsBlackList>;
  DownloadSensitiveFilesPagination?: Maybe<DownloadSensitiveFilesPagination>;
  DownloadUsersInfo?: Maybe<DownloadUsersInfo>;
  DownloadAcceptedTerms?: Maybe<DownloadAcceptedTerms>;
  DownloadUsersAcceptedPoints?: Maybe<DownloadUsersAcceptedPoints>;
  DownloadUsersWithPoints?: Maybe<DownloadUsersWithPoints>;
  DownloadUsersBase?: Maybe<DownloadUsersInfo>;
  ImportUsersPlanFindMany?: Maybe<Array<Maybe<ImportUsersPlan>>>;
  ImportUsersPlanFindById?: Maybe<ImportUsersPlan>;
  ImportUsersPlanFindByIds?: Maybe<Array<Maybe<ImportUsersPlan>>>;
  ImportUsersPlanPagination?: Maybe<ImportUsersPlanPagination>;
  UserDeletedFindByIds?: Maybe<Array<Maybe<UserDeleted>>>;
  UserDeletedFindOne?: Maybe<UserDeleted>;
  UserDeletedFindMany?: Maybe<Array<Maybe<UserDeleted>>>;
  UserHistoryBlockFindMany?: Maybe<Array<Maybe<UserHistoryBlock>>>;
  UserHistoryBlockPagination?: Maybe<UserHistoryBlockPagination>;
  FutureOperationFindById?: Maybe<FutureOperation>;
  EmailStatsFindOne?: Maybe<EmailStats>;
  EmailStatsFindMany?: Maybe<Array<Maybe<EmailStats>>>;
  EmailUnsubscribeFindOne?: Maybe<EmailUnsubscribe>;
  EmailUnsubscribeFindMany?: Maybe<Array<Maybe<EmailUnsubscribe>>>;
  /** Descadastra o e-mail para recebimento futuro de e-mails */
  EmailUnsubscribeAction?: Maybe<EmailUnsubscribeActionPayload>;
  HomebannerFindMany?: Maybe<Array<Maybe<Homebanner>>>;
  PlaceFindMany?: Maybe<Array<Maybe<Place>>>;
  PlaceFindOne?: Maybe<Place>;
  PlaceFindById?: Maybe<Place>;
  PlacePagination?: Maybe<PlacePagination>;
  /** Executa a busca de places por canal,
   *       ufs, DDDs, cidades, focu de comunicação, modelo de loja,
   *       tecnologias de rede e produtos comercializados
   */
  PlaceSearch?: Maybe<PlaceSearch>;
  /** Executa a busca dos locais filtrando por id e produtos relacionados ao inventário */
  PlaceSearchByIdsTypeAndName?: Maybe<PlaceSearchByIdsTypeAndName>;
  /** Executa a busca da tabela de preço por região e canal */
  PlaceSearchByChannelSapCodeOrPlaceAndRegion?: Maybe<
    PlaceSearchByChannelSapCodeOrPlaceAndRegion
  >;
  /** Executa a busca dos locais filtrando por id e produtos relacionados ao inventário */
  PlaceSearchByIdsLpuCategoryAndContainsInventory?: Maybe<
    PlaceSearchByIdsLpuCategoryAndContainsInventory
  >;
  PlacePaginationCustom?: Maybe<PlacePaginationCustom>;
  LocationOptions?: Maybe<LocationOptionsPayload>;
  /** Executa a busca de um place através do CNPJ */
  PlaceSearchByCnpj?: Maybe<Place>;
  PlacesBaseDownload?: Maybe<BaseDownload>;
  PlacesBaseValidation?: Maybe<Scalars['JSON']>;
  PlacesPermissions?: Maybe<Array<Maybe<PlacesPermissionsEnum>>>;
  ContractPagination?: Maybe<ContractPagination>;
  ContractFindOne?: Maybe<Contract>;
  ContractFindByIds?: Maybe<Array<Maybe<Contract>>>;
  ProductPagination?: Maybe<ProductPagination>;
  ProductFindById?: Maybe<Product>;
  ProductFindByIds?: Maybe<Array<Maybe<Product>>>;
  ProductBaseDownload?: Maybe<BaseDownload>;
  ProductBaseValidation?: Maybe<Scalars['JSON']>;
  /** Executa a busca dos produtos por nome */
  ProductSearchByNameOrSupplier?: Maybe<ProductSearchByNameOrSupplier>;
  /** Executa a busca dos produtos por nome */
  ProductSearchByNameOrSupplierAndNotContainsSomeIds?: Maybe<
    ProductSearchByNameOrSupplierAndNotContainsSomeIds
  >;
  /** Executa a busca dos produtos filtrando por id, local do fornecedor e que contem inventário ou não */
  ProductSearchBySupplierZipCodeAndContainsInventory?: Maybe<
    ProductSearchBySupplierZipCodeAndContainsInventory
  >;
  SupplierPagination?: Maybe<SupplierPagination>;
  SupplierFindOne?: Maybe<Supplier>;
  SupplierFindMany?: Maybe<Array<Maybe<Supplier>>>;
  /** Executa a busca dos produtos por nome */
  ResolverSupplierSearchByNameAndContainsLpu?: Maybe<
    ResolverSupplierSearchByNameAndContainsLpu
  >;
  SupplierPermissions?: Maybe<Array<Maybe<SupplierPermissionsEnum>>>;
  TablePriceFindMany?: Maybe<Array<Maybe<TablePrice>>>;
  TablePriceFindOne?: Maybe<TablePrice>;
  TablePriceFindById?: Maybe<TablePrice>;
  TablePricePagination?: Maybe<TablePricePagination>;
  TablePrice?: Maybe<TablePrice>;
  /** Busca por tabelas de preço em alguma das regiões listada */
  TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds?: Maybe<
    Array<Maybe<TablePrice>>
  >;
  /** Busca os acessórios na tabela de preço */
  TablePriceAccessories?: Maybe<Array<Maybe<TablePriceAccessories>>>;
  /** Busca tabelas de preço e device filtrando por código SAP e modelo do aparelho */
  TablePriceSearchBySapCodeAndDeviceModel?: Maybe<
    TablePriceSearchBySapCodeAndDeviceModel
  >;
  /** Busca por tabelas de preço de um código SAP */
  TablePriceSearchBySapCode?: Maybe<ListCodes>;
  /** Busca os preços dos dispositivos na tabela de preço */
  TablePriceDevices?: Maybe<Array<Maybe<TablePriceDevices>>>;
  /** Busca tabelas de preço e device filtrando por código SAP e modelo do aparelho */
  TablePriceDeviceBySapCode?: Maybe<TablePriceDeviceBySapCode>;
  TablePricePermissions?: Maybe<Array<Maybe<TablePricePermissionsEnum>>>;
  PricingTableFindById?: Maybe<PricingTable>;
  PricingTablePagination?: Maybe<PricingTablePagination>;
  PricingTableFindMany?: Maybe<Array<Maybe<PricingTable>>>;
  LegalTextFindById?: Maybe<LegalText>;
  LegalTextPagination?: Maybe<LegalTextPagination>;
  DeviceFindMany?: Maybe<Array<Maybe<Device>>>;
  DeviceFindOne?: Maybe<Device>;
  DeviceFindById?: Maybe<Device>;
  DevicePagination?: Maybe<DevicePagination>;
  /** Executa a busca do device */
  DeviceSearch?: Maybe<Array<Maybe<DeviceSearch>>>;
  /** Busca por todas fabricantes dos aparelhos cadastrados */
  DeviceFindManufacturers?: Maybe<Array<Maybe<DeviceFindManufacturers>>>;
  /** Executa a busca do device por ID */
  DeviceSearchById?: Maybe<Device>;
  DeviceBaseDownload?: Maybe<DeviceBaseDownloadPayload>;
  DeviceBaseValidation?: Maybe<Scalars['JSON']>;
  AppDevice?: Maybe<Device>;
  AppDeviceByManufacturer?: Maybe<Array<Maybe<Device>>>;
  DevicePermissions?: Maybe<Array<Maybe<DevicePermissionsEnum>>>;
  DigitalBrochureFindMany?: Maybe<Array<Maybe<DigitalBrochure>>>;
  DigitalBrochureFindOne?: Maybe<DigitalBrochure>;
  DigitalBrochureFindById?: Maybe<DigitalBrochure>;
  DigitalBrochurePagination?: Maybe<DigitalBrochurePagination>;
  /** Executa a busca dos folhetos digitais */
  DigitalBrochureSearchByDDDAndService?: Maybe<
    DigitalBrochureSearchByDddAndService
  >;
  DigitalBrochurePermissions?: Maybe<
    Array<Maybe<DigitalBrochurePermissionsEnum>>
  >;
  CampaignPagination?: Maybe<CampaignPagination>;
  CampaignFindOne?: Maybe<Campaign>;
  CampaignFindById?: Maybe<Campaign>;
  /** Executa a busca das campanhas por titulo e descricao */
  CampaignSearchByTitleDescriptionOrStatus?: Maybe<
    CampaignSearchByTitleDescriptionOrStatus
  >;
  /** Executa a busca das campanhas por titulo e descricao */
  CampaignSearchByTitleOrDescription?: Maybe<
    CampaignSearchByTitleOrDescription
  >;
  CampaignPermissions?: Maybe<Array<Maybe<CampaignPermissionsEnum>>>;
  LayoutPagination?: Maybe<LayoutPagination>;
  LayoutFindOne?: Maybe<Layout>;
  LayoutFindMany?: Maybe<Array<Maybe<Layout>>>;
  LayoutFindByIds?: Maybe<Array<Maybe<Layout>>>;
  /** Executa a busca dos layouts por nome */
  LayoutSearchByName?: Maybe<LayoutSearchByName>;
  /** Executa a busca dos layouts por nome */
  LayoutSearchByNameAndContainsLpuIntervalQty?: Maybe<
    LayoutSearchByNameAndContainsLpuIntervalQty
  >;
  /** Executa a busca de conteúdo dos layouts por data */
  LayoutSearchContentByIds?: Maybe<LayoutContentListPayload>;
  LayoutPermissions?: Maybe<Array<Maybe<LayoutPermissionsEnum>>>;
  RegionalBudgetFindOne?: Maybe<RegionalBudget>;
  RegionalBudgetFindById?: Maybe<RegionalBudget>;
  RegionalBudgetPagination?: Maybe<RegionalBudgetPagination>;
  ProductionFindById?: Maybe<Production>;
  ProductionPagination?: Maybe<ProductionPagination>;
  ProductionReport?: Maybe<ProductionReportPayload>;
  ProductionDeliveryReport?: Maybe<ProductionReportPayload>;
  ProductionPermissions?: Maybe<Array<Maybe<ProductionPermissionsEnum>>>;
  BudgetFindOne?: Maybe<Budget>;
  BudgetFindById?: Maybe<Budget>;
  BudgetPagination?: Maybe<BudgetPagination>;
  BudgetPermissions?: Maybe<Array<Maybe<BudgetPermissionsEnum>>>;
  SupplierDeliveryPagination?: Maybe<SupplierDeliveryPagination>;
  SupplierDeliveryFindMany?: Maybe<Array<Maybe<SupplierDelivery>>>;
  InventoryItemFindById?: Maybe<InventoryItem>;
  InventoryItemFindOne?: Maybe<InventoryItem>;
  InventoryItemPagination?: Maybe<InventoryItemPagination>;
  InventoryItemFindMany?: Maybe<Array<Maybe<InventoryItem>>>;
  InventoryWithDrawPagination?: Maybe<InventoryWithDrawPagination>;
  InventoryWithDrawFindById?: Maybe<InventoryWithDraw>;
  DeliveryOrderFindById?: Maybe<DeliveryOrder>;
  DeliveryOrderPagination?: Maybe<DeliveryOrderPagination>;
  DeliveryOrderValidation?: Maybe<DeliveryOrderValidatedReport>;
  DeliveryOrderExecution?: Maybe<Array<Maybe<DeliveryOrderRecords>>>;
  UserLogisticsPermissions?: Maybe<Array<Maybe<LogisticsPermissionsEnum>>>;
  MetadataFindOne?: Maybe<Metadata>;
  MetadataFindById?: Maybe<Metadata>;
  PositivatorFindById?: Maybe<Positivator>;
  PositivatorFindMany?: Maybe<Array<Maybe<Positivator>>>;
  PositivatorPagination?: Maybe<PositivatorPagination>;
  PlacePositivators?: Maybe<PlacePositivatorsPayload>;
  PositivationSumary?: Maybe<Scalars['JSON']>;
  PlacePositivationPagination?: Maybe<PlacePositivationPagination>;
  PositivationReportByGroup?: Maybe<BaseDownload>;
  PositivationReportByItem?: Maybe<BaseDownload>;
  PositivationReportByPlace?: Maybe<BaseDownload>;
  PositivatorPermissions?: Maybe<Array<Maybe<PositivatorPermissionsEnum>>>;
  PositivatorItemFindById?: Maybe<PositivatorItem>;
  PositivatorItemPagination?: Maybe<PositivatorItemPagination>;
  PositivatorItemFindMany?: Maybe<Array<Maybe<PositivatorItem>>>;
  DeviceNotificationPagination?: Maybe<DeviceNotificationPagination>;
  UserMerchanPagination?: Maybe<UserMerchanPagination>;
  UsersBaseDownload?: Maybe<BaseDownload>;
  MerchanUserCheck?: Maybe<MerchanUserCheck>;
  UserMerchanPermissions?: Maybe<Array<Maybe<UserMerchanPermissionsEnum>>>;
  PlaceAnalyticsPagination?: Maybe<PlaceAnalyticsPagination>;
  PlaceAnalyticsFindOne?: Maybe<PlaceAnalytics>;
  PlaceAnalyticsFindByIds?: Maybe<Array<Maybe<PlaceAnalytics>>>;
  PlaceAnalyticsFindById?: Maybe<PlaceAnalytics>;
  PlaceAnalyticsFindMany?: Maybe<Array<Maybe<PlaceAnalytics>>>;
  StructuralFindMany?: Maybe<Array<Maybe<Structural>>>;
  StructuralFindOne?: Maybe<Structural>;
  StructuralFindById?: Maybe<Structural>;
  StructuralPagination?: Maybe<StructuralPagination>;
  Structural?: Maybe<Structural>;
  StructuralPermissions?: Maybe<Array<Maybe<StructuralPermissionsEnum>>>;
  ProjectSLA?: Maybe<ProjectSlaPayload>;
  DigitalBrochureAnalyticsPagination?: Maybe<
    DigitalBrochureAnalyticsPagination
  >;
  DigitalBrochureAnalyticsFindMany?: Maybe<
    Array<Maybe<DigitalBrochureAnalytics>>
  >;
  DigitalBrochureAnalyticsFindById?: Maybe<DigitalBrochureAnalytics>;
  DigitalBrochureAnalyticsFindByIds?: Maybe<
    Array<Maybe<DigitalBrochureAnalytics>>
  >;
  DigitalBrochureAnalyticsFindOne?: Maybe<DigitalBrochureAnalytics>;
  DigitalBrochureAnalyticsPermissions?: Maybe<
    Array<Maybe<DigitalBrochureAnalyticsPermissionsEnum>>
  >;
  DigitalBrochureAnalyticsCount: Scalars['Int'];
  AreaLojaPagination?: Maybe<AreaLojaPagination>;
  RegraMapaPagination?: Maybe<RegraMapaPagination>;
  FormatoPagination?: Maybe<FormatoPagination>;
  PecaMapaPagination?: Maybe<PecaMapaPagination>;
  MapaPDVPagination?: Maybe<MapaPdvPagination>;
  MapaPDVFindById?: Maybe<MapaPdv>;
  MyMapaPDV?: Maybe<MyMapaPdvPayload>;
};

export type QueryRegionFindManyArgs = {
  filter?: Maybe<FilterFindManyRegionInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRegionInput>;
};

export type QueryRegionFindOneArgs = {
  filter?: Maybe<FilterFindOneRegionInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRegionInput>;
};

export type QueryRegionPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRegionInput>;
  sort?: Maybe<SortFindManyRegionInput>;
};

export type QueryRegionItemFindManyArgs = {
  filter?: Maybe<FilterFindManyRegionItemInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRegionItemInput>;
};

export type QueryRegionItemFindOneArgs = {
  filter?: Maybe<FilterFindOneRegionItemInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRegionItemInput>;
};

export type QueryRegionItemPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRegionItemInput>;
  sort?: Maybe<SortFindManyRegionItemInput>;
};

export type QueryJobTitleFindOneArgs = {
  filter?: Maybe<FilterFindOneJobTitleInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneJobTitleInput>;
};

export type QueryJobTitleFindManyArgs = {
  filter?: Maybe<FilterFindManyJobTitleInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyJobTitleInput>;
};

export type QueryRoleGroupFindOneArgs = {
  filter?: Maybe<FilterFindOneRoleGroupInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRoleGroupInput>;
};

export type QueryRoleGroupFindManyArgs = {
  filter?: Maybe<FilterFindManyRoleGroupInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRoleGroupInput>;
};

export type QueryRoleGroupPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRoleGroupInput>;
  sort?: Maybe<SortFindManyRoleGroupInput>;
};

export type QueryChannelFindManyArgs = {
  filter?: Maybe<FilterFindManyChannelInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyChannelInput>;
};

export type QueryChannelFindOneArgs = {
  filter?: Maybe<FilterFindOneChannelInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneChannelInput>;
};

export type QueryChannelPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyChannelInput>;
  sort?: Maybe<SortFindManyChannelInput>;
};

export type QueryUserContentPermissionFindOneArgs = {
  filter?: Maybe<FilterFindOneUserContentPermissionInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneUserContentPermissionInput>;
};

export type QueryUserPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyUserInput>;
  sort?: Maybe<SortFindManyUserInput>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryUserFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsUserInput>;
};

export type QueryUserFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryUserFindOneArgs = {
  filter?: Maybe<FilterFindOneUserInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneUserInput>;
};

export type QueryUserFindManyArgs = {
  filter?: Maybe<FilterFindManyUserInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyUserInput>;
};

export type QueryUserSearchArgs = {
  search: Scalars['String'];
  roleGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryUserSearchPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<UserSearchPaginationFilterInput>;
  sort?: Maybe<SortFindManyUserInput>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryTextFindBlackListArgs = {
  text: Scalars['String'];
};

export type QueryUserFindByEmailsArgs = {
  emails: Array<Maybe<Scalars['String']>>;
};

export type QueryUserFindByMatriculaArgs = {
  isMatriculaNull?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryFileFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsFileInput>;
};

export type QueryFileFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryFilePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyFileInput>;
  sort?: Maybe<SortFindManyFileInput>;
};

export type QueryMaterialFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

export type QueryMaterialFindManyArgs = {
  filter?: Maybe<FilterFindManyMaterialInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyMaterialInput>;
};

export type QueryMaterialFindOneArgs = {
  filter?: Maybe<FilterFindOneMaterialInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneMaterialInput>;
};

export type QueryMaterialPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyMaterialInput>;
  sort?: Maybe<SortFindManyMaterialInput>;
};

export type QueryMaterialCountArgs = {
  filter?: Maybe<FilterMaterialInput>;
  sort?: Maybe<Sort>;
};

export type QueryMaterialSearchOwnersArgs = {
  kind?: Maybe<EnumMaterialKind>;
};

export type QueryEmailFindManyArgs = {
  filter?: Maybe<FilterFindManyEmailInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyEmailInput>;
};

export type QueryEmailPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyEmailInput>;
  sort?: Maybe<SortFindManyEmailInput>;
};

export type QueryEmailFindOneArgs = {
  filter?: Maybe<FilterFindOneEmailInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEmailInput>;
};

export type QueryEmailCheckReadArgs = {
  emailId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type QueryMaterialSearchArgs = {
  search: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  sort?: Maybe<SortFindManyMaterialInput>;
  category?: Maybe<Scalars['String']>;
};

export type QueryMaterialFindPinnedArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterFindManyMaterialInput>;
  sort?: Maybe<SortFindManyMaterialInput>;
};

export type QueryNotificationFindManyArgs = {
  filter?: Maybe<FilterFindManyNotificationInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyNotificationInput>;
};

export type QueryNotificationFindOneArgs = {
  filter?: Maybe<FilterFindOneNotificationInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneNotificationInput>;
};

export type QueryNotificationPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyNotificationInput>;
  sort?: Maybe<SortFindManyNotificationInput>;
};

export type QueryHelperAddressArgs = {
  zipCode: Scalars['String'];
};

export type QueryCategoryFindOneArgs = {
  filter?: Maybe<FilterFindOneCategoryInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneCategoryInput>;
};

export type QueryCategoryFindByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<SortMenuEnum>;
  filter?: Maybe<Filter>;
};

export type QueryCategoryFindManyArgs = {
  filter?: Maybe<FilterFindManyCategoryInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyCategoryInput>;
};

export type QueryCategoryFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsCategoryInput>;
  filter?: Maybe<Filter>;
};

export type QueryPinFindManyArgs = {
  filter?: Maybe<FilterFindManyPinInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyPinInput>;
};

export type QueryPinPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyPinInput>;
  sort?: Maybe<SortFindManyPinInput>;
};

export type QueryShareFindManyArgs = {
  filter?: Maybe<FilterFindManyShareInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyShareInput>;
};

export type QuerySharePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyShareInput>;
  sort?: Maybe<SortFindManyShareInput>;
};

export type QueryCommentPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyCommentInput>;
  sort?: Maybe<SortFindManyCommentInput>;
};

export type QueryCommentFindOneArgs = {
  filter?: Maybe<FilterFindOneCommentInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneCommentInput>;
};

export type QueryCommentFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryCommentFindManyArgs = {
  filter?: Maybe<FilterFindManyCommentInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyCommentInput>;
};

export type QueryCommentSortPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<CommentSortPaginationFilterInput>;
  sort?: Maybe<SortFindManyCommentInput>;
};

export type QueryEventLivePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyEventLiveInput>;
  sort?: Maybe<SortFindManyEventLiveInput>;
};

export type QueryEventLiveFindOneArgs = {
  filter?: Maybe<FilterFindOneEventLiveInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEventLiveInput>;
};

export type QueryEventLiveFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type QueryBannerFindManyArgs = {
  filter?: Maybe<FilterFindManyBannerInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyBannerInput>;
};

export type QueryBannerFindOneArgs = {
  filter?: Maybe<FilterFindOneBannerInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneBannerInput>;
};

export type QueryAnalyticsCountArgs = {
  filter?: Maybe<FilterAnalyticsInput>;
};

export type QueryAnalyticsPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyAnalyticsInput>;
  sort?: Maybe<SortFindManyAnalyticsInput>;
};

export type QueryAnalyticsUsersReportArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
};

export type QueryAnalyticsFindReportArgs = {
  filter?: Maybe<AnalyticsReportFilterInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAnalyticsUserNotificationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAnalyticsPinArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAnalyticsCommentArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAnalyticsPointsArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type QueryAnalyticsDownloadsArgs = {
  fileId?: Maybe<Scalars['String']>;
};

export type QuerySearchCountFindManyArgs = {
  filter?: Maybe<FilterFindManySearchCountInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManySearchCountInput>;
};

export type QuerySearchCountPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManySearchCountInput>;
  sort?: Maybe<SortFindManySearchCountInput>;
};

export type QueryFolderFindManyArgs = {
  filter?: Maybe<FilterFindManyFolderInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyFolderInput>;
};

export type QueryFolderFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryFolderPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyFolderInput>;
  sort?: Maybe<SortFindManyFolderInput>;
};

export type QueryRecentsActivitiesPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRecentsActivitiesInput>;
  sort?: Maybe<SortFindManyRecentsActivitiesInput>;
};

export type QueryUserWordsBlackListFindManyArgs = {
  filter?: Maybe<FilterFindManyUserWordsBlackListInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyUserWordsBlackListInput>;
};

export type QueryUserWordsBlackListFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryDownloadSensitiveFilesPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyDownloadSensitiveFilesInput>;
  sort?: Maybe<SortFindManyDownloadSensitiveFilesInput>;
};

export type QueryDownloadAcceptedTermsArgs = {
  incentiveCampaignId: Scalars['String'];
};

export type QueryDownloadUsersAcceptedPointsArgs = {
  incentiveCampaignId: Scalars['String'];
};

export type QueryImportUsersPlanFindManyArgs = {
  filter?: Maybe<FilterFindManyImportUsersPlanInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyImportUsersPlanInput>;
};

export type QueryImportUsersPlanFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type QueryImportUsersPlanFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsImportUsersPlanInput>;
  filter?: Maybe<Filter>;
};

export type QueryImportUsersPlanPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyImportUsersPlanInput>;
  sort?: Maybe<SortFindManyImportUsersPlanInput>;
};

export type QueryUserDeletedFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsUserDeletedInput>;
};

export type QueryUserDeletedFindOneArgs = {
  filter?: Maybe<FilterFindOneUserDeletedInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneUserDeletedInput>;
};

export type QueryUserDeletedFindManyArgs = {
  filter?: Maybe<FilterFindManyUserDeletedInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyUserDeletedInput>;
};

export type QueryUserHistoryBlockFindManyArgs = {
  filter?: Maybe<FilterFindManyUserHistoryBlockInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyUserHistoryBlockInput>;
};

export type QueryUserHistoryBlockPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyUserHistoryBlockInput>;
  sort?: Maybe<SortFindManyUserHistoryBlockInput>;
};

export type QueryFutureOperationFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryEmailStatsFindOneArgs = {
  filter?: Maybe<FilterFindOneEmailStatsInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEmailStatsInput>;
};

export type QueryEmailStatsFindManyArgs = {
  filter?: Maybe<FilterFindManyEmailStatsInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyEmailStatsInput>;
};

export type QueryEmailUnsubscribeFindOneArgs = {
  filter?: Maybe<FilterFindOneEmailUnsubscribeInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneEmailUnsubscribeInput>;
};

export type QueryEmailUnsubscribeFindManyArgs = {
  filter?: Maybe<FilterFindManyEmailUnsubscribeInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyEmailUnsubscribeInput>;
};

export type QueryEmailUnsubscribeActionArgs = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type QueryHomebannerFindManyArgs = {
  filter?: Maybe<FilterFindManyHomebannerInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyHomebannerInput>;
};

export type QueryPlaceFindManyArgs = {
  filter?: Maybe<FilterFindManyPlaceInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyPlaceInput>;
};

export type QueryPlaceFindOneArgs = {
  filter?: Maybe<FilterFindOnePlaceInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOnePlaceInput>;
};

export type QueryPlaceFindByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<SortPlaceEnum>;
  filter?: Maybe<Filter>;
};

export type QueryPlacePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyPlaceInput>;
  sort?: Maybe<SortFindManyPlaceInput>;
};

export type QueryPlaceSearchArgs = {
  channel?: Maybe<Array<Maybe<Scalars['String']>>>;
  DDD?: Maybe<Array<Maybe<Scalars['String']>>>;
  uf?: Maybe<Array<Maybe<Scalars['String']>>>;
  city?: Maybe<Array<Maybe<Scalars['String']>>>;
  communicationFocus?: Maybe<Array<Maybe<Scalars['String']>>>;
  storeModel?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkTechnology?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketedProduct?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  adminId?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Array<Maybe<Scalars['String']>>>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  razao_social?: Maybe<Scalars['String']>;
};

export type QueryPlaceSearchByIdsTypeAndNameArgs = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryPlaceSearchByChannelSapCodeOrPlaceAndRegionArgs = {
  disabled?: Maybe<Scalars['Boolean']>;
  filterLocationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterSapCodeOrPlaceName?: Maybe<Scalars['String']>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryPlaceSearchByIdsLpuCategoryAndContainsInventoryArgs = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  lpu?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryLpu?: Maybe<Scalars['String']>;
  relatedToCategoryLpu?: Maybe<Scalars['String']>;
  containsInventory?: Maybe<Scalars['Boolean']>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryPlacePaginationCustomArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['JSON']>;
  sort?: Maybe<SortFindManyPlaceInput>;
};

export type QueryLocationOptionsArgs = {
  uf?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type QueryPlaceSearchByCnpjArgs = {
  CNPJ?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryPlacesBaseDownloadArgs = {
  filters?: Maybe<Scalars['JSON']>;
  isExample?: Maybe<Scalars['Boolean']>;
};

export type QueryPlacesBaseValidationArgs = {
  fileId: Scalars['String'];
};

export type QueryContractPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyContractInput>;
  sort?: Maybe<SortFindManyContractInput>;
};

export type QueryContractFindOneArgs = {
  filter?: Maybe<FilterFindOneContractInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneContractInput>;
};

export type QueryContractFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsContractInput>;
  filter?: Maybe<Filter>;
};

export type QueryProductPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyProductInput>;
  sort?: Maybe<SortFindManyProductInput>;
};

export type QueryProductFindByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<SortProductEnum>;
  filter?: Maybe<Filter>;
};

export type QueryProductFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsProductInput>;
  filter?: Maybe<Filter>;
};

export type QueryProductBaseDownloadArgs = {
  filters?: Maybe<Scalars['JSON']>;
  isExample?: Maybe<Scalars['Boolean']>;
};

export type QueryProductBaseValidationArgs = {
  fileId: Scalars['String'];
};

export type QueryProductSearchByNameOrSupplierArgs = {
  description?: Maybe<Scalars['String']>;
  onlyStructural?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  supplier?: Maybe<Scalars['String']>;
  category?: Maybe<EnumProductCategoria>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryProductSearchByNameOrSupplierAndNotContainsSomeIdsArgs = {
  name?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryProductSearchBySupplierZipCodeAndContainsInventoryArgs = {
  id: Array<Maybe<Scalars['String']>>;
  zipcode?: Maybe<Array<Maybe<Scalars['String']>>>;
  containsInventory?: Maybe<Scalars['Boolean']>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QuerySupplierPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManySupplierInput>;
  sort?: Maybe<SortFindManySupplierInput>;
};

export type QuerySupplierFindOneArgs = {
  filter?: Maybe<FilterFindOneSupplierInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneSupplierInput>;
};

export type QuerySupplierFindManyArgs = {
  filter?: Maybe<FilterFindManySupplierInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManySupplierInput>;
};

export type QueryResolverSupplierSearchByNameAndContainsLpuArgs = {
  name?: Maybe<Scalars['String']>;
  lpu?: Maybe<Array<Maybe<Scalars['String']>>>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryTablePriceFindManyArgs = {
  filter?: Maybe<FilterFindManyTablePriceInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyTablePriceInput>;
};

export type QueryTablePriceFindOneArgs = {
  filter?: Maybe<FilterFindOneTablePriceInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneTablePriceInput>;
};

export type QueryTablePriceFindByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<SortSupplierEnum>;
};

export type QueryTablePricePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyTablePriceInput>;
  sort?: Maybe<SortFindManyTablePriceInput>;
};

export type QueryTablePriceSearchByRegionsLocationTypeAndNotContainsSomeIdsArgs = {
  regions: Array<Maybe<Scalars['String']>>;
  locationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids: Array<Maybe<Scalars['String']>>;
};

export type QueryTablePriceAccessoriesArgs = {
  tablePrice: Scalars['String'];
};

export type QueryTablePriceSearchBySapCodeAndDeviceModelArgs = {
  sapCode?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  debuggerInfo?: Maybe<DeviceNotificationDebuggerInfoInput>;
};

export type QueryTablePriceSearchBySapCodeArgs = {
  sapCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
};

export type QueryTablePriceDevicesArgs = {
  devices: Array<Maybe<Scalars['String']>>;
  tablePrice: Scalars['String'];
};

export type QueryTablePriceDeviceBySapCodeArgs = {
  CNPJ: Scalars['String'];
  sapCode?: Maybe<Array<Scalars['String']>>;
  deviceModel?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  debuggerInfo?: Maybe<DeviceNotificationDebuggerInfoInput>;
};

export type QueryPricingTableFindByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<SortPricingTableEnum>;
};

export type QueryPricingTablePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyPricingTableInput>;
  sort?: Maybe<SortFindManyPricingTableInput>;
};

export type QueryPricingTableFindManyArgs = {
  filter?: Maybe<FilterFindManyPricingTableInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyPricingTableInput>;
};

export type QueryLegalTextFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryLegalTextPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyLegalTextInput>;
  sort?: Maybe<SortFindManyLegalTextInput>;
};

export type QueryDeviceFindManyArgs = {
  filter?: Maybe<FilterFindManyDeviceInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyDeviceInput>;
};

export type QueryDeviceFindOneArgs = {
  filter?: Maybe<FilterFindOneDeviceInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneDeviceInput>;
};

export type QueryDeviceFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type QueryDevicePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyDeviceInput>;
  sort?: Maybe<SortFindManyDeviceInput>;
};

export type QueryDeviceSearchArgs = {
  search?: Maybe<Scalars['String']>;
  manufacturers?: Maybe<Array<Maybe<Scalars['String']>>>;
  skipManufacturers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryDeviceSearchByIdArgs = {
  deviceId: Scalars['String'];
};

export type QueryDeviceBaseDownloadArgs = {
  filters?: Maybe<Scalars['JSON']>;
  isExample?: Maybe<Scalars['Boolean']>;
};

export type QueryDeviceBaseValidationArgs = {
  fileId: Scalars['String'];
};

export type QueryAppDeviceArgs = {
  CNPJ: Scalars['String'];
  model: Scalars['String'];
};

export type QueryAppDeviceByManufacturerArgs = {
  CNPJ: Scalars['String'];
  manufacturer: Scalars['String'];
};

export type QueryDigitalBrochureFindManyArgs = {
  filter?: Maybe<FilterFindManyDigitalBrochureInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyDigitalBrochureInput>;
};

export type QueryDigitalBrochureFindOneArgs = {
  filter?: Maybe<FilterFindOneDigitalBrochureInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneDigitalBrochureInput>;
};

export type QueryDigitalBrochureFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type QueryDigitalBrochurePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyDigitalBrochureInput>;
  sort?: Maybe<SortFindManyDigitalBrochureInput>;
};

export type QueryDigitalBrochureSearchByDddAndServiceArgs = {
  filterDDD?: Maybe<Scalars['String']>;
  filterService?: Maybe<Scalars['String']>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryCampaignPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyCampaignInput>;
  sort?: Maybe<SortFindManyCampaignInput>;
};

export type QueryCampaignFindOneArgs = {
  filter?: Maybe<FilterFindOneCampaignInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneCampaignInput>;
};

export type QueryCampaignFindByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<SortCampaignEnum>;
};

export type QueryCampaignSearchByTitleDescriptionOrStatusArgs = {
  title?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryCampaignSearchByTitleOrDescriptionArgs = {
  title?: Maybe<Scalars['String']>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryLayoutPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyLayoutInput>;
  sort?: Maybe<SortFindManyLayoutInput>;
};

export type QueryLayoutFindOneArgs = {
  filter?: Maybe<FilterFindOneLayoutInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneLayoutInput>;
};

export type QueryLayoutFindManyArgs = {
  filter?: Maybe<FilterFindManyLayoutInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyLayoutInput>;
};

export type QueryLayoutFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsLayoutInput>;
};

export type QueryLayoutSearchByNameArgs = {
  name?: Maybe<Scalars['String']>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryLayoutSearchByNameAndContainsLpuIntervalQtyArgs = {
  name?: Maybe<Scalars['String']>;
  containsIntervalQty?: Maybe<Scalars['Boolean']>;
  perPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type QueryLayoutSearchContentByIdsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryRegionalBudgetFindOneArgs = {
  filter?: Maybe<FilterFindOneRegionalBudgetInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneRegionalBudgetInput>;
};

export type QueryRegionalBudgetFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryRegionalBudgetPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRegionalBudgetInput>;
  sort?: Maybe<SortFindManyRegionalBudgetInput>;
};

export type QueryProductionFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
  sort?: Maybe<SortProductionEnum>;
};

export type QueryProductionPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyProductionInput>;
  sort?: Maybe<SortFindManyProductionInput>;
};

export type QueryProductionReportArgs = {
  productionId?: Maybe<Scalars['String']>;
};

export type QueryProductionDeliveryReportArgs = {
  productionId?: Maybe<Scalars['String']>;
};

export type QueryBudgetFindOneArgs = {
  filter?: Maybe<FilterFindOneBudgetInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneBudgetInput>;
};

export type QueryBudgetFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryBudgetPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyBudgetInput>;
  sort?: Maybe<SortFindManyBudgetInput>;
};

export type QuerySupplierDeliveryPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManySupplierDeliveryInput>;
  sort?: Maybe<SortFindManySupplierDeliveryInput>;
};

export type QuerySupplierDeliveryFindManyArgs = {
  filter?: Maybe<FilterFindManySupplierDeliveryInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManySupplierDeliveryInput>;
};

export type QueryInventoryItemFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type QueryInventoryItemFindOneArgs = {
  filter?: Maybe<FilterFindOneInventoryItemInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneInventoryItemInput>;
};

export type QueryInventoryItemPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyInventoryItemInput>;
  sort?: Maybe<SortFindManyInventoryItemInput>;
};

export type QueryInventoryItemFindManyArgs = {
  filter?: Maybe<FilterFindManyInventoryItemInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyInventoryItemInput>;
};

export type QueryInventoryWithDrawPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyInventoryWithDrawInput>;
  sort?: Maybe<SortFindManyInventoryWithDrawInput>;
};

export type QueryInventoryWithDrawFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryDeliveryOrderFindByIdArgs = {
  _id: Scalars['MongoID'];
  sort?: Maybe<SortDeliveryOrderEnum>;
};

export type QueryDeliveryOrderPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyDeliveryOrderInput>;
  sort?: Maybe<SortFindManyDeliveryOrderInput>;
};

export type QueryDeliveryOrderValidationArgs = {
  orderIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryDeliveryOrderExecutionArgs = {
  orderIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryMetadataFindOneArgs = {
  filter?: Maybe<FilterFindOneMetadataInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneMetadataInput>;
};

export type QueryMetadataFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryPositivatorFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
};

export type QueryPositivatorFindManyArgs = {
  filter?: Maybe<FilterFindManyPositivatorInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyPositivatorInput>;
};

export type QueryPositivatorPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyPositivatorInput>;
  sort?: Maybe<SortFindManyPositivatorInput>;
};

export type QueryPositivationSumaryArgs = {
  region?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['String']>;
  finishDate?: Maybe<Scalars['String']>;
};

export type QueryPlacePositivationPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['String']>;
};

export type QueryPositivationReportByGroupArgs = {
  initialDate?: Maybe<Scalars['String']>;
  finishDate?: Maybe<Scalars['String']>;
};

export type QueryPositivationReportByItemArgs = {
  initialDate?: Maybe<Scalars['String']>;
  finishDate?: Maybe<Scalars['String']>;
};

export type QueryPositivationReportByPlaceArgs = {
  initialDate?: Maybe<Scalars['String']>;
  finishDate?: Maybe<Scalars['String']>;
};

export type QueryPositivatorItemFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryPositivatorItemPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyPositivatorItemInput>;
  sort?: Maybe<SortFindManyPositivatorItemInput>;
};

export type QueryPositivatorItemFindManyArgs = {
  filter?: Maybe<FilterFindManyPositivatorItemInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyPositivatorItemInput>;
};

export type QueryDeviceNotificationPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter: FilterFindManyDeviceNotificationInput;
  sort?: Maybe<SortFindManyDeviceNotificationInput>;
};

export type QueryUserMerchanPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterFindManyUserInput>;
  sort?: Maybe<SortFindManyUserInput>;
};

export type QueryUsersBaseDownloadArgs = {
  filters?: Maybe<Scalars['JSON']>;
  isExample?: Maybe<Scalars['Boolean']>;
};

export type QueryMerchanUserCheckArgs = {
  CPF?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
};

export type QueryPlaceAnalyticsPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyPlaceAnalyticsInput>;
  sort?: Maybe<SortFindManyPlaceAnalyticsInput>;
};

export type QueryPlaceAnalyticsFindOneArgs = {
  filter?: Maybe<FilterFindOnePlaceAnalyticsInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOnePlaceAnalyticsInput>;
};

export type QueryPlaceAnalyticsFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsPlaceAnalyticsInput>;
};

export type QueryPlaceAnalyticsFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryPlaceAnalyticsFindManyArgs = {
  filter?: Maybe<FilterFindManyPlaceAnalyticsInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyPlaceAnalyticsInput>;
};

export type QueryStructuralFindManyArgs = {
  filter?: Maybe<FilterFindManyStructuralInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyStructuralInput>;
};

export type QueryStructuralFindOneArgs = {
  filter?: Maybe<FilterFindOneStructuralInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneStructuralInput>;
};

export type QueryStructuralFindByIdArgs = {
  _id: Scalars['MongoID'];
  filter?: Maybe<Filter>;
  sort?: Maybe<SortSupplierEnum>;
};

export type QueryStructuralPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyStructuralInput>;
  sort?: Maybe<SortFindManyStructuralInput>;
};

export type QueryProjectSlaArgs = {
  technicalVisit?: Maybe<Scalars['Boolean']>;
  projetoComplementar?: Maybe<Scalars['Boolean']>;
};

export type QueryDigitalBrochureAnalyticsPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyDigitalBrochureAnalyticsInput>;
  sort?: Maybe<SortFindManyDigitalBrochureAnalyticsInput>;
};

export type QueryDigitalBrochureAnalyticsFindManyArgs = {
  filter?: Maybe<FilterFindManyDigitalBrochureAnalyticsInput>;
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyDigitalBrochureAnalyticsInput>;
};

export type QueryDigitalBrochureAnalyticsFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type QueryDigitalBrochureAnalyticsFindByIdsArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsDigitalBrochureAnalyticsInput>;
};

export type QueryDigitalBrochureAnalyticsFindOneArgs = {
  filter?: Maybe<FilterFindOneDigitalBrochureAnalyticsInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortFindOneDigitalBrochureAnalyticsInput>;
};

export type QueryDigitalBrochureAnalyticsCountArgs = {
  _id: Scalars['String'];
};

export type QueryAreaLojaPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyAreaLojaInput>;
  sort?: Maybe<SortFindManyAreaLojaInput>;
};

export type QueryRegraMapaPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyRegraMapaInput>;
  sort?: Maybe<SortFindManyRegraMapaInput>;
};

export type QueryFormatoPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyFormatoInput>;
  sort?: Maybe<SortFindManyFormatoInput>;
};

export type QueryPecaMapaPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyPecaMapaInput>;
  sort?: Maybe<SortFindManyPecaMapaInput>;
};

export type QueryMapaPdvPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  filter?: Maybe<FilterFindManyMapaPdvInput>;
  sort?: Maybe<SortFindManyMapaPdvInput>;
};

export type QueryMapaPdvFindByIdArgs = {
  _id: Scalars['MongoID'];
};

export type RecentsActivities = {
  __typename?: 'RecentsActivities';
  owner?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  viewsHistory?: Maybe<Array<Maybe<Scalars['Date']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  material?: Maybe<Material>;
};

/** List of items with pagination. */
export type RecentsActivitiesPagination = {
  __typename?: 'RecentsActivitiesPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<RecentsActivities>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type ReconheceAnalytics = {
  __typename?: 'ReconheceAnalytics';
  availablePoints?: Maybe<Scalars['Float']>;
  reservedPoints?: Maybe<Scalars['Float']>;
  usedPoints?: Maybe<Scalars['Float']>;
};

export type RecordIdOperatorsFilterFindManyPinInput = {
  /** ID do item que está sendo favoritado. */
  gt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  gte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  ne?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID do item que está sendo favoritado. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RecordIdOperatorsFilterRemoveManyPinInput = {
  /** ID do item que está sendo favoritado. */
  gt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  gte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  ne?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID do item que está sendo favoritado. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RecordIdOperatorsFilterRemoveOnePinInput = {
  /** ID do item que está sendo favoritado. */
  gt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  gte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lt?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  lte?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  ne?: Maybe<Scalars['String']>;
  /** ID do item que está sendo favoritado. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** ID do item que está sendo favoritado. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Region = {
  __typename?: 'Region';
  _id: Scalars['String'];
  /** Nome da regiao. Ex: Sudeste */
  name?: Maybe<Scalars['String']>;
  itemIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  items?: Maybe<Array<Maybe<RegionItem>>>;
};

export type RegionItemsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRegionItemInput>;
};

export type RegionalBudget = {
  __typename?: 'RegionalBudget';
  parentBudgetId?: Maybe<Scalars['String']>;
  region?: Maybe<EnumRegionalBudgetRegion>;
  deadline?: Maybe<Scalars['Date']>;
  maxValue?: Maybe<Scalars['Float']>;
  usedValue?: Maybe<Scalars['Float']>;
  remainingValue?: Maybe<Scalars['Float']>;
  availableAtChannels?: Maybe<
    Array<Maybe<EnumRegionalBudgetAvailableAtChannels>>
  >;
  allowedLayouts?: Maybe<Array<Maybe<RegionalBudgetAllowedLayouts>>>;
  productionItems?: Maybe<Array<Maybe<RegionalBudgetProductionItems>>>;
  /** True se a regional preencheu os valores para que a HQ possa criar a produção  */
  filledByRegional?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EnumRegionalBudgetStatus>;
  views?: Maybe<Array<Maybe<RegionalBudgetViews>>>;
  /** Replica as informações do produto no momento em que o budget foi salvo */
  finalProductsPricing?: Maybe<
    Array<Maybe<RegionalBudgetFinalProductsPricing>>
  >;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  parentBudget?: Maybe<Budget>;
  deadlineFormatted?: Maybe<Scalars['String']>;
};

export type RegionalBudgetAllowedLayouts = {
  __typename?: 'RegionalBudgetAllowedLayouts';
  codeT?: Maybe<Scalars['String']>;
  baseQuantity?: Maybe<Scalars['Float']>;
};

export type RegionalBudgetAllowedLayoutsInput = {
  codeT?: Maybe<Scalars['String']>;
  baseQuantity?: Maybe<Scalars['Float']>;
};

export type RegionalBudgetFinalProductsPricing = {
  __typename?: 'RegionalBudgetFinalProductsPricing';
  layoutId?: Maybe<Scalars['String']>;
  destinationsCount?: Maybe<Scalars['Float']>;
  layoutTitle?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  /** Intervalo de preços */
  productPriceInterval?: Maybe<
    Array<Maybe<RegionalBudgetFinalProductsPricingProductPriceInterval>>
  >;
  productFinalPriceByItem?: Maybe<Scalars['Float']>;
  productTotalPrice?: Maybe<Scalars['Float']>;
  productAmount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productTitle?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierCNPJ?: Maybe<Scalars['String']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignTitle?: Maybe<Scalars['String']>;
};

export type RegionalBudgetFinalProductsPricingInput = {
  layoutId?: Maybe<Scalars['String']>;
  destinationsCount?: Maybe<Scalars['Float']>;
  layoutTitle?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  /** Intervalo de preços */
  productPriceInterval?: Maybe<
    Array<Maybe<RegionalBudgetFinalProductsPricingProductPriceIntervalInput>>
  >;
  productFinalPriceByItem?: Maybe<Scalars['Float']>;
  productTotalPrice?: Maybe<Scalars['Float']>;
  productAmount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productTitle?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierCNPJ?: Maybe<Scalars['String']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignTitle?: Maybe<Scalars['String']>;
};

export type RegionalBudgetFinalProductsPricingProductPriceInterval = {
  __typename?: 'RegionalBudgetFinalProductsPricingProductPriceInterval';
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RegionalBudgetFinalProductsPricingProductPriceIntervalInput = {
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** List of items with pagination. */
export type RegionalBudgetPagination = {
  __typename?: 'RegionalBudgetPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<RegionalBudget>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type RegionalBudgetParseItemsPayload = {
  __typename?: 'RegionalBudgetParseItemsPayload';
  count?: Maybe<Scalars['Float']>;
  byDestination?: Maybe<
    Array<Maybe<RegionalBudgetParseItemsPayloadByDestination>>
  >;
  byProduct?: Maybe<Array<Maybe<RegionalBudgetParseItemsPayloadByProduct>>>;
  regionalUsedAmount: Scalars['Float'];
};

export type RegionalBudgetParseItemsPayloadByDestination = {
  __typename?: 'RegionalBudgetParseItemsPayloadByDestination';
  frontendGroupId?: Maybe<Scalars['String']>;
  destinationCNPJ?: Maybe<Scalars['String']>;
  destinationRazaoSocial?: Maybe<Scalars['String']>;
  destinationNomeFantasia?: Maybe<Scalars['String']>;
  destinationDDD?: Maybe<Scalars['String']>;
  quantityByFactorial?: Maybe<Scalars['Float']>;
  extraQuantityByDistributionCenter?: Maybe<Scalars['Float']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  destinationSAPCode?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  isQuantityCalculatedFromFactorial?: Maybe<Scalars['Boolean']>;
  deliveryPlaceType?: Maybe<
    EnumRegionalBudgetParseItemsPayloadByDestinationDeliveryPlaceType
  >;
  layoutId?: Maybe<Scalars['String']>;
  destinationsCount?: Maybe<Scalars['Float']>;
  layoutTitle?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  /** Intervalo de preços */
  productPriceInterval?: Maybe<
    Array<
      Maybe<RegionalBudgetParseItemsPayloadByDestinationProductPriceInterval>
    >
  >;
  productFinalPriceByItem?: Maybe<Scalars['Float']>;
  productTotalPrice?: Maybe<Scalars['Float']>;
  productAmount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productTitle?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierCNPJ?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignTitle?: Maybe<Scalars['String']>;
};

export type RegionalBudgetParseItemsPayloadByDestinationProductPriceInterval = {
  __typename?: 'RegionalBudgetParseItemsPayloadByDestinationProductPriceInterval';
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RegionalBudgetParseItemsPayloadByProduct = {
  __typename?: 'RegionalBudgetParseItemsPayloadByProduct';
  frontendGroupId?: Maybe<Scalars['String']>;
  quantityByDestination?: Maybe<Scalars['Float']>;
  isQuantityFromFactorial?: Maybe<Scalars['Boolean']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  extraQuantityByDistributionCenter?: Maybe<Scalars['Float']>;
  totalPlaces?: Maybe<Scalars['Float']>;
  quantityFromFactorial?: Maybe<Scalars['Float']>;
  groupTotal?: Maybe<Scalars['Float']>;
  placesInFatorial?: Maybe<
    Array<
      Maybe<
        RegionalBudgetProductionItemsQuantityByProductOutputPlacesInFatorial
      >
    >
  >;
  totalPlacesInFactorial?: Maybe<Scalars['Float']>;
  layoutId?: Maybe<Scalars['String']>;
  destinationsCount?: Maybe<Scalars['Float']>;
  layoutTitle?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  /** Intervalo de preços */
  productPriceInterval?: Maybe<
    Array<Maybe<RegionalBudgetParseItemsPayloadByProductProductPriceInterval>>
  >;
  productFinalPriceByItem?: Maybe<Scalars['Float']>;
  productTotalPrice?: Maybe<Scalars['Float']>;
  productAmount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['String']>;
  productTitle?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierCNPJ?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignTitle?: Maybe<Scalars['String']>;
};

export type RegionalBudgetParseItemsPayloadByProductProductPriceInterval = {
  __typename?: 'RegionalBudgetParseItemsPayloadByProductProductPriceInterval';
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RegionalBudgetProductionItems = {
  __typename?: 'RegionalBudgetProductionItems';
  frontendGroupId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  quantityInputMode?: Maybe<EnumRegionalBudgetProductionItemsQuantityInputMode>;
  destinationsFilters?: Maybe<RegionalBudgetProductionItemsDestinationsFilters>;
  /** Códigos SAP dos locais (Lojas, Escriotorios, etc) encontrados a partir do filtro informado no campo "destinationsFilters" */
  foundDestinationsSAPCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  quantityByProductInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductInput>>
  >;
  quantityByProductOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductOutput>>
  >;
  quantityByDestinationInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationInput>>
  >;
  /** processado no backend a partir do quantityByProductInput ou do quantityByDestinationInput dependendo do quantityInputMode */
  quantityByDestinationOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationOutput>>
  >;
  supplierDeliveryDestinationType?: Maybe<
    EnumRegionalBudgetProductionItemsSupplierDeliveryDestinationType
  >;
  extraItemsOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsExtraItemsOutput>>
  >;
};

export type RegionalBudgetProductionItemsDestinationsFilters = {
  __typename?: 'RegionalBudgetProductionItemsDestinationsFilters';
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  DDD?: Maybe<Array<Maybe<Scalars['String']>>>;
  UF?: Maybe<Array<Maybe<Scalars['String']>>>;
  cityId?: Maybe<Array<Maybe<Scalars['String']>>>;
  communicationFocus?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketedProduct?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkTechnology?: Maybe<Array<Maybe<Scalars['String']>>>;
  storeModel?: Maybe<Array<Maybe<Scalars['String']>>>;
  CNPJ?: Maybe<Array<Maybe<Scalars['String']>>>;
  sapCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  estrutura_de_loja?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RegionalBudgetProductionItemsDestinationsFiltersInput = {
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  DDD?: Maybe<Array<Maybe<Scalars['String']>>>;
  UF?: Maybe<Array<Maybe<Scalars['String']>>>;
  cityId?: Maybe<Array<Maybe<Scalars['String']>>>;
  communicationFocus?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketedProduct?: Maybe<Array<Maybe<Scalars['String']>>>;
  networkTechnology?: Maybe<Array<Maybe<Scalars['String']>>>;
  storeModel?: Maybe<Array<Maybe<Scalars['String']>>>;
  CNPJ?: Maybe<Array<Maybe<Scalars['String']>>>;
  sapCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  estrutura_de_loja?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RegionalBudgetProductionItemsExtraItemsOutput = {
  __typename?: 'RegionalBudgetProductionItemsExtraItemsOutput';
  frontendGroupId?: Maybe<Scalars['String']>;
  deliveryPlaceType?: Maybe<Scalars['String']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  extraValue?: Maybe<Scalars['Float']>;
};

export type RegionalBudgetProductionItemsExtraItemsOutputInput = {
  frontendGroupId?: Maybe<Scalars['String']>;
  deliveryPlaceType?: Maybe<Scalars['String']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  extraValue?: Maybe<Scalars['Float']>;
};

export type RegionalBudgetProductionItemsInput = {
  frontendGroupId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  quantityInputMode?: Maybe<EnumRegionalBudgetProductionItemsQuantityInputMode>;
  destinationsFilters?: Maybe<
    RegionalBudgetProductionItemsDestinationsFiltersInput
  >;
  /** Códigos SAP dos locais (Lojas, Escriotorios, etc) encontrados a partir do filtro informado no campo "destinationsFilters" */
  foundDestinationsSAPCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  quantityByProductInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductInputInput>>
  >;
  quantityByProductOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByProductOutputInput>>
  >;
  quantityByDestinationInput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationInputInput>>
  >;
  /** processado no backend a partir do quantityByProductInput ou do quantityByDestinationInput dependendo do quantityInputMode */
  quantityByDestinationOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsQuantityByDestinationOutputInput>>
  >;
  supplierDeliveryDestinationType?: Maybe<
    EnumRegionalBudgetProductionItemsSupplierDeliveryDestinationType
  >;
  extraItemsOutput?: Maybe<
    Array<Maybe<RegionalBudgetProductionItemsExtraItemsOutputInput>>
  >;
};

export type RegionalBudgetProductionItemsQuantityByDestinationInput = {
  __typename?: 'RegionalBudgetProductionItemsQuantityByDestinationInput';
  quantityByFactorial?: Maybe<Scalars['Float']>;
  extraQuantityByDistributionCenter?: Maybe<Scalars['Float']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  destinationSAPCode?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  isQuantityCalculatedFromFactorial?: Maybe<Scalars['Boolean']>;
};

export type RegionalBudgetProductionItemsQuantityByDestinationInputInput = {
  quantityByFactorial?: Maybe<Scalars['Float']>;
  extraQuantityByDistributionCenter?: Maybe<Scalars['Float']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  destinationSAPCode?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  isQuantityCalculatedFromFactorial?: Maybe<Scalars['Boolean']>;
};

export type RegionalBudgetProductionItemsQuantityByDestinationOutput = {
  __typename?: 'RegionalBudgetProductionItemsQuantityByDestinationOutput';
  quantityByFactorial?: Maybe<Scalars['Float']>;
  extraQuantityByDistributionCenter?: Maybe<Scalars['Float']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  destinationSAPCode?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  isQuantityCalculatedFromFactorial?: Maybe<Scalars['Boolean']>;
  deliveryPlaceType?: Maybe<
    EnumRegionalBudgetProductionItemsQuantityByDestinationOutputDeliveryPlaceType
  >;
};

export type RegionalBudgetProductionItemsQuantityByDestinationOutputInput = {
  quantityByFactorial?: Maybe<Scalars['Float']>;
  extraQuantityByDistributionCenter?: Maybe<Scalars['Float']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  destinationSAPCode?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  isQuantityCalculatedFromFactorial?: Maybe<Scalars['Boolean']>;
  deliveryPlaceType?: Maybe<
    EnumRegionalBudgetProductionItemsQuantityByDestinationOutputDeliveryPlaceType
  >;
};

export type RegionalBudgetProductionItemsQuantityByProductInput = {
  __typename?: 'RegionalBudgetProductionItemsQuantityByProductInput';
  quantityByDestination?: Maybe<Scalars['Float']>;
  isQuantityFromFactorial?: Maybe<Scalars['Boolean']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  extraQuantityByDistributionCenter?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RegionalBudgetProductionItemsQuantityByProductInputInput = {
  quantityByDestination?: Maybe<Scalars['Float']>;
  isQuantityFromFactorial?: Maybe<Scalars['Boolean']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  extraQuantityByDistributionCenter?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RegionalBudgetProductionItemsQuantityByProductOutput = {
  __typename?: 'RegionalBudgetProductionItemsQuantityByProductOutput';
  quantityByDestination?: Maybe<Scalars['Float']>;
  isQuantityFromFactorial?: Maybe<Scalars['Boolean']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  extraQuantityByDistributionCenter?: Maybe<Scalars['Float']>;
  totalPlaces?: Maybe<Scalars['Float']>;
  quantityFromFactorial?: Maybe<Scalars['Float']>;
  groupTotal?: Maybe<Scalars['Float']>;
  placesInFatorial?: Maybe<
    Array<
      Maybe<
        RegionalBudgetProductionItemsQuantityByProductOutputPlacesInFatorial
      >
    >
  >;
  totalPlacesInFactorial?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RegionalBudgetProductionItemsQuantityByProductOutputInput = {
  quantityByDestination?: Maybe<Scalars['Float']>;
  isQuantityFromFactorial?: Maybe<Scalars['Boolean']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  extraQuantityByDistributionCenter?: Maybe<Scalars['Float']>;
  totalPlaces?: Maybe<Scalars['Float']>;
  quantityFromFactorial?: Maybe<Scalars['Float']>;
  groupTotal?: Maybe<Scalars['Float']>;
  placesInFatorial?: Maybe<
    Array<
      Maybe<
        RegionalBudgetProductionItemsQuantityByProductOutputPlacesInFatorialInput
      >
    >
  >;
  totalPlacesInFactorial?: Maybe<Scalars['Float']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type RegionalBudgetProductionItemsQuantityByProductOutputPlacesInFatorial = {
  __typename?: 'RegionalBudgetProductionItemsQuantityByProductOutputPlacesInFatorial';
  sapCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  structural?: Maybe<Scalars['String']>;
  qnt?: Maybe<Scalars['Float']>;
};

export type RegionalBudgetProductionItemsQuantityByProductOutputPlacesInFatorialInput = {
  sapCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  structural?: Maybe<Scalars['String']>;
  qnt?: Maybe<Scalars['Float']>;
};

export type RegionalBudgetViews = {
  __typename?: 'RegionalBudgetViews';
  userId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type RegionalBudgetViewsInput = {
  userId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};

export type RegionItem = {
  __typename?: 'RegionItem';
  /** _id composto pelos parents desta area. ex. para BH: _BR_MG_31_Belo Horizonte_ */
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** sigla do local, quando aplicavel. ex: BR, SP, AL. Ou o nome se não aplicável. */
  abbreviation?: Maybe<Scalars['String']>;
  /** O nivel de uma area, 0 para pais, 1 para estado, 3 DDD (area), 4 para cidades, etc */
  level?: Maybe<Scalars['Float']>;
  UF?: Maybe<Scalars['String']>;
  DDD?: Maybe<Scalars['String']>;
  /** _id do parent - ex: para a cidade de Sorocaba o parent seria _15_ para 15 seria _SP_, para SP seria _BR_ */
  parentId?: Maybe<Scalars['String']>;
  /** código IBGE */
  IBGE?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['Boolean']>;
  isVirtualRegion?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  items?: Maybe<Array<Maybe<RegionItem>>>;
};

export type RegionItemItemsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
  sort?: Maybe<SortFindManyRegionItemInput>;
};

export type RegionItemDistinctDdd = {
  __typename?: 'RegionItemDistinctDDD';
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RegionItemDistinctRegionAndUf = {
  __typename?: 'RegionItemDistinctRegionAndUF';
  items?: Maybe<Array<Maybe<RegionItemDistinctRegionAndUfItem>>>;
};

export type RegionItemDistinctRegionAndUfItem = {
  __typename?: 'RegionItemDistinctRegionAndUFItem';
  region?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type RegionItemPagination = {
  __typename?: 'RegionItemPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<RegionItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type RegionOperatorsFilterFindManyInventoryItemInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RegionOperatorsFilterFindOneInventoryItemInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** List of items with pagination. */
export type RegionPagination = {
  __typename?: 'RegionPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Region>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type RegionPayload = {
  __typename?: 'RegionPayload';
  uf?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  ddd?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RegraMapa = {
  __typename?: 'RegraMapa';
  area?: Maybe<Scalars['String']>;
  cluster?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  image?: Maybe<File>;
  creationInfo?: Maybe<Scalars['String']>;
  areaInfo?: Maybe<AreaLoja>;
};

/** List of items with pagination. */
export type RegraMapaPagination = {
  __typename?: 'RegraMapaPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<RegraMapa>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type RemoveByIdAreaLojaPayload = {
  __typename?: 'RemoveByIdAreaLojaPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<AreaLoja>;
};

export type RemoveByIdBannerPayload = {
  __typename?: 'RemoveByIdBannerPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Banner>;
};

export type RemoveByIdBudgetPayload = {
  __typename?: 'RemoveByIdBudgetPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Budget>;
};

export type RemoveByIdCampaignPayload = {
  __typename?: 'RemoveByIdCampaignPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Campaign>;
};

export type RemoveByIdCategoryPayload = {
  __typename?: 'RemoveByIdCategoryPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Category>;
};

export type RemoveByIdCommentPayload = {
  __typename?: 'RemoveByIdCommentPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Comment>;
};

export type RemoveByIdContractPayload = {
  __typename?: 'RemoveByIdContractPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Contract>;
};

export type RemoveByIdDeliveryOrderPayload = {
  __typename?: 'RemoveByIdDeliveryOrderPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<DeliveryOrder>;
};

export type RemoveByIdDevicePayload = {
  __typename?: 'RemoveByIdDevicePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Device>;
};

export type RemoveByIdDigitalBrochurePayload = {
  __typename?: 'RemoveByIdDigitalBrochurePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<DigitalBrochure>;
};

export type RemoveByIdEventLivePayload = {
  __typename?: 'RemoveByIdEventLivePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<EventLive>;
};

export type RemoveByIdFilePayload = {
  __typename?: 'RemoveByIdFilePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<File>;
};

export type RemoveByIdFormatoPayload = {
  __typename?: 'RemoveByIdFormatoPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Formato>;
};

export type RemoveByIdLayoutPayload = {
  __typename?: 'RemoveByIdLayoutPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Layout>;
};

export type RemoveByIdLegalTextPayload = {
  __typename?: 'RemoveByIdLegalTextPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<LegalText>;
};

export type RemoveByIdMapaPdvPayload = {
  __typename?: 'RemoveByIdMapaPDVPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<MapaPdv>;
};

export type RemoveByIdMetadataPayload = {
  __typename?: 'RemoveByIdMetadataPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Metadata>;
};

export type RemoveByIdNotificationPayload = {
  __typename?: 'RemoveByIdNotificationPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Notification>;
};

export type RemoveByIdPecaMapaPayload = {
  __typename?: 'RemoveByIdPecaMapaPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<PecaMapa>;
};

export type RemoveByIdPlacePayload = {
  __typename?: 'RemoveByIdPlacePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Place>;
};

export type RemoveByIdPositivatorItemPayload = {
  __typename?: 'RemoveByIdPositivatorItemPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<PositivatorItem>;
};

export type RemoveByIdPositivatorPayload = {
  __typename?: 'RemoveByIdPositivatorPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Positivator>;
};

export type RemoveByIdPricingTablePayload = {
  __typename?: 'RemoveByIdPricingTablePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<PricingTable>;
};

export type RemoveByIdProductionPayload = {
  __typename?: 'RemoveByIdProductionPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Production>;
};

export type RemoveByIdProductPayload = {
  __typename?: 'RemoveByIdProductPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Product>;
};

export type RemoveByIdRegraMapaPayload = {
  __typename?: 'RemoveByIdRegraMapaPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<RegraMapa>;
};

export type RemoveByIdStructuralPayload = {
  __typename?: 'RemoveByIdStructuralPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Structural>;
};

export type RemoveByIdSupplierPayload = {
  __typename?: 'RemoveByIdSupplierPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Supplier>;
};

export type RemoveByIdTablePricePayload = {
  __typename?: 'RemoveByIdTablePricePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<TablePrice>;
};

export type RemoveByIdUserPayload = {
  __typename?: 'RemoveByIdUserPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<User>;
};

export type RemoveManyFolderPayload = {
  __typename?: 'RemoveManyFolderPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
};

export type RemoveManyPinPayload = {
  __typename?: 'RemoveManyPinPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
};

export type RemoveManyPositivatorPayload = {
  __typename?: 'RemoveManyPositivatorPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
};

export type RemoveOneFolderPayload = {
  __typename?: 'RemoveOneFolderPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Folder>;
};

export type RemoveOneMaterialPayload = {
  __typename?: 'RemoveOneMaterialPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Material>;
};

export type RemoveOnePinPayload = {
  __typename?: 'RemoveOnePinPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Pin>;
};

export type RemoveOneRegionPayload = {
  __typename?: 'RemoveOneRegionPayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Region>;
};

export type RemoveOneSharePayload = {
  __typename?: 'RemoveOneSharePayload';
  /** Removed document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<Share>;
};

export type ResolverSupplierSearchByNameAndContainsLpu = {
  __typename?: 'ResolverSupplierSearchByNameAndContainsLpu';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<Supplier>>>;
};

/** Perfil do usuário. Contém um conjunto de permissoes. */
export type RoleGroup = {
  __typename?: 'RoleGroup';
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  /** Quando aplicavel será o nome do canal pai */
  channelName?: Maybe<Scalars['String']>;
  /** Uma lista de permissoes incluidas no perfil. */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<Scalars['Float']>;
  /** O Nivel de acesso hierarquico deste perfil. Usado para limitar por hierarquia as acoes de um perfil sobre outro. */
  accessLevel?: Maybe<Scalars['Float']>;
  /** ID do canal `parent`; caso o perfil esteja atrelado a um canal. */
  parentId?: Maybe<Scalars['String']>;
  idm?: Maybe<RoleGroupIdm>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type RoleGroupIdm = {
  __typename?: 'RoleGroupIdm';
  type?: Maybe<EnumRoleGroupIdmType>;
  items?: Maybe<Array<Maybe<RoleGroupIdmItems>>>;
};

export type RoleGroupIdmInput = {
  type?: Maybe<EnumRoleGroupIdmType>;
  items?: Maybe<Array<Maybe<RoleGroupIdmItemsInput>>>;
};

export type RoleGroupIdmItems = {
  __typename?: 'RoleGroupIdmItems';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type RoleGroupIdmItemsInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type RoleGroupPagination = {
  __typename?: 'RoleGroupPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<RoleGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type SearchCount = {
  __typename?: 'SearchCount';
  _id: Scalars['String'];
  term?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

/** List of items with pagination. */
export type SearchCountPagination = {
  __typename?: 'SearchCountPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<SearchCount>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type SentEmailHtml = {
  __typename?: 'SentEmailHTML';
  body?: Maybe<Scalars['String']>;
};

export type Share = {
  __typename?: 'Share';
  owner?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumShareKind>;
  title?: Maybe<Scalars['String']>;
  /** O id do item compartilhado */
  recordId?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  material?: Maybe<Material>;
};

export type ShareMaterialArgs = {
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

/** List of items with pagination. */
export type SharePagination = {
  __typename?: 'SharePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Share>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type SocialUser = {
  __typename?: 'SocialUser';
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  blockedCommenting?: Maybe<Scalars['Boolean']>;
  _id: Scalars['String'];
};

export enum Sort {
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortCampaignEnum {
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  ProducttypeAsc = 'PRODUCTTYPE_ASC',
  ProducttypeDesc = 'PRODUCTTYPE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortDeliveryOrderEnum {
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC',
  FinishedAsc = 'FINISHED_ASC',
  FinishedDesc = 'FINISHED_DESC'
}

export enum SortFindByIdsCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  AvailableatrolesAsc = 'AVAILABLEATROLES_ASC',
  AvailableatrolesDesc = 'AVAILABLEATROLES_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindByIdsContractInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindByIdsDigitalBrochureAnalyticsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindByIdsFileInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  ParentidsAsc = 'PARENTIDS_ASC',
  ParentidsDesc = 'PARENTIDS_DESC',
  ParentidsKindAsc = 'PARENTIDS__KIND_ASC',
  ParentidsKindDesc = 'PARENTIDS__KIND_DESC'
}

export enum SortFindByIdsImportUsersPlanInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindByIdsLayoutInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CodetmAsc = 'CODETM_ASC',
  CodetmDesc = 'CODETM_DESC',
  CodetAsc = 'CODET_ASC',
  CodetDesc = 'CODET_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindByIdsPlaceAnalyticsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  CnpjAsc = 'CNPJ_ASC',
  CnpjDesc = 'CNPJ_DESC',
  CodigoSapAsc = 'CODIGO_SAP_ASC',
  CodigoSapDesc = 'CODIGO_SAP_DESC'
}

export enum SortFindByIdsProductInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CodeTmAsc = 'CODE_TM_ASC',
  CodeTmDesc = 'CODE_TM_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  NomeProdutoAsc = 'NOME_PRODUTO_ASC',
  NomeProdutoDesc = 'NOME_PRODUTO_DESC'
}

export enum SortFindByIdsUserDeletedInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindByIdsUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NameMatriculaAsc = 'NAME__MATRICULA_ASC',
  NameMatriculaDesc = 'NAME__MATRICULA_DESC'
}

export enum SortFindManyAnalyticsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  EventAsc = 'EVENT_ASC',
  EventDesc = 'EVENT_DESC',
  EventParentidAsc = 'EVENT__PARENTID_ASC',
  EventParentidDesc = 'EVENT__PARENTID_DESC',
  DayAsc = 'DAY_ASC',
  DayDesc = 'DAY_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC'
}

export enum SortFindManyAreaLojaInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedbyAsc = 'CREATEDBY_ASC',
  CreatedbyDesc = 'CREATEDBY_DESC'
}

export enum SortFindManyBannerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC'
}

export enum SortFindManyBudgetInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  ProducttypeAsc = 'PRODUCTTYPE_ASC',
  ProducttypeDesc = 'PRODUCTTYPE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  AvailableatrolesAsc = 'AVAILABLEATROLES_ASC',
  AvailableatrolesDesc = 'AVAILABLEATROLES_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManyChannelInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManyCommentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  LikecountAsc = 'LIKECOUNT_ASC',
  LikecountDesc = 'LIKECOUNT_DESC',
  SubcommentcountAsc = 'SUBCOMMENTCOUNT_ASC',
  SubcommentcountDesc = 'SUBCOMMENTCOUNT_DESC'
}

export enum SortFindManyContractInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyDeliveryOrderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedbyAsc = 'CREATEDBY_ASC',
  CreatedbyDesc = 'CREATEDBY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC',
  FinishedAsc = 'FINISHED_ASC',
  FinishedDesc = 'FINISHED_DESC'
}

export enum SortFindManyDeviceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyDeviceNotificationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyDigitalBrochureAnalyticsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyDigitalBrochureInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyDownloadSensitiveFilesInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyEmailInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyEmailStatsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyEmailUnsubscribeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyEventLiveInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  EventdateAsc = 'EVENTDATE_ASC',
  EventdateDesc = 'EVENTDATE_DESC'
}

export enum SortFindManyFileInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  ParentidsAsc = 'PARENTIDS_ASC',
  ParentidsDesc = 'PARENTIDS_DESC',
  ParentidsKindAsc = 'PARENTIDS__KIND_ASC',
  ParentidsKindDesc = 'PARENTIDS__KIND_DESC'
}

export enum SortFindManyFolderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyFormatoInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedbyAsc = 'CREATEDBY_ASC',
  CreatedbyDesc = 'CREATEDBY_DESC'
}

export enum SortFindManyHomebannerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyImportUsersPlanInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyInventoryItemInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  LayoutcodetmAsc = 'LAYOUTCODETM_ASC',
  LayoutcodetmDesc = 'LAYOUTCODETM_DESC',
  LocationsapcodeAsc = 'LOCATIONSAPCODE_ASC',
  LocationsapcodeDesc = 'LOCATIONSAPCODE_DESC',
  DepositnumberAsc = 'DEPOSITNUMBER_ASC',
  DepositnumberDesc = 'DEPOSITNUMBER_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  DescriptiontmAsc = 'DESCRIPTIONTM_ASC',
  DescriptiontmDesc = 'DESCRIPTIONTM_DESC'
}

export enum SortFindManyInventoryWithDrawInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyJobTitleInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyLayoutInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CodetmAsc = 'CODETM_ASC',
  CodetmDesc = 'CODETM_DESC',
  CodetAsc = 'CODET_ASC',
  CodetDesc = 'CODET_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyLegalTextInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedbyAsc = 'CREATEDBY_ASC',
  CreatedbyDesc = 'CREATEDBY_DESC'
}

export enum SortFindManyMapaPdvInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedbyAsc = 'CREATEDBY_ASC',
  CreatedbyDesc = 'CREATEDBY_DESC'
}

export enum SortFindManyMaterialInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  KindNumericidAsc = 'KIND__NUMERICID_ASC',
  KindNumericidDesc = 'KIND__NUMERICID_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyNotificationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  JobtitleAsc = 'JOBTITLE_ASC',
  JobtitleDesc = 'JOBTITLE_DESC',
  ChannelAsc = 'CHANNEL_ASC',
  ChannelDesc = 'CHANNEL_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  LoggedatAsc = 'LOGGEDAT_ASC',
  LoggedatDesc = 'LOGGEDAT_DESC'
}

export enum SortFindManyPecaMapaInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedbyAsc = 'CREATEDBY_ASC',
  CreatedbyDesc = 'CREATEDBY_DESC'
}

export enum SortFindManyPinInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  RecordidAsc = 'RECORDID_ASC',
  RecordidDesc = 'RECORDID_DESC'
}

export enum SortFindManyPlaceAnalyticsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  CnpjAsc = 'CNPJ_ASC',
  CnpjDesc = 'CNPJ_DESC',
  CodigoSapAsc = 'CODIGO_SAP_ASC',
  CodigoSapDesc = 'CODIGO_SAP_DESC'
}

export enum SortFindManyPlaceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CnpjAsc = 'CNPJ_ASC',
  CnpjDesc = 'CNPJ_DESC',
  CodigoSapAsc = 'CODIGO_SAP_ASC',
  CodigoSapDesc = 'CODIGO_SAP_DESC',
  NomeFantasiaAsc = 'NOME_FANTASIA_ASC',
  NomeFantasiaDesc = 'NOME_FANTASIA_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC',
  DisableAsc = 'DISABLE_ASC',
  DisableDesc = 'DISABLE_DESC'
}

export enum SortFindManyPositivatorInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyPositivatorItemInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedbyAsc = 'CREATEDBY_ASC',
  CreatedbyDesc = 'CREATEDBY_DESC'
}

export enum SortFindManyPricingTableInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedbyAsc = 'CREATEDBY_ASC',
  CreatedbyDesc = 'CREATEDBY_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

export enum SortFindManyProductInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CodeTmAsc = 'CODE_TM_ASC',
  CodeTmDesc = 'CODE_TM_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  NomeProdutoAsc = 'NOME_PRODUTO_ASC',
  NomeProdutoDesc = 'NOME_PRODUTO_DESC'
}

export enum SortFindManyProductionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

export enum SortFindManyRecentsActivitiesInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  EventAsc = 'EVENT_ASC',
  EventDesc = 'EVENT_DESC',
  EventParentidAsc = 'EVENT__PARENTID_ASC',
  EventParentidDesc = 'EVENT__PARENTID_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  JobtitleAsc = 'JOBTITLE_ASC',
  JobtitleDesc = 'JOBTITLE_DESC',
  ChannelAsc = 'CHANNEL_ASC',
  ChannelDesc = 'CHANNEL_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  LoggedatAsc = 'LOGGEDAT_ASC',
  LoggedatDesc = 'LOGGEDAT_DESC'
}

export enum SortFindManyRegionalBudgetInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyRegionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManyRegionItemInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyRegraMapaInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedbyAsc = 'CREATEDBY_ASC',
  CreatedbyDesc = 'CREATEDBY_DESC'
}

export enum SortFindManyRoleGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindManySearchCountInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  TermAsc = 'TERM_ASC',
  TermDesc = 'TERM_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC'
}

export enum SortFindManyShareInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindManyStructuralInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManySupplierDeliveryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManySupplierInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  RazaoSocialAsc = 'RAZAO_SOCIAL_ASC',
  RazaoSocialDesc = 'RAZAO_SOCIAL_DESC',
  CnpjAsc = 'CNPJ_ASC',
  CnpjDesc = 'CNPJ_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC'
}

export enum SortFindManyTablePriceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC'
}

export enum SortFindManyUserDeletedInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyUserHistoryBlockInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindManyUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NameMatriculaAsc = 'NAME__MATRICULA_ASC',
  NameMatriculaDesc = 'NAME__MATRICULA_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC',
  JobtitleAsc = 'JOBTITLE_ASC',
  JobtitleDesc = 'JOBTITLE_DESC',
  ChannelAsc = 'CHANNEL_ASC',
  ChannelDesc = 'CHANNEL_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  LoggedatAsc = 'LOGGEDAT_ASC',
  LoggedatDesc = 'LOGGEDAT_DESC'
}

export enum SortFindManyUserWordsBlackListInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindOneBannerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC'
}

export enum SortFindOneBudgetInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindOneCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  ProducttypeAsc = 'PRODUCTTYPE_ASC',
  ProducttypeDesc = 'PRODUCTTYPE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindOneCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  AvailableatrolesAsc = 'AVAILABLEATROLES_ASC',
  AvailableatrolesDesc = 'AVAILABLEATROLES_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindOneChannelInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindOneCommentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortFindOneContractInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneDeviceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneDigitalBrochureAnalyticsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneDigitalBrochureInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneEmailInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneEmailStatsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneEmailUnsubscribeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneEventLiveInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  EventdateAsc = 'EVENTDATE_ASC',
  EventdateDesc = 'EVENTDATE_DESC'
}

export enum SortFindOneInventoryItemInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  LayoutcodetmAsc = 'LAYOUTCODETM_ASC',
  LayoutcodetmDesc = 'LAYOUTCODETM_DESC',
  LocationsapcodeAsc = 'LOCATIONSAPCODE_ASC',
  LocationsapcodeDesc = 'LOCATIONSAPCODE_DESC',
  DepositnumberAsc = 'DEPOSITNUMBER_ASC',
  DepositnumberDesc = 'DEPOSITNUMBER_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  DescriptiontmAsc = 'DESCRIPTIONTM_ASC',
  DescriptiontmDesc = 'DESCRIPTIONTM_DESC'
}

export enum SortFindOneJobTitleInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneLayoutInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CodetmAsc = 'CODETM_ASC',
  CodetmDesc = 'CODETM_DESC',
  CodetAsc = 'CODET_ASC',
  CodetDesc = 'CODET_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindOneMaterialInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  KindNumericidAsc = 'KIND__NUMERICID_ASC',
  KindNumericidDesc = 'KIND__NUMERICID_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindOneMetadataInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneNotificationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  JobtitleAsc = 'JOBTITLE_ASC',
  JobtitleDesc = 'JOBTITLE_DESC',
  ChannelAsc = 'CHANNEL_ASC',
  ChannelDesc = 'CHANNEL_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  LoggedatAsc = 'LOGGEDAT_ASC',
  LoggedatDesc = 'LOGGEDAT_DESC'
}

export enum SortFindOnePlaceAnalyticsInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC',
  CnpjAsc = 'CNPJ_ASC',
  CnpjDesc = 'CNPJ_DESC',
  CodigoSapAsc = 'CODIGO_SAP_ASC',
  CodigoSapDesc = 'CODIGO_SAP_DESC'
}

export enum SortFindOnePlaceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CnpjAsc = 'CNPJ_ASC',
  CnpjDesc = 'CNPJ_DESC',
  CodigoSapAsc = 'CODIGO_SAP_ASC',
  CodigoSapDesc = 'CODIGO_SAP_DESC',
  NomeFantasiaAsc = 'NOME_FANTASIA_ASC',
  NomeFantasiaDesc = 'NOME_FANTASIA_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC',
  DisableAsc = 'DISABLE_ASC',
  DisableDesc = 'DISABLE_DESC'
}

export enum SortFindOneRegionalBudgetInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneRegionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindOneRegionItemInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneRoleGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortFindOneStructuralInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortFindOneSupplierInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  RazaoSocialAsc = 'RAZAO_SOCIAL_ASC',
  RazaoSocialDesc = 'RAZAO_SOCIAL_DESC',
  CnpjAsc = 'CNPJ_ASC',
  CnpjDesc = 'CNPJ_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC'
}

export enum SortFindOneTablePriceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC'
}

export enum SortFindOneUserContentPermissionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  UseridAsc = 'USERID_ASC',
  UseridDesc = 'USERID_DESC'
}

export enum SortFindOneUserDeletedInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NameMatriculaAsc = 'NAME__MATRICULA_ASC',
  NameMatriculaDesc = 'NAME__MATRICULA_DESC'
}

export enum SortMenuEnum {
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortPlaceEnum {
  CnpjAsc = 'CNPJ_ASC',
  CnpjDesc = 'CNPJ_DESC',
  NomeFantasiaAsc = 'NOME_FANTASIA_ASC',
  NomeFantasiaDesc = 'NOME_FANTASIA_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UfAsc = 'UF_ASC',
  UfDesc = 'UF_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC',
  DisableAsc = 'DISABLE_ASC',
  DisableDesc = 'DISABLE_DESC'
}

export enum SortPricingTableEnum {
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

export enum SortProductEnum {
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  NomeProdutoAsc = 'NOME_PRODUTO_ASC',
  NomeProdutoDesc = 'NOME_PRODUTO_DESC'
}

export enum SortProductionEnum {
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

export enum SortRemoveOneFolderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortRemoveOneMaterialInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  KindNumericidAsc = 'KIND__NUMERICID_ASC',
  KindNumericidDesc = 'KIND__NUMERICID_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortRemoveOnePinInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  RecordidAsc = 'RECORDID_ASC',
  RecordidDesc = 'RECORDID_DESC'
}

export enum SortRemoveOneRegionInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortRemoveOneShareInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortSupplierEnum {
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DisabledAsc = 'DISABLED_ASC',
  DisabledDesc = 'DISABLED_DESC'
}

export enum SortUpdateManyPositivatorInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneBannerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  AvailableatrolesAsc = 'AVAILABLEATROLES_ASC',
  AvailableatrolesDesc = 'AVAILABLEATROLES_DESC'
}

export enum SortUpdateOneCommentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneContractInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneDeviceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneDigitalBrochureInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneEventLiveInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  EventdateAsc = 'EVENTDATE_ASC',
  EventdateDesc = 'EVENTDATE_DESC'
}

export enum SortUpdateOneFolderInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneImportUsersPlanInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneLayoutInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CodetmAsc = 'CODETM_ASC',
  CodetmDesc = 'CODETM_DESC'
}

export enum SortUpdateOneMaterialInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  KindAsc = 'KIND_ASC',
  KindDesc = 'KIND_DESC',
  KindNumericidAsc = 'KIND__NUMERICID_ASC',
  KindNumericidDesc = 'KIND__NUMERICID_DESC',
  PublishedatAsc = 'PUBLISHEDAT_ASC',
  PublishedatDesc = 'PUBLISHEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  CreatedatAsc = 'CREATEDAT_ASC',
  CreatedatDesc = 'CREATEDAT_DESC'
}

export enum SortUpdateOneMetadataInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneNotificationInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  AvailableatregionsAsc = 'AVAILABLEATREGIONS_ASC',
  AvailableatregionsDesc = 'AVAILABLEATREGIONS_DESC',
  AvailableatchannelsAsc = 'AVAILABLEATCHANNELS_ASC',
  AvailableatchannelsDesc = 'AVAILABLEATCHANNELS_DESC',
  AvailableatrolegroupsAsc = 'AVAILABLEATROLEGROUPS_ASC',
  AvailableatrolegroupsDesc = 'AVAILABLEATROLEGROUPS_DESC',
  AvailableatregionalprofilesAsc = 'AVAILABLEATREGIONALPROFILES_ASC',
  AvailableatregionalprofilesDesc = 'AVAILABLEATREGIONALPROFILES_DESC',
  AvailableforusersAsc = 'AVAILABLEFORUSERS_ASC',
  AvailableforusersDesc = 'AVAILABLEFORUSERS_DESC',
  DestinationemailsfrommailingfileAsc = 'DESTINATIONEMAILSFROMMAILINGFILE_ASC',
  DestinationemailsfrommailingfileDesc = 'DESTINATIONEMAILSFROMMAILINGFILE_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOnePlaceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CnpjAsc = 'CNPJ_ASC',
  CnpjDesc = 'CNPJ_DESC',
  CodigoSapAsc = 'CODIGO_SAP_ASC',
  CodigoSapDesc = 'CODIGO_SAP_DESC'
}

export enum SortUpdateOnePositivatorInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export enum SortUpdateOneProductInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  CodeTmAsc = 'CODE_TM_ASC',
  CodeTmDesc = 'CODE_TM_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum SortUpdateOneStructuralInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneSupplierInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneTablePriceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortUpdateOneUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  NameMatriculaAsc = 'NAME__MATRICULA_ASC',
  NameMatriculaDesc = 'NAME__MATRICULA_DESC'
}

export type StorageReport = {
  __typename?: 'StorageReport';
  depositPath?: Maybe<Scalars['String']>;
  itemCodeTM?: Maybe<Scalars['String']>;
  itemDescription?: Maybe<Scalars['String']>;
  withdrawAmount?: Maybe<Scalars['Int']>;
  storageAmount?: Maybe<Scalars['Int']>;
};

export type Structural = {
  __typename?: 'Structural';
  cnpj?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tipo_modificacao?: Maybe<Scalars['String']>;
  tipo_loja?: Maybe<Scalars['String']>;
  financial?: Maybe<Array<Maybe<StructuralFinancial>>>;
  canal?: Maybe<Scalars['String']>;
  classificacao_pdv?: Maybe<Scalars['String']>;
  ponto_de_cessao?: Maybe<Scalars['String']>;
  loja_sap?: Maybe<Scalars['String']>;
  patrimonio_tombado?: Maybe<Scalars['String']>;
  reembolso_obra?: Maybe<Scalars['String']>;
  local?: Maybe<Scalars['String']>;
  ddd?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  escritorio_vt?: Maybe<Scalars['String']>;
  escritorio_resp?: Maybe<Scalars['String']>;
  status?: Maybe<EnumStructuralStatus>;
  stage?: Maybe<EnumStructuralStage>;
  projectSLA?: Maybe<Scalars['JSON']>;
  stageDATA?: Maybe<Scalars['JSON']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  history?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type StructuralEditStatusPayload = {
  __typename?: 'StructuralEditStatusPayload';
  stageDATA?: Maybe<Scalars['JSON']>;
};

export type StructuralFinancial = {
  __typename?: 'StructuralFinancial';
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  previewValue?: Maybe<Scalars['Float']>;
  actualValue?: Maybe<Scalars['Float']>;
};

export type StructuralFinancialInput = {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  previewValue?: Maybe<Scalars['Float']>;
  actualValue?: Maybe<Scalars['Float']>;
};

/** List of items with pagination. */
export type StructuralPagination = {
  __typename?: 'StructuralPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Structural>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum StructuralPermissionsEnum {
  CreateStructural = 'create_structural',
  ReadStructural = 'read_structural',
  UpdateStructural = 'update_structural',
  DeleteStructural = 'delete_structural'
}

export type Supplier = {
  __typename?: 'Supplier';
  numero_fornecedor_sap?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  regionais_ufs_atendidas?: Maybe<Array<Maybe<Scalars['String']>>>;
  contatos?: Maybe<Array<Maybe<User>>>;
  nome?: Maybe<Scalars['String']>;
  telefone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nivel_aprovacao?: Maybe<Scalars['String']>;
  valor_qualificacao?: Maybe<Scalars['Float']>;
  validade_qualificacao?: Maybe<Scalars['Date']>;
  contratos?: Maybe<Array<Maybe<Contract>>>;
  produtos_unitarios?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type SupplierContatosArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsUserInput>;
};

export type SupplierContratosArgs = {
  limit: Scalars['Int'];
  sort?: Maybe<SortFindByIdsContractInput>;
  filter?: Maybe<Filter>;
};

export type SupplierDelivery = {
  __typename?: 'SupplierDelivery';
  /** Codigo sap do fornecedor. numero_fornecedor_sap na entidade Supplier. */
  supplierSAPCode?: Maybe<Scalars['String']>;
  /** Codigo T do layout */
  layoutCodeT?: Maybe<Scalars['String']>;
  /** Codigo TM do layout */
  layoutCodeTM?: Maybe<Scalars['String']>;
  productionId?: Maybe<Scalars['String']>;
  productionTitle?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['Boolean']>;
  expectedDeliveryDate?: Maybe<Scalars['Date']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  sentEmailIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalNotes?: Maybe<Scalars['String']>;
  placeRegion?: Maybe<Scalars['String']>;
  depositPath?: Maybe<Scalars['String']>;
  depositNumber?: Maybe<Scalars['String']>;
  deliveryPlaceId?: Maybe<Scalars['String']>;
  deliveryPlaceType?: Maybe<Scalars['String']>;
  deliveryPlaceTypeName?: Maybe<Scalars['String']>;
  deliveryPlaceName?: Maybe<Scalars['String']>;
  deliveryPlaceCNPJ?: Maybe<Scalars['String']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  deliveryPlaceRazaoSocial?: Maybe<Scalars['String']>;
  deliveryPlaceNomeFantasia?: Maybe<Scalars['String']>;
  deliveryPlaceAddress?: Maybe<Scalars['String']>;
  deliveryPlaceNumber?: Maybe<Scalars['String']>;
  deliveryPlaceComplement?: Maybe<Scalars['String']>;
  deliveryPlaceZipCode?: Maybe<Scalars['String']>;
  deliveryPlaceNeighborhood?: Maybe<Scalars['String']>;
  deliveryPlaceCity?: Maybe<Scalars['String']>;
  deliveryPlaceState?: Maybe<Scalars['String']>;
  deliveryPlaceIsRegional?: Maybe<Scalars['Boolean']>;
  quantityToDelivery?: Maybe<Scalars['Float']>;
  quantityDelivered?: Maybe<Scalars['Float']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  receiverPersonalName?: Maybe<Scalars['String']>;
  receiverPersonalDocument?: Maybe<Scalars['String']>;
  archivedToUserIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sumario da producão que deu origem as este LogisticItem */
  originSummary?: Maybe<SupplierDeliveryOriginSummary>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  productionStatus?: Maybe<Scalars['String']>;
  deliveryStatus?: Maybe<Scalars['String']>;
};

export type SupplierDeliveryOriginSummary = {
  __typename?: 'SupplierDeliveryOriginSummary';
  productionCode?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierSAPCode?: Maybe<Scalars['String']>;
  productionId?: Maybe<Scalars['String']>;
  productionTitle?: Maybe<Scalars['String']>;
  layoutId?: Maybe<Scalars['String']>;
  layoutTitle?: Maybe<Scalars['String']>;
  layoutPassword?: Maybe<Scalars['String']>;
  layoutLink?: Maybe<Scalars['String']>;
  deliveryPlaceId?: Maybe<Scalars['String']>;
  deliveryPlaceType?: Maybe<Scalars['String']>;
  deliveryPlaceName?: Maybe<Scalars['String']>;
  deliveryPlaceTypeName?: Maybe<Scalars['String']>;
  deliveryPlaceCNPJ?: Maybe<Scalars['String']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  deliveryPlaceRazaoSocial?: Maybe<Scalars['String']>;
  deliveryPlaceNomeFantasia?: Maybe<Scalars['String']>;
  deliveryPlaceAddress?: Maybe<Scalars['String']>;
  deliveryPlaceNumber?: Maybe<Scalars['String']>;
  deliveryPlaceComplement?: Maybe<Scalars['String']>;
  deliveryPlaceZipCode?: Maybe<Scalars['String']>;
  deliveryPlaceNeighborhood?: Maybe<Scalars['String']>;
  deliveryPlaceCity?: Maybe<Scalars['String']>;
  deliveryPlaceState?: Maybe<Scalars['String']>;
  deliveryPlaceIsRegional?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['String']>;
  placeCustCode?: Maybe<Scalars['String']>;
  placeCNPJ?: Maybe<Scalars['String']>;
  placeRazaoSocial?: Maybe<Scalars['String']>;
  placeNomeFantasia?: Maybe<Scalars['String']>;
  placeRegion?: Maybe<Scalars['String']>;
  placeCity?: Maybe<Scalars['String']>;
  placeNeighborhood?: Maybe<Scalars['String']>;
  placeZipCode?: Maybe<Scalars['String']>;
  placeState?: Maybe<Scalars['String']>;
  placeAreaCode?: Maybe<Scalars['String']>;
  placeSAPCode?: Maybe<Scalars['String']>;
  placeCluster?: Maybe<Scalars['String']>;
  placeCommunicationFocus?: Maybe<Scalars['String']>;
  placeStoreModel?: Maybe<Scalars['String']>;
  placeNetworkTech?: Maybe<Scalars['String']>;
  placeInCapital?: Maybe<Scalars['Boolean']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  layoutCodeTM?: Maybe<Scalars['String']>;
  layoutDescription?: Maybe<Scalars['String']>;
  layoutExtraValueByCDL?: Maybe<Scalars['Float']>;
  layoutFinalCountAtSameDeliveryPlace?: Maybe<Scalars['Float']>;
  layoutProductionCount?: Maybe<Scalars['Float']>;
  destinationPlaceQuantity?: Maybe<Scalars['Float']>;
  layoutProductionUnitPrice?: Maybe<Scalars['Float']>;
  layoutProductionUnitPriceFormatted?: Maybe<Scalars['String']>;
  layoutProductionTotalPrice?: Maybe<Scalars['Float']>;
  layoutProductionTotalPriceFormatted?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

export type SupplierDeliveryOriginSummaryInput = {
  productionCode?: Maybe<Scalars['String']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierId?: Maybe<Scalars['String']>;
  supplierSAPCode?: Maybe<Scalars['String']>;
  productionId?: Maybe<Scalars['String']>;
  productionTitle?: Maybe<Scalars['String']>;
  layoutId?: Maybe<Scalars['String']>;
  layoutTitle?: Maybe<Scalars['String']>;
  layoutPassword?: Maybe<Scalars['String']>;
  layoutLink?: Maybe<Scalars['String']>;
  deliveryPlaceId?: Maybe<Scalars['String']>;
  deliveryPlaceType?: Maybe<Scalars['String']>;
  deliveryPlaceName?: Maybe<Scalars['String']>;
  deliveryPlaceTypeName?: Maybe<Scalars['String']>;
  deliveryPlaceCNPJ?: Maybe<Scalars['String']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  deliveryPlaceRazaoSocial?: Maybe<Scalars['String']>;
  deliveryPlaceNomeFantasia?: Maybe<Scalars['String']>;
  deliveryPlaceAddress?: Maybe<Scalars['String']>;
  deliveryPlaceNumber?: Maybe<Scalars['String']>;
  deliveryPlaceComplement?: Maybe<Scalars['String']>;
  deliveryPlaceZipCode?: Maybe<Scalars['String']>;
  deliveryPlaceNeighborhood?: Maybe<Scalars['String']>;
  deliveryPlaceCity?: Maybe<Scalars['String']>;
  deliveryPlaceState?: Maybe<Scalars['String']>;
  deliveryPlaceIsRegional?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['String']>;
  placeCustCode?: Maybe<Scalars['String']>;
  placeCNPJ?: Maybe<Scalars['String']>;
  placeRazaoSocial?: Maybe<Scalars['String']>;
  placeNomeFantasia?: Maybe<Scalars['String']>;
  placeRegion?: Maybe<Scalars['String']>;
  placeCity?: Maybe<Scalars['String']>;
  placeNeighborhood?: Maybe<Scalars['String']>;
  placeZipCode?: Maybe<Scalars['String']>;
  placeState?: Maybe<Scalars['String']>;
  placeAreaCode?: Maybe<Scalars['String']>;
  placeSAPCode?: Maybe<Scalars['String']>;
  placeCluster?: Maybe<Scalars['String']>;
  placeCommunicationFocus?: Maybe<Scalars['String']>;
  placeStoreModel?: Maybe<Scalars['String']>;
  placeNetworkTech?: Maybe<Scalars['String']>;
  placeInCapital?: Maybe<Scalars['Boolean']>;
  layoutCodeT?: Maybe<Scalars['String']>;
  layoutCodeTM?: Maybe<Scalars['String']>;
  layoutDescription?: Maybe<Scalars['String']>;
  layoutExtraValueByCDL?: Maybe<Scalars['Float']>;
  layoutFinalCountAtSameDeliveryPlace?: Maybe<Scalars['Float']>;
  layoutProductionCount?: Maybe<Scalars['Float']>;
  destinationPlaceQuantity?: Maybe<Scalars['Float']>;
  layoutProductionUnitPrice?: Maybe<Scalars['Float']>;
  layoutProductionUnitPriceFormatted?: Maybe<Scalars['String']>;
  layoutProductionTotalPrice?: Maybe<Scalars['Float']>;
  layoutProductionTotalPriceFormatted?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type SupplierDeliveryPagination = {
  __typename?: 'SupplierDeliveryPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<SupplierDelivery>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type SupplierDeliveryUpdateStatusInput = {
  delivered?: Maybe<Scalars['Boolean']>;
  deliveryPlaceName?: Maybe<Scalars['String']>;
  deliveryPlaceSAPCode?: Maybe<Scalars['String']>;
  expectedDeliveryDate?: Maybe<Scalars['Date']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  supplierDeliveryId: Scalars['String'];
  receiverPersonalDocument?: Maybe<Scalars['String']>;
  receiverPersonalName?: Maybe<Scalars['String']>;
  additionalNotes?: Maybe<Scalars['String']>;
};

/** List of items with pagination. */
export type SupplierPagination = {
  __typename?: 'SupplierPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<Supplier>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum SupplierPermissionsEnum {
  SupplierViewModule = 'supplier_viewModule',
  CreateSupplier = 'create_supplier',
  ReadSupplier = 'read_supplier',
  UpdateSupplier = 'update_supplier',
  UpdateStatusSupplier = 'update_status_supplier',
  DeleteSupplier = 'delete_supplier',
  CreateContract = 'create_contract',
  ReadContract = 'read_contract',
  UpdateContract = 'update_contract',
  UpdateStatusContract = 'update_status_contract',
  DeleteContract = 'delete_contract',
  CreateProduct = 'create_product',
  ReadProduct = 'read_product',
  UpdateProduct = 'update_product',
  UpdateStatusProduct = 'update_status_product',
  DeleteProduct = 'delete_product'
}

export type TablePlansPayload = {
  __typename?: 'TablePlansPayload';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type TablePrice = {
  __typename?: 'TablePrice';
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  upload?: Maybe<Scalars['String']>;
  sapCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  planPre?: Maybe<Scalars['String']>;
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  coolText?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  plansDevice?: Maybe<Array<Maybe<TablePricePlansDevice>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  uploadFile?: Maybe<File>;
  uploadFileContent?: Maybe<Scalars['String']>;
};

export type TablePriceAccessories = {
  __typename?: 'TablePriceAccessories';
  timCode?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type TablePriceDeviceBySapCode = {
  __typename?: 'TablePriceDeviceBySapCode';
  tablePrice?: Maybe<TablePrice>;
  items?: Maybe<Array<Maybe<TablePriceDevicePrice>>>;
};

export type TablePriceDevicePrice = {
  __typename?: 'TablePriceDevicePrice';
  device?: Maybe<Device>;
  price?: Maybe<DevicePrices>;
};

export type TablePriceDevices = {
  __typename?: 'TablePriceDevices';
  device?: Maybe<Device>;
  devicePlans?: Maybe<DevicePlans>;
  legalText: Scalars['String'];
  price?: Maybe<DevicePrices>;
};

/** List of items with pagination. */
export type TablePricePagination = {
  __typename?: 'TablePricePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<TablePrice>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export enum TablePricePermissionsEnum {
  TablePriceViewModule = 'table_price_view_module',
  CreateTablePrice = 'create_table_price',
  ReadTablePrice = 'read_table_price',
  UpdateTablePrice = 'update_table_price',
  UpdateStatusTablePrice = 'update_status_table_price',
  DeleteTablePrice = 'delete_table_price'
}

export type TablePricePlansDevice = {
  __typename?: 'TablePricePlansDevice';
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  device?: Maybe<TablePricePlansDeviceDevice>;
};

export type TablePricePlansDeviceDevice = {
  __typename?: 'TablePricePlansDeviceDevice';
  _id?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  codeTim?: Maybe<Scalars['String']>;
  legalText?: Maybe<Scalars['String']>;
};

export type TablePricePlansDeviceDeviceInput = {
  _id?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  codeTim?: Maybe<Scalars['String']>;
  legalText?: Maybe<Scalars['String']>;
};

export type TablePricePlansDeviceInput = {
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  device?: Maybe<TablePricePlansDeviceDeviceInput>;
};

export type TablePriceSearchBySapCodeAndDeviceModel = {
  __typename?: 'TablePriceSearchBySapCodeAndDeviceModel';
  tablePrice?: Maybe<TablePrice>;
  items?: Maybe<Array<Maybe<TablePriceSearchBySapCodeAndDeviceModelDevice>>>;
};

export type TablePriceSearchBySapCodeAndDeviceModelDevice = {
  __typename?: 'TablePriceSearchBySapCodeAndDeviceModelDevice';
  device?: Maybe<Device>;
  price?: Maybe<DevicePrices>;
};

export type TermOperatorsFilterFindManySearchCountInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TextFindBlackList = {
  __typename?: 'TextFindBlackList';
  isOk?: Maybe<Scalars['Boolean']>;
};

export type UpdateByIdAreaLojaInput = {
  position?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdAreaLojaPayload = {
  __typename?: 'UpdateByIdAreaLojaPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<AreaLoja>;
};

export type UpdateByIdBudgetInput = {
  status?: Maybe<EnumBudgetStatus>;
  /** Titulo da verba. Exemplo "Verba para campanha de natal" */
  title?: Maybe<Scalars['String']>;
  /** Valor total da verba a ser distribuida entre as regionais. */
  value?: Maybe<Scalars['Float']>;
  /** Quanto cada regional poderá usar para produzir. */
  regionalValues?: Maybe<Array<Maybe<BudgetRegionalValuesInput>>>;
  allowedLayouts?: Maybe<Array<Maybe<BudgetAllowedLayoutsInput>>>;
  /** se true os itens criados na entidade RegionalBudget serão apenas para registro. Regionais não fazem produção pelo portal */
  isRegionalProduction?: Maybe<Scalars['Boolean']>;
  /** Canais (tipos) de lojas que poderão receber os produtos dessa verba. */
  availableAtChannels?: Maybe<Array<Maybe<EnumBudgetAvailableAtChannels>>>;
  /** ID da producão gerada a partir dessa verba */
  usedInProductionId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdBudgetPayload = {
  __typename?: 'UpdateByIdBudgetPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Budget>;
};

export type UpdateByIdContractInput = {
  nr_clm?: Maybe<Scalars['String']>;
  nr_contrato?: Maybe<Scalars['String']>;
  inicio_vigencia?: Maybe<Scalars['Date']>;
  final_vigencia?: Maybe<Scalars['Date']>;
  valor_total?: Maybe<Scalars['Float']>;
  valor_utilizado?: Maybe<Scalars['Float']>;
  nr_contrato_derivado?: Maybe<Scalars['String']>;
  inicio_vigencia_derivado?: Maybe<Scalars['Date']>;
  final_vigencia_derivado?: Maybe<Scalars['Date']>;
  valor_total_derivado?: Maybe<Scalars['Float']>;
  valor_utilizado_derivado?: Maybe<Scalars['Float']>;
  margem_saldo_derivado?: Maybe<Scalars['Float']>;
  natureza_contrato?: Maybe<Scalars['String']>;
  natureza_verba?: Maybe<Scalars['String']>;
  nrs_cic?: Maybe<Array<Maybe<ContractNrs_CicInput>>>;
  prazo_pagto?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdContractPayload = {
  __typename?: 'UpdateByIdContractPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Contract>;
};

export type UpdateByIdDeliveryOrderInput = {
  title?: Maybe<Scalars['String']>;
  status?: Maybe<EnumDeliveryOrderStatus>;
  groups?: Maybe<Array<Maybe<DeliveryOrderGroupsInput>>>;
  records?: Maybe<Array<Maybe<DeliveryOrderRecordsInput>>>;
  region?: Maybe<Scalars['String']>;
  origin?: Maybe<EnumDeliveryOrderOrigin>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  executedAt?: Maybe<Scalars['Date']>;
  executedById?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdDeliveryOrderPayload = {
  __typename?: 'UpdateByIdDeliveryOrderPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<DeliveryOrder>;
};

export type UpdateByIdFileInput = {
  origin?: Maybe<Scalars['String']>;
  /** O _id dos materiais que usam este arquivo */
  parentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O tipo de uso do material. Ex: "video_poster" */
  kind?: Maybe<EnumFileKind>;
  title?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  uploaded?: Maybe<Scalars['Boolean']>;
  /** A url original do arquivo, sem token de autorização. */
  url?: Maybe<Scalars['String']>;
  destinationKey?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  sizeDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  imageInfo?: Maybe<FileImageInfoInput>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdFilePayload = {
  __typename?: 'UpdateByIdFilePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<File>;
};

export type UpdateByIdFormatoInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdFormatoPayload = {
  __typename?: 'UpdateByIdFormatoPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Formato>;
};

export type UpdateByIdHomebannerInput = {
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  content?: Maybe<Array<Maybe<HomebannerContentInput>>>;
  visible?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdHomebannerPayload = {
  __typename?: 'UpdateByIdHomebannerPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Homebanner>;
};

export type UpdateByIdLayoutInput = {
  lpu?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  codeT?: Maybe<Scalars['String']>;
  codeTM?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnailID?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdLayoutPayload = {
  __typename?: 'UpdateByIdLayoutPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Layout>;
};

export type UpdateByIdLegalTextInput = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdLegalTextPayload = {
  __typename?: 'UpdateByIdLegalTextPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<LegalText>;
};

export type UpdateByIdMapaPdvInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  obs?: Maybe<Scalars['String']>;
  hasObs?: Maybe<Scalars['Boolean']>;
  clusters?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterState?: Maybe<Scalars['JSON']>;
  hasPeriod?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  pecas?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdMapaPdvPayload = {
  __typename?: 'UpdateByIdMapaPDVPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<MapaPdv>;
};

export type UpdateByIdMaterialInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<MaterialEditorInput>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdMaterialPayload = {
  __typename?: 'UpdateByIdMaterialPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Material>;
};

export type UpdateByIdMetadataInput = {
  title?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdMetadataPayload = {
  __typename?: 'UpdateByIdMetadataPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Metadata>;
};

export type UpdateByIdPecaMapaInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['JSON']>;
  formats?: Maybe<Array<Maybe<Scalars['String']>>>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdPecaMapaPayload = {
  __typename?: 'UpdateByIdPecaMapaPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<PecaMapa>;
};

export type UpdateByIdPlaceInput = {
  type?: Maybe<EnumPlaceType>;
  disabled?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  inscricao_estadual?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  custcode?: Maybe<Scalars['String']>;
  codigo_sap?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<PlaceAddressInput>;
  modelo_de_loja?: Maybe<EnumPlaceModelo_De_Loja>;
  tipo_de_loja?: Maybe<EnumPlaceTipo_De_Loja>;
  tecnologia_de_rede?: Maybe<Scalars['String']>;
  produtos_comercializados?: Maybe<
    Array<Maybe<EnumPlaceProdutos_Comercializados>>
  >;
  foco_de_comunicacao?: Maybe<EnumPlaceFoco_De_Comunicacao>;
  cluster?: Maybe<EnumPlaceCluster>;
  canal_distribuicao?: Maybe<Scalars['String']>;
  organizacao_vendas?: Maybe<Scalars['String']>;
  setor_atividade?: Maybe<Scalars['String']>;
  quantidade_de_funcionarios?: Maybe<Scalars['Float']>;
  localizacao?: Maybe<EnumPlaceLocalizacao>;
  nome_da_galeria?: Maybe<Scalars['String']>;
  nome_do_shopping?: Maybe<Scalars['String']>;
  parcelamento?: Maybe<Scalars['Float']>;
  estrutura_de_loja?: Maybe<Array<Maybe<PlaceEstrutura_De_LojaInput>>>;
  inicio_da_operacao_mes?: Maybe<Scalars['Float']>;
  inicio_da_operacao_ano?: Maybe<Scalars['Float']>;
  ultima_modificacao?: Maybe<EnumPlaceUltima_Modificacao>;
  data_de_modificacao_mes?: Maybe<Scalars['Float']>;
  data_de_modificacao_ano?: Maybe<Scalars['Float']>;
  metragem?: Maybe<PlaceMetragemInput>;
  tipo_de_fachada?: Maybe<EnumPlaceTipo_De_Fachada>;
  revestimento_externo?: Maybe<EnumPlaceRevestimento_Externo>;
  posicoes_de_atendimento?: Maybe<Scalars['Float']>;
  posicoes_de_pos_venda?: Maybe<Scalars['Float']>;
  sala_de_gerente?: Maybe<Scalars['Boolean']>;
  back_office?: Maybe<Scalars['Boolean']>;
  wifi_clientes?: Maybe<Scalars['Boolean']>;
  sinal_live?: Maybe<Scalars['Boolean']>;
  display_de_senha?: Maybe<Scalars['Boolean']>;
  sistema_de_som?: Maybe<Scalars['Boolean']>;
  video_wall?: Maybe<Array<Maybe<PlaceVideo_WallInput>>>;
  adminInput?: Maybe<PlaceAdminInputInput>;
  adminId?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdPlacePayload = {
  __typename?: 'UpdateByIdPlacePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Place>;
};

export type UpdateByIdPositivatorItemInput = {
  title?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  positivator?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUpload?: Maybe<Scalars['String']>;
  status?: Maybe<EnumPositivatorItemStatus>;
  place?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdPositivatorItemPayload = {
  __typename?: 'UpdateByIdPositivatorItemPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<PositivatorItem>;
};

export type UpdateByIdPricingTableInput = {
  status?: Maybe<EnumPricingTableStatus>;
  description?: Maybe<Scalars['String']>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  disabled?: Maybe<Scalars['Boolean']>;
  xlsxId?: Maybe<Scalars['String']>;
  activeSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  planPre?: Maybe<Scalars['String']>;
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  legalText?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  incidents?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  previous?: Maybe<Scalars['String']>;
  plansDevice?: Maybe<Scalars['JSON']>;
  devicePrices?: Maybe<Array<Maybe<PricingTableDevicePricesInput>>>;
  gadgetPrices?: Maybe<Array<Maybe<PricingTableGadgetPricesInput>>>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdPricingTablePayload = {
  __typename?: 'UpdateByIdPricingTablePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<PricingTable>;
};

export type UpdateByIdProductInput = {
  nome_produto?: Maybe<Scalars['String']>;
  preco_produto?: Maybe<Scalars['Float']>;
  categoria?: Maybe<EnumProductCategoria>;
  abreviacao_producao?: Maybe<Scalars['String']>;
  especificacao?: Maybe<Scalars['String']>;
  faixa_preco?: Maybe<Scalars['Boolean']>;
  especificacao_peso?: Maybe<Scalars['Float']>;
  especificacao_largura?: Maybe<Scalars['Float']>;
  especificacao_altura?: Maybe<Scalars['Float']>;
  especificacao_profundidade?: Maybe<Scalars['Float']>;
  especificacao_preco?: Maybe<Scalars['Float']>;
  intervalo_quantidade?: Maybe<Array<Maybe<ProductIntervalo_QuantidadeInput>>>;
  code_tm?: Maybe<Scalars['String']>;
  qtd_dias?: Maybe<Scalars['Float']>;
  contagem?: Maybe<Scalars['String']>;
  tipo_multiplicador?: Maybe<EnumProductTipo_Multiplicador>;
  multiplicador_pdv?: Maybe<Scalars['Float']>;
  multiplicador_mobiliario?: Maybe<Scalars['Float']>;
  multiplicador_posicao?: Maybe<Scalars['Float']>;
  ncm?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Codigo SAP do fornecedor. "numero_fornecedor_sap" na entidade Supplier */
  supplierSAPCode?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdProductionInput = {
  title?: Maybe<Scalars['String']>;
  /** lista de locais para receber os produtos mais os filtros
   * utilizados para obter a lista de locais
   */
  productionItems?: Maybe<Array<Maybe<ProductionProductionItemsInput>>>;
  /** quando uma producão é criada a partir de uma verba
   * salvamos o _id da verba
   */
  originBudgetId?: Maybe<Scalars['String']>;
  status?: Maybe<EnumProductionStatus>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdProductionPayload = {
  __typename?: 'UpdateByIdProductionPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Production>;
};

export type UpdateByIdProductPayload = {
  __typename?: 'UpdateByIdProductPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Product>;
};

export type UpdateByIdRegraMapaInput = {
  area?: Maybe<Scalars['String']>;
  cluster?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdRegraMapaPayload = {
  __typename?: 'UpdateByIdRegraMapaPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<RegraMapa>;
};

export type UpdateByIdStructuralInput = {
  cnpj?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tipo_modificacao?: Maybe<Scalars['String']>;
  tipo_loja?: Maybe<Scalars['String']>;
  financial?: Maybe<Array<Maybe<StructuralFinancialInput>>>;
  canal?: Maybe<Scalars['String']>;
  classificacao_pdv?: Maybe<Scalars['String']>;
  ponto_de_cessao?: Maybe<Scalars['String']>;
  loja_sap?: Maybe<Scalars['String']>;
  patrimonio_tombado?: Maybe<Scalars['String']>;
  reembolso_obra?: Maybe<Scalars['String']>;
  local?: Maybe<Scalars['String']>;
  ddd?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  escritorio_vt?: Maybe<Scalars['String']>;
  escritorio_resp?: Maybe<Scalars['String']>;
  status?: Maybe<EnumStructuralStatus>;
  stage?: Maybe<EnumStructuralStage>;
  projectSLA?: Maybe<Scalars['JSON']>;
  stageDATA?: Maybe<Scalars['JSON']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdStructuralPayload = {
  __typename?: 'UpdateByIdStructuralPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Structural>;
};

export type UpdateByIdSupplierInput = {
  numero_fornecedor_sap?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  regionais_ufs_atendidas?: Maybe<Array<Maybe<Scalars['String']>>>;
  contatos?: Maybe<Array<Maybe<Scalars['String']>>>;
  nome?: Maybe<Scalars['String']>;
  telefone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nivel_aprovacao?: Maybe<Scalars['String']>;
  valor_qualificacao?: Maybe<Scalars['Float']>;
  validade_qualificacao?: Maybe<Scalars['Date']>;
  contratos?: Maybe<Array<Maybe<Scalars['String']>>>;
  produtos_unitarios?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
};

export type UpdateByIdSupplierPayload = {
  __typename?: 'UpdateByIdSupplierPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Supplier>;
};

export type UpdatedAtOperatorsFilterFindManyRecentsActivitiesInput = {
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  ne?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

export type UpdateManyPositivatorInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  isSubcategoryActive?: Maybe<Scalars['Boolean']>;
  isPositivatorActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  /** Enum no formato "PlaceType_X_ModeloDeLoja"' */
  channels?: Maybe<Array<Maybe<EnumPositivatorChannels>>>;
  cnpjs?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateManyPositivatorPayload = {
  __typename?: 'UpdateManyPositivatorPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
};

export type UpdateOneBannerInput = {
  validity?: Maybe<BannerValidityInput>;
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<EnumBannerStatus>;
  textButton?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<BannerImagesInput>>>;
  sort?: Maybe<Scalars['Float']>;
  changedBy?: Maybe<BannerChangedByInput>;
};

export type UpdateOneBannerPayload = {
  __typename?: 'UpdateOneBannerPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Banner>;
};

export type UpdateOneCampaignInput = {
  code?: Maybe<Scalars['String']>;
  productType?: Maybe<EnumCampaignProductType>;
  releaseDate?: Maybe<Scalars['Date']>;
  archivingDone?: Maybe<Scalars['Boolean']>;
  archive?: Maybe<EnumCampaignArchive>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateOneCampaignPayload = {
  __typename?: 'UpdateOneCampaignPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Campaign>;
};

export type UpdateOneCategoryInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  availableAtRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['String']>;
  subCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  routeName?: Maybe<Scalars['String']>;
  /** Icone para ser exibido na categoria e na página */
  typeIcon?: Maybe<Scalars['String']>;
};

export type UpdateOneCategoryPayload = {
  __typename?: 'UpdateOneCategoryPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Category>;
};

export type UpdateOneCommentInput = {
  parentId?: Maybe<Scalars['String']>;
  roomId?: Maybe<Scalars['String']>;
  approved?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  replyRecipientId?: Maybe<Scalars['String']>;
};

export type UpdateOneCommentPayload = {
  __typename?: 'UpdateOneCommentPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Comment>;
};

export type UpdateOneContractInput = {
  nr_clm?: Maybe<Scalars['String']>;
  nr_contrato?: Maybe<Scalars['String']>;
  inicio_vigencia?: Maybe<Scalars['Date']>;
  final_vigencia?: Maybe<Scalars['Date']>;
  valor_total?: Maybe<Scalars['Float']>;
  valor_utilizado?: Maybe<Scalars['Float']>;
  nr_contrato_derivado?: Maybe<Scalars['String']>;
  inicio_vigencia_derivado?: Maybe<Scalars['Date']>;
  final_vigencia_derivado?: Maybe<Scalars['Date']>;
  valor_total_derivado?: Maybe<Scalars['Float']>;
  valor_utilizado_derivado?: Maybe<Scalars['Float']>;
  margem_saldo_derivado?: Maybe<Scalars['Float']>;
  natureza_contrato?: Maybe<Scalars['String']>;
  natureza_verba?: Maybe<Scalars['String']>;
  nrs_cic?: Maybe<Array<Maybe<ContractNrs_CicInput>>>;
  prazo_pagto?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateOneContractPayload = {
  __typename?: 'UpdateOneContractPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Contract>;
};

export type UpdateOneDeviceInput = {
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  inclusion?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  pricingTable?: Maybe<Scalars['String']>;
  featured?: Maybe<Array<Maybe<Scalars['String']>>>;
  comments?: Maybe<Scalars['String']>;
  chip?: Maybe<Scalars['String']>;
  nfc?: Maybe<Scalars['JSON']>;
  offer_date?: Maybe<Scalars['Date']>;
  launch_date?: Maybe<Scalars['Date']>;
  /** Código do aparelho (cliente chama de código do fornecedor). Código retornado pelo device. Ex: "MWC22" */
  codeProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
  codeTim?: Maybe<Array<Maybe<Scalars['String']>>>;
  releaseDateOf?: Maybe<Scalars['Date']>;
  availableColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  eSim?: Maybe<Scalars['Boolean']>;
  dualSim?: Maybe<Scalars['Boolean']>;
  connectionType5G?: Maybe<Scalars['Boolean']>;
  ram?: Maybe<Scalars['JSON']>;
  internalMemory?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  windowSize?: Maybe<Scalars['String']>;
  windowResolution?: Maybe<Scalars['String']>;
  cameraMegaPixels?: Maybe<Scalars['String']>;
  cameraFrontal?: Maybe<Scalars['JSON']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  legalText?: Maybe<Scalars['String']>;
  status?: Maybe<EnumDeviceStatus>;
  type?: Maybe<Scalars['String']>;
  devicePlan?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  flags?: Maybe<Scalars['String']>;
  operationalSystem?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  connectionType?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  expandableMemory?: Maybe<Scalars['String']>;
  pixelDensity?: Maybe<Scalars['JSON']>;
  colors?: Maybe<Scalars['String']>;
  cameraResolution?: Maybe<Scalars['String']>;
  cameraResolutionBy?: Maybe<Scalars['String']>;
  cameraOpeningCapacity?: Maybe<Scalars['JSON']>;
  cameraStabilization?: Maybe<Scalars['Boolean']>;
  cameraAutofocus?: Maybe<Scalars['Boolean']>;
  cameraOpticalZoom?: Maybe<Scalars['Boolean']>;
  cameraDigitalZoom?: Maybe<Scalars['Boolean']>;
  cameraFlash?: Maybe<Scalars['Boolean']>;
  cameraHdr?: Maybe<Scalars['Boolean']>;
  cameraLocation?: Maybe<Scalars['Boolean']>;
  cameraFacialDetection?: Maybe<Scalars['Boolean']>;
  videoResolution?: Maybe<Scalars['String']>;
  videoStabilization?: Maybe<Scalars['Boolean']>;
  videoAutofocus?: Maybe<Scalars['Boolean']>;
  videoSlowMotion?: Maybe<Scalars['Boolean']>;
  videoHdr?: Maybe<Scalars['Boolean']>;
  gps?: Maybe<Scalars['Boolean']>;
  compass?: Maybe<Scalars['Boolean']>;
  biometric?: Maybe<Scalars['Boolean']>;
  radio?: Maybe<Scalars['Boolean']>;
  tv?: Maybe<Scalars['Boolean']>;
  waterproof?: Maybe<Scalars['Boolean']>;
  coolText?: Maybe<Scalars['String']>;
};

export type UpdateOneDevicePayload = {
  __typename?: 'UpdateOneDevicePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Device>;
};

export type UpdateOneDigitalBrochureInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['Date']>;
  link?: Maybe<Scalars['String']>;
  thumbnailQuality?: Maybe<Scalars['Float']>;
  isVisibleToPublic?: Maybe<Scalars['Boolean']>;
};

export type UpdateOneDigitalBrochurePayload = {
  __typename?: 'UpdateOneDigitalBrochurePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<DigitalBrochure>;
};

export type UpdateOneEventLiveInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  closedBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  videoUrl?: Maybe<Scalars['String']>;
  /** Data do evento */
  eventDate?: Maybe<Scalars['Date']>;
  /** Data do fim do evento */
  endTime?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumEventLiveKind>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<EnumEventLiveStatus>;
  /** O status anterior ao atual */
  previousStatus?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  changedBy?: Maybe<EventLiveChangedByInput>;
};

export type UpdateOneEventLivePayload = {
  __typename?: 'UpdateOneEventLivePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<EventLive>;
};

export type UpdateOneFolderInput = {
  kind?: Maybe<EnumFolderKind>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateOneFolderPayload = {
  __typename?: 'UpdateOneFolderPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Folder>;
};

export type UpdateOneImportUsersPlanInput = {
  fileId?: Maybe<Scalars['String']>;
  statusImportUser?: Maybe<EnumImportUsersPlanStatusImportUser>;
  origin?: Maybe<EnumImportUsersPlanOrigin>;
  errorsFile?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateOneImportUsersPlanPayload = {
  __typename?: 'UpdateOneImportUsersPlanPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<ImportUsersPlan>;
};

export type UpdateOneLayoutInput = {
  lpu?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  codeT?: Maybe<Scalars['String']>;
  codeTM?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnailID?: Maybe<Scalars['String']>;
};

export type UpdateOneLayoutPayload = {
  __typename?: 'UpdateOneLayoutPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Layout>;
};

export type UpdateOneMaterialInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  availableAtRoleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Limita pela combinação rolegroup + region de cada usuário */
  availableAtRegionalProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ids dos usuários que terão acesso ao material independente dos outros delimitadores como região, canal, etc */
  availableForUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Utilizado para envio de notificações e informativos */
  destinationEmailsFromMailingFile?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvalFlow?: Maybe<Scalars['Boolean']>;
  mailingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que foram solicitados para aprovação */
  usersToApprove?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que faltam aprovar o material */
  awaitingApprovers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Usuários que aprovaram o material */
  approvedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  detachedVideo?: Maybe<Scalars['Boolean']>;
  latestPublications?: Maybe<Scalars['Boolean']>;
  orderLatestPublications?: Maybe<Scalars['Float']>;
  mainIdCategory?: Maybe<Scalars['String']>;
  kind?: Maybe<EnumMaterialKind>;
  validity?: Maybe<MaterialValidityInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Habilitar comentários e curtidas */
  enableSocialInteraction?: Maybe<Scalars['Boolean']>;
  /** Mensagem para o aprovador. */
  messageToApprover?: Maybe<Scalars['String']>;
  /** Mensagem de reprovação. */
  disapprovalMessage?: Maybe<Scalars['String']>;
  /** Caso o tipo do material seja `notification` um link poderá ser informado neste campo. */
  notificationLink?: Maybe<Scalars['String']>;
  status?: Maybe<EnumMaterialStatus>;
  previousStatus?: Maybe<EnumMaterialPreviousStatus>;
  /** Configurações e status de envio de push notification/emails */
  communicationItemsConfig?: Maybe<
    Array<Maybe<MaterialCommunicationItemsConfigInput>>
  >;
  /** Data em que o material foi enviado pela última vez, usado em casos de frequência para calcular o próximo envio */
  lastSentAt?: Maybe<Scalars['Date']>;
  /** Material em destaque */
  spotlight?: Maybe<Scalars['Boolean']>;
  html?: Maybe<Scalars['String']>;
  /** Gerador de HTML do Corp. */
  editor?: Maybe<Array<Maybe<MaterialEditorInput>>>;
  editorFiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Ex. url externa do video do vimeo. */
  externalSrc?: Maybe<Scalars['String']>;
  changedBy?: Maybe<MaterialChangedByInput>;
  /** True somente quando um material com o kind video já tiver sido uma live */
  isLive?: Maybe<Scalars['Boolean']>;
  associatedEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  frequency?: Maybe<Scalars['String']>;
  descriptionBulletin?: Maybe<Scalars['String']>;
  bulletinMaterialsId?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishNow?: Maybe<Scalars['Boolean']>;
};

export type UpdateOneMaterialPayload = {
  __typename?: 'UpdateOneMaterialPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Material>;
};

export type UpdateOneMetadataInput = {
  title?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  createdById?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['String']>;
};

export type UpdateOneMetadataPayload = {
  __typename?: 'UpdateOneMetadataPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Metadata>;
};

export type UpdateOneNotificationInput = {
  kind?: Maybe<Scalars['String']>;
  /** O _id de um item Material quando for criado a partir de um informativo, por exemplo */
  parentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  fileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  redirectionLink?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['Boolean']>;
  /** Data de envio. Quando o inicio do disparo ocorreu. */
  sentAt?: Maybe<Scalars['Date']>;
  schedule?: Maybe<Scalars['Date']>;
  clientData?: Maybe<Scalars['JSON']>;
};

export type UpdateOneNotificationPayload = {
  __typename?: 'UpdateOneNotificationPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Notification>;
};

export type UpdateOnePlaceInput = {
  type?: Maybe<EnumPlaceType>;
  disabled?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  inscricao_estadual?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  custcode?: Maybe<Scalars['String']>;
  codigo_sap?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<PlaceAddressInput>;
  modelo_de_loja?: Maybe<EnumPlaceModelo_De_Loja>;
  tipo_de_loja?: Maybe<EnumPlaceTipo_De_Loja>;
  tecnologia_de_rede?: Maybe<Scalars['String']>;
  produtos_comercializados?: Maybe<
    Array<Maybe<EnumPlaceProdutos_Comercializados>>
  >;
  foco_de_comunicacao?: Maybe<EnumPlaceFoco_De_Comunicacao>;
  cluster?: Maybe<EnumPlaceCluster>;
  canal_distribuicao?: Maybe<Scalars['String']>;
  organizacao_vendas?: Maybe<Scalars['String']>;
  setor_atividade?: Maybe<Scalars['String']>;
  quantidade_de_funcionarios?: Maybe<Scalars['Float']>;
  localizacao?: Maybe<EnumPlaceLocalizacao>;
  nome_da_galeria?: Maybe<Scalars['String']>;
  nome_do_shopping?: Maybe<Scalars['String']>;
  parcelamento?: Maybe<Scalars['Float']>;
  estrutura_de_loja?: Maybe<Array<Maybe<PlaceEstrutura_De_LojaInput>>>;
  inicio_da_operacao_mes?: Maybe<Scalars['Float']>;
  inicio_da_operacao_ano?: Maybe<Scalars['Float']>;
  ultima_modificacao?: Maybe<EnumPlaceUltima_Modificacao>;
  data_de_modificacao_mes?: Maybe<Scalars['Float']>;
  data_de_modificacao_ano?: Maybe<Scalars['Float']>;
  metragem?: Maybe<PlaceMetragemInput>;
  tipo_de_fachada?: Maybe<EnumPlaceTipo_De_Fachada>;
  revestimento_externo?: Maybe<EnumPlaceRevestimento_Externo>;
  posicoes_de_atendimento?: Maybe<Scalars['Float']>;
  posicoes_de_pos_venda?: Maybe<Scalars['Float']>;
  sala_de_gerente?: Maybe<Scalars['Boolean']>;
  back_office?: Maybe<Scalars['Boolean']>;
  wifi_clientes?: Maybe<Scalars['Boolean']>;
  sinal_live?: Maybe<Scalars['Boolean']>;
  display_de_senha?: Maybe<Scalars['Boolean']>;
  sistema_de_som?: Maybe<Scalars['Boolean']>;
  video_wall?: Maybe<Array<Maybe<PlaceVideo_WallInput>>>;
  adminInput?: Maybe<PlaceAdminInputInput>;
  adminId?: Maybe<Scalars['String']>;
};

export type UpdateOnePlacePayload = {
  __typename?: 'UpdateOnePlacePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Place>;
};

export type UpdateOnePositivatorInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  isSubcategoryActive?: Maybe<Scalars['Boolean']>;
  isPositivatorActive?: Maybe<Scalars['Boolean']>;
  isMandatory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  /** Enum no formato "PlaceType_X_ModeloDeLoja"' */
  channels?: Maybe<Array<Maybe<EnumPositivatorChannels>>>;
  cnpjs?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  initialDate?: Maybe<Scalars['Date']>;
  finalDate?: Maybe<Scalars['Date']>;
  frequency?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateOnePositivatorPayload = {
  __typename?: 'UpdateOnePositivatorPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Positivator>;
};

export type UpdateOneProductInput = {
  nome_produto?: Maybe<Scalars['String']>;
  preco_produto?: Maybe<Scalars['Float']>;
  categoria?: Maybe<EnumProductCategoria>;
  abreviacao_producao?: Maybe<Scalars['String']>;
  especificacao?: Maybe<Scalars['String']>;
  faixa_preco?: Maybe<Scalars['Boolean']>;
  especificacao_peso?: Maybe<Scalars['Float']>;
  especificacao_largura?: Maybe<Scalars['Float']>;
  especificacao_altura?: Maybe<Scalars['Float']>;
  especificacao_profundidade?: Maybe<Scalars['Float']>;
  especificacao_preco?: Maybe<Scalars['Float']>;
  intervalo_quantidade?: Maybe<Array<Maybe<ProductIntervalo_QuantidadeInput>>>;
  code_tm?: Maybe<Scalars['String']>;
  qtd_dias?: Maybe<Scalars['Float']>;
  contagem?: Maybe<Scalars['String']>;
  tipo_multiplicador?: Maybe<EnumProductTipo_Multiplicador>;
  multiplicador_pdv?: Maybe<Scalars['Float']>;
  multiplicador_mobiliario?: Maybe<Scalars['Float']>;
  multiplicador_posicao?: Maybe<Scalars['Float']>;
  ncm?: Maybe<Scalars['String']>;
  relatedTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Codigo SAP do fornecedor. "numero_fornecedor_sap" na entidade Supplier */
  supplierSAPCode?: Maybe<Scalars['String']>;
};

export type UpdateOneProductPayload = {
  __typename?: 'UpdateOneProductPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Product>;
};

export type UpdateOneStructuralInput = {
  cnpj?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  tipo_modificacao?: Maybe<Scalars['String']>;
  tipo_loja?: Maybe<Scalars['String']>;
  financial?: Maybe<Array<Maybe<StructuralFinancialInput>>>;
  canal?: Maybe<Scalars['String']>;
  classificacao_pdv?: Maybe<Scalars['String']>;
  ponto_de_cessao?: Maybe<Scalars['String']>;
  loja_sap?: Maybe<Scalars['String']>;
  patrimonio_tombado?: Maybe<Scalars['String']>;
  reembolso_obra?: Maybe<Scalars['String']>;
  local?: Maybe<Scalars['String']>;
  ddd?: Maybe<Scalars['String']>;
  uf?: Maybe<Scalars['String']>;
  escritorio_vt?: Maybe<Scalars['String']>;
  escritorio_resp?: Maybe<Scalars['String']>;
  status?: Maybe<EnumStructuralStatus>;
  stage?: Maybe<EnumStructuralStage>;
  projectSLA?: Maybe<Scalars['JSON']>;
  stageDATA?: Maybe<Scalars['JSON']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateOneStructuralPayload = {
  __typename?: 'UpdateOneStructuralPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Structural>;
};

export type UpdateOneSupplierInput = {
  numero_fornecedor_sap?: Maybe<Scalars['String']>;
  CNPJ?: Maybe<Scalars['String']>;
  razao_social?: Maybe<Scalars['String']>;
  nome_fantasia?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  regionais_ufs_atendidas?: Maybe<Array<Maybe<Scalars['String']>>>;
  contatos?: Maybe<Array<Maybe<Scalars['String']>>>;
  nome?: Maybe<Scalars['String']>;
  telefone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nivel_aprovacao?: Maybe<Scalars['String']>;
  valor_qualificacao?: Maybe<Scalars['Float']>;
  validade_qualificacao?: Maybe<Scalars['Date']>;
  contratos?: Maybe<Array<Maybe<Scalars['String']>>>;
  produtos_unitarios?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateOneSupplierPayload = {
  __typename?: 'UpdateOneSupplierPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<Supplier>;
};

export type UpdateOneTablePriceInput = {
  availableAtRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  upload?: Maybe<Scalars['String']>;
  sapCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedSapCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  planPre?: Maybe<Scalars['String']>;
  planControl?: Maybe<Scalars['String']>;
  planPos?: Maybe<Scalars['String']>;
  coolText?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['Date']>;
  plansDevice?: Maybe<Array<Maybe<TablePricePlansDeviceInput>>>;
};

export type UpdateOneTablePricePayload = {
  __typename?: 'UpdateOneTablePricePayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<TablePrice>;
};

export type UpdateOneUserInput = {
  email?: Maybe<Scalars['String']>;
  loggedAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledWithPoints?: Maybe<Scalars['Boolean']>;
  disabledAt?: Maybe<Scalars['Date']>;
  acceptedTerms?: Maybe<Array<Maybe<UserAcceptedTermsInput>>>;
  oldPasswords?: Maybe<Array<Maybe<UserOldPasswordsInput>>>;
  approverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  isApprover?: Maybe<Scalars['Boolean']>;
  /** Lista de DDDs aos quais a pessoa tem acesso - proveniente do GU. */
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Permissões do usuário. */
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** É true se o usuário recebeu alguma contentPermission */
  isContentTypeAdmin?: Maybe<Scalars['Boolean']>;
  /** ID do Pefil do usuario */
  roleGroup?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  isTempCPF?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  /** O id da imagem do perfil do usuário. */
  avatarId?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['Date']>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
  blockedByFailedAttempts?: Maybe<Scalars['Boolean']>;
  isBeta?: Maybe<Scalars['Boolean']>;
  failedAtempts?: Maybe<Scalars['Float']>;
};

export type UpdateOneUserPayload = {
  __typename?: 'UpdateOneUserPayload';
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  loggedAt?: Maybe<Scalars['Date']>;
  disabled?: Maybe<Scalars['Boolean']>;
  disabledWithPoints?: Maybe<Scalars['Boolean']>;
  disabledAt?: Maybe<Scalars['Date']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  acceptedTerms?: Maybe<Array<Maybe<UserAcceptedTerms>>>;
  /** Vetor de senhas já utilizadas pelo usuário. */
  oldPasswords?: Maybe<Array<Maybe<OldPassword>>>;
  approverAtChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
  isApprover?: Maybe<Scalars['Boolean']>;
  /** Lista de DDDs aos quais a pessoa tem acesso - proveniente do GU. */
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Permissões do usuário. */
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** É true se o usuário recebeu alguma contentPermission */
  isContentTypeAdmin?: Maybe<Scalars['Boolean']>;
  /** Perfil do usuario. Contem um conjunto de permissoes. */
  roleGroup?: Maybe<RoleGroup>;
  jobTitle?: Maybe<JobTitle>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** O nome completo do usuario. */
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  isTempCPF?: Maybe<Scalars['Boolean']>;
  CNPJ?: Maybe<Scalars['String']>;
  /** O id da imagem do perfil do usuário. */
  avatarId?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  emailSecondary?: Maybe<Scalars['String']>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInDate?: Maybe<Scalars['Date']>;
  viewOtherUsersContent?: Maybe<Scalars['Boolean']>;
  blockedByFailedAttempts?: Maybe<Scalars['Boolean']>;
  isBeta?: Maybe<Scalars['Boolean']>;
  failedAtempts?: Maybe<Scalars['Float']>;
  isTempEmail?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<Notifications>;
  /** Um campo virtual, aponta para as roles do perfil do user. */
  roles: Array<Maybe<Scalars['String']>>;
  token?: Maybe<LoginToken>;
  isTempPassword?: Maybe<Scalars['Boolean']>;
  blockedCommenting?: Maybe<Scalars['Boolean']>;
  failedAttempts?: Maybe<Scalars['Int']>;
  avatar?: Maybe<File>;
  /** Lojas que possuem este usuário como responsável */
  places?: Maybe<Array<Maybe<Place>>>;
};

export type UserAcceptedTerms = {
  __typename?: 'UserAcceptedTerms';
  term?: Maybe<Scalars['String']>;
  acceptedAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UserAcceptedTermsInput = {
  term?: Maybe<Scalars['String']>;
  acceptedAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type UserContentPermission = {
  __typename?: 'UserContentPermission';
  userId?: Maybe<Scalars['String']>;
  roleGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteContentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  DDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserDeleted = {
  __typename?: 'UserDeleted';
  name?: Maybe<Scalars['String']>;
  CPF?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  roleGroup?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  CNPJ?: Maybe<Scalars['String']>;
  UF?: Maybe<Scalars['String']>;
  allowedDDDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Scalars['String']>;
  regionPath?: Maybe<Scalars['String']>;
  optInDate?: Maybe<Scalars['Date']>;
  optIn?: Maybe<Scalars['Boolean']>;
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  notifications?: Maybe<UserDeletedNotifications>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UserDeletedNotifications = {
  __typename?: 'UserDeletedNotifications';
  unreadIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  readIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserDeletedNotificationsInput = {
  unreadIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  readIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserHistoryBlock = {
  __typename?: 'UserHistoryBlock';
  /** Responsável pelo bloqueio */
  owner?: Maybe<Scalars['String']>;
  /** Usuário bloqueado */
  userId?: Maybe<Scalars['String']>;
  /** Conteúdo que originou o bloqueio do usuário */
  parentId?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  Owner?: Maybe<User>;
  User?: Maybe<User>;
  Material?: Maybe<Material>;
};

export type UserHistoryBlockMaterialArgs = {
  filter?: Maybe<Filter>;
  sort?: Maybe<Sort>;
};

/** List of items with pagination. */
export type UserHistoryBlockPagination = {
  __typename?: 'UserHistoryBlockPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<UserHistoryBlock>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type UserIdOperatorsFilterAnalyticsInput = {
  /** Id do usuário que executou a ação */
  gt?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  gte?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  lt?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  lte?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  ne?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Id do usuário que executou a ação */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserIdOperatorsFilterFindManyAnalyticsInput = {
  /** Id do usuário que executou a ação */
  gt?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  gte?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  lt?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  lte?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  ne?: Maybe<Scalars['String']>;
  /** Id do usuário que executou a ação */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Id do usuário que executou a ação */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserIdOperatorsFilterFindManyPlaceAnalyticsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserIdOperatorsFilterFindOnePlaceAnalyticsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserIdOperatorsFilterFindOneUserContentPermissionInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserMerchanPagination = {
  __typename?: 'UserMerchanPagination';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<User>>>;
  pageInfo: PaginationInfo;
};

export enum UserMerchanPermissionsEnum {
  UserMerchanViewHome = 'userMerchan_viewHome',
  UserMerchanViewDash = 'userMerchan_viewDash',
  UserMerchanViewModule = 'userMerchan_viewModule',
  CreateUser = 'create_user',
  ReadUser = 'read_user',
  UpdateUser = 'update_user',
  UpdateStatusUser = 'update_status_user',
  DeleteUser = 'delete_user'
}

export type UserOldPasswordsInput = {
  password?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** List of items with pagination. */
export type UserPagination = {
  __typename?: 'UserPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type UserRegistrationPayload = {
  __typename?: 'UserRegistrationPayload';
  record?: Maybe<User>;
  recordId?: Maybe<Scalars['String']>;
};

export type UserRegistrationTypeInput = {
  /** Nome completo do usuário */
  name: Scalars['String'];
  email: Scalars['String'];
  matricula?: Maybe<Scalars['String']>;
  /** Id do perfil do usuario.
   *  ex de query para obter a lista de opcoes:
   *  `query roles { RoleGroupFindMany {_id name roles}}`.
   */
  roleGroup: Scalars['String'];
  /** Lista de ids dos canais a que o usuario tem acesso. consultar valores: `query canais { ChannelFindMany { _id name } }` */
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Se true o usuario sera salvo como inativo */
  disabled?: Maybe<Scalars['Boolean']>;
  /** Id do usuario na origem. ex: id do usuario no PCS */
  externalId?: Maybe<Scalars['String']>;
  /** CPF do usuário cadastrado. */
  CPF?: Maybe<Scalars['String']>;
  /** CNPJ do usuário cadastrado. */
  CNPJ?: Maybe<Scalars['String']>;
  /** Sigla da unidade de federação. Ex: RJ */
  UF?: Maybe<Scalars['String']>;
  /** Telefone de contato do usuario, incluindo o DDD - apenas numeros. */
  phone?: Maybe<Scalars['String']>;
  /** Id do descritivo do cargo do usuario: ex: `especialista` - consultar opcoes:
   * `query jobTitle { JobTitleFindMany { _id name } }`
   */
  jobTitle?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatarId?: Maybe<Scalars['String']>;
  /** Id da regiao do usuario. Para uma lista de valores aceitos, consultar a query: `query regioes { RegionFindMany { _id } }` */
  region?: Maybe<Scalars['String']>;
};

export type UserSearch = {
  __typename?: 'UserSearch';
  _id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
};

export type UserSearchPaginationFilterInput = {
  channel?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  matricula?: Maybe<Scalars['String']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type UserSimpleResult = {
  __typename?: 'UserSimpleResult';
  _id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
};

export type UserTaNaRede = {
  __typename?: 'UserTaNaRede';
  token?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserWordsBlackList = {
  __typename?: 'UserWordsBlackList';
  owner?: Maybe<Scalars['String']>;
  word?: Maybe<Scalars['String']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  User?: Maybe<User>;
};

export type ValidatedDeliveryRecord = {
  __typename?: 'ValidatedDeliveryRecord';
  groupID?: Maybe<Scalars['String']>;
  depositPath?: Maybe<Scalars['String']>;
  originId?: Maybe<Scalars['String']>;
  originSapCode?: Maybe<Scalars['String']>;
  destinyId?: Maybe<Scalars['String']>;
  destinySapCode?: Maybe<Scalars['String']>;
  destinyName?: Maybe<Scalars['String']>;
  destinyActivitieSector?: Maybe<Scalars['String']>;
  destinyDistributionChannel?: Maybe<Scalars['String']>;
  destinySalesOrganization?: Maybe<Scalars['String']>;
  itemCodeTM?: Maybe<Scalars['String']>;
  itemDescription?: Maybe<Scalars['String']>;
  qnt?: Maybe<Scalars['Float']>;
  executedQnt?: Maybe<Scalars['Float']>;
  withdrawAmount?: Maybe<Scalars['Int']>;
  storageAmount?: Maybe<Scalars['Int']>;
};

export enum ValidityEnum {
  Expired = 'EXPIRED',
  Future = 'FUTURE',
  Current = 'CURRENT',
  Any = 'ANY'
}

export interface Methods extends MethodsDict {
  RegionFindMany: Method<QueryRegionFindManyArgs, Query['RegionFindMany']>;

  RegionFindOne: Method<QueryRegionFindOneArgs, Query['RegionFindOne']>;

  RegionPagination: Method<
    QueryRegionPaginationArgs,
    Query['RegionPagination']
  >;

  RegionItemFindMany: Method<
    QueryRegionItemFindManyArgs,
    Query['RegionItemFindMany']
  >;

  RegionItemFindOne: Method<
    QueryRegionItemFindOneArgs,
    Query['RegionItemFindOne']
  >;

  RegionItemPagination: Method<
    QueryRegionItemPaginationArgs,
    Query['RegionItemPagination']
  >;

  RegionItemDistinctDDD: Method<{}, Query['RegionItemDistinctDDD']>;

  RegionItemDistinctRegionAndUF: Method<
    {},
    Query['RegionItemDistinctRegionAndUF']
  >;

  JobTitleFindOne: Method<QueryJobTitleFindOneArgs, Query['JobTitleFindOne']>;

  JobTitleFindMany: Method<
    QueryJobTitleFindManyArgs,
    Query['JobTitleFindMany']
  >;

  RoleGroupFindOne: Method<
    QueryRoleGroupFindOneArgs,
    Query['RoleGroupFindOne']
  >;

  RoleGroupFindMany: Method<
    QueryRoleGroupFindManyArgs,
    Query['RoleGroupFindMany']
  >;

  RoleGroupPagination: Method<
    QueryRoleGroupPaginationArgs,
    Query['RoleGroupPagination']
  >;

  ChannelFindMany: Method<QueryChannelFindManyArgs, Query['ChannelFindMany']>;

  ChannelFindOne: Method<QueryChannelFindOneArgs, Query['ChannelFindOne']>;

  ChannelPagination: Method<
    QueryChannelPaginationArgs,
    Query['ChannelPagination']
  >;

  UserContentPermissionFindOne: Method<
    QueryUserContentPermissionFindOneArgs,
    Query['UserContentPermissionFindOne']
  >;

  ContentPermissionsHelper: Method<{}, Query['ContentPermissionsHelper']>;

  UserPagination: Method<QueryUserPaginationArgs, Query['UserPagination']>;

  UserFindByIds: Method<QueryUserFindByIdsArgs, Query['UserFindByIds']>;

  UserFindById: Method<QueryUserFindByIdArgs, Query['UserFindById']>;

  UserFindOne: Method<QueryUserFindOneArgs, Query['UserFindOne']>;

  UserFindMany: Method<QueryUserFindManyArgs, Query['UserFindMany']>;

  User: Method<{}, Query['User']>;

  UserSearch: Method<QueryUserSearchArgs, Query['UserSearch']>;

  UserSearchPagination: Method<
    QueryUserSearchPaginationArgs,
    Query['UserSearchPagination']
  >;

  TextFindBlackList: Method<
    QueryTextFindBlackListArgs,
    Query['TextFindBlackList']
  >;

  UserFindByEmails: Method<
    QueryUserFindByEmailsArgs,
    Query['UserFindByEmails']
  >;

  UserFindByMatricula: Method<
    QueryUserFindByMatriculaArgs,
    Query['UserFindByMatricula']
  >;

  FileFindByIds: Method<QueryFileFindByIdsArgs, Query['FileFindByIds']>;

  FileFindById: Method<QueryFileFindByIdArgs, Query['FileFindById']>;

  FilePagination: Method<QueryFilePaginationArgs, Query['FilePagination']>;

  MaterialFindById: Method<
    QueryMaterialFindByIdArgs,
    Query['MaterialFindById']
  >;

  MaterialFindMany: Method<
    QueryMaterialFindManyArgs,
    Query['MaterialFindMany']
  >;

  MaterialFindOne: Method<QueryMaterialFindOneArgs, Query['MaterialFindOne']>;

  MaterialPagination: Method<
    QueryMaterialPaginationArgs,
    Query['MaterialPagination']
  >;

  MaterialCount: Method<QueryMaterialCountArgs, Query['MaterialCount']>;

  MaterialStatusOptions: Method<{}, Query['MaterialStatusOptions']>;

  MaterialSearchOwners: Method<
    QueryMaterialSearchOwnersArgs,
    Query['MaterialSearchOwners']
  >;

  EmailFindMany: Method<QueryEmailFindManyArgs, Query['EmailFindMany']>;

  EmailPagination: Method<QueryEmailPaginationArgs, Query['EmailPagination']>;

  EmailFindOne: Method<QueryEmailFindOneArgs, Query['EmailFindOne']>;

  EmailCheckRead: Method<QueryEmailCheckReadArgs, Query['EmailCheckRead']>;

  MaterialSearch: Method<QueryMaterialSearchArgs, Query['MaterialSearch']>;

  MaterialFindPinned: Method<
    QueryMaterialFindPinnedArgs,
    Query['MaterialFindPinned']
  >;

  NotificationFindMany: Method<
    QueryNotificationFindManyArgs,
    Query['NotificationFindMany']
  >;

  NotificationFindOne: Method<
    QueryNotificationFindOneArgs,
    Query['NotificationFindOne']
  >;

  NotificationPagination: Method<
    QueryNotificationPaginationArgs,
    Query['NotificationPagination']
  >;

  HelperAddress: Method<QueryHelperAddressArgs, Query['HelperAddress']>;

  CategoryFindOne: Method<QueryCategoryFindOneArgs, Query['CategoryFindOne']>;

  CategoryFindById: Method<
    QueryCategoryFindByIdArgs,
    Query['CategoryFindById']
  >;

  CategoryFindMany: Method<
    QueryCategoryFindManyArgs,
    Query['CategoryFindMany']
  >;

  CategoryFindByIds: Method<
    QueryCategoryFindByIdsArgs,
    Query['CategoryFindByIds']
  >;

  WebPushPublicKey: Method<{}, Query['WebPushPublicKey']>;

  PinFindMany: Method<QueryPinFindManyArgs, Query['PinFindMany']>;

  PinPagination: Method<QueryPinPaginationArgs, Query['PinPagination']>;

  ShareFindMany: Method<QueryShareFindManyArgs, Query['ShareFindMany']>;

  SharePagination: Method<QuerySharePaginationArgs, Query['SharePagination']>;

  CommentPagination: Method<
    QueryCommentPaginationArgs,
    Query['CommentPagination']
  >;

  CommentFindOne: Method<QueryCommentFindOneArgs, Query['CommentFindOne']>;

  CommentFindById: Method<QueryCommentFindByIdArgs, Query['CommentFindById']>;

  CommentFindMany: Method<QueryCommentFindManyArgs, Query['CommentFindMany']>;

  CommentSortPagination: Method<
    QueryCommentSortPaginationArgs,
    Query['CommentSortPagination']
  >;

  EventLivePagination: Method<
    QueryEventLivePaginationArgs,
    Query['EventLivePagination']
  >;

  EventLiveFindOne: Method<
    QueryEventLiveFindOneArgs,
    Query['EventLiveFindOne']
  >;

  EventLiveFindById: Method<
    QueryEventLiveFindByIdArgs,
    Query['EventLiveFindById']
  >;

  BannerFindMany: Method<QueryBannerFindManyArgs, Query['BannerFindMany']>;

  BannerFindOne: Method<QueryBannerFindOneArgs, Query['BannerFindOne']>;

  AnalyticsCount: Method<QueryAnalyticsCountArgs, Query['AnalyticsCount']>;

  AnalyticsPagination: Method<
    QueryAnalyticsPaginationArgs,
    Query['AnalyticsPagination']
  >;

  AnalyticsUsersReport: Method<
    QueryAnalyticsUsersReportArgs,
    Query['AnalyticsUsersReport']
  >;

  AnalyticsFindReport: Method<
    QueryAnalyticsFindReportArgs,
    Query['AnalyticsFindReport']
  >;

  AnalyticsUserNotification: Method<
    QueryAnalyticsUserNotificationArgs,
    Query['AnalyticsUserNotification']
  >;

  AnalyticsPin: Method<QueryAnalyticsPinArgs, Query['AnalyticsPin']>;

  AnalyticsComment: Method<
    QueryAnalyticsCommentArgs,
    Query['AnalyticsComment']
  >;

  AnalyticsPoints: Method<QueryAnalyticsPointsArgs, Query['AnalyticsPoints']>;

  AnalyticsDownloads: Method<
    QueryAnalyticsDownloadsArgs,
    Query['AnalyticsDownloads']
  >;

  SearchCountFindMany: Method<
    QuerySearchCountFindManyArgs,
    Query['SearchCountFindMany']
  >;

  SearchCountPagination: Method<
    QuerySearchCountPaginationArgs,
    Query['SearchCountPagination']
  >;

  FolderFindMany: Method<QueryFolderFindManyArgs, Query['FolderFindMany']>;

  FolderFindById: Method<QueryFolderFindByIdArgs, Query['FolderFindById']>;

  FolderPagination: Method<
    QueryFolderPaginationArgs,
    Query['FolderPagination']
  >;

  RecentsActivitiesPagination: Method<
    QueryRecentsActivitiesPaginationArgs,
    Query['RecentsActivitiesPagination']
  >;

  UserWordsBlackListFindMany: Method<
    QueryUserWordsBlackListFindManyArgs,
    Query['UserWordsBlackListFindMany']
  >;

  UserWordsBlackListFindById: Method<
    QueryUserWordsBlackListFindByIdArgs,
    Query['UserWordsBlackListFindById']
  >;

  DownloadSensitiveFilesPagination: Method<
    QueryDownloadSensitiveFilesPaginationArgs,
    Query['DownloadSensitiveFilesPagination']
  >;

  DownloadUsersInfo: Method<{}, Query['DownloadUsersInfo']>;

  DownloadAcceptedTerms: Method<
    QueryDownloadAcceptedTermsArgs,
    Query['DownloadAcceptedTerms']
  >;

  DownloadUsersAcceptedPoints: Method<
    QueryDownloadUsersAcceptedPointsArgs,
    Query['DownloadUsersAcceptedPoints']
  >;

  DownloadUsersWithPoints: Method<{}, Query['DownloadUsersWithPoints']>;

  DownloadUsersBase: Method<{}, Query['DownloadUsersBase']>;

  ImportUsersPlanFindMany: Method<
    QueryImportUsersPlanFindManyArgs,
    Query['ImportUsersPlanFindMany']
  >;

  ImportUsersPlanFindById: Method<
    QueryImportUsersPlanFindByIdArgs,
    Query['ImportUsersPlanFindById']
  >;

  ImportUsersPlanFindByIds: Method<
    QueryImportUsersPlanFindByIdsArgs,
    Query['ImportUsersPlanFindByIds']
  >;

  ImportUsersPlanPagination: Method<
    QueryImportUsersPlanPaginationArgs,
    Query['ImportUsersPlanPagination']
  >;

  UserDeletedFindByIds: Method<
    QueryUserDeletedFindByIdsArgs,
    Query['UserDeletedFindByIds']
  >;

  UserDeletedFindOne: Method<
    QueryUserDeletedFindOneArgs,
    Query['UserDeletedFindOne']
  >;

  UserDeletedFindMany: Method<
    QueryUserDeletedFindManyArgs,
    Query['UserDeletedFindMany']
  >;

  UserHistoryBlockFindMany: Method<
    QueryUserHistoryBlockFindManyArgs,
    Query['UserHistoryBlockFindMany']
  >;

  UserHistoryBlockPagination: Method<
    QueryUserHistoryBlockPaginationArgs,
    Query['UserHistoryBlockPagination']
  >;

  FutureOperationFindById: Method<
    QueryFutureOperationFindByIdArgs,
    Query['FutureOperationFindById']
  >;

  EmailStatsFindOne: Method<
    QueryEmailStatsFindOneArgs,
    Query['EmailStatsFindOne']
  >;

  EmailStatsFindMany: Method<
    QueryEmailStatsFindManyArgs,
    Query['EmailStatsFindMany']
  >;

  EmailUnsubscribeFindOne: Method<
    QueryEmailUnsubscribeFindOneArgs,
    Query['EmailUnsubscribeFindOne']
  >;

  EmailUnsubscribeFindMany: Method<
    QueryEmailUnsubscribeFindManyArgs,
    Query['EmailUnsubscribeFindMany']
  >;

  EmailUnsubscribeAction: Method<
    QueryEmailUnsubscribeActionArgs,
    Query['EmailUnsubscribeAction']
  >;

  HomebannerFindMany: Method<
    QueryHomebannerFindManyArgs,
    Query['HomebannerFindMany']
  >;

  PlaceFindMany: Method<QueryPlaceFindManyArgs, Query['PlaceFindMany']>;

  PlaceFindOne: Method<QueryPlaceFindOneArgs, Query['PlaceFindOne']>;

  PlaceFindById: Method<QueryPlaceFindByIdArgs, Query['PlaceFindById']>;

  PlacePagination: Method<QueryPlacePaginationArgs, Query['PlacePagination']>;

  PlaceSearch: Method<QueryPlaceSearchArgs, Query['PlaceSearch']>;

  PlaceSearchByIdsTypeAndName: Method<
    QueryPlaceSearchByIdsTypeAndNameArgs,
    Query['PlaceSearchByIdsTypeAndName']
  >;

  PlaceSearchByChannelSapCodeOrPlaceAndRegion: Method<
    QueryPlaceSearchByChannelSapCodeOrPlaceAndRegionArgs,
    Query['PlaceSearchByChannelSapCodeOrPlaceAndRegion']
  >;

  PlaceSearchByIdsLpuCategoryAndContainsInventory: Method<
    QueryPlaceSearchByIdsLpuCategoryAndContainsInventoryArgs,
    Query['PlaceSearchByIdsLpuCategoryAndContainsInventory']
  >;

  PlacePaginationCustom: Method<
    QueryPlacePaginationCustomArgs,
    Query['PlacePaginationCustom']
  >;

  LocationOptions: Method<QueryLocationOptionsArgs, Query['LocationOptions']>;

  PlaceSearchByCnpj: Method<
    QueryPlaceSearchByCnpjArgs,
    Query['PlaceSearchByCnpj']
  >;

  PlacesBaseDownload: Method<
    QueryPlacesBaseDownloadArgs,
    Query['PlacesBaseDownload']
  >;

  PlacesBaseValidation: Method<
    QueryPlacesBaseValidationArgs,
    Query['PlacesBaseValidation']
  >;

  PlacesPermissions: Method<{}, Query['PlacesPermissions']>;

  ContractPagination: Method<
    QueryContractPaginationArgs,
    Query['ContractPagination']
  >;

  ContractFindOne: Method<QueryContractFindOneArgs, Query['ContractFindOne']>;

  ContractFindByIds: Method<
    QueryContractFindByIdsArgs,
    Query['ContractFindByIds']
  >;

  ProductPagination: Method<
    QueryProductPaginationArgs,
    Query['ProductPagination']
  >;

  ProductFindById: Method<QueryProductFindByIdArgs, Query['ProductFindById']>;

  ProductFindByIds: Method<
    QueryProductFindByIdsArgs,
    Query['ProductFindByIds']
  >;

  ProductBaseDownload: Method<
    QueryProductBaseDownloadArgs,
    Query['ProductBaseDownload']
  >;

  ProductBaseValidation: Method<
    QueryProductBaseValidationArgs,
    Query['ProductBaseValidation']
  >;

  ProductSearchByNameOrSupplier: Method<
    QueryProductSearchByNameOrSupplierArgs,
    Query['ProductSearchByNameOrSupplier']
  >;

  ProductSearchByNameOrSupplierAndNotContainsSomeIds: Method<
    QueryProductSearchByNameOrSupplierAndNotContainsSomeIdsArgs,
    Query['ProductSearchByNameOrSupplierAndNotContainsSomeIds']
  >;

  ProductSearchBySupplierZipCodeAndContainsInventory: Method<
    QueryProductSearchBySupplierZipCodeAndContainsInventoryArgs,
    Query['ProductSearchBySupplierZipCodeAndContainsInventory']
  >;

  SupplierPagination: Method<
    QuerySupplierPaginationArgs,
    Query['SupplierPagination']
  >;

  SupplierFindOne: Method<QuerySupplierFindOneArgs, Query['SupplierFindOne']>;

  SupplierFindMany: Method<
    QuerySupplierFindManyArgs,
    Query['SupplierFindMany']
  >;

  ResolverSupplierSearchByNameAndContainsLpu: Method<
    QueryResolverSupplierSearchByNameAndContainsLpuArgs,
    Query['ResolverSupplierSearchByNameAndContainsLpu']
  >;

  SupplierPermissions: Method<{}, Query['SupplierPermissions']>;

  TablePriceFindMany: Method<
    QueryTablePriceFindManyArgs,
    Query['TablePriceFindMany']
  >;

  TablePriceFindOne: Method<
    QueryTablePriceFindOneArgs,
    Query['TablePriceFindOne']
  >;

  TablePriceFindById: Method<
    QueryTablePriceFindByIdArgs,
    Query['TablePriceFindById']
  >;

  TablePricePagination: Method<
    QueryTablePricePaginationArgs,
    Query['TablePricePagination']
  >;

  TablePrice: Method<{}, Query['TablePrice']>;

  TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds: Method<
    QueryTablePriceSearchByRegionsLocationTypeAndNotContainsSomeIdsArgs,
    Query['TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds']
  >;

  TablePriceAccessories: Method<
    QueryTablePriceAccessoriesArgs,
    Query['TablePriceAccessories']
  >;

  TablePriceSearchBySapCodeAndDeviceModel: Method<
    QueryTablePriceSearchBySapCodeAndDeviceModelArgs,
    Query['TablePriceSearchBySapCodeAndDeviceModel']
  >;

  TablePriceSearchBySapCode: Method<
    QueryTablePriceSearchBySapCodeArgs,
    Query['TablePriceSearchBySapCode']
  >;

  TablePriceDevices: Method<
    QueryTablePriceDevicesArgs,
    Query['TablePriceDevices']
  >;

  TablePriceDeviceBySapCode: Method<
    QueryTablePriceDeviceBySapCodeArgs,
    Query['TablePriceDeviceBySapCode']
  >;

  TablePricePermissions: Method<{}, Query['TablePricePermissions']>;

  PricingTableFindById: Method<
    QueryPricingTableFindByIdArgs,
    Query['PricingTableFindById']
  >;

  PricingTablePagination: Method<
    QueryPricingTablePaginationArgs,
    Query['PricingTablePagination']
  >;

  PricingTableFindMany: Method<
    QueryPricingTableFindManyArgs,
    Query['PricingTableFindMany']
  >;

  LegalTextFindById: Method<
    QueryLegalTextFindByIdArgs,
    Query['LegalTextFindById']
  >;

  LegalTextPagination: Method<
    QueryLegalTextPaginationArgs,
    Query['LegalTextPagination']
  >;

  DeviceFindMany: Method<QueryDeviceFindManyArgs, Query['DeviceFindMany']>;

  DeviceFindOne: Method<QueryDeviceFindOneArgs, Query['DeviceFindOne']>;

  DeviceFindById: Method<QueryDeviceFindByIdArgs, Query['DeviceFindById']>;

  DevicePagination: Method<
    QueryDevicePaginationArgs,
    Query['DevicePagination']
  >;

  DeviceSearch: Method<QueryDeviceSearchArgs, Query['DeviceSearch']>;

  DeviceFindManufacturers: Method<{}, Query['DeviceFindManufacturers']>;

  DeviceSearchById: Method<
    QueryDeviceSearchByIdArgs,
    Query['DeviceSearchById']
  >;

  DeviceBaseDownload: Method<
    QueryDeviceBaseDownloadArgs,
    Query['DeviceBaseDownload']
  >;

  DeviceBaseValidation: Method<
    QueryDeviceBaseValidationArgs,
    Query['DeviceBaseValidation']
  >;

  AppDevice: Method<QueryAppDeviceArgs, Query['AppDevice']>;

  AppDeviceByManufacturer: Method<
    QueryAppDeviceByManufacturerArgs,
    Query['AppDeviceByManufacturer']
  >;

  DevicePermissions: Method<{}, Query['DevicePermissions']>;

  DigitalBrochureFindMany: Method<
    QueryDigitalBrochureFindManyArgs,
    Query['DigitalBrochureFindMany']
  >;

  DigitalBrochureFindOne: Method<
    QueryDigitalBrochureFindOneArgs,
    Query['DigitalBrochureFindOne']
  >;

  DigitalBrochureFindById: Method<
    QueryDigitalBrochureFindByIdArgs,
    Query['DigitalBrochureFindById']
  >;

  DigitalBrochurePagination: Method<
    QueryDigitalBrochurePaginationArgs,
    Query['DigitalBrochurePagination']
  >;

  DigitalBrochureSearchByDDDAndService: Method<
    QueryDigitalBrochureSearchByDddAndServiceArgs,
    Query['DigitalBrochureSearchByDDDAndService']
  >;

  DigitalBrochurePermissions: Method<{}, Query['DigitalBrochurePermissions']>;

  CampaignPagination: Method<
    QueryCampaignPaginationArgs,
    Query['CampaignPagination']
  >;

  CampaignFindOne: Method<QueryCampaignFindOneArgs, Query['CampaignFindOne']>;

  CampaignFindById: Method<
    QueryCampaignFindByIdArgs,
    Query['CampaignFindById']
  >;

  CampaignSearchByTitleDescriptionOrStatus: Method<
    QueryCampaignSearchByTitleDescriptionOrStatusArgs,
    Query['CampaignSearchByTitleDescriptionOrStatus']
  >;

  CampaignSearchByTitleOrDescription: Method<
    QueryCampaignSearchByTitleOrDescriptionArgs,
    Query['CampaignSearchByTitleOrDescription']
  >;

  CampaignPermissions: Method<{}, Query['CampaignPermissions']>;

  LayoutPagination: Method<
    QueryLayoutPaginationArgs,
    Query['LayoutPagination']
  >;

  LayoutFindOne: Method<QueryLayoutFindOneArgs, Query['LayoutFindOne']>;

  LayoutFindMany: Method<QueryLayoutFindManyArgs, Query['LayoutFindMany']>;

  LayoutFindByIds: Method<QueryLayoutFindByIdsArgs, Query['LayoutFindByIds']>;

  LayoutSearchByName: Method<
    QueryLayoutSearchByNameArgs,
    Query['LayoutSearchByName']
  >;

  LayoutSearchByNameAndContainsLpuIntervalQty: Method<
    QueryLayoutSearchByNameAndContainsLpuIntervalQtyArgs,
    Query['LayoutSearchByNameAndContainsLpuIntervalQty']
  >;

  LayoutSearchContentByIds: Method<
    QueryLayoutSearchContentByIdsArgs,
    Query['LayoutSearchContentByIds']
  >;

  LayoutPermissions: Method<{}, Query['LayoutPermissions']>;

  RegionalBudgetFindOne: Method<
    QueryRegionalBudgetFindOneArgs,
    Query['RegionalBudgetFindOne']
  >;

  RegionalBudgetFindById: Method<
    QueryRegionalBudgetFindByIdArgs,
    Query['RegionalBudgetFindById']
  >;

  RegionalBudgetPagination: Method<
    QueryRegionalBudgetPaginationArgs,
    Query['RegionalBudgetPagination']
  >;

  ProductionFindById: Method<
    QueryProductionFindByIdArgs,
    Query['ProductionFindById']
  >;

  ProductionPagination: Method<
    QueryProductionPaginationArgs,
    Query['ProductionPagination']
  >;

  ProductionReport: Method<
    QueryProductionReportArgs,
    Query['ProductionReport']
  >;

  ProductionDeliveryReport: Method<
    QueryProductionDeliveryReportArgs,
    Query['ProductionDeliveryReport']
  >;

  ProductionPermissions: Method<{}, Query['ProductionPermissions']>;

  BudgetFindOne: Method<QueryBudgetFindOneArgs, Query['BudgetFindOne']>;

  BudgetFindById: Method<QueryBudgetFindByIdArgs, Query['BudgetFindById']>;

  BudgetPagination: Method<
    QueryBudgetPaginationArgs,
    Query['BudgetPagination']
  >;

  BudgetPermissions: Method<{}, Query['BudgetPermissions']>;

  SupplierDeliveryPagination: Method<
    QuerySupplierDeliveryPaginationArgs,
    Query['SupplierDeliveryPagination']
  >;

  SupplierDeliveryFindMany: Method<
    QuerySupplierDeliveryFindManyArgs,
    Query['SupplierDeliveryFindMany']
  >;

  InventoryItemFindById: Method<
    QueryInventoryItemFindByIdArgs,
    Query['InventoryItemFindById']
  >;

  InventoryItemFindOne: Method<
    QueryInventoryItemFindOneArgs,
    Query['InventoryItemFindOne']
  >;

  InventoryItemPagination: Method<
    QueryInventoryItemPaginationArgs,
    Query['InventoryItemPagination']
  >;

  InventoryItemFindMany: Method<
    QueryInventoryItemFindManyArgs,
    Query['InventoryItemFindMany']
  >;

  InventoryWithDrawPagination: Method<
    QueryInventoryWithDrawPaginationArgs,
    Query['InventoryWithDrawPagination']
  >;

  InventoryWithDrawFindById: Method<
    QueryInventoryWithDrawFindByIdArgs,
    Query['InventoryWithDrawFindById']
  >;

  DeliveryOrderFindById: Method<
    QueryDeliveryOrderFindByIdArgs,
    Query['DeliveryOrderFindById']
  >;

  DeliveryOrderPagination: Method<
    QueryDeliveryOrderPaginationArgs,
    Query['DeliveryOrderPagination']
  >;

  DeliveryOrderValidation: Method<
    QueryDeliveryOrderValidationArgs,
    Query['DeliveryOrderValidation']
  >;

  DeliveryOrderExecution: Method<
    QueryDeliveryOrderExecutionArgs,
    Query['DeliveryOrderExecution']
  >;

  UserLogisticsPermissions: Method<{}, Query['UserLogisticsPermissions']>;

  MetadataFindOne: Method<QueryMetadataFindOneArgs, Query['MetadataFindOne']>;

  MetadataFindById: Method<
    QueryMetadataFindByIdArgs,
    Query['MetadataFindById']
  >;

  PositivatorFindById: Method<
    QueryPositivatorFindByIdArgs,
    Query['PositivatorFindById']
  >;

  PositivatorFindMany: Method<
    QueryPositivatorFindManyArgs,
    Query['PositivatorFindMany']
  >;

  PositivatorPagination: Method<
    QueryPositivatorPaginationArgs,
    Query['PositivatorPagination']
  >;

  PlacePositivators: Method<{}, Query['PlacePositivators']>;

  PositivationSumary: Method<
    QueryPositivationSumaryArgs,
    Query['PositivationSumary']
  >;

  PlacePositivationPagination: Method<
    QueryPlacePositivationPaginationArgs,
    Query['PlacePositivationPagination']
  >;

  PositivationReportByGroup: Method<
    QueryPositivationReportByGroupArgs,
    Query['PositivationReportByGroup']
  >;

  PositivationReportByItem: Method<
    QueryPositivationReportByItemArgs,
    Query['PositivationReportByItem']
  >;

  PositivationReportByPlace: Method<
    QueryPositivationReportByPlaceArgs,
    Query['PositivationReportByPlace']
  >;

  PositivatorPermissions: Method<{}, Query['PositivatorPermissions']>;

  PositivatorItemFindById: Method<
    QueryPositivatorItemFindByIdArgs,
    Query['PositivatorItemFindById']
  >;

  PositivatorItemPagination: Method<
    QueryPositivatorItemPaginationArgs,
    Query['PositivatorItemPagination']
  >;

  PositivatorItemFindMany: Method<
    QueryPositivatorItemFindManyArgs,
    Query['PositivatorItemFindMany']
  >;

  DeviceNotificationPagination: Method<
    QueryDeviceNotificationPaginationArgs,
    Query['DeviceNotificationPagination']
  >;

  UserMerchanPagination: Method<
    QueryUserMerchanPaginationArgs,
    Query['UserMerchanPagination']
  >;

  UsersBaseDownload: Method<
    QueryUsersBaseDownloadArgs,
    Query['UsersBaseDownload']
  >;

  MerchanUserCheck: Method<
    QueryMerchanUserCheckArgs,
    Query['MerchanUserCheck']
  >;

  UserMerchanPermissions: Method<{}, Query['UserMerchanPermissions']>;

  PlaceAnalyticsPagination: Method<
    QueryPlaceAnalyticsPaginationArgs,
    Query['PlaceAnalyticsPagination']
  >;

  PlaceAnalyticsFindOne: Method<
    QueryPlaceAnalyticsFindOneArgs,
    Query['PlaceAnalyticsFindOne']
  >;

  PlaceAnalyticsFindByIds: Method<
    QueryPlaceAnalyticsFindByIdsArgs,
    Query['PlaceAnalyticsFindByIds']
  >;

  PlaceAnalyticsFindById: Method<
    QueryPlaceAnalyticsFindByIdArgs,
    Query['PlaceAnalyticsFindById']
  >;

  PlaceAnalyticsFindMany: Method<
    QueryPlaceAnalyticsFindManyArgs,
    Query['PlaceAnalyticsFindMany']
  >;

  StructuralFindMany: Method<
    QueryStructuralFindManyArgs,
    Query['StructuralFindMany']
  >;

  StructuralFindOne: Method<
    QueryStructuralFindOneArgs,
    Query['StructuralFindOne']
  >;

  StructuralFindById: Method<
    QueryStructuralFindByIdArgs,
    Query['StructuralFindById']
  >;

  StructuralPagination: Method<
    QueryStructuralPaginationArgs,
    Query['StructuralPagination']
  >;

  Structural: Method<{}, Query['Structural']>;

  StructuralPermissions: Method<{}, Query['StructuralPermissions']>;

  ProjectSLA: Method<QueryProjectSlaArgs, Query['ProjectSLA']>;

  DigitalBrochureAnalyticsPagination: Method<
    QueryDigitalBrochureAnalyticsPaginationArgs,
    Query['DigitalBrochureAnalyticsPagination']
  >;

  DigitalBrochureAnalyticsFindMany: Method<
    QueryDigitalBrochureAnalyticsFindManyArgs,
    Query['DigitalBrochureAnalyticsFindMany']
  >;

  DigitalBrochureAnalyticsFindById: Method<
    QueryDigitalBrochureAnalyticsFindByIdArgs,
    Query['DigitalBrochureAnalyticsFindById']
  >;

  DigitalBrochureAnalyticsFindByIds: Method<
    QueryDigitalBrochureAnalyticsFindByIdsArgs,
    Query['DigitalBrochureAnalyticsFindByIds']
  >;

  DigitalBrochureAnalyticsFindOne: Method<
    QueryDigitalBrochureAnalyticsFindOneArgs,
    Query['DigitalBrochureAnalyticsFindOne']
  >;

  DigitalBrochureAnalyticsPermissions: Method<
    {},
    Query['DigitalBrochureAnalyticsPermissions']
  >;

  DigitalBrochureAnalyticsCount: Method<
    QueryDigitalBrochureAnalyticsCountArgs,
    Query['DigitalBrochureAnalyticsCount']
  >;

  AreaLojaPagination: Method<
    QueryAreaLojaPaginationArgs,
    Query['AreaLojaPagination']
  >;

  RegraMapaPagination: Method<
    QueryRegraMapaPaginationArgs,
    Query['RegraMapaPagination']
  >;

  FormatoPagination: Method<
    QueryFormatoPaginationArgs,
    Query['FormatoPagination']
  >;

  PecaMapaPagination: Method<
    QueryPecaMapaPaginationArgs,
    Query['PecaMapaPagination']
  >;

  MapaPDVPagination: Method<
    QueryMapaPdvPaginationArgs,
    Query['MapaPDVPagination']
  >;

  MapaPDVFindById: Method<QueryMapaPdvFindByIdArgs, Query['MapaPDVFindById']>;

  MyMapaPDV: Method<{}, Query['MyMapaPDV']>;

  RegionRemoveOne: Method<
    MutationRegionRemoveOneArgs,
    Mutation['RegionRemoveOne']
  >;

  UserContentPermissionToggle: Method<
    MutationUserContentPermissionToggleArgs,
    Mutation['UserContentPermissionToggle']
  >;

  UserUpdateOne: Method<MutationUserUpdateOneArgs, Mutation['UserUpdateOne']>;

  UserRemoveById: Method<
    MutationUserRemoveByIdArgs,
    Mutation['UserRemoveById']
  >;

  UserAcceptTerm: Method<
    MutationUserAcceptTermArgs,
    Mutation['UserAcceptTerm']
  >;

  UserLoginWithPassword: Method<
    MutationUserLoginWithPasswordArgs,
    Mutation['UserLoginWithPassword']
  >;

  UserLoginWithToken: Method<
    MutationUserLoginWithTokenArgs,
    Mutation['UserLoginWithToken']
  >;

  UserLoginRenewToken: Method<
    MutationUserLoginRenewTokenArgs,
    Mutation['UserLoginRenewToken']
  >;

  UserSaveLogoutAnalytics: Method<{}, Mutation['UserSaveLogoutAnalytics']>;

  UserSaveLoginAnalyticsPCS: Method<{}, Mutation['UserSaveLoginAnalyticsPCS']>;

  UserFillEmail: Method<MutationUserFillEmailArgs, Mutation['UserFillEmail']>;

  UserFillAvatarId: Method<
    MutationUserFillAvatarIdArgs,
    Mutation['UserFillAvatarId']
  >;

  UserOptIn: Method<MutationUserOptInArgs, Mutation['UserOptIn']>;

  UserMarkNotificationAsRead: Method<
    MutationUserMarkNotificationAsReadArgs,
    Mutation['UserMarkNotificationAsRead']
  >;

  UserDeleteNotification: Method<
    MutationUserDeleteNotificationArgs,
    Mutation['UserDeleteNotification']
  >;

  UserDeleteManyNotification: Method<
    MutationUserDeleteManyNotificationArgs,
    Mutation['UserDeleteManyNotification']
  >;

  UserRegistration: Method<
    MutationUserRegistrationArgs,
    Mutation['UserRegistration']
  >;

  UserBlockComments: Method<
    MutationUserBlockCommentsArgs,
    Mutation['UserBlockComments']
  >;

  ChangeToUserTaNarede: Method<{}, Mutation['ChangeToUserTaNarede']>;

  UserChangePassword: Method<
    MutationUserChangePasswordArgs,
    Mutation['UserChangePassword']
  >;

  UserResetPassword: Method<
    MutationUserResetPasswordArgs,
    Mutation['UserResetPassword']
  >;

  FileCreateOne: Method<MutationFileCreateOneArgs, Mutation['FileCreateOne']>;

  FileRemoveById: Method<
    MutationFileRemoveByIdArgs,
    Mutation['FileRemoveById']
  >;

  FileUpdateById: Method<
    MutationFileUpdateByIdArgs,
    Mutation['FileUpdateById']
  >;

  MaterialCreateOne: Method<
    MutationMaterialCreateOneArgs,
    Mutation['MaterialCreateOne']
  >;

  MaterialUpdateOne: Method<
    MutationMaterialUpdateOneArgs,
    Mutation['MaterialUpdateOne']
  >;

  MaterialUpdateById: Method<
    MutationMaterialUpdateByIdArgs,
    Mutation['MaterialUpdateById']
  >;

  MaterialRemoveOne: Method<
    MutationMaterialRemoveOneArgs,
    Mutation['MaterialRemoveOne']
  >;

  CommunicationCreateOne: Method<
    MutationCommunicationCreateOneArgs,
    Mutation['CommunicationCreateOne']
  >;

  CommunicationUpdateOne: Method<
    MutationCommunicationUpdateOneArgs,
    Mutation['CommunicationUpdateOne']
  >;

  CommunicationApproveChannel: Method<
    MutationCommunicationApproveChannelArgs,
    Mutation['CommunicationApproveChannel']
  >;

  CommunicationDisapproveChannel: Method<
    MutationCommunicationDisapproveChannelArgs,
    Mutation['CommunicationDisapproveChannel']
  >;

  CommunicationRemoveApprover: Method<
    MutationCommunicationRemoveApproverArgs,
    Mutation['CommunicationRemoveApprover']
  >;

  EmailFillTemplate: Method<
    MutationEmailFillTemplateArgs,
    Mutation['EmailFillTemplate']
  >;

  EmailMarkAsRead: Method<
    MutationEmailMarkAsReadArgs,
    Mutation['EmailMarkAsRead']
  >;

  SendEmailFront: Method<
    MutationSendEmailFrontArgs,
    Mutation['SendEmailFront']
  >;

  MaterialSendTestEmail: Method<
    MutationMaterialSendTestEmailArgs,
    Mutation['MaterialSendTestEmail']
  >;

  MaterialLikeToggle: Method<
    MutationMaterialLikeToggleArgs,
    Mutation['MaterialLikeToggle']
  >;

  UpdateDeteachedAndLatestVideo: Method<
    MutationUpdateDeteachedAndLatestVideoArgs,
    Mutation['UpdateDeteachedAndLatestVideo']
  >;

  NotificationCreateOne: Method<
    MutationNotificationCreateOneArgs,
    Mutation['NotificationCreateOne']
  >;

  NotificationCreateMany: Method<
    MutationNotificationCreateManyArgs,
    Mutation['NotificationCreateMany']
  >;

  NotificationUpdateOne: Method<
    MutationNotificationUpdateOneArgs,
    Mutation['NotificationUpdateOne']
  >;

  NotificationRemoveById: Method<
    MutationNotificationRemoveByIdArgs,
    Mutation['NotificationRemoveById']
  >;

  CategoryCreateOne: Method<
    MutationCategoryCreateOneArgs,
    Mutation['CategoryCreateOne']
  >;

  CategoryUpdateOne: Method<
    MutationCategoryUpdateOneArgs,
    Mutation['CategoryUpdateOne']
  >;

  CategoryRemoveById: Method<
    MutationCategoryRemoveByIdArgs,
    Mutation['CategoryRemoveById']
  >;

  CategoryCreateMany: Method<
    MutationCategoryCreateManyArgs,
    Mutation['CategoryCreateMany']
  >;

  WebPushSubscribe: Method<
    MutationWebPushSubscribeArgs,
    Mutation['WebPushSubscribe']
  >;

  WebPushSendNotification: Method<
    MutationWebPushSendNotificationArgs,
    Mutation['WebPushSendNotification']
  >;

  PinCreateOne: Method<MutationPinCreateOneArgs, Mutation['PinCreateOne']>;

  PinRemoveOne: Method<MutationPinRemoveOneArgs, Mutation['PinRemoveOne']>;

  PinRemoveMany: Method<MutationPinRemoveManyArgs, Mutation['PinRemoveMany']>;

  ShareCreateOne: Method<
    MutationShareCreateOneArgs,
    Mutation['ShareCreateOne']
  >;

  ShareRemoveOne: Method<
    MutationShareRemoveOneArgs,
    Mutation['ShareRemoveOne']
  >;

  ShareRemoveMe: Method<MutationShareRemoveMeArgs, Mutation['ShareRemoveMe']>;

  CommentCreateOne: Method<
    MutationCommentCreateOneArgs,
    Mutation['CommentCreateOne']
  >;

  CommentUpdateOne: Method<
    MutationCommentUpdateOneArgs,
    Mutation['CommentUpdateOne']
  >;

  CommentRemoveById: Method<
    MutationCommentRemoveByIdArgs,
    Mutation['CommentRemoveById']
  >;

  CommentLikeToggle: Method<
    MutationCommentLikeToggleArgs,
    Mutation['CommentLikeToggle']
  >;

  EventLiveCreateOne: Method<
    MutationEventLiveCreateOneArgs,
    Mutation['EventLiveCreateOne']
  >;

  EventLiveRemoveById: Method<
    MutationEventLiveRemoveByIdArgs,
    Mutation['EventLiveRemoveById']
  >;

  EventLiveUpdateOne: Method<
    MutationEventLiveUpdateOneArgs,
    Mutation['EventLiveUpdateOne']
  >;

  EventLiveCloseRoom: Method<
    MutationEventLiveCloseRoomArgs,
    Mutation['EventLiveCloseRoom']
  >;

  EventLiveLikeToggle: Method<
    MutationEventLiveLikeToggleArgs,
    Mutation['EventLiveLikeToggle']
  >;

  BannerRemoveById: Method<
    MutationBannerRemoveByIdArgs,
    Mutation['BannerRemoveById']
  >;

  BannerCreateOne: Method<
    MutationBannerCreateOneArgs,
    Mutation['BannerCreateOne']
  >;

  BannerUpdateOne: Method<
    MutationBannerUpdateOneArgs,
    Mutation['BannerUpdateOne']
  >;

  AnalyticsPageView: Method<
    MutationAnalyticsPageViewArgs,
    Mutation['AnalyticsPageView']
  >;

  AnalyticsTrackEvent: Method<
    MutationAnalyticsTrackEventArgs,
    Mutation['AnalyticsTrackEvent']
  >;

  FolderCreateOne: Method<
    MutationFolderCreateOneArgs,
    Mutation['FolderCreateOne']
  >;

  FolderRemoveOne: Method<
    MutationFolderRemoveOneArgs,
    Mutation['FolderRemoveOne']
  >;

  FolderRemoveMany: Method<
    MutationFolderRemoveManyArgs,
    Mutation['FolderRemoveMany']
  >;

  FolderUpdateOne: Method<
    MutationFolderUpdateOneArgs,
    Mutation['FolderUpdateOne']
  >;

  RecentsActivitiesCreateOne: Method<
    MutationRecentsActivitiesCreateOneArgs,
    Mutation['RecentsActivitiesCreateOne']
  >;

  UserWordsBlackListCreateOne: Method<
    MutationUserWordsBlackListCreateOneArgs,
    Mutation['UserWordsBlackListCreateOne']
  >;

  ImportUsersPlanCreateOne: Method<
    MutationImportUsersPlanCreateOneArgs,
    Mutation['ImportUsersPlanCreateOne']
  >;

  ImportUsersPlanUpdateOne: Method<
    MutationImportUsersPlanUpdateOneArgs,
    Mutation['ImportUsersPlanUpdateOne']
  >;

  UserHistoryBlockCreateOne: Method<
    MutationUserHistoryBlockCreateOneArgs,
    Mutation['UserHistoryBlockCreateOne']
  >;

  EmailStatsCreateMany: Method<
    MutationEmailStatsCreateManyArgs,
    Mutation['EmailStatsCreateMany']
  >;

  EmailStatsCreateOne: Method<
    MutationEmailStatsCreateOneArgs,
    Mutation['EmailStatsCreateOne']
  >;

  EmailUnsubscribeCreateMany: Method<
    MutationEmailUnsubscribeCreateManyArgs,
    Mutation['EmailUnsubscribeCreateMany']
  >;

  EmailUnsubscribeCreateOne: Method<
    MutationEmailUnsubscribeCreateOneArgs,
    Mutation['EmailUnsubscribeCreateOne']
  >;

  HomebannerCreateOne: Method<
    MutationHomebannerCreateOneArgs,
    Mutation['HomebannerCreateOne']
  >;

  HomebannerUpdateById: Method<
    MutationHomebannerUpdateByIdArgs,
    Mutation['HomebannerUpdateById']
  >;

  PlaceRemoveById: Method<
    MutationPlaceRemoveByIdArgs,
    Mutation['PlaceRemoveById']
  >;

  PlaceCreateOne: Method<
    MutationPlaceCreateOneArgs,
    Mutation['PlaceCreateOne']
  >;

  PlaceUpdateById: Method<
    MutationPlaceUpdateByIdArgs,
    Mutation['PlaceUpdateById']
  >;

  PlaceUpdateOne: Method<
    MutationPlaceUpdateOneArgs,
    Mutation['PlaceUpdateOne']
  >;

  PlacesBaseUpload: Method<
    MutationPlacesBaseUploadArgs,
    Mutation['PlacesBaseUpload']
  >;

  ContractCreateOne: Method<
    MutationContractCreateOneArgs,
    Mutation['ContractCreateOne']
  >;

  ContractCreateMany: Method<
    MutationContractCreateManyArgs,
    Mutation['ContractCreateMany']
  >;

  ContractUpdateOne: Method<
    MutationContractUpdateOneArgs,
    Mutation['ContractUpdateOne']
  >;

  ContractUpdateById: Method<
    MutationContractUpdateByIdArgs,
    Mutation['ContractUpdateById']
  >;

  ContractRemoveById: Method<
    MutationContractRemoveByIdArgs,
    Mutation['ContractRemoveById']
  >;

  ProductCreateOne: Method<
    MutationProductCreateOneArgs,
    Mutation['ProductCreateOne']
  >;

  ProductUpdateOne: Method<
    MutationProductUpdateOneArgs,
    Mutation['ProductUpdateOne']
  >;

  ProductCreateMany: Method<
    MutationProductCreateManyArgs,
    Mutation['ProductCreateMany']
  >;

  ProductUpdateById: Method<
    MutationProductUpdateByIdArgs,
    Mutation['ProductUpdateById']
  >;

  ProductRemoveById: Method<
    MutationProductRemoveByIdArgs,
    Mutation['ProductRemoveById']
  >;

  ProductBaseUpload: Method<
    MutationProductBaseUploadArgs,
    Mutation['ProductBaseUpload']
  >;

  SupplierCreateOne: Method<
    MutationSupplierCreateOneArgs,
    Mutation['SupplierCreateOne']
  >;

  SupplierUpdateOne: Method<
    MutationSupplierUpdateOneArgs,
    Mutation['SupplierUpdateOne']
  >;

  SupplierUpdateById: Method<
    MutationSupplierUpdateByIdArgs,
    Mutation['SupplierUpdateById']
  >;

  SupplierRemoveById: Method<
    MutationSupplierRemoveByIdArgs,
    Mutation['SupplierRemoveById']
  >;

  TablePriceCreateOne: Method<
    MutationTablePriceCreateOneArgs,
    Mutation['TablePriceCreateOne']
  >;

  TablePriceUpdateOne: Method<
    MutationTablePriceUpdateOneArgs,
    Mutation['TablePriceUpdateOne']
  >;

  TablePriceRemoveById: Method<
    MutationTablePriceRemoveByIdArgs,
    Mutation['TablePriceRemoveById']
  >;

  PricingTableCreateOne: Method<
    MutationPricingTableCreateOneArgs,
    Mutation['PricingTableCreateOne']
  >;

  PricingTableRemoveById: Method<
    MutationPricingTableRemoveByIdArgs,
    Mutation['PricingTableRemoveById']
  >;

  PricingTableUpdateById: Method<
    MutationPricingTableUpdateByIdArgs,
    Mutation['PricingTableUpdateById']
  >;

  LegalTextCreateOne: Method<
    MutationLegalTextCreateOneArgs,
    Mutation['LegalTextCreateOne']
  >;

  LegalTextUpdateById: Method<
    MutationLegalTextUpdateByIdArgs,
    Mutation['LegalTextUpdateById']
  >;

  LegalTextRemoveById: Method<
    MutationLegalTextRemoveByIdArgs,
    Mutation['LegalTextRemoveById']
  >;

  DeviceCreateOne: Method<
    MutationDeviceCreateOneArgs,
    Mutation['DeviceCreateOne']
  >;

  DeviceUpdateOne: Method<
    MutationDeviceUpdateOneArgs,
    Mutation['DeviceUpdateOne']
  >;

  DeviceRemoveById: Method<
    MutationDeviceRemoveByIdArgs,
    Mutation['DeviceRemoveById']
  >;

  DeleteDevice: Method<MutationDeleteDeviceArgs, Mutation['DeleteDevice']>;

  DeviceBaseUpload: Method<
    MutationDeviceBaseUploadArgs,
    Mutation['DeviceBaseUpload']
  >;

  DigitalBrochureCreateOne: Method<
    MutationDigitalBrochureCreateOneArgs,
    Mutation['DigitalBrochureCreateOne']
  >;

  DigitalBrochureUpdateOne: Method<
    MutationDigitalBrochureUpdateOneArgs,
    Mutation['DigitalBrochureUpdateOne']
  >;

  DigitalBrochureRemoveById: Method<
    MutationDigitalBrochureRemoveByIdArgs,
    Mutation['DigitalBrochureRemoveById']
  >;

  BrochureUpdateThumbnail: Method<
    MutationBrochureUpdateThumbnailArgs,
    Mutation['BrochureUpdateThumbnail']
  >;

  CampaignCreateOne: Method<
    MutationCampaignCreateOneArgs,
    Mutation['CampaignCreateOne']
  >;

  CampaignUpdateOne: Method<
    MutationCampaignUpdateOneArgs,
    Mutation['CampaignUpdateOne']
  >;

  CampaignRemoveById: Method<
    MutationCampaignRemoveByIdArgs,
    Mutation['CampaignRemoveById']
  >;

  LayoutCreateOne: Method<
    MutationLayoutCreateOneArgs,
    Mutation['LayoutCreateOne']
  >;

  LayoutCreateMany: Method<
    MutationLayoutCreateManyArgs,
    Mutation['LayoutCreateMany']
  >;

  LayoutUpdateOne: Method<
    MutationLayoutUpdateOneArgs,
    Mutation['LayoutUpdateOne']
  >;

  LayoutUpdateById: Method<
    MutationLayoutUpdateByIdArgs,
    Mutation['LayoutUpdateById']
  >;

  LayoutRemoveById: Method<
    MutationLayoutRemoveByIdArgs,
    Mutation['LayoutRemoveById']
  >;

  LayoutAssociateCodeTM: Method<
    MutationLayoutAssociateCodeTmArgs,
    Mutation['LayoutAssociateCodeTM']
  >;

  RegionalBudgetMarkAsViewed: Method<
    MutationRegionalBudgetMarkAsViewedArgs,
    Mutation['RegionalBudgetMarkAsViewed']
  >;

  BudgetMarkAsFinished: Method<
    MutationBudgetMarkAsFinishedArgs,
    Mutation['BudgetMarkAsFinished']
  >;

  RegionalBudgetParseItems: Method<
    MutationRegionalBudgetParseItemsArgs,
    Mutation['RegionalBudgetParseItems']
  >;

  RegionalBudgetUpdateProductionItems: Method<
    MutationRegionalBudgetUpdateProductionItemsArgs,
    Mutation['RegionalBudgetUpdateProductionItems']
  >;

  ProductionCreateOne: Method<
    MutationProductionCreateOneArgs,
    Mutation['ProductionCreateOne']
  >;

  ProductionUpdateById: Method<
    MutationProductionUpdateByIdArgs,
    Mutation['ProductionUpdateById']
  >;

  ProductionRemoveById: Method<
    MutationProductionRemoveByIdArgs,
    Mutation['ProductionRemoveById']
  >;

  ProductionArchiveItem: Method<
    MutationProductionArchiveItemArgs,
    Mutation['ProductionArchiveItem']
  >;

  ProductionParseItems: Method<
    MutationProductionParseItemsArgs,
    Mutation['ProductionParseItems']
  >;

  BudgetCreateOne: Method<
    MutationBudgetCreateOneArgs,
    Mutation['BudgetCreateOne']
  >;

  BudgetUpdateById: Method<
    MutationBudgetUpdateByIdArgs,
    Mutation['BudgetUpdateById']
  >;

  BudgetRemoveById: Method<
    MutationBudgetRemoveByIdArgs,
    Mutation['BudgetRemoveById']
  >;

  SupplierDeliveryUpdateStatus: Method<
    MutationSupplierDeliveryUpdateStatusArgs,
    Mutation['SupplierDeliveryUpdateStatus']
  >;

  InventorySync: Method<MutationInventorySyncArgs, Mutation['InventorySync']>;

  InventoryWithDrawCreateOne: Method<
    MutationInventoryWithDrawCreateOneArgs,
    Mutation['InventoryWithDrawCreateOne']
  >;

  InventoryCreateWithDraw: Method<
    MutationInventoryCreateWithDrawArgs,
    Mutation['InventoryCreateWithDraw']
  >;

  DeliveryOrderCreateOne: Method<
    MutationDeliveryOrderCreateOneArgs,
    Mutation['DeliveryOrderCreateOne']
  >;

  DeliveryOrderUpdateById: Method<
    MutationDeliveryOrderUpdateByIdArgs,
    Mutation['DeliveryOrderUpdateById']
  >;

  DeliveryOrderRemoveById: Method<
    MutationDeliveryOrderRemoveByIdArgs,
    Mutation['DeliveryOrderRemoveById']
  >;

  MetadataCreateOne: Method<
    MutationMetadataCreateOneArgs,
    Mutation['MetadataCreateOne']
  >;

  MetadataUpdateOne: Method<
    MutationMetadataUpdateOneArgs,
    Mutation['MetadataUpdateOne']
  >;

  MetadataRemoveById: Method<
    MutationMetadataRemoveByIdArgs,
    Mutation['MetadataRemoveById']
  >;

  MetadataUpdateById: Method<
    MutationMetadataUpdateByIdArgs,
    Mutation['MetadataUpdateById']
  >;

  PositivatorCreateOne: Method<
    MutationPositivatorCreateOneArgs,
    Mutation['PositivatorCreateOne']
  >;

  PositivatorUpdateOne: Method<
    MutationPositivatorUpdateOneArgs,
    Mutation['PositivatorUpdateOne']
  >;

  PositivatorUpdateMany: Method<
    MutationPositivatorUpdateManyArgs,
    Mutation['PositivatorUpdateMany']
  >;

  PositivatorRemoveById: Method<
    MutationPositivatorRemoveByIdArgs,
    Mutation['PositivatorRemoveById']
  >;

  PositivatorRemoveMany: Method<
    MutationPositivatorRemoveManyArgs,
    Mutation['PositivatorRemoveMany']
  >;

  PositivatorNotificationCreateOne: Method<
    MutationPositivatorNotificationCreateOneArgs,
    Mutation['PositivatorNotificationCreateOne']
  >;

  PositivatorItemCreateOne: Method<
    MutationPositivatorItemCreateOneArgs,
    Mutation['PositivatorItemCreateOne']
  >;

  PositivatorItemUpdateById: Method<
    MutationPositivatorItemUpdateByIdArgs,
    Mutation['PositivatorItemUpdateById']
  >;

  PositivatorItemRemoveById: Method<
    MutationPositivatorItemRemoveByIdArgs,
    Mutation['PositivatorItemRemoveById']
  >;

  DeviceNotificationMarkAsRead: Method<
    MutationDeviceNotificationMarkAsReadArgs,
    Mutation['DeviceNotificationMarkAsRead']
  >;

  DeviceNotificationMarkAsSolved: Method<
    MutationDeviceNotificationMarkAsSolvedArgs,
    Mutation['DeviceNotificationMarkAsSolved']
  >;

  MerchanRequestTempPassword: Method<
    MutationMerchanRequestTempPasswordArgs,
    Mutation['MerchanRequestTempPassword']
  >;

  MerchanVerifyTempCode: Method<
    MutationMerchanVerifyTempCodeArgs,
    Mutation['MerchanVerifyTempCode']
  >;

  MerchanChangePassword: Method<
    MutationMerchanChangePasswordArgs,
    Mutation['MerchanChangePassword']
  >;

  StructuralCreateOne: Method<
    MutationStructuralCreateOneArgs,
    Mutation['StructuralCreateOne']
  >;

  StructuralUpdateOne: Method<
    MutationStructuralUpdateOneArgs,
    Mutation['StructuralUpdateOne']
  >;

  StructuralRemoveById: Method<
    MutationStructuralRemoveByIdArgs,
    Mutation['StructuralRemoveById']
  >;

  StructuralUpdateById: Method<
    MutationStructuralUpdateByIdArgs,
    Mutation['StructuralUpdateById']
  >;

  StructuralEditStatus: Method<
    MutationStructuralEditStatusArgs,
    Mutation['StructuralEditStatus']
  >;

  DigitalBrochureAnalyticsCreateOne: Method<
    MutationDigitalBrochureAnalyticsCreateOneArgs,
    Mutation['DigitalBrochureAnalyticsCreateOne']
  >;

  AreaLojaCreateOne: Method<
    MutationAreaLojaCreateOneArgs,
    Mutation['AreaLojaCreateOne']
  >;

  AreaLojaUpdateById: Method<
    MutationAreaLojaUpdateByIdArgs,
    Mutation['AreaLojaUpdateById']
  >;

  AreaLojaRemoveById: Method<
    MutationAreaLojaRemoveByIdArgs,
    Mutation['AreaLojaRemoveById']
  >;

  RegraMapaCreateOne: Method<
    MutationRegraMapaCreateOneArgs,
    Mutation['RegraMapaCreateOne']
  >;

  RegraMapaUpdateById: Method<
    MutationRegraMapaUpdateByIdArgs,
    Mutation['RegraMapaUpdateById']
  >;

  RegraMapaRemoveById: Method<
    MutationRegraMapaRemoveByIdArgs,
    Mutation['RegraMapaRemoveById']
  >;

  FormatoCreateOne: Method<
    MutationFormatoCreateOneArgs,
    Mutation['FormatoCreateOne']
  >;

  FormatoUpdateById: Method<
    MutationFormatoUpdateByIdArgs,
    Mutation['FormatoUpdateById']
  >;

  FormatoRemoveById: Method<
    MutationFormatoRemoveByIdArgs,
    Mutation['FormatoRemoveById']
  >;

  PecaMapaCreateOne: Method<
    MutationPecaMapaCreateOneArgs,
    Mutation['PecaMapaCreateOne']
  >;

  PecaMapaUpdateById: Method<
    MutationPecaMapaUpdateByIdArgs,
    Mutation['PecaMapaUpdateById']
  >;

  PecaMapaRemoveById: Method<
    MutationPecaMapaRemoveByIdArgs,
    Mutation['PecaMapaRemoveById']
  >;

  MapaPDVCreateOne: Method<
    MutationMapaPdvCreateOneArgs,
    Mutation['MapaPDVCreateOne']
  >;

  MapaPDVUpdateById: Method<
    MutationMapaPdvUpdateByIdArgs,
    Mutation['MapaPDVUpdateById']
  >;

  MapaPDVRemoveById: Method<
    MutationMapaPdvRemoveByIdArgs,
    Mutation['MapaPDVRemoveById']
  >;
}

export interface MethodsInfo extends MethodsInfoDict {
  RegionFindMany: {
    type: '[Region]';
    schemaKey: 'RegionFindMany';
    entityName: 'Region';
    isList: true;
    argsTSName: 'QueryRegionFindManyArgs';
    returnTSName: "Query['RegionFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Region]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRegionInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyRegionInput' }
      ];
      name: 'RegionFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionFindOne: {
    type: 'Region';
    schemaKey: 'RegionFindOne';
    entityName: 'Region';
    isList: false;
    argsTSName: 'QueryRegionFindOneArgs';
    returnTSName: "Query['RegionFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Region';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneRegionInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneRegionInput' }
      ];
      name: 'RegionFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionPagination: {
    type: 'RegionPagination';
    schemaKey: 'RegionPagination';
    entityName: 'RegionPagination';
    isList: false;
    argsTSName: 'QueryRegionPaginationArgs';
    returnTSName: "Query['RegionPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRegionInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyRegionInput' }
      ];
      name: 'RegionPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionItemFindMany: {
    type: '[RegionItem]';
    schemaKey: 'RegionItemFindMany';
    entityName: 'RegionItem';
    isList: true;
    argsTSName: 'QueryRegionItemFindManyArgs';
    returnTSName: "Query['RegionItemFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[RegionItem]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRegionItemInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyRegionItemInput' }
      ];
      name: 'RegionItemFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionItemFindOne: {
    type: 'RegionItem';
    schemaKey: 'RegionItemFindOne';
    entityName: 'RegionItem';
    isList: false;
    argsTSName: 'QueryRegionItemFindOneArgs';
    returnTSName: "Query['RegionItemFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionItem';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneRegionItemInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneRegionItemInput' }
      ];
      name: 'RegionItemFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionItemPagination: {
    type: 'RegionItemPagination';
    schemaKey: 'RegionItemPagination';
    entityName: 'RegionItemPagination';
    isList: false;
    argsTSName: 'QueryRegionItemPaginationArgs';
    returnTSName: "Query['RegionItemPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionItemPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRegionItemInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyRegionItemInput' }
      ];
      name: 'RegionItemPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionItemDistinctDDD: {
    type: 'RegionItemDistinctDDD';
    schemaKey: 'RegionItemDistinctDDD';
    entityName: 'RegionItemDistinctDDD';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['RegionItemDistinctDDD']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos itens de região';
      deprecationReason: null;
      type: 'RegionItemDistinctDDD';
      args: [];
      name: 'RegionItemDistinctDDD';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionItemDistinctRegionAndUF: {
    type: 'RegionItemDistinctRegionAndUF';
    schemaKey: 'RegionItemDistinctRegionAndUF';
    entityName: 'RegionItemDistinctRegionAndUF';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['RegionItemDistinctRegionAndUF']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos itens distintos por regional e UF (não inclui THQ para isso será necessário adicionar um filtro)';
      deprecationReason: null;
      type: 'RegionItemDistinctRegionAndUF';
      args: [];
      name: 'RegionItemDistinctRegionAndUF';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  JobTitleFindOne: {
    type: 'JobTitle';
    schemaKey: 'JobTitleFindOne';
    entityName: 'JobTitle';
    isList: false;
    argsTSName: 'QueryJobTitleFindOneArgs';
    returnTSName: "Query['JobTitleFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'JobTitle';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneJobTitleInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneJobTitleInput' }
      ];
      name: 'JobTitleFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  JobTitleFindMany: {
    type: '[JobTitle]';
    schemaKey: 'JobTitleFindMany';
    entityName: 'JobTitle';
    isList: true;
    argsTSName: 'QueryJobTitleFindManyArgs';
    returnTSName: "Query['JobTitleFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[JobTitle]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyJobTitleInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyJobTitleInput' }
      ];
      name: 'JobTitleFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RoleGroupFindOne: {
    type: 'RoleGroup';
    schemaKey: 'RoleGroupFindOne';
    entityName: 'RoleGroup';
    isList: false;
    argsTSName: 'QueryRoleGroupFindOneArgs';
    returnTSName: "Query['RoleGroupFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RoleGroup';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneRoleGroupInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneRoleGroupInput' }
      ];
      name: 'RoleGroupFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RoleGroupFindMany: {
    type: '[RoleGroup]';
    schemaKey: 'RoleGroupFindMany';
    entityName: 'RoleGroup';
    isList: true;
    argsTSName: 'QueryRoleGroupFindManyArgs';
    returnTSName: "Query['RoleGroupFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[RoleGroup]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRoleGroupInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyRoleGroupInput' }
      ];
      name: 'RoleGroupFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RoleGroupPagination: {
    type: 'RoleGroupPagination';
    schemaKey: 'RoleGroupPagination';
    entityName: 'RoleGroupPagination';
    isList: false;
    argsTSName: 'QueryRoleGroupPaginationArgs';
    returnTSName: "Query['RoleGroupPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RoleGroupPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRoleGroupInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyRoleGroupInput' }
      ];
      name: 'RoleGroupPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ChannelFindMany: {
    type: '[Channel]';
    schemaKey: 'ChannelFindMany';
    entityName: 'Channel';
    isList: true;
    argsTSName: 'QueryChannelFindManyArgs';
    returnTSName: "Query['ChannelFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Channel]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyChannelInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyChannelInput' }
      ];
      name: 'ChannelFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ChannelFindOne: {
    type: 'Channel';
    schemaKey: 'ChannelFindOne';
    entityName: 'Channel';
    isList: false;
    argsTSName: 'QueryChannelFindOneArgs';
    returnTSName: "Query['ChannelFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Channel';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneChannelInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneChannelInput' }
      ];
      name: 'ChannelFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ChannelPagination: {
    type: 'ChannelPagination';
    schemaKey: 'ChannelPagination';
    entityName: 'ChannelPagination';
    isList: false;
    argsTSName: 'QueryChannelPaginationArgs';
    returnTSName: "Query['ChannelPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ChannelPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyChannelInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyChannelInput' }
      ];
      name: 'ChannelPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserContentPermissionFindOne: {
    type: 'UserContentPermission';
    schemaKey: 'UserContentPermissionFindOne';
    entityName: 'UserContentPermission';
    isList: false;
    argsTSName: 'QueryUserContentPermissionFindOneArgs';
    returnTSName: "Query['UserContentPermissionFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserContentPermission';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneUserContentPermissionInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneUserContentPermissionInput';
        }
      ];
      name: 'UserContentPermissionFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ContentPermissionsHelper: {
    type: '[ContentPermissionsHelperOption]';
    schemaKey: 'ContentPermissionsHelper';
    entityName: 'ContentPermissionsHelperOption';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['ContentPermissionsHelper']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Lista de possiveis content-permissions que os usuários podem ter.';
      deprecationReason: null;
      type: '[ContentPermissionsHelperOption]';
      args: [];
      name: 'ContentPermissionsHelper';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserPagination: {
    type: 'UserPagination';
    schemaKey: 'UserPagination';
    entityName: 'UserPagination';
    isList: false;
    argsTSName: 'QueryUserPaginationArgs';
    returnTSName: "Query['UserPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyUserInput' },
        { name: 'limit'; description: null; type: 'Int' }
      ];
      name: 'UserPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindByIds: {
    type: '[User]';
    schemaKey: 'UserFindByIds';
    entityName: 'User';
    isList: true;
    argsTSName: 'QueryUserFindByIdsArgs';
    returnTSName: "Query['UserFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[User]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindByIdsUserInput' }
      ];
      name: 'UserFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindById: {
    type: 'User';
    schemaKey: 'UserFindById';
    entityName: 'User';
    isList: false;
    argsTSName: 'QueryUserFindByIdArgs';
    returnTSName: "Query['UserFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'UserFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindOne: {
    type: 'User';
    schemaKey: 'UserFindOne';
    entityName: 'User';
    isList: false;
    argsTSName: 'QueryUserFindOneArgs';
    returnTSName: "Query['UserFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneUserInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneUserInput' }
      ];
      name: 'UserFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindMany: {
    type: '[User]';
    schemaKey: 'UserFindMany';
    entityName: 'User';
    isList: true;
    argsTSName: 'QueryUserFindManyArgs';
    returnTSName: "Query['UserFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[User]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyUserInput' }
      ];
      name: 'UserFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  User: {
    type: 'User';
    schemaKey: 'User';
    entityName: 'User';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['User']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [];
      name: 'User';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserSearch: {
    type: '[UserSearch]';
    schemaKey: 'UserSearch';
    entityName: 'UserSearch';
    isList: true;
    argsTSName: 'QueryUserSearchArgs';
    returnTSName: "Query['UserSearch']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca do usuario';
      deprecationReason: null;
      type: '[UserSearch]';
      args: [
        {
          name: 'search';
          description: 'Nome parcial, email parcial ou ObjectId';
          type: 'String!';
        },
        { name: 'roleGroup'; description: null; type: '[String]' }
      ];
      name: 'UserSearch';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserSearchPagination: {
    type: 'UserPagination';
    schemaKey: 'UserSearchPagination';
    entityName: 'UserPagination';
    isList: false;
    argsTSName: 'QueryUserSearchPaginationArgs';
    returnTSName: "Query['UserSearchPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'UserSearchPaginationFilterInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyUserInput' },
        { name: 'limit'; description: null; type: 'Int' }
      ];
      name: 'UserSearchPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TextFindBlackList: {
    type: 'TextFindBlackList';
    schemaKey: 'TextFindBlackList';
    entityName: 'TextFindBlackList';
    isList: false;
    argsTSName: 'QueryTextFindBlackListArgs';
    returnTSName: "Query['TextFindBlackList']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'TextFindBlackList';
      args: [{ name: 'text'; description: null; type: 'String!' }];
      name: 'TextFindBlackList';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindByEmails: {
    type: '[UserSimpleResult]';
    schemaKey: 'UserFindByEmails';
    entityName: 'UserSimpleResult';
    isList: true;
    argsTSName: 'QueryUserFindByEmailsArgs';
    returnTSName: "Query['UserFindByEmails']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca do usuario';
      deprecationReason: null;
      type: '[UserSimpleResult]';
      args: [
        {
          name: 'emails';
          description: 'Lista de emails de usuários';
          type: '[String]!';
        }
      ];
      name: 'UserFindByEmails';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserFindByMatricula: {
    type: '[User]';
    schemaKey: 'UserFindByMatricula';
    entityName: 'User';
    isList: true;
    argsTSName: 'QueryUserFindByMatriculaArgs';
    returnTSName: "Query['UserFindByMatricula']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[User]';
      args: [
        { name: 'isMatriculaNull'; description: null; type: 'Boolean' },
        { name: 'email'; description: null; type: 'String' },
        { name: 'name'; description: null; type: 'String' },
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'UserFindByMatricula';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FileFindByIds: {
    type: '[File]';
    schemaKey: 'FileFindByIds';
    entityName: 'File';
    isList: true;
    argsTSName: 'QueryFileFindByIdsArgs';
    returnTSName: "Query['FileFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[File]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindByIdsFileInput' }
      ];
      name: 'FileFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FileFindById: {
    type: 'File';
    schemaKey: 'FileFindById';
    entityName: 'File';
    isList: false;
    argsTSName: 'QueryFileFindByIdArgs';
    returnTSName: "Query['FileFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'File';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'FileFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FilePagination: {
    type: 'FilePagination';
    schemaKey: 'FilePagination';
    entityName: 'FilePagination';
    isList: false;
    argsTSName: 'QueryFilePaginationArgs';
    returnTSName: "Query['FilePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'FilePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyFileInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyFileInput' }
      ];
      name: 'FilePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialFindById: {
    type: 'Material';
    schemaKey: 'MaterialFindById';
    entityName: 'Material';
    isList: false;
    argsTSName: 'QueryMaterialFindByIdArgs';
    returnTSName: "Query['MaterialFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Material';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'MaterialFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialFindMany: {
    type: '[Material]';
    schemaKey: 'MaterialFindMany';
    entityName: 'Material';
    isList: true;
    argsTSName: 'QueryMaterialFindManyArgs';
    returnTSName: "Query['MaterialFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Material]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyMaterialInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyMaterialInput' }
      ];
      name: 'MaterialFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialFindOne: {
    type: 'Material';
    schemaKey: 'MaterialFindOne';
    entityName: 'Material';
    isList: false;
    argsTSName: 'QueryMaterialFindOneArgs';
    returnTSName: "Query['MaterialFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Material';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneMaterialInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneMaterialInput' }
      ];
      name: 'MaterialFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialPagination: {
    type: 'MaterialPagination';
    schemaKey: 'MaterialPagination';
    entityName: 'MaterialPagination';
    isList: false;
    argsTSName: 'QueryMaterialPaginationArgs';
    returnTSName: "Query['MaterialPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MaterialPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyMaterialInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyMaterialInput' }
      ];
      name: 'MaterialPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialCount: {
    type: 'Int';
    schemaKey: 'MaterialCount';
    entityName: 'Int';
    isList: false;
    argsTSName: 'QueryMaterialCountArgs';
    returnTSName: "Query['MaterialCount']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Int';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterMaterialInput';
        },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'MaterialCount';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialStatusOptions: {
    type: '[MaterialStatusOption]';
    schemaKey: 'MaterialStatusOptions';
    entityName: 'MaterialStatusOption';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['MaterialStatusOptions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[MaterialStatusOption]';
      args: [];
      name: 'MaterialStatusOptions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialSearchOwners: {
    type: '[MaterialSearchOwners]';
    schemaKey: 'MaterialSearchOwners';
    entityName: 'MaterialSearchOwners';
    isList: true;
    argsTSName: 'QueryMaterialSearchOwnersArgs';
    returnTSName: "Query['MaterialSearchOwners']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[MaterialSearchOwners]';
      args: [{ name: 'kind'; description: null; type: 'EnumMaterialKind' }];
      name: 'MaterialSearchOwners';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailFindMany: {
    type: '[Email]';
    schemaKey: 'EmailFindMany';
    entityName: 'Email';
    isList: true;
    argsTSName: 'QueryEmailFindManyArgs';
    returnTSName: "Query['EmailFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Email]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEmailInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyEmailInput' }
      ];
      name: 'EmailFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailPagination: {
    type: 'EmailPagination';
    schemaKey: 'EmailPagination';
    entityName: 'EmailPagination';
    isList: false;
    argsTSName: 'QueryEmailPaginationArgs';
    returnTSName: "Query['EmailPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EmailPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEmailInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyEmailInput' }
      ];
      name: 'EmailPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailFindOne: {
    type: 'Email';
    schemaKey: 'EmailFindOne';
    entityName: 'Email';
    isList: false;
    argsTSName: 'QueryEmailFindOneArgs';
    returnTSName: "Query['EmailFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Email';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneEmailInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneEmailInput' }
      ];
      name: 'EmailFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailCheckRead: {
    type: 'Boolean';
    schemaKey: 'EmailCheckRead';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'QueryEmailCheckReadArgs';
    returnTSName: "Query['EmailCheckRead']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Retorna true se o email foi marcado como lido pelo destinatario informado';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'emailId'; description: null; type: 'String!' },
        { name: 'userEmail'; description: null; type: 'String!' }
      ];
      name: 'EmailCheckRead';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialSearch: {
    type: 'MaterialSearch';
    schemaKey: 'MaterialSearch';
    entityName: 'MaterialSearch';
    isList: false;
    argsTSName: 'QueryMaterialSearchArgs';
    returnTSName: "Query['MaterialSearch']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'MaterialSearch';
      args: [
        { name: 'search'; description: null; type: 'String!' },
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int'; defaultValue: 20 },
        { name: 'sort'; description: null; type: 'SortFindManyMaterialInput' },
        { name: 'category'; description: null; type: 'String' }
      ];
      name: 'MaterialSearch';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MaterialFindPinned: {
    type: 'MaterialPagination';
    schemaKey: 'MaterialFindPinned';
    entityName: 'MaterialPagination';
    isList: false;
    argsTSName: 'QueryMaterialFindPinnedArgs';
    returnTSName: "Query['MaterialFindPinned']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MaterialPagination';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int' },
        {
          name: 'filter';
          description: null;
          type: 'FilterFindManyMaterialInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyMaterialInput' }
      ];
      name: 'MaterialFindPinned';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  NotificationFindMany: {
    type: '[Notification]';
    schemaKey: 'NotificationFindMany';
    entityName: 'Notification';
    isList: true;
    argsTSName: 'QueryNotificationFindManyArgs';
    returnTSName: "Query['NotificationFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Notification]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyNotificationInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyNotificationInput';
        }
      ];
      name: 'NotificationFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  NotificationFindOne: {
    type: 'Notification';
    schemaKey: 'NotificationFindOne';
    entityName: 'Notification';
    isList: false;
    argsTSName: 'QueryNotificationFindOneArgs';
    returnTSName: "Query['NotificationFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Notification';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneNotificationInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneNotificationInput';
        }
      ];
      name: 'NotificationFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  NotificationPagination: {
    type: 'NotificationPagination';
    schemaKey: 'NotificationPagination';
    entityName: 'NotificationPagination';
    isList: false;
    argsTSName: 'QueryNotificationPaginationArgs';
    returnTSName: "Query['NotificationPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'NotificationPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyNotificationInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyNotificationInput';
        }
      ];
      name: 'NotificationPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  HelperAddress: {
    type: 'AddressHelperPayload';
    schemaKey: 'HelperAddress';
    entityName: 'AddressHelperPayload';
    isList: false;
    argsTSName: 'QueryHelperAddressArgs';
    returnTSName: "Query['HelperAddress']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'AddressHelperPayload';
      args: [{ name: 'zipCode'; description: null; type: 'String!' }];
      name: 'HelperAddress';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CategoryFindOne: {
    type: 'Category';
    schemaKey: 'CategoryFindOne';
    entityName: 'Category';
    isList: false;
    argsTSName: 'QueryCategoryFindOneArgs';
    returnTSName: "Query['CategoryFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Category';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneCategoryInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneCategoryInput' }
      ];
      name: 'CategoryFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CategoryFindById: {
    type: 'Category';
    schemaKey: 'CategoryFindById';
    entityName: 'Category';
    isList: false;
    argsTSName: 'QueryCategoryFindByIdArgs';
    returnTSName: "Query['CategoryFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Category';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'SortMenuEnum' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CategoryFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CategoryFindMany: {
    type: '[Category]';
    schemaKey: 'CategoryFindMany';
    entityName: 'Category';
    isList: true;
    argsTSName: 'QueryCategoryFindManyArgs';
    returnTSName: "Query['CategoryFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Category]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyCategoryInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyCategoryInput' }
      ];
      name: 'CategoryFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CategoryFindByIds: {
    type: '[Category]';
    schemaKey: 'CategoryFindByIds';
    entityName: 'Category';
    isList: true;
    argsTSName: 'QueryCategoryFindByIdsArgs';
    returnTSName: "Query['CategoryFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Category]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindByIdsCategoryInput' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CategoryFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  WebPushPublicKey: {
    type: 'String!';
    schemaKey: 'WebPushPublicKey';
    entityName: 'String';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['WebPushPublicKey']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Retorna a chave publica (VAPID key) pra a subscription de web push notifications';
      deprecationReason: null;
      type: 'String!';
      args: [];
      name: 'WebPushPublicKey';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'query';
  };

  PinFindMany: {
    type: '[Pin]';
    schemaKey: 'PinFindMany';
    entityName: 'Pin';
    isList: true;
    argsTSName: 'QueryPinFindManyArgs';
    returnTSName: "Query['PinFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Pin]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPinInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyPinInput' }
      ];
      name: 'PinFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PinPagination: {
    type: 'PinPagination';
    schemaKey: 'PinPagination';
    entityName: 'PinPagination';
    isList: false;
    argsTSName: 'QueryPinPaginationArgs';
    returnTSName: "Query['PinPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PinPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPinInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyPinInput' }
      ];
      name: 'PinPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ShareFindMany: {
    type: '[Share]';
    schemaKey: 'ShareFindMany';
    entityName: 'Share';
    isList: true;
    argsTSName: 'QueryShareFindManyArgs';
    returnTSName: "Query['ShareFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Share]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyShareInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyShareInput' }
      ];
      name: 'ShareFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SharePagination: {
    type: 'SharePagination';
    schemaKey: 'SharePagination';
    entityName: 'SharePagination';
    isList: false;
    argsTSName: 'QuerySharePaginationArgs';
    returnTSName: "Query['SharePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'SharePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyShareInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyShareInput' }
      ];
      name: 'SharePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CommentPagination: {
    type: 'CommentPagination';
    schemaKey: 'CommentPagination';
    entityName: 'CommentPagination';
    isList: false;
    argsTSName: 'QueryCommentPaginationArgs';
    returnTSName: "Query['CommentPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CommentPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyCommentInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyCommentInput' }
      ];
      name: 'CommentPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CommentFindOne: {
    type: 'Comment';
    schemaKey: 'CommentFindOne';
    entityName: 'Comment';
    isList: false;
    argsTSName: 'QueryCommentFindOneArgs';
    returnTSName: "Query['CommentFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Comment';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneCommentInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneCommentInput' }
      ];
      name: 'CommentFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CommentFindById: {
    type: 'Comment';
    schemaKey: 'CommentFindById';
    entityName: 'Comment';
    isList: false;
    argsTSName: 'QueryCommentFindByIdArgs';
    returnTSName: "Query['CommentFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Comment';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'CommentFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CommentFindMany: {
    type: '[Comment]';
    schemaKey: 'CommentFindMany';
    entityName: 'Comment';
    isList: true;
    argsTSName: 'QueryCommentFindManyArgs';
    returnTSName: "Query['CommentFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Comment]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyCommentInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyCommentInput' }
      ];
      name: 'CommentFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CommentSortPagination: {
    type: 'CommentPagination';
    schemaKey: 'CommentSortPagination';
    entityName: 'CommentPagination';
    isList: false;
    argsTSName: 'QueryCommentSortPaginationArgs';
    returnTSName: "Query['CommentSortPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CommentPagination';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int' },
        {
          name: 'filter';
          description: null;
          type: 'CommentSortPaginationFilterInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyCommentInput' }
      ];
      name: 'CommentSortPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EventLivePagination: {
    type: 'EventLivePagination';
    schemaKey: 'EventLivePagination';
    entityName: 'EventLivePagination';
    isList: false;
    argsTSName: 'QueryEventLivePaginationArgs';
    returnTSName: "Query['EventLivePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EventLivePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEventLiveInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyEventLiveInput' }
      ];
      name: 'EventLivePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EventLiveFindOne: {
    type: 'EventLive';
    schemaKey: 'EventLiveFindOne';
    entityName: 'EventLive';
    isList: false;
    argsTSName: 'QueryEventLiveFindOneArgs';
    returnTSName: "Query['EventLiveFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EventLive';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneEventLiveInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneEventLiveInput' }
      ];
      name: 'EventLiveFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EventLiveFindById: {
    type: 'EventLive';
    schemaKey: 'EventLiveFindById';
    entityName: 'EventLive';
    isList: false;
    argsTSName: 'QueryEventLiveFindByIdArgs';
    returnTSName: "Query['EventLiveFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EventLive';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'EventLiveFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  BannerFindMany: {
    type: '[Banner]';
    schemaKey: 'BannerFindMany';
    entityName: 'Banner';
    isList: true;
    argsTSName: 'QueryBannerFindManyArgs';
    returnTSName: "Query['BannerFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Banner]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyBannerInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyBannerInput' }
      ];
      name: 'BannerFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  BannerFindOne: {
    type: 'Banner';
    schemaKey: 'BannerFindOne';
    entityName: 'Banner';
    isList: false;
    argsTSName: 'QueryBannerFindOneArgs';
    returnTSName: "Query['BannerFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Banner';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneBannerInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneBannerInput' }
      ];
      name: 'BannerFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsCount: {
    type: 'Int';
    schemaKey: 'AnalyticsCount';
    entityName: 'Int';
    isList: false;
    argsTSName: 'QueryAnalyticsCountArgs';
    returnTSName: "Query['AnalyticsCount']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Int';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterAnalyticsInput';
        }
      ];
      name: 'AnalyticsCount';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsPagination: {
    type: 'AnalyticsPagination';
    schemaKey: 'AnalyticsPagination';
    entityName: 'AnalyticsPagination';
    isList: false;
    argsTSName: 'QueryAnalyticsPaginationArgs';
    returnTSName: "Query['AnalyticsPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'AnalyticsPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyAnalyticsInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyAnalyticsInput' }
      ];
      name: 'AnalyticsPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsUsersReport: {
    type: '[AnalyticsUser]';
    schemaKey: 'AnalyticsUsersReport';
    entityName: 'AnalyticsUser';
    isList: true;
    argsTSName: 'QueryAnalyticsUsersReportArgs';
    returnTSName: "Query['AnalyticsUsersReport']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: '[AnalyticsUser]';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int'; defaultValue: 20 }
      ];
      name: 'AnalyticsUsersReport';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsFindReport: {
    type: '[AnalyticsReportItem]';
    schemaKey: 'AnalyticsFindReport';
    entityName: 'AnalyticsReportItem';
    isList: true;
    argsTSName: 'QueryAnalyticsFindReportArgs';
    returnTSName: "Query['AnalyticsFindReport']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsReportItem]';
      args: [
        {
          name: 'filter';
          description: null;
          type: 'AnalyticsReportFilterInput';
        },
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'AnalyticsFindReport';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsUserNotification: {
    type: '[AnalyticsUserNotification]';
    schemaKey: 'AnalyticsUserNotification';
    entityName: 'AnalyticsUserNotification';
    isList: true;
    argsTSName: 'QueryAnalyticsUserNotificationArgs';
    returnTSName: "Query['AnalyticsUserNotification']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsUserNotification]';
      args: [
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'AnalyticsUserNotification';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsPin: {
    type: '[AnalyticsPin]';
    schemaKey: 'AnalyticsPin';
    entityName: 'AnalyticsPin';
    isList: true;
    argsTSName: 'QueryAnalyticsPinArgs';
    returnTSName: "Query['AnalyticsPin']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsPin]';
      args: [
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'AnalyticsPin';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsComment: {
    type: '[AnalyticsComment]';
    schemaKey: 'AnalyticsComment';
    entityName: 'AnalyticsComment';
    isList: true;
    argsTSName: 'QueryAnalyticsCommentArgs';
    returnTSName: "Query['AnalyticsComment']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsComment]';
      args: [
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'AnalyticsComment';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsPoints: {
    type: '[AnalyticsPoints]';
    schemaKey: 'AnalyticsPoints';
    entityName: 'AnalyticsPoints';
    isList: true;
    argsTSName: 'QueryAnalyticsPointsArgs';
    returnTSName: "Query['AnalyticsPoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[AnalyticsPoints]';
      args: [
        { name: 'page'; description: 'Página atual'; type: 'Int' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' }
      ];
      name: 'AnalyticsPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AnalyticsDownloads: {
    type: 'Boolean';
    schemaKey: 'AnalyticsDownloads';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'QueryAnalyticsDownloadsArgs';
    returnTSName: "Query['AnalyticsDownloads']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'fileId'; description: null; type: 'String' }];
      name: 'AnalyticsDownloads';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SearchCountFindMany: {
    type: '[SearchCount]';
    schemaKey: 'SearchCountFindMany';
    entityName: 'SearchCount';
    isList: true;
    argsTSName: 'QuerySearchCountFindManyArgs';
    returnTSName: "Query['SearchCountFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[SearchCount]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManySearchCountInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManySearchCountInput';
        }
      ];
      name: 'SearchCountFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SearchCountPagination: {
    type: 'SearchCountPagination';
    schemaKey: 'SearchCountPagination';
    entityName: 'SearchCountPagination';
    isList: false;
    argsTSName: 'QuerySearchCountPaginationArgs';
    returnTSName: "Query['SearchCountPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'SearchCountPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManySearchCountInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManySearchCountInput';
        }
      ];
      name: 'SearchCountPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FolderFindMany: {
    type: '[Folder]';
    schemaKey: 'FolderFindMany';
    entityName: 'Folder';
    isList: true;
    argsTSName: 'QueryFolderFindManyArgs';
    returnTSName: "Query['FolderFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Folder]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyFolderInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyFolderInput' }
      ];
      name: 'FolderFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FolderFindById: {
    type: 'Folder';
    schemaKey: 'FolderFindById';
    entityName: 'Folder';
    isList: false;
    argsTSName: 'QueryFolderFindByIdArgs';
    returnTSName: "Query['FolderFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Folder';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'FolderFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FolderPagination: {
    type: 'FolderPagination';
    schemaKey: 'FolderPagination';
    entityName: 'FolderPagination';
    isList: false;
    argsTSName: 'QueryFolderPaginationArgs';
    returnTSName: "Query['FolderPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'FolderPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyFolderInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyFolderInput' }
      ];
      name: 'FolderPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RecentsActivitiesPagination: {
    type: 'RecentsActivitiesPagination';
    schemaKey: 'RecentsActivitiesPagination';
    entityName: 'RecentsActivitiesPagination';
    isList: false;
    argsTSName: 'QueryRecentsActivitiesPaginationArgs';
    returnTSName: "Query['RecentsActivitiesPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RecentsActivitiesPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRecentsActivitiesInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyRecentsActivitiesInput';
        }
      ];
      name: 'RecentsActivitiesPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserWordsBlackListFindMany: {
    type: '[UserWordsBlackList]';
    schemaKey: 'UserWordsBlackListFindMany';
    entityName: 'UserWordsBlackList';
    isList: true;
    argsTSName: 'QueryUserWordsBlackListFindManyArgs';
    returnTSName: "Query['UserWordsBlackListFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserWordsBlackList]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserWordsBlackListInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyUserWordsBlackListInput';
        }
      ];
      name: 'UserWordsBlackListFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserWordsBlackListFindById: {
    type: 'UserWordsBlackList';
    schemaKey: 'UserWordsBlackListFindById';
    entityName: 'UserWordsBlackList';
    isList: false;
    argsTSName: 'QueryUserWordsBlackListFindByIdArgs';
    returnTSName: "Query['UserWordsBlackListFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserWordsBlackList';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'UserWordsBlackListFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadSensitiveFilesPagination: {
    type: 'DownloadSensitiveFilesPagination';
    schemaKey: 'DownloadSensitiveFilesPagination';
    entityName: 'DownloadSensitiveFilesPagination';
    isList: false;
    argsTSName: 'QueryDownloadSensitiveFilesPaginationArgs';
    returnTSName: "Query['DownloadSensitiveFilesPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DownloadSensitiveFilesPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyDownloadSensitiveFilesInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyDownloadSensitiveFilesInput';
        }
      ];
      name: 'DownloadSensitiveFilesPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadUsersInfo: {
    type: 'DownloadUsersInfo';
    schemaKey: 'DownloadUsersInfo';
    entityName: 'DownloadUsersInfo';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['DownloadUsersInfo']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadUsersInfo';
      args: [];
      name: 'DownloadUsersInfo';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadAcceptedTerms: {
    type: 'DownloadAcceptedTerms';
    schemaKey: 'DownloadAcceptedTerms';
    entityName: 'DownloadAcceptedTerms';
    isList: false;
    argsTSName: 'QueryDownloadAcceptedTermsArgs';
    returnTSName: "Query['DownloadAcceptedTerms']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadAcceptedTerms';
      args: [
        { name: 'incentiveCampaignId'; description: null; type: 'String!' }
      ];
      name: 'DownloadAcceptedTerms';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadUsersAcceptedPoints: {
    type: 'DownloadUsersAcceptedPoints';
    schemaKey: 'DownloadUsersAcceptedPoints';
    entityName: 'DownloadUsersAcceptedPoints';
    isList: false;
    argsTSName: 'QueryDownloadUsersAcceptedPointsArgs';
    returnTSName: "Query['DownloadUsersAcceptedPoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadUsersAcceptedPoints';
      args: [
        { name: 'incentiveCampaignId'; description: null; type: 'String!' }
      ];
      name: 'DownloadUsersAcceptedPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadUsersWithPoints: {
    type: 'DownloadUsersWithPoints';
    schemaKey: 'DownloadUsersWithPoints';
    entityName: 'DownloadUsersWithPoints';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['DownloadUsersWithPoints']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadUsersWithPoints';
      args: [];
      name: 'DownloadUsersWithPoints';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DownloadUsersBase: {
    type: 'DownloadUsersInfo';
    schemaKey: 'DownloadUsersBase';
    entityName: 'DownloadUsersInfo';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['DownloadUsersBase']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'DownloadUsersInfo';
      args: [];
      name: 'DownloadUsersBase';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ImportUsersPlanFindMany: {
    type: '[ImportUsersPlan]';
    schemaKey: 'ImportUsersPlanFindMany';
    entityName: 'ImportUsersPlan';
    isList: true;
    argsTSName: 'QueryImportUsersPlanFindManyArgs';
    returnTSName: "Query['ImportUsersPlanFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[ImportUsersPlan]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyImportUsersPlanInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyImportUsersPlanInput';
        }
      ];
      name: 'ImportUsersPlanFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ImportUsersPlanFindById: {
    type: 'ImportUsersPlan';
    schemaKey: 'ImportUsersPlanFindById';
    entityName: 'ImportUsersPlan';
    isList: false;
    argsTSName: 'QueryImportUsersPlanFindByIdArgs';
    returnTSName: "Query['ImportUsersPlanFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ImportUsersPlan';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'ImportUsersPlanFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ImportUsersPlanFindByIds: {
    type: '[ImportUsersPlan]';
    schemaKey: 'ImportUsersPlanFindByIds';
    entityName: 'ImportUsersPlan';
    isList: true;
    argsTSName: 'QueryImportUsersPlanFindByIdsArgs';
    returnTSName: "Query['ImportUsersPlanFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[ImportUsersPlan]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindByIdsImportUsersPlanInput';
        },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'ImportUsersPlanFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ImportUsersPlanPagination: {
    type: 'ImportUsersPlanPagination';
    schemaKey: 'ImportUsersPlanPagination';
    entityName: 'ImportUsersPlanPagination';
    isList: false;
    argsTSName: 'QueryImportUsersPlanPaginationArgs';
    returnTSName: "Query['ImportUsersPlanPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ImportUsersPlanPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyImportUsersPlanInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyImportUsersPlanInput';
        }
      ];
      name: 'ImportUsersPlanPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserDeletedFindByIds: {
    type: '[UserDeleted]';
    schemaKey: 'UserDeletedFindByIds';
    entityName: 'UserDeleted';
    isList: true;
    argsTSName: 'QueryUserDeletedFindByIdsArgs';
    returnTSName: "Query['UserDeletedFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserDeleted]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindByIdsUserDeletedInput';
        }
      ];
      name: 'UserDeletedFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserDeletedFindOne: {
    type: 'UserDeleted';
    schemaKey: 'UserDeletedFindOne';
    entityName: 'UserDeleted';
    isList: false;
    argsTSName: 'QueryUserDeletedFindOneArgs';
    returnTSName: "Query['UserDeletedFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserDeleted';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneUserDeletedInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneUserDeletedInput' }
      ];
      name: 'UserDeletedFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserDeletedFindMany: {
    type: '[UserDeleted]';
    schemaKey: 'UserDeletedFindMany';
    entityName: 'UserDeleted';
    isList: true;
    argsTSName: 'QueryUserDeletedFindManyArgs';
    returnTSName: "Query['UserDeletedFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserDeleted]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserDeletedInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyUserDeletedInput';
        }
      ];
      name: 'UserDeletedFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserHistoryBlockFindMany: {
    type: '[UserHistoryBlock]';
    schemaKey: 'UserHistoryBlockFindMany';
    entityName: 'UserHistoryBlock';
    isList: true;
    argsTSName: 'QueryUserHistoryBlockFindManyArgs';
    returnTSName: "Query['UserHistoryBlockFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserHistoryBlock]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserHistoryBlockInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyUserHistoryBlockInput';
        }
      ];
      name: 'UserHistoryBlockFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserHistoryBlockPagination: {
    type: 'UserHistoryBlockPagination';
    schemaKey: 'UserHistoryBlockPagination';
    entityName: 'UserHistoryBlockPagination';
    isList: false;
    argsTSName: 'QueryUserHistoryBlockPaginationArgs';
    returnTSName: "Query['UserHistoryBlockPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserHistoryBlockPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyUserHistoryBlockInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyUserHistoryBlockInput';
        }
      ];
      name: 'UserHistoryBlockPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FutureOperationFindById: {
    type: 'FutureOperation';
    schemaKey: 'FutureOperationFindById';
    entityName: 'FutureOperation';
    isList: false;
    argsTSName: 'QueryFutureOperationFindByIdArgs';
    returnTSName: "Query['FutureOperationFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'FutureOperation';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'FutureOperationFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailStatsFindOne: {
    type: 'EmailStats';
    schemaKey: 'EmailStatsFindOne';
    entityName: 'EmailStats';
    isList: false;
    argsTSName: 'QueryEmailStatsFindOneArgs';
    returnTSName: "Query['EmailStatsFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EmailStats';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneEmailStatsInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneEmailStatsInput' }
      ];
      name: 'EmailStatsFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailStatsFindMany: {
    type: '[EmailStats]';
    schemaKey: 'EmailStatsFindMany';
    entityName: 'EmailStats';
    isList: true;
    argsTSName: 'QueryEmailStatsFindManyArgs';
    returnTSName: "Query['EmailStatsFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[EmailStats]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEmailStatsInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyEmailStatsInput' }
      ];
      name: 'EmailStatsFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailUnsubscribeFindOne: {
    type: 'EmailUnsubscribe';
    schemaKey: 'EmailUnsubscribeFindOne';
    entityName: 'EmailUnsubscribe';
    isList: false;
    argsTSName: 'QueryEmailUnsubscribeFindOneArgs';
    returnTSName: "Query['EmailUnsubscribeFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'EmailUnsubscribe';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneEmailUnsubscribeInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneEmailUnsubscribeInput';
        }
      ];
      name: 'EmailUnsubscribeFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailUnsubscribeFindMany: {
    type: '[EmailUnsubscribe]';
    schemaKey: 'EmailUnsubscribeFindMany';
    entityName: 'EmailUnsubscribe';
    isList: true;
    argsTSName: 'QueryEmailUnsubscribeFindManyArgs';
    returnTSName: "Query['EmailUnsubscribeFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[EmailUnsubscribe]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyEmailUnsubscribeInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyEmailUnsubscribeInput';
        }
      ];
      name: 'EmailUnsubscribeFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  EmailUnsubscribeAction: {
    type: 'EmailUnsubscribeActionPayload';
    schemaKey: 'EmailUnsubscribeAction';
    entityName: 'EmailUnsubscribeActionPayload';
    isList: false;
    argsTSName: 'QueryEmailUnsubscribeActionArgs';
    returnTSName: "Query['EmailUnsubscribeAction']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Descadastra o e-mail para recebimento futuro de e-mails';
      deprecationReason: null;
      type: 'EmailUnsubscribeActionPayload';
      args: [
        { name: 'id'; description: null; type: 'String' },
        { name: 'email'; description: null; type: 'String' }
      ];
      name: 'EmailUnsubscribeAction';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  HomebannerFindMany: {
    type: '[Homebanner]';
    schemaKey: 'HomebannerFindMany';
    entityName: 'Homebanner';
    isList: true;
    argsTSName: 'QueryHomebannerFindManyArgs';
    returnTSName: "Query['HomebannerFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Homebanner]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyHomebannerInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyHomebannerInput' }
      ];
      name: 'HomebannerFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceFindMany: {
    type: '[Place]';
    schemaKey: 'PlaceFindMany';
    entityName: 'Place';
    isList: true;
    argsTSName: 'QueryPlaceFindManyArgs';
    returnTSName: "Query['PlaceFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Place]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPlaceInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyPlaceInput' }
      ];
      name: 'PlaceFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceFindOne: {
    type: 'Place';
    schemaKey: 'PlaceFindOne';
    entityName: 'Place';
    isList: false;
    argsTSName: 'QueryPlaceFindOneArgs';
    returnTSName: "Query['PlaceFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Place';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOnePlaceInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOnePlaceInput' }
      ];
      name: 'PlaceFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceFindById: {
    type: 'Place';
    schemaKey: 'PlaceFindById';
    entityName: 'Place';
    isList: false;
    argsTSName: 'QueryPlaceFindByIdArgs';
    returnTSName: "Query['PlaceFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Place';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'SortPlaceEnum' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'PlaceFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlacePagination: {
    type: 'PlacePagination';
    schemaKey: 'PlacePagination';
    entityName: 'PlacePagination';
    isList: false;
    argsTSName: 'QueryPlacePaginationArgs';
    returnTSName: "Query['PlacePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PlacePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPlaceInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyPlaceInput' }
      ];
      name: 'PlacePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceSearch: {
    type: 'PlaceSearch';
    schemaKey: 'PlaceSearch';
    entityName: 'PlaceSearch';
    isList: false;
    argsTSName: 'QueryPlaceSearchArgs';
    returnTSName: "Query['PlaceSearch']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca de places por canal,\n      ufs, DDDs, cidades, focu de comunicação, modelo de loja,\n      tecnologias de rede e produtos comercializados';
      deprecationReason: null;
      type: 'PlaceSearch';
      args: [
        { name: 'channel'; description: 'Lista de canais'; type: '[String]' },
        { name: 'DDD'; description: 'Lista de DDDs'; type: '[String]' },
        { name: 'uf'; description: 'Lista de estados'; type: '[String]' },
        { name: 'city'; description: 'Lista de cidades'; type: '[String]' },
        {
          name: 'communicationFocus';
          description: 'Lista de focu de comunicação';
          type: '[String]';
        },
        {
          name: 'storeModel';
          description: 'Lista de modelo de loja';
          type: '[String]';
        },
        {
          name: 'networkTechnology';
          description: 'Lista de tecnologias de rede';
          type: '[String]';
        },
        {
          name: 'marketedProduct';
          description: 'Lista de produtos comercializados';
          type: '[String]';
        },
        {
          name: 'region';
          description: 'Regional relacionada ao local';
          type: '[String]';
        },
        {
          name: 'adminId';
          description: 'ID do admin do local';
          type: 'String';
        },
        { name: 'CNPJ'; description: 'CNPJ do local'; type: '[String]' },
        {
          name: 'perPage';
          description: 'Número de resultados por página';
          type: 'Int';
        },
        { name: 'page'; description: 'Página dos dados'; type: 'Int' },
        { name: 'razao_social'; description: 'Razão social'; type: 'String' }
      ];
      name: 'PlaceSearch';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceSearchByIdsTypeAndName: {
    type: 'PlaceSearchByIdsTypeAndName';
    schemaKey: 'PlaceSearchByIdsTypeAndName';
    entityName: 'PlaceSearchByIdsTypeAndName';
    isList: false;
    argsTSName: 'QueryPlaceSearchByIdsTypeAndNameArgs';
    returnTSName: "Query['PlaceSearchByIdsTypeAndName']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos locais filtrando por id e produtos relacionados ao inventário';
      deprecationReason: null;
      type: 'PlaceSearchByIdsTypeAndName';
      args: [
        {
          name: 'id';
          description: 'Lista de ids relacionadas ao local';
          type: '[String]';
        },
        {
          name: 'name';
          description: 'Nome fantasia ou razão social relacionada ao local';
          type: 'String';
        },
        {
          name: 'type';
          description: 'Lista de tipos relacionados aos locais';
          type: '[String]';
        },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' },
        { name: 'page'; description: 'Página atual'; type: 'Int' }
      ];
      name: 'PlaceSearchByIdsTypeAndName';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceSearchByChannelSapCodeOrPlaceAndRegion: {
    type: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion';
    schemaKey: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion';
    entityName: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion';
    isList: false;
    argsTSName: 'QueryPlaceSearchByChannelSapCodeOrPlaceAndRegionArgs';
    returnTSName: "Query['PlaceSearchByChannelSapCodeOrPlaceAndRegion']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca da tabela de preço por região e canal';
      deprecationReason: null;
      type: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion';
      args: [
        {
          name: 'disabled';
          description: 'Status da tabela de preço';
          type: 'Boolean';
        },
        {
          name: 'filterLocationType';
          description: 'Canal da tabela de preço';
          type: '[String]';
        },
        {
          name: 'filterRegions';
          description: 'Região da tabela de preço';
          type: '[String]';
        },
        {
          name: 'filterSapCodeOrPlaceName';
          description: 'Código SAP ou nome do local';
          type: 'String';
        },
        {
          name: 'perPage';
          description: 'Número de resultados por página';
          type: 'Int';
        },
        { name: 'page'; description: 'Página dos dados'; type: 'Int' }
      ];
      name: 'PlaceSearchByChannelSapCodeOrPlaceAndRegion';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceSearchByIdsLpuCategoryAndContainsInventory: {
    type: 'PlaceSearchByIdsLpuCategoryAndContainsInventory';
    schemaKey: 'PlaceSearchByIdsLpuCategoryAndContainsInventory';
    entityName: 'PlaceSearchByIdsLpuCategoryAndContainsInventory';
    isList: false;
    argsTSName: 'QueryPlaceSearchByIdsLpuCategoryAndContainsInventoryArgs';
    returnTSName: "Query['PlaceSearchByIdsLpuCategoryAndContainsInventory']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos locais filtrando por id e produtos relacionados ao inventário';
      deprecationReason: null;
      type: 'PlaceSearchByIdsLpuCategoryAndContainsInventory';
      args: [
        {
          name: 'id';
          description: 'Lista de ids relacionadas ao local';
          type: '[String]';
        },
        {
          name: 'lpu';
          description: 'Lista de ids relacionadas a lpu';
          type: '[String]';
        },
        {
          name: 'categoryLpu';
          description: 'Se a lpu precisa ter uma categoria em especifico (estrutural/merchandising)';
          type: 'String';
        },
        {
          name: 'relatedToCategoryLpu';
          description: 'Se a lpu precisa estar relacionada a outra lpu a categoria em questão';
          type: 'String';
        },
        {
          name: 'containsInventory';
          description: 'Se tem inventario ou não';
          type: 'Boolean';
        },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' },
        { name: 'page'; description: 'Página atual'; type: 'Int' }
      ];
      name: 'PlaceSearchByIdsLpuCategoryAndContainsInventory';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlacePaginationCustom: {
    type: 'PlacePaginationCustom';
    schemaKey: 'PlacePaginationCustom';
    entityName: 'PlacePaginationCustom';
    isList: false;
    argsTSName: 'QueryPlacePaginationCustomArgs';
    returnTSName: "Query['PlacePaginationCustom']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PlacePaginationCustom';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int' },
        { name: 'filter'; description: null; type: 'JSON' },
        { name: 'sort'; description: null; type: 'SortFindManyPlaceInput' }
      ];
      name: 'PlacePaginationCustom';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LocationOptions: {
    type: 'LocationOptionsPayload';
    schemaKey: 'LocationOptions';
    entityName: 'LocationOptionsPayload';
    isList: false;
    argsTSName: 'QueryLocationOptionsArgs';
    returnTSName: "Query['LocationOptions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'LocationOptionsPayload';
      args: [
        { name: 'uf'; description: 'UF'; type: 'String' },
        { name: 'region'; description: 'Região'; type: 'String' }
      ];
      name: 'LocationOptions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceSearchByCnpj: {
    type: 'Place';
    schemaKey: 'PlaceSearchByCnpj';
    entityName: 'Place';
    isList: false;
    argsTSName: 'QueryPlaceSearchByCnpjArgs';
    returnTSName: "Query['PlaceSearchByCnpj']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca de um place através do CNPJ';
      deprecationReason: null;
      type: 'Place';
      args: [{ name: 'CNPJ'; description: 'CNPJ do local'; type: '[String]' }];
      name: 'PlaceSearchByCnpj';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlacesBaseDownload: {
    type: 'BaseDownload';
    schemaKey: 'PlacesBaseDownload';
    entityName: 'BaseDownload';
    isList: false;
    argsTSName: 'QueryPlacesBaseDownloadArgs';
    returnTSName: "Query['PlacesBaseDownload']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'BaseDownload';
      args: [
        { name: 'filters'; description: null; type: 'JSON' },
        { name: 'isExample'; description: null; type: 'Boolean' }
      ];
      name: 'PlacesBaseDownload';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlacesBaseValidation: {
    type: 'JSON';
    schemaKey: 'PlacesBaseValidation';
    entityName: 'JSON';
    isList: false;
    argsTSName: 'QueryPlacesBaseValidationArgs';
    returnTSName: "Query['PlacesBaseValidation']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'JSON';
      args: [{ name: 'fileId'; description: null; type: 'String!' }];
      name: 'PlacesBaseValidation';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlacesPermissions: {
    type: '[PlacesPermissionsEnum]';
    schemaKey: 'PlacesPermissions';
    entityName: 'PlacesPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['PlacesPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[PlacesPermissionsEnum]';
      args: [];
      name: 'PlacesPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ContractPagination: {
    type: 'ContractPagination';
    schemaKey: 'ContractPagination';
    entityName: 'ContractPagination';
    isList: false;
    argsTSName: 'QueryContractPaginationArgs';
    returnTSName: "Query['ContractPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ContractPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyContractInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyContractInput' }
      ];
      name: 'ContractPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ContractFindOne: {
    type: 'Contract';
    schemaKey: 'ContractFindOne';
    entityName: 'Contract';
    isList: false;
    argsTSName: 'QueryContractFindOneArgs';
    returnTSName: "Query['ContractFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Contract';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneContractInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneContractInput' }
      ];
      name: 'ContractFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ContractFindByIds: {
    type: '[Contract]';
    schemaKey: 'ContractFindByIds';
    entityName: 'Contract';
    isList: true;
    argsTSName: 'QueryContractFindByIdsArgs';
    returnTSName: "Query['ContractFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Contract]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindByIdsContractInput' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'ContractFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductPagination: {
    type: 'ProductPagination';
    schemaKey: 'ProductPagination';
    entityName: 'ProductPagination';
    isList: false;
    argsTSName: 'QueryProductPaginationArgs';
    returnTSName: "Query['ProductPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ProductPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyProductInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyProductInput' }
      ];
      name: 'ProductPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductFindById: {
    type: 'Product';
    schemaKey: 'ProductFindById';
    entityName: 'Product';
    isList: false;
    argsTSName: 'QueryProductFindByIdArgs';
    returnTSName: "Query['ProductFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Product';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'SortProductEnum' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'ProductFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductFindByIds: {
    type: '[Product]';
    schemaKey: 'ProductFindByIds';
    entityName: 'Product';
    isList: true;
    argsTSName: 'QueryProductFindByIdsArgs';
    returnTSName: "Query['ProductFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Product]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindByIdsProductInput' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'ProductFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductBaseDownload: {
    type: 'BaseDownload';
    schemaKey: 'ProductBaseDownload';
    entityName: 'BaseDownload';
    isList: false;
    argsTSName: 'QueryProductBaseDownloadArgs';
    returnTSName: "Query['ProductBaseDownload']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'BaseDownload';
      args: [
        { name: 'filters'; description: null; type: 'JSON' },
        { name: 'isExample'; description: null; type: 'Boolean' }
      ];
      name: 'ProductBaseDownload';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductBaseValidation: {
    type: 'JSON';
    schemaKey: 'ProductBaseValidation';
    entityName: 'JSON';
    isList: false;
    argsTSName: 'QueryProductBaseValidationArgs';
    returnTSName: "Query['ProductBaseValidation']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'JSON';
      args: [{ name: 'fileId'; description: null; type: 'String!' }];
      name: 'ProductBaseValidation';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductSearchByNameOrSupplier: {
    type: 'ProductSearchByNameOrSupplier';
    schemaKey: 'ProductSearchByNameOrSupplier';
    entityName: 'ProductSearchByNameOrSupplier';
    isList: false;
    argsTSName: 'QueryProductSearchByNameOrSupplierArgs';
    returnTSName: "Query['ProductSearchByNameOrSupplier']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos produtos por nome';
      deprecationReason: null;
      type: 'ProductSearchByNameOrSupplier';
      args: [
        {
          name: 'description';
          description: 'Abreviação produção';
          type: 'String';
        },
        { name: 'onlyStructural'; description: null; type: 'Boolean' },
        { name: 'name'; description: 'Nome dos produtos'; type: 'String' },
        { name: 'ids'; description: null; type: '[String]' },
        {
          name: 'supplier';
          description: 'Razão social ou Nome Fantasia do fornecedor';
          type: 'String';
        },
        {
          name: 'category';
          description: 'Categoria ';
          type: 'EnumProductCategoria';
        },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' },
        { name: 'page'; description: 'Página atual'; type: 'Int' }
      ];
      name: 'ProductSearchByNameOrSupplier';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductSearchByNameOrSupplierAndNotContainsSomeIds: {
    type: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds';
    schemaKey: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds';
    entityName: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds';
    isList: false;
    argsTSName: 'QueryProductSearchByNameOrSupplierAndNotContainsSomeIdsArgs';
    returnTSName: "Query['ProductSearchByNameOrSupplierAndNotContainsSomeIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos produtos por nome';
      deprecationReason: null;
      type: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds';
      args: [
        { name: 'name'; description: 'Nome dos produtos'; type: 'String' },
        {
          name: 'supplier';
          description: 'Razão social ou Nome Fantasia do fornecedor';
          type: 'String';
        },
        { name: 'ids'; description: 'Ids ignorados'; type: '[String]' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' },
        { name: 'page'; description: 'Página atual'; type: 'Int' }
      ];
      name: 'ProductSearchByNameOrSupplierAndNotContainsSomeIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductSearchBySupplierZipCodeAndContainsInventory: {
    type: 'ProductSearchBySupplierZipCodeAndContainsInventory';
    schemaKey: 'ProductSearchBySupplierZipCodeAndContainsInventory';
    entityName: 'ProductSearchBySupplierZipCodeAndContainsInventory';
    isList: false;
    argsTSName: 'QueryProductSearchBySupplierZipCodeAndContainsInventoryArgs';
    returnTSName: "Query['ProductSearchBySupplierZipCodeAndContainsInventory']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos produtos filtrando por id, local do fornecedor e que contem inventário ou não';
      deprecationReason: null;
      type: 'ProductSearchBySupplierZipCodeAndContainsInventory';
      args: [
        {
          name: 'id';
          description: 'Lista de ids relacionadas a lpu';
          type: '[String]!';
        },
        {
          name: 'zipcode';
          description: 'Lista de ceps relacionadas ao fornecedor';
          type: '[String]';
        },
        {
          name: 'containsInventory';
          description: 'Se tem inventario ou não';
          type: 'Boolean';
        },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' },
        { name: 'page'; description: 'Página atual'; type: 'Int' }
      ];
      name: 'ProductSearchBySupplierZipCodeAndContainsInventory';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SupplierPagination: {
    type: 'SupplierPagination';
    schemaKey: 'SupplierPagination';
    entityName: 'SupplierPagination';
    isList: false;
    argsTSName: 'QuerySupplierPaginationArgs';
    returnTSName: "Query['SupplierPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'SupplierPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManySupplierInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManySupplierInput' }
      ];
      name: 'SupplierPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SupplierFindOne: {
    type: 'Supplier';
    schemaKey: 'SupplierFindOne';
    entityName: 'Supplier';
    isList: false;
    argsTSName: 'QuerySupplierFindOneArgs';
    returnTSName: "Query['SupplierFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Supplier';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneSupplierInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneSupplierInput' }
      ];
      name: 'SupplierFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SupplierFindMany: {
    type: '[Supplier]';
    schemaKey: 'SupplierFindMany';
    entityName: 'Supplier';
    isList: true;
    argsTSName: 'QuerySupplierFindManyArgs';
    returnTSName: "Query['SupplierFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Supplier]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManySupplierInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManySupplierInput' }
      ];
      name: 'SupplierFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ResolverSupplierSearchByNameAndContainsLpu: {
    type: 'ResolverSupplierSearchByNameAndContainsLpu';
    schemaKey: 'ResolverSupplierSearchByNameAndContainsLpu';
    entityName: 'ResolverSupplierSearchByNameAndContainsLpu';
    isList: false;
    argsTSName: 'QueryResolverSupplierSearchByNameAndContainsLpuArgs';
    returnTSName: "Query['ResolverSupplierSearchByNameAndContainsLpu']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos produtos por nome';
      deprecationReason: null;
      type: 'ResolverSupplierSearchByNameAndContainsLpu';
      args: [
        {
          name: 'name';
          description: 'Razão social ou nome fantasia do fornecedor';
          type: 'String';
        },
        {
          name: 'lpu';
          description: 'Lista de id das lpu relacionadas com o fornecedor';
          type: '[String]';
        },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' },
        { name: 'page'; description: 'Página atual'; type: 'Int' }
      ];
      name: 'ResolverSupplierSearchByNameAndContainsLpu';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SupplierPermissions: {
    type: '[SupplierPermissionsEnum]';
    schemaKey: 'SupplierPermissions';
    entityName: 'SupplierPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['SupplierPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[SupplierPermissionsEnum]';
      args: [];
      name: 'SupplierPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePriceFindMany: {
    type: '[TablePrice]';
    schemaKey: 'TablePriceFindMany';
    entityName: 'TablePrice';
    isList: true;
    argsTSName: 'QueryTablePriceFindManyArgs';
    returnTSName: "Query['TablePriceFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[TablePrice]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyTablePriceInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyTablePriceInput' }
      ];
      name: 'TablePriceFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePriceFindOne: {
    type: 'TablePrice';
    schemaKey: 'TablePriceFindOne';
    entityName: 'TablePrice';
    isList: false;
    argsTSName: 'QueryTablePriceFindOneArgs';
    returnTSName: "Query['TablePriceFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'TablePrice';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneTablePriceInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneTablePriceInput' }
      ];
      name: 'TablePriceFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePriceFindById: {
    type: 'TablePrice';
    schemaKey: 'TablePriceFindById';
    entityName: 'TablePrice';
    isList: false;
    argsTSName: 'QueryTablePriceFindByIdArgs';
    returnTSName: "Query['TablePriceFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'TablePrice';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'SortSupplierEnum' }
      ];
      name: 'TablePriceFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePricePagination: {
    type: 'TablePricePagination';
    schemaKey: 'TablePricePagination';
    entityName: 'TablePricePagination';
    isList: false;
    argsTSName: 'QueryTablePricePaginationArgs';
    returnTSName: "Query['TablePricePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'TablePricePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyTablePriceInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyTablePriceInput' }
      ];
      name: 'TablePricePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePrice: {
    type: 'TablePrice';
    schemaKey: 'TablePrice';
    entityName: 'TablePrice';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['TablePrice']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'TablePrice';
      args: [];
      name: 'TablePrice';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds: {
    type: '[TablePrice]';
    schemaKey: 'TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds';
    entityName: 'TablePrice';
    isList: true;
    argsTSName: 'QueryTablePriceSearchByRegionsLocationTypeAndNotContainsSomeIdsArgs';
    returnTSName: "Query['TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Busca por tabelas de preço em alguma das regiões listada';
      deprecationReason: null;
      type: '[TablePrice]';
      args: [
        {
          name: 'regions';
          description: 'Lista de regiões usadas no filtro';
          type: '[String]!';
        },
        {
          name: 'locationType';
          description: 'Lista com tipos de local usadas no filtro';
          type: '[String]';
        },
        {
          name: 'ids';
          description: 'Lista de ids que devem ser ignoradas';
          type: '[String]!';
        }
      ];
      name: 'TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePriceAccessories: {
    type: '[TablePriceAccessories]';
    schemaKey: 'TablePriceAccessories';
    entityName: 'TablePriceAccessories';
    isList: true;
    argsTSName: 'QueryTablePriceAccessoriesArgs';
    returnTSName: "Query['TablePriceAccessories']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Busca os acessórios na tabela de preço';
      deprecationReason: null;
      type: '[TablePriceAccessories]';
      args: [{ name: 'tablePrice'; description: null; type: 'String!' }];
      name: 'TablePriceAccessories';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePriceSearchBySapCodeAndDeviceModel: {
    type: 'TablePriceSearchBySapCodeAndDeviceModel';
    schemaKey: 'TablePriceSearchBySapCodeAndDeviceModel';
    entityName: 'TablePriceSearchBySapCodeAndDeviceModel';
    isList: false;
    argsTSName: 'QueryTablePriceSearchBySapCodeAndDeviceModelArgs';
    returnTSName: "Query['TablePriceSearchBySapCodeAndDeviceModel']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Busca tabelas de preço e device filtrando por código SAP e modelo do aparelho';
      deprecationReason: null;
      type: 'TablePriceSearchBySapCodeAndDeviceModel';
      args: [
        { name: 'sapCode'; description: null; type: '[String!]' },
        { name: 'status'; description: null; type: 'String' },
        { name: 'deviceModel'; description: null; type: 'String' },
        { name: 'deviceId'; description: null; type: 'String' },
        {
          name: 'debuggerInfo';
          description: null;
          type: 'DeviceNotificationDebuggerInfoInput';
        }
      ];
      name: 'TablePriceSearchBySapCodeAndDeviceModel';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePriceSearchBySapCode: {
    type: 'listCodes';
    schemaKey: 'TablePriceSearchBySapCode';
    entityName: 'listCodes';
    isList: false;
    argsTSName: 'QueryTablePriceSearchBySapCodeArgs';
    returnTSName: "Query['TablePriceSearchBySapCode']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Busca por tabelas de preço de um código SAP';
      deprecationReason: null;
      type: 'listCodes';
      args: [
        { name: 'sapCode'; description: null; type: '[String]' },
        { name: 'status'; description: null; type: 'String' }
      ];
      name: 'TablePriceSearchBySapCode';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePriceDevices: {
    type: '[TablePriceDevices]';
    schemaKey: 'TablePriceDevices';
    entityName: 'TablePriceDevices';
    isList: true;
    argsTSName: 'QueryTablePriceDevicesArgs';
    returnTSName: "Query['TablePriceDevices']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Busca os preços dos dispositivos na tabela de preço';
      deprecationReason: null;
      type: '[TablePriceDevices]';
      args: [
        { name: 'devices'; description: null; type: '[String]!' },
        { name: 'tablePrice'; description: null; type: 'String!' }
      ];
      name: 'TablePriceDevices';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePriceDeviceBySapCode: {
    type: 'TablePriceDeviceBySapCode';
    schemaKey: 'TablePriceDeviceBySapCode';
    entityName: 'TablePriceDeviceBySapCode';
    isList: false;
    argsTSName: 'QueryTablePriceDeviceBySapCodeArgs';
    returnTSName: "Query['TablePriceDeviceBySapCode']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Busca tabelas de preço e device filtrando por código SAP e modelo do aparelho';
      deprecationReason: null;
      type: 'TablePriceDeviceBySapCode';
      args: [
        { name: 'CNPJ'; description: null; type: 'String!' },
        { name: 'sapCode'; description: null; type: '[String!]' },
        { name: 'deviceModel'; description: null; type: 'String' },
        { name: 'deviceId'; description: null; type: 'String' },
        {
          name: 'debuggerInfo';
          description: null;
          type: 'DeviceNotificationDebuggerInfoInput';
        }
      ];
      name: 'TablePriceDeviceBySapCode';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  TablePricePermissions: {
    type: '[TablePricePermissionsEnum]';
    schemaKey: 'TablePricePermissions';
    entityName: 'TablePricePermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['TablePricePermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[TablePricePermissionsEnum]';
      args: [];
      name: 'TablePricePermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PricingTableFindById: {
    type: 'PricingTable';
    schemaKey: 'PricingTableFindById';
    entityName: 'PricingTable';
    isList: false;
    argsTSName: 'QueryPricingTableFindByIdArgs';
    returnTSName: "Query['PricingTableFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PricingTable';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'SortPricingTableEnum' }
      ];
      name: 'PricingTableFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PricingTablePagination: {
    type: 'PricingTablePagination';
    schemaKey: 'PricingTablePagination';
    entityName: 'PricingTablePagination';
    isList: false;
    argsTSName: 'QueryPricingTablePaginationArgs';
    returnTSName: "Query['PricingTablePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PricingTablePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPricingTableInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyPricingTableInput';
        }
      ];
      name: 'PricingTablePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PricingTableFindMany: {
    type: '[PricingTable]';
    schemaKey: 'PricingTableFindMany';
    entityName: 'PricingTable';
    isList: true;
    argsTSName: 'QueryPricingTableFindManyArgs';
    returnTSName: "Query['PricingTableFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[PricingTable]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPricingTableInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyPricingTableInput';
        }
      ];
      name: 'PricingTableFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LegalTextFindById: {
    type: 'LegalText';
    schemaKey: 'LegalTextFindById';
    entityName: 'LegalText';
    isList: false;
    argsTSName: 'QueryLegalTextFindByIdArgs';
    returnTSName: "Query['LegalTextFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'LegalText';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'LegalTextFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LegalTextPagination: {
    type: 'LegalTextPagination';
    schemaKey: 'LegalTextPagination';
    entityName: 'LegalTextPagination';
    isList: false;
    argsTSName: 'QueryLegalTextPaginationArgs';
    returnTSName: "Query['LegalTextPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'LegalTextPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyLegalTextInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyLegalTextInput' }
      ];
      name: 'LegalTextPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeviceFindMany: {
    type: '[Device]';
    schemaKey: 'DeviceFindMany';
    entityName: 'Device';
    isList: true;
    argsTSName: 'QueryDeviceFindManyArgs';
    returnTSName: "Query['DeviceFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Device]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyDeviceInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyDeviceInput' }
      ];
      name: 'DeviceFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeviceFindOne: {
    type: 'Device';
    schemaKey: 'DeviceFindOne';
    entityName: 'Device';
    isList: false;
    argsTSName: 'QueryDeviceFindOneArgs';
    returnTSName: "Query['DeviceFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Device';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneDeviceInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneDeviceInput' }
      ];
      name: 'DeviceFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeviceFindById: {
    type: 'Device';
    schemaKey: 'DeviceFindById';
    entityName: 'Device';
    isList: false;
    argsTSName: 'QueryDeviceFindByIdArgs';
    returnTSName: "Query['DeviceFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Device';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'DeviceFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DevicePagination: {
    type: 'DevicePagination';
    schemaKey: 'DevicePagination';
    entityName: 'DevicePagination';
    isList: false;
    argsTSName: 'QueryDevicePaginationArgs';
    returnTSName: "Query['DevicePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DevicePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyDeviceInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyDeviceInput' }
      ];
      name: 'DevicePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeviceSearch: {
    type: '[DeviceSearch]';
    schemaKey: 'DeviceSearch';
    entityName: 'DeviceSearch';
    isList: true;
    argsTSName: 'QueryDeviceSearchArgs';
    returnTSName: "Query['DeviceSearch']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca do device';
      deprecationReason: null;
      type: '[DeviceSearch]';
      args: [
        { name: 'search'; description: 'Modelo parcial'; type: 'String' },
        {
          name: 'manufacturers';
          description: 'Filtrar por fabricantes específicos';
          type: '[String]';
        },
        {
          name: 'skipManufacturers';
          description: 'Pular fabricantes específicos';
          type: '[String]';
        }
      ];
      name: 'DeviceSearch';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeviceFindManufacturers: {
    type: '[DeviceFindManufacturers]';
    schemaKey: 'DeviceFindManufacturers';
    entityName: 'DeviceFindManufacturers';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['DeviceFindManufacturers']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Busca por todas fabricantes dos aparelhos cadastrados';
      deprecationReason: null;
      type: '[DeviceFindManufacturers]';
      args: [];
      name: 'DeviceFindManufacturers';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeviceSearchById: {
    type: 'Device';
    schemaKey: 'DeviceSearchById';
    entityName: 'Device';
    isList: false;
    argsTSName: 'QueryDeviceSearchByIdArgs';
    returnTSName: "Query['DeviceSearchById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca do device por ID';
      deprecationReason: null;
      type: 'Device';
      args: [
        { name: 'deviceId'; description: 'ID do device'; type: 'String!' }
      ];
      name: 'DeviceSearchById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeviceBaseDownload: {
    type: 'DeviceBaseDownloadPayload';
    schemaKey: 'DeviceBaseDownload';
    entityName: 'DeviceBaseDownloadPayload';
    isList: false;
    argsTSName: 'QueryDeviceBaseDownloadArgs';
    returnTSName: "Query['DeviceBaseDownload']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DeviceBaseDownloadPayload';
      args: [
        { name: 'filters'; description: null; type: 'JSON' },
        { name: 'isExample'; description: null; type: 'Boolean' }
      ];
      name: 'DeviceBaseDownload';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeviceBaseValidation: {
    type: 'JSON';
    schemaKey: 'DeviceBaseValidation';
    entityName: 'JSON';
    isList: false;
    argsTSName: 'QueryDeviceBaseValidationArgs';
    returnTSName: "Query['DeviceBaseValidation']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'JSON';
      args: [{ name: 'fileId'; description: null; type: 'String!' }];
      name: 'DeviceBaseValidation';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AppDevice: {
    type: 'Device';
    schemaKey: 'AppDevice';
    entityName: 'Device';
    isList: false;
    argsTSName: 'QueryAppDeviceArgs';
    returnTSName: "Query['AppDevice']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Device';
      args: [
        { name: 'CNPJ'; description: null; type: 'String!' },
        { name: 'model'; description: null; type: 'String!' }
      ];
      name: 'AppDevice';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  AppDeviceByManufacturer: {
    type: '[Device]';
    schemaKey: 'AppDeviceByManufacturer';
    entityName: 'Device';
    isList: true;
    argsTSName: 'QueryAppDeviceByManufacturerArgs';
    returnTSName: "Query['AppDeviceByManufacturer']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Device]';
      args: [
        { name: 'CNPJ'; description: null; type: 'String!' },
        { name: 'manufacturer'; description: null; type: 'String!' }
      ];
      name: 'AppDeviceByManufacturer';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DevicePermissions: {
    type: '[DevicePermissionsEnum]';
    schemaKey: 'DevicePermissions';
    entityName: 'DevicePermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['DevicePermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[DevicePermissionsEnum]';
      args: [];
      name: 'DevicePermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureFindMany: {
    type: '[DigitalBrochure]';
    schemaKey: 'DigitalBrochureFindMany';
    entityName: 'DigitalBrochure';
    isList: true;
    argsTSName: 'QueryDigitalBrochureFindManyArgs';
    returnTSName: "Query['DigitalBrochureFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[DigitalBrochure]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyDigitalBrochureInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyDigitalBrochureInput';
        }
      ];
      name: 'DigitalBrochureFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureFindOne: {
    type: 'DigitalBrochure';
    schemaKey: 'DigitalBrochureFindOne';
    entityName: 'DigitalBrochure';
    isList: false;
    argsTSName: 'QueryDigitalBrochureFindOneArgs';
    returnTSName: "Query['DigitalBrochureFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DigitalBrochure';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneDigitalBrochureInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneDigitalBrochureInput';
        }
      ];
      name: 'DigitalBrochureFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureFindById: {
    type: 'DigitalBrochure';
    schemaKey: 'DigitalBrochureFindById';
    entityName: 'DigitalBrochure';
    isList: false;
    argsTSName: 'QueryDigitalBrochureFindByIdArgs';
    returnTSName: "Query['DigitalBrochureFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DigitalBrochure';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'DigitalBrochureFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochurePagination: {
    type: 'DigitalBrochurePagination';
    schemaKey: 'DigitalBrochurePagination';
    entityName: 'DigitalBrochurePagination';
    isList: false;
    argsTSName: 'QueryDigitalBrochurePaginationArgs';
    returnTSName: "Query['DigitalBrochurePagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DigitalBrochurePagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyDigitalBrochureInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyDigitalBrochureInput';
        }
      ];
      name: 'DigitalBrochurePagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureSearchByDDDAndService: {
    type: 'DigitalBrochureSearchByDDDAndService';
    schemaKey: 'DigitalBrochureSearchByDDDAndService';
    entityName: 'DigitalBrochureSearchByDDDAndService';
    isList: false;
    argsTSName: 'QueryDigitalBrochureSearchByDddAndServiceArgs';
    returnTSName: "Query['DigitalBrochureSearchByDDDAndService']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos folhetos digitais';
      deprecationReason: null;
      type: 'DigitalBrochureSearchByDDDAndService';
      args: [
        {
          name: 'filterDDD';
          description: 'DDD da região do folheto digital';
          type: 'String';
        },
        {
          name: 'filterService';
          description: 'Serviço relacionado ao folheto digital';
          type: 'String';
        },
        {
          name: 'perPage';
          description: 'Número de resultados por página';
          type: 'Int';
        },
        { name: 'page'; description: 'Página dos dados'; type: 'Int' }
      ];
      name: 'DigitalBrochureSearchByDDDAndService';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochurePermissions: {
    type: '[DigitalBrochurePermissionsEnum]';
    schemaKey: 'DigitalBrochurePermissions';
    entityName: 'DigitalBrochurePermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['DigitalBrochurePermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[DigitalBrochurePermissionsEnum]';
      args: [];
      name: 'DigitalBrochurePermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CampaignPagination: {
    type: 'CampaignPagination';
    schemaKey: 'CampaignPagination';
    entityName: 'CampaignPagination';
    isList: false;
    argsTSName: 'QueryCampaignPaginationArgs';
    returnTSName: "Query['CampaignPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CampaignPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyCampaignInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyCampaignInput' }
      ];
      name: 'CampaignPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CampaignFindOne: {
    type: 'Campaign';
    schemaKey: 'CampaignFindOne';
    entityName: 'Campaign';
    isList: false;
    argsTSName: 'QueryCampaignFindOneArgs';
    returnTSName: "Query['CampaignFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Campaign';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneCampaignInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneCampaignInput' }
      ];
      name: 'CampaignFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CampaignFindById: {
    type: 'Campaign';
    schemaKey: 'CampaignFindById';
    entityName: 'Campaign';
    isList: false;
    argsTSName: 'QueryCampaignFindByIdArgs';
    returnTSName: "Query['CampaignFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Campaign';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'SortCampaignEnum' }
      ];
      name: 'CampaignFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CampaignSearchByTitleDescriptionOrStatus: {
    type: 'CampaignSearchByTitleDescriptionOrStatus';
    schemaKey: 'CampaignSearchByTitleDescriptionOrStatus';
    entityName: 'CampaignSearchByTitleDescriptionOrStatus';
    isList: false;
    argsTSName: 'QueryCampaignSearchByTitleDescriptionOrStatusArgs';
    returnTSName: "Query['CampaignSearchByTitleDescriptionOrStatus']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca das campanhas por titulo e descricao';
      deprecationReason: null;
      type: 'CampaignSearchByTitleDescriptionOrStatus';
      args: [
        {
          name: 'title';
          description: 'Titulo ou descrição das campanhas';
          type: 'String';
        },
        {
          name: 'archived';
          description: 'Se a campanha está arquivada';
          type: 'Boolean';
        },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' },
        { name: 'page'; description: 'Página atual'; type: 'Int' }
      ];
      name: 'CampaignSearchByTitleDescriptionOrStatus';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CampaignSearchByTitleOrDescription: {
    type: 'CampaignSearchByTitleOrDescription';
    schemaKey: 'CampaignSearchByTitleOrDescription';
    entityName: 'CampaignSearchByTitleOrDescription';
    isList: false;
    argsTSName: 'QueryCampaignSearchByTitleOrDescriptionArgs';
    returnTSName: "Query['CampaignSearchByTitleOrDescription']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca das campanhas por titulo e descricao';
      deprecationReason: null;
      type: 'CampaignSearchByTitleOrDescription';
      args: [
        {
          name: 'title';
          description: 'Titulo ou descrição das campanhas';
          type: 'String';
        },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' },
        { name: 'page'; description: 'Página atual'; type: 'Int' }
      ];
      name: 'CampaignSearchByTitleOrDescription';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  CampaignPermissions: {
    type: '[CampaignPermissionsEnum]';
    schemaKey: 'CampaignPermissions';
    entityName: 'CampaignPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['CampaignPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[CampaignPermissionsEnum]';
      args: [];
      name: 'CampaignPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LayoutPagination: {
    type: 'LayoutPagination';
    schemaKey: 'LayoutPagination';
    entityName: 'LayoutPagination';
    isList: false;
    argsTSName: 'QueryLayoutPaginationArgs';
    returnTSName: "Query['LayoutPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'LayoutPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyLayoutInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyLayoutInput' }
      ];
      name: 'LayoutPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LayoutFindOne: {
    type: 'Layout';
    schemaKey: 'LayoutFindOne';
    entityName: 'Layout';
    isList: false;
    argsTSName: 'QueryLayoutFindOneArgs';
    returnTSName: "Query['LayoutFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Layout';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneLayoutInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneLayoutInput' }
      ];
      name: 'LayoutFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LayoutFindMany: {
    type: '[Layout]';
    schemaKey: 'LayoutFindMany';
    entityName: 'Layout';
    isList: true;
    argsTSName: 'QueryLayoutFindManyArgs';
    returnTSName: "Query['LayoutFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Layout]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyLayoutInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyLayoutInput' }
      ];
      name: 'LayoutFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LayoutFindByIds: {
    type: '[Layout]';
    schemaKey: 'LayoutFindByIds';
    entityName: 'Layout';
    isList: true;
    argsTSName: 'QueryLayoutFindByIdsArgs';
    returnTSName: "Query['LayoutFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Layout]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindByIdsLayoutInput' }
      ];
      name: 'LayoutFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LayoutSearchByName: {
    type: 'LayoutSearchByName';
    schemaKey: 'LayoutSearchByName';
    entityName: 'LayoutSearchByName';
    isList: false;
    argsTSName: 'QueryLayoutSearchByNameArgs';
    returnTSName: "Query['LayoutSearchByName']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos layouts por nome';
      deprecationReason: null;
      type: 'LayoutSearchByName';
      args: [
        { name: 'name'; description: 'Nome dos layouts'; type: 'String' },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' },
        { name: 'page'; description: 'Página atual'; type: 'Int' }
      ];
      name: 'LayoutSearchByName';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LayoutSearchByNameAndContainsLpuIntervalQty: {
    type: 'LayoutSearchByNameAndContainsLpuIntervalQty';
    schemaKey: 'LayoutSearchByNameAndContainsLpuIntervalQty';
    entityName: 'LayoutSearchByNameAndContainsLpuIntervalQty';
    isList: false;
    argsTSName: 'QueryLayoutSearchByNameAndContainsLpuIntervalQtyArgs';
    returnTSName: "Query['LayoutSearchByNameAndContainsLpuIntervalQty']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca dos layouts por nome';
      deprecationReason: null;
      type: 'LayoutSearchByNameAndContainsLpuIntervalQty';
      args: [
        { name: 'name'; description: 'Nome dos layouts'; type: 'String' },
        {
          name: 'containsIntervalQty';
          description: 'Contain intervao de quantidade nos layouts';
          type: 'Boolean';
        },
        { name: 'perPage'; description: 'Resultados por página'; type: 'Int' },
        { name: 'page'; description: 'Página atual'; type: 'Int' }
      ];
      name: 'LayoutSearchByNameAndContainsLpuIntervalQty';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LayoutSearchContentByIds: {
    type: 'LayoutContentListPayload';
    schemaKey: 'LayoutSearchContentByIds';
    entityName: 'LayoutContentListPayload';
    isList: false;
    argsTSName: 'QueryLayoutSearchContentByIdsArgs';
    returnTSName: "Query['LayoutSearchContentByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: 'Executa a busca de conteúdo dos layouts por data';
      deprecationReason: null;
      type: 'LayoutContentListPayload';
      args: [
        {
          name: 'ids';
          description: 'Lista de dados dos layouts';
          type: '[String]';
        }
      ];
      name: 'LayoutSearchContentByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  LayoutPermissions: {
    type: '[LayoutPermissionsEnum]';
    schemaKey: 'LayoutPermissions';
    entityName: 'LayoutPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['LayoutPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[LayoutPermissionsEnum]';
      args: [];
      name: 'LayoutPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionalBudgetFindOne: {
    type: 'RegionalBudget';
    schemaKey: 'RegionalBudgetFindOne';
    entityName: 'RegionalBudget';
    isList: false;
    argsTSName: 'QueryRegionalBudgetFindOneArgs';
    returnTSName: "Query['RegionalBudgetFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionalBudget';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneRegionalBudgetInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneRegionalBudgetInput';
        }
      ];
      name: 'RegionalBudgetFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionalBudgetFindById: {
    type: 'RegionalBudget';
    schemaKey: 'RegionalBudgetFindById';
    entityName: 'RegionalBudget';
    isList: false;
    argsTSName: 'QueryRegionalBudgetFindByIdArgs';
    returnTSName: "Query['RegionalBudgetFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionalBudget';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'RegionalBudgetFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionalBudgetPagination: {
    type: 'RegionalBudgetPagination';
    schemaKey: 'RegionalBudgetPagination';
    entityName: 'RegionalBudgetPagination';
    isList: false;
    argsTSName: 'QueryRegionalBudgetPaginationArgs';
    returnTSName: "Query['RegionalBudgetPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionalBudgetPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRegionalBudgetInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyRegionalBudgetInput';
        }
      ];
      name: 'RegionalBudgetPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductionFindById: {
    type: 'Production';
    schemaKey: 'ProductionFindById';
    entityName: 'Production';
    isList: false;
    argsTSName: 'QueryProductionFindByIdArgs';
    returnTSName: "Query['ProductionFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Production';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'SortProductionEnum' }
      ];
      name: 'ProductionFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductionPagination: {
    type: 'ProductionPagination';
    schemaKey: 'ProductionPagination';
    entityName: 'ProductionPagination';
    isList: false;
    argsTSName: 'QueryProductionPaginationArgs';
    returnTSName: "Query['ProductionPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ProductionPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyProductionInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyProductionInput' }
      ];
      name: 'ProductionPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductionReport: {
    type: 'ProductionReportPayload';
    schemaKey: 'ProductionReport';
    entityName: 'ProductionReportPayload';
    isList: false;
    argsTSName: 'QueryProductionReportArgs';
    returnTSName: "Query['ProductionReport']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ProductionReportPayload';
      args: [{ name: 'productionId'; description: null; type: 'String' }];
      name: 'ProductionReport';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductionDeliveryReport: {
    type: 'ProductionReportPayload';
    schemaKey: 'ProductionDeliveryReport';
    entityName: 'ProductionReportPayload';
    isList: false;
    argsTSName: 'QueryProductionDeliveryReportArgs';
    returnTSName: "Query['ProductionDeliveryReport']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ProductionReportPayload';
      args: [{ name: 'productionId'; description: null; type: 'String' }];
      name: 'ProductionDeliveryReport';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProductionPermissions: {
    type: '[ProductionPermissionsEnum]';
    schemaKey: 'ProductionPermissions';
    entityName: 'ProductionPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['ProductionPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[ProductionPermissionsEnum]';
      args: [];
      name: 'ProductionPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  BudgetFindOne: {
    type: 'Budget';
    schemaKey: 'BudgetFindOne';
    entityName: 'Budget';
    isList: false;
    argsTSName: 'QueryBudgetFindOneArgs';
    returnTSName: "Query['BudgetFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Budget';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneBudgetInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneBudgetInput' }
      ];
      name: 'BudgetFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  BudgetFindById: {
    type: 'Budget';
    schemaKey: 'BudgetFindById';
    entityName: 'Budget';
    isList: false;
    argsTSName: 'QueryBudgetFindByIdArgs';
    returnTSName: "Query['BudgetFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Budget';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'BudgetFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  BudgetPagination: {
    type: 'BudgetPagination';
    schemaKey: 'BudgetPagination';
    entityName: 'BudgetPagination';
    isList: false;
    argsTSName: 'QueryBudgetPaginationArgs';
    returnTSName: "Query['BudgetPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'BudgetPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyBudgetInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyBudgetInput' }
      ];
      name: 'BudgetPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  BudgetPermissions: {
    type: '[BudgetPermissionsEnum]';
    schemaKey: 'BudgetPermissions';
    entityName: 'BudgetPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['BudgetPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[BudgetPermissionsEnum]';
      args: [];
      name: 'BudgetPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SupplierDeliveryPagination: {
    type: 'SupplierDeliveryPagination';
    schemaKey: 'SupplierDeliveryPagination';
    entityName: 'SupplierDeliveryPagination';
    isList: false;
    argsTSName: 'QuerySupplierDeliveryPaginationArgs';
    returnTSName: "Query['SupplierDeliveryPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'SupplierDeliveryPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManySupplierDeliveryInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManySupplierDeliveryInput';
        }
      ];
      name: 'SupplierDeliveryPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  SupplierDeliveryFindMany: {
    type: '[SupplierDelivery]';
    schemaKey: 'SupplierDeliveryFindMany';
    entityName: 'SupplierDelivery';
    isList: true;
    argsTSName: 'QuerySupplierDeliveryFindManyArgs';
    returnTSName: "Query['SupplierDeliveryFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[SupplierDelivery]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManySupplierDeliveryInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManySupplierDeliveryInput';
        }
      ];
      name: 'SupplierDeliveryFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  InventoryItemFindById: {
    type: 'InventoryItem';
    schemaKey: 'InventoryItemFindById';
    entityName: 'InventoryItem';
    isList: false;
    argsTSName: 'QueryInventoryItemFindByIdArgs';
    returnTSName: "Query['InventoryItemFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'InventoryItem';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'InventoryItemFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  InventoryItemFindOne: {
    type: 'InventoryItem';
    schemaKey: 'InventoryItemFindOne';
    entityName: 'InventoryItem';
    isList: false;
    argsTSName: 'QueryInventoryItemFindOneArgs';
    returnTSName: "Query['InventoryItemFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'InventoryItem';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneInventoryItemInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneInventoryItemInput';
        }
      ];
      name: 'InventoryItemFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  InventoryItemPagination: {
    type: 'InventoryItemPagination';
    schemaKey: 'InventoryItemPagination';
    entityName: 'InventoryItemPagination';
    isList: false;
    argsTSName: 'QueryInventoryItemPaginationArgs';
    returnTSName: "Query['InventoryItemPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'InventoryItemPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyInventoryItemInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyInventoryItemInput';
        }
      ];
      name: 'InventoryItemPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  InventoryItemFindMany: {
    type: '[InventoryItem]';
    schemaKey: 'InventoryItemFindMany';
    entityName: 'InventoryItem';
    isList: true;
    argsTSName: 'QueryInventoryItemFindManyArgs';
    returnTSName: "Query['InventoryItemFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[InventoryItem]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyInventoryItemInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyInventoryItemInput';
        }
      ];
      name: 'InventoryItemFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  InventoryWithDrawPagination: {
    type: 'InventoryWithDrawPagination';
    schemaKey: 'InventoryWithDrawPagination';
    entityName: 'InventoryWithDrawPagination';
    isList: false;
    argsTSName: 'QueryInventoryWithDrawPaginationArgs';
    returnTSName: "Query['InventoryWithDrawPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'InventoryWithDrawPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyInventoryWithDrawInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyInventoryWithDrawInput';
        }
      ];
      name: 'InventoryWithDrawPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  InventoryWithDrawFindById: {
    type: 'InventoryWithDraw';
    schemaKey: 'InventoryWithDrawFindById';
    entityName: 'InventoryWithDraw';
    isList: false;
    argsTSName: 'QueryInventoryWithDrawFindByIdArgs';
    returnTSName: "Query['InventoryWithDrawFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'InventoryWithDraw';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'InventoryWithDrawFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeliveryOrderFindById: {
    type: 'DeliveryOrder';
    schemaKey: 'DeliveryOrderFindById';
    entityName: 'DeliveryOrder';
    isList: false;
    argsTSName: 'QueryDeliveryOrderFindByIdArgs';
    returnTSName: "Query['DeliveryOrderFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DeliveryOrder';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'sort'; description: null; type: 'SortDeliveryOrderEnum' }
      ];
      name: 'DeliveryOrderFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeliveryOrderPagination: {
    type: 'DeliveryOrderPagination';
    schemaKey: 'DeliveryOrderPagination';
    entityName: 'DeliveryOrderPagination';
    isList: false;
    argsTSName: 'QueryDeliveryOrderPaginationArgs';
    returnTSName: "Query['DeliveryOrderPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DeliveryOrderPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyDeliveryOrderInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyDeliveryOrderInput';
        }
      ];
      name: 'DeliveryOrderPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeliveryOrderValidation: {
    type: 'DeliveryOrderValidatedReport';
    schemaKey: 'DeliveryOrderValidation';
    entityName: 'DeliveryOrderValidatedReport';
    isList: false;
    argsTSName: 'QueryDeliveryOrderValidationArgs';
    returnTSName: "Query['DeliveryOrderValidation']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DeliveryOrderValidatedReport';
      args: [{ name: 'orderIds'; description: null; type: '[String]' }];
      name: 'DeliveryOrderValidation';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeliveryOrderExecution: {
    type: '[DeliveryOrderRecords]';
    schemaKey: 'DeliveryOrderExecution';
    entityName: 'DeliveryOrderRecords';
    isList: true;
    argsTSName: 'QueryDeliveryOrderExecutionArgs';
    returnTSName: "Query['DeliveryOrderExecution']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[DeliveryOrderRecords]';
      args: [{ name: 'orderIds'; description: null; type: '[String]' }];
      name: 'DeliveryOrderExecution';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserLogisticsPermissions: {
    type: '[LogisticsPermissionsEnum]';
    schemaKey: 'UserLogisticsPermissions';
    entityName: 'LogisticsPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['UserLogisticsPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[LogisticsPermissionsEnum]';
      args: [];
      name: 'UserLogisticsPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MetadataFindOne: {
    type: 'Metadata';
    schemaKey: 'MetadataFindOne';
    entityName: 'Metadata';
    isList: false;
    argsTSName: 'QueryMetadataFindOneArgs';
    returnTSName: "Query['MetadataFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Metadata';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneMetadataInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneMetadataInput' }
      ];
      name: 'MetadataFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MetadataFindById: {
    type: 'Metadata';
    schemaKey: 'MetadataFindById';
    entityName: 'Metadata';
    isList: false;
    argsTSName: 'QueryMetadataFindByIdArgs';
    returnTSName: "Query['MetadataFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Metadata';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'MetadataFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivatorFindById: {
    type: 'Positivator';
    schemaKey: 'PositivatorFindById';
    entityName: 'Positivator';
    isList: false;
    argsTSName: 'QueryPositivatorFindByIdArgs';
    returnTSName: "Query['PositivatorFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Positivator';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'PositivatorFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivatorFindMany: {
    type: '[Positivator]';
    schemaKey: 'PositivatorFindMany';
    entityName: 'Positivator';
    isList: true;
    argsTSName: 'QueryPositivatorFindManyArgs';
    returnTSName: "Query['PositivatorFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Positivator]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPositivatorInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyPositivatorInput';
        }
      ];
      name: 'PositivatorFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivatorPagination: {
    type: 'PositivatorPagination';
    schemaKey: 'PositivatorPagination';
    entityName: 'PositivatorPagination';
    isList: false;
    argsTSName: 'QueryPositivatorPaginationArgs';
    returnTSName: "Query['PositivatorPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PositivatorPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPositivatorInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyPositivatorInput';
        }
      ];
      name: 'PositivatorPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlacePositivators: {
    type: 'PlacePositivatorsPayload';
    schemaKey: 'PlacePositivators';
    entityName: 'PlacePositivatorsPayload';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['PlacePositivators']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PlacePositivatorsPayload';
      args: [];
      name: 'PlacePositivators';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivationSumary: {
    type: 'JSON';
    schemaKey: 'PositivationSumary';
    entityName: 'JSON';
    isList: false;
    argsTSName: 'QueryPositivationSumaryArgs';
    returnTSName: "Query['PositivationSumary']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'JSON';
      args: [
        { name: 'region'; description: null; type: 'String' },
        { name: 'type'; description: null; type: 'String' },
        { name: 'initialDate'; description: null; type: 'String' },
        { name: 'finishDate'; description: null; type: 'String' }
      ];
      name: 'PositivationSumary';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlacePositivationPagination: {
    type: 'PlacePositivationPagination';
    schemaKey: 'PlacePositivationPagination';
    entityName: 'PlacePositivationPagination';
    isList: false;
    argsTSName: 'QueryPlacePositivationPaginationArgs';
    returnTSName: "Query['PlacePositivationPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'PlacePositivationPagination';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int' },
        { name: 'filter'; description: null; type: 'JSON' },
        { name: 'sort'; description: null; type: 'String' }
      ];
      name: 'PlacePositivationPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivationReportByGroup: {
    type: 'BaseDownload';
    schemaKey: 'PositivationReportByGroup';
    entityName: 'BaseDownload';
    isList: false;
    argsTSName: 'QueryPositivationReportByGroupArgs';
    returnTSName: "Query['PositivationReportByGroup']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'BaseDownload';
      args: [
        { name: 'initialDate'; description: null; type: 'String' },
        { name: 'finishDate'; description: null; type: 'String' }
      ];
      name: 'PositivationReportByGroup';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivationReportByItem: {
    type: 'BaseDownload';
    schemaKey: 'PositivationReportByItem';
    entityName: 'BaseDownload';
    isList: false;
    argsTSName: 'QueryPositivationReportByItemArgs';
    returnTSName: "Query['PositivationReportByItem']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'BaseDownload';
      args: [
        { name: 'initialDate'; description: null; type: 'String' },
        { name: 'finishDate'; description: null; type: 'String' }
      ];
      name: 'PositivationReportByItem';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivationReportByPlace: {
    type: 'BaseDownload';
    schemaKey: 'PositivationReportByPlace';
    entityName: 'BaseDownload';
    isList: false;
    argsTSName: 'QueryPositivationReportByPlaceArgs';
    returnTSName: "Query['PositivationReportByPlace']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'BaseDownload';
      args: [
        { name: 'initialDate'; description: null; type: 'String' },
        { name: 'finishDate'; description: null; type: 'String' }
      ];
      name: 'PositivationReportByPlace';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivatorPermissions: {
    type: '[PositivatorPermissionsEnum]';
    schemaKey: 'PositivatorPermissions';
    entityName: 'PositivatorPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['PositivatorPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[PositivatorPermissionsEnum]';
      args: [];
      name: 'PositivatorPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivatorItemFindById: {
    type: 'PositivatorItem';
    schemaKey: 'PositivatorItemFindById';
    entityName: 'PositivatorItem';
    isList: false;
    argsTSName: 'QueryPositivatorItemFindByIdArgs';
    returnTSName: "Query['PositivatorItemFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PositivatorItem';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'PositivatorItemFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivatorItemPagination: {
    type: 'PositivatorItemPagination';
    schemaKey: 'PositivatorItemPagination';
    entityName: 'PositivatorItemPagination';
    isList: false;
    argsTSName: 'QueryPositivatorItemPaginationArgs';
    returnTSName: "Query['PositivatorItemPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PositivatorItemPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPositivatorItemInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyPositivatorItemInput';
        }
      ];
      name: 'PositivatorItemPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PositivatorItemFindMany: {
    type: '[PositivatorItem]';
    schemaKey: 'PositivatorItemFindMany';
    entityName: 'PositivatorItem';
    isList: true;
    argsTSName: 'QueryPositivatorItemFindManyArgs';
    returnTSName: "Query['PositivatorItemFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[PositivatorItem]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPositivatorItemInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyPositivatorItemInput';
        }
      ];
      name: 'PositivatorItemFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DeviceNotificationPagination: {
    type: 'DeviceNotificationPagination';
    schemaKey: 'DeviceNotificationPagination';
    entityName: 'DeviceNotificationPagination';
    isList: false;
    argsTSName: 'QueryDeviceNotificationPaginationArgs';
    returnTSName: "Query['DeviceNotificationPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DeviceNotificationPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyDeviceNotificationInput';
          defaultValue: { includeSolved: false };
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyDeviceNotificationInput';
        }
      ];
      name: 'DeviceNotificationPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserMerchanPagination: {
    type: 'UserMerchanPagination';
    schemaKey: 'UserMerchanPagination';
    entityName: 'UserMerchanPagination';
    isList: false;
    argsTSName: 'QueryUserMerchanPaginationArgs';
    returnTSName: "Query['UserMerchanPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserMerchanPagination';
      args: [
        { name: 'page'; description: null; type: 'Int' },
        { name: 'perPage'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int' },
        { name: 'filter'; description: null; type: 'FilterFindManyUserInput' },
        { name: 'sort'; description: null; type: 'SortFindManyUserInput' }
      ];
      name: 'UserMerchanPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UsersBaseDownload: {
    type: 'BaseDownload';
    schemaKey: 'UsersBaseDownload';
    entityName: 'BaseDownload';
    isList: false;
    argsTSName: 'QueryUsersBaseDownloadArgs';
    returnTSName: "Query['UsersBaseDownload']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'BaseDownload';
      args: [
        { name: 'filters'; description: null; type: 'JSON' },
        { name: 'isExample'; description: null; type: 'Boolean' }
      ];
      name: 'UsersBaseDownload';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MerchanUserCheck: {
    type: 'MerchanUserCheck';
    schemaKey: 'MerchanUserCheck';
    entityName: 'MerchanUserCheck';
    isList: false;
    argsTSName: 'QueryMerchanUserCheckArgs';
    returnTSName: "Query['MerchanUserCheck']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MerchanUserCheck';
      args: [
        { name: 'CPF'; description: null; type: 'String' },
        { name: 'CNPJ'; description: null; type: 'String' }
      ];
      name: 'MerchanUserCheck';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  UserMerchanPermissions: {
    type: '[UserMerchanPermissionsEnum]';
    schemaKey: 'UserMerchanPermissions';
    entityName: 'UserMerchanPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['UserMerchanPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[UserMerchanPermissionsEnum]';
      args: [];
      name: 'UserMerchanPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceAnalyticsPagination: {
    type: 'PlaceAnalyticsPagination';
    schemaKey: 'PlaceAnalyticsPagination';
    entityName: 'PlaceAnalyticsPagination';
    isList: false;
    argsTSName: 'QueryPlaceAnalyticsPaginationArgs';
    returnTSName: "Query['PlaceAnalyticsPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PlaceAnalyticsPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPlaceAnalyticsInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyPlaceAnalyticsInput';
        }
      ];
      name: 'PlaceAnalyticsPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceAnalyticsFindOne: {
    type: 'PlaceAnalytics';
    schemaKey: 'PlaceAnalyticsFindOne';
    entityName: 'PlaceAnalytics';
    isList: false;
    argsTSName: 'QueryPlaceAnalyticsFindOneArgs';
    returnTSName: "Query['PlaceAnalyticsFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PlaceAnalytics';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOnePlaceAnalyticsInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOnePlaceAnalyticsInput';
        }
      ];
      name: 'PlaceAnalyticsFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceAnalyticsFindByIds: {
    type: '[PlaceAnalytics]';
    schemaKey: 'PlaceAnalyticsFindByIds';
    entityName: 'PlaceAnalytics';
    isList: true;
    argsTSName: 'QueryPlaceAnalyticsFindByIdsArgs';
    returnTSName: "Query['PlaceAnalyticsFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[PlaceAnalytics]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindByIdsPlaceAnalyticsInput';
        }
      ];
      name: 'PlaceAnalyticsFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceAnalyticsFindById: {
    type: 'PlaceAnalytics';
    schemaKey: 'PlaceAnalyticsFindById';
    entityName: 'PlaceAnalytics';
    isList: false;
    argsTSName: 'QueryPlaceAnalyticsFindByIdArgs';
    returnTSName: "Query['PlaceAnalyticsFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PlaceAnalytics';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'PlaceAnalyticsFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PlaceAnalyticsFindMany: {
    type: '[PlaceAnalytics]';
    schemaKey: 'PlaceAnalyticsFindMany';
    entityName: 'PlaceAnalytics';
    isList: true;
    argsTSName: 'QueryPlaceAnalyticsFindManyArgs';
    returnTSName: "Query['PlaceAnalyticsFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[PlaceAnalytics]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPlaceAnalyticsInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyPlaceAnalyticsInput';
        }
      ];
      name: 'PlaceAnalyticsFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  StructuralFindMany: {
    type: '[Structural]';
    schemaKey: 'StructuralFindMany';
    entityName: 'Structural';
    isList: true;
    argsTSName: 'QueryStructuralFindManyArgs';
    returnTSName: "Query['StructuralFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[Structural]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyStructuralInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        { name: 'sort'; description: null; type: 'SortFindManyStructuralInput' }
      ];
      name: 'StructuralFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  StructuralFindOne: {
    type: 'Structural';
    schemaKey: 'StructuralFindOne';
    entityName: 'Structural';
    isList: false;
    argsTSName: 'QueryStructuralFindOneArgs';
    returnTSName: "Query['StructuralFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Structural';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneStructuralInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'sort'; description: null; type: 'SortFindOneStructuralInput' }
      ];
      name: 'StructuralFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  StructuralFindById: {
    type: 'Structural';
    schemaKey: 'StructuralFindById';
    entityName: 'Structural';
    isList: false;
    argsTSName: 'QueryStructuralFindByIdArgs';
    returnTSName: "Query['StructuralFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Structural';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'SortSupplierEnum' }
      ];
      name: 'StructuralFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  StructuralPagination: {
    type: 'StructuralPagination';
    schemaKey: 'StructuralPagination';
    entityName: 'StructuralPagination';
    isList: false;
    argsTSName: 'QueryStructuralPaginationArgs';
    returnTSName: "Query['StructuralPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'StructuralPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyStructuralInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyStructuralInput' }
      ];
      name: 'StructuralPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  Structural: {
    type: 'Structural';
    schemaKey: 'Structural';
    entityName: 'Structural';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['Structural']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Structural';
      args: [];
      name: 'Structural';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  StructuralPermissions: {
    type: '[StructuralPermissionsEnum]';
    schemaKey: 'StructuralPermissions';
    entityName: 'StructuralPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['StructuralPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[StructuralPermissionsEnum]';
      args: [];
      name: 'StructuralPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  ProjectSLA: {
    type: 'ProjectSLAPayload';
    schemaKey: 'ProjectSLA';
    entityName: 'ProjectSLAPayload';
    isList: false;
    argsTSName: 'QueryProjectSlaArgs';
    returnTSName: "Query['ProjectSLA']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ProjectSLAPayload';
      args: [
        {
          name: 'technicalVisit';
          description: 'Haverá visita técnica?';
          type: 'Boolean';
        },
        {
          name: 'projetoComplementar';
          description: 'Haverá projeto complementar?';
          type: 'Boolean';
        }
      ];
      name: 'ProjectSLA';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureAnalyticsPagination: {
    type: 'DigitalBrochureAnalyticsPagination';
    schemaKey: 'DigitalBrochureAnalyticsPagination';
    entityName: 'DigitalBrochureAnalyticsPagination';
    isList: false;
    argsTSName: 'QueryDigitalBrochureAnalyticsPaginationArgs';
    returnTSName: "Query['DigitalBrochureAnalyticsPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DigitalBrochureAnalyticsPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyDigitalBrochureAnalyticsInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyDigitalBrochureAnalyticsInput';
        }
      ];
      name: 'DigitalBrochureAnalyticsPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureAnalyticsFindMany: {
    type: '[DigitalBrochureAnalytics]';
    schemaKey: 'DigitalBrochureAnalyticsFindMany';
    entityName: 'DigitalBrochureAnalytics';
    isList: true;
    argsTSName: 'QueryDigitalBrochureAnalyticsFindManyArgs';
    returnTSName: "Query['DigitalBrochureAnalyticsFindMany']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[DigitalBrochureAnalytics]';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyDigitalBrochureAnalyticsInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindManyDigitalBrochureAnalyticsInput';
        }
      ];
      name: 'DigitalBrochureAnalyticsFindMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureAnalyticsFindById: {
    type: 'DigitalBrochureAnalytics';
    schemaKey: 'DigitalBrochureAnalyticsFindById';
    entityName: 'DigitalBrochureAnalytics';
    isList: false;
    argsTSName: 'QueryDigitalBrochureAnalyticsFindByIdArgs';
    returnTSName: "Query['DigitalBrochureAnalyticsFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DigitalBrochureAnalytics';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'DigitalBrochureAnalyticsFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureAnalyticsFindByIds: {
    type: '[DigitalBrochureAnalytics]';
    schemaKey: 'DigitalBrochureAnalyticsFindByIds';
    entityName: 'DigitalBrochureAnalytics';
    isList: true;
    argsTSName: 'QueryDigitalBrochureAnalyticsFindByIdsArgs';
    returnTSName: "Query['DigitalBrochureAnalyticsFindByIds']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[DigitalBrochureAnalytics]';
      args: [
        { name: '_ids'; description: null; type: '[MongoID]!' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 },
        {
          name: 'sort';
          description: null;
          type: 'SortFindByIdsDigitalBrochureAnalyticsInput';
        }
      ];
      name: 'DigitalBrochureAnalyticsFindByIds';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureAnalyticsFindOne: {
    type: 'DigitalBrochureAnalytics';
    schemaKey: 'DigitalBrochureAnalyticsFindOne';
    entityName: 'DigitalBrochureAnalytics';
    isList: false;
    argsTSName: 'QueryDigitalBrochureAnalyticsFindOneArgs';
    returnTSName: "Query['DigitalBrochureAnalyticsFindOne']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DigitalBrochureAnalytics';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindOneDigitalBrochureAnalyticsInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        {
          name: 'sort';
          description: null;
          type: 'SortFindOneDigitalBrochureAnalyticsInput';
        }
      ];
      name: 'DigitalBrochureAnalyticsFindOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureAnalyticsPermissions: {
    type: '[DigitalBrochureAnalyticsPermissionsEnum]';
    schemaKey: 'DigitalBrochureAnalyticsPermissions';
    entityName: 'DigitalBrochureAnalyticsPermissionsEnum';
    isList: true;
    argsTSName: '{}';
    returnTSName: "Query['DigitalBrochureAnalyticsPermissions']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[DigitalBrochureAnalyticsPermissionsEnum]';
      args: [];
      name: 'DigitalBrochureAnalyticsPermissions';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  DigitalBrochureAnalyticsCount: {
    type: 'Int!';
    schemaKey: 'DigitalBrochureAnalyticsCount';
    entityName: 'Int';
    isList: false;
    argsTSName: 'QueryDigitalBrochureAnalyticsCountArgs';
    returnTSName: "Query['DigitalBrochureAnalyticsCount']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Int!';
      args: [{ name: '_id'; description: null; type: 'String!' }];
      name: 'DigitalBrochureAnalyticsCount';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'query';
  };

  AreaLojaPagination: {
    type: 'AreaLojaPagination';
    schemaKey: 'AreaLojaPagination';
    entityName: 'AreaLojaPagination';
    isList: false;
    argsTSName: 'QueryAreaLojaPaginationArgs';
    returnTSName: "Query['AreaLojaPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'AreaLojaPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyAreaLojaInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyAreaLojaInput' }
      ];
      name: 'AreaLojaPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegraMapaPagination: {
    type: 'RegraMapaPagination';
    schemaKey: 'RegraMapaPagination';
    entityName: 'RegraMapaPagination';
    isList: false;
    argsTSName: 'QueryRegraMapaPaginationArgs';
    returnTSName: "Query['RegraMapaPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegraMapaPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyRegraMapaInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyRegraMapaInput' }
      ];
      name: 'RegraMapaPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  FormatoPagination: {
    type: 'FormatoPagination';
    schemaKey: 'FormatoPagination';
    entityName: 'FormatoPagination';
    isList: false;
    argsTSName: 'QueryFormatoPaginationArgs';
    returnTSName: "Query['FormatoPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'FormatoPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyFormatoInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyFormatoInput' }
      ];
      name: 'FormatoPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  PecaMapaPagination: {
    type: 'PecaMapaPagination';
    schemaKey: 'PecaMapaPagination';
    entityName: 'PecaMapaPagination';
    isList: false;
    argsTSName: 'QueryPecaMapaPaginationArgs';
    returnTSName: "Query['PecaMapaPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'PecaMapaPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyPecaMapaInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyPecaMapaInput' }
      ];
      name: 'PecaMapaPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MapaPDVPagination: {
    type: 'MapaPDVPagination';
    schemaKey: 'MapaPDVPagination';
    entityName: 'MapaPDVPagination';
    isList: false;
    argsTSName: 'QueryMapaPdvPaginationArgs';
    returnTSName: "Query['MapaPDVPagination']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MapaPDVPagination';
      args: [
        {
          name: 'page';
          description: 'Page number for displaying';
          type: 'Int';
        },
        { name: 'perPage'; description: ''; type: 'Int'; defaultValue: 20 },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterFindManyMapaPDVInput';
        },
        { name: 'sort'; description: null; type: 'SortFindManyMapaPDVInput' }
      ];
      name: 'MapaPDVPagination';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MapaPDVFindById: {
    type: 'MapaPDV';
    schemaKey: 'MapaPDVFindById';
    entityName: 'MapaPDV';
    isList: false;
    argsTSName: 'QueryMapaPdvFindByIdArgs';
    returnTSName: "Query['MapaPDVFindById']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MapaPDV';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'MapaPDVFindById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  MyMapaPDV: {
    type: 'MyMapaPDVPayload';
    schemaKey: 'MyMapaPDV';
    entityName: 'MyMapaPDVPayload';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Query['MyMapaPDV']";
    isMutation: false;
    isQuery: true;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'MyMapaPDVPayload';
      args: [];
      name: 'MyMapaPDV';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'query';
  };

  RegionRemoveOne: {
    type: 'RemoveOneRegionPayload';
    schemaKey: 'RegionRemoveOne';
    entityName: 'RemoveOneRegionPayload';
    isList: false;
    argsTSName: 'MutationRegionRemoveOneArgs';
    returnTSName: "Mutation['RegionRemoveOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveOneRegionPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveOneRegionInput';
        },
        { name: 'sort'; description: null; type: 'SortRemoveOneRegionInput' }
      ];
      name: 'RegionRemoveOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserContentPermissionToggle: {
    type: 'Boolean';
    schemaKey: 'UserContentPermissionToggle';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserContentPermissionToggleArgs';
    returnTSName: "Mutation['UserContentPermissionToggle']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'userId'; description: null; type: 'String!' },
        { name: 'roleGroups'; description: null; type: '[String]!' },
        { name: 'regions'; description: null; type: '[String]!' },
        { name: 'contentTypes'; description: null; type: '[String]!' },
        { name: 'viewOtherUsersContent'; description: null; type: 'Boolean' }
      ];
      name: 'UserContentPermissionToggle';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserUpdateOne: {
    type: 'UpdateOneUserPayload';
    schemaKey: 'UserUpdateOne';
    entityName: 'UpdateOneUserPayload';
    isList: false;
    argsTSName: 'MutationUserUpdateOneArgs';
    returnTSName: "Mutation['UserUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneUserPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneUserInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneUserInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneUserInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'UserUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserRemoveById: {
    type: 'RemoveByIdUserPayload';
    schemaKey: 'UserRemoveById';
    entityName: 'RemoveByIdUserPayload';
    isList: false;
    argsTSName: 'MutationUserRemoveByIdArgs';
    returnTSName: "Mutation['UserRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdUserPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'UserRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserAcceptTerm: {
    type: 'Boolean';
    schemaKey: 'UserAcceptTerm';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserAcceptTermArgs';
    returnTSName: "Mutation['UserAcceptTerm']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'term'; description: null; type: 'String!' }];
      name: 'UserAcceptTerm';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserLoginWithPassword: {
    type: 'User';
    schemaKey: 'UserLoginWithPassword';
    entityName: 'User';
    isList: false;
    argsTSName: 'MutationUserLoginWithPasswordArgs';
    returnTSName: "Mutation['UserLoginWithPassword']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [
        {
          name: 'user';
          description: 'email, CPF, CNPJ ou matricula';
          type: 'String!';
        },
        { name: 'password'; description: null; type: 'String!' },
        { name: 'platformName'; description: null; type: 'PlatformNamesEnum' }
      ];
      name: 'UserLoginWithPassword';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserLoginWithToken: {
    type: 'User';
    schemaKey: 'UserLoginWithToken';
    entityName: 'User';
    isList: false;
    argsTSName: 'MutationUserLoginWithTokenArgs';
    returnTSName: "Mutation['UserLoginWithToken']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [
        { name: 'token'; description: null; type: 'String!' },
        { name: 'platformName'; description: null; type: 'PlatformNamesEnum' }
      ];
      name: 'UserLoginWithToken';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserLoginRenewToken: {
    type: 'User';
    schemaKey: 'UserLoginRenewToken';
    entityName: 'User';
    isList: false;
    argsTSName: 'MutationUserLoginRenewTokenArgs';
    returnTSName: "Mutation['UserLoginRenewToken']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'User';
      args: [{ name: 'token'; description: null; type: 'String!' }];
      name: 'UserLoginRenewToken';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserSaveLogoutAnalytics: {
    type: 'Boolean';
    schemaKey: 'UserSaveLogoutAnalytics';
    entityName: 'Boolean';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Mutation['UserSaveLogoutAnalytics']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [];
      name: 'UserSaveLogoutAnalytics';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserSaveLoginAnalyticsPCS: {
    type: 'Boolean';
    schemaKey: 'UserSaveLoginAnalyticsPCS';
    entityName: 'Boolean';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Mutation['UserSaveLoginAnalyticsPCS']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [];
      name: 'UserSaveLoginAnalyticsPCS';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserFillEmail: {
    type: 'Boolean';
    schemaKey: 'UserFillEmail';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserFillEmailArgs';
    returnTSName: "Mutation['UserFillEmail']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'email'; description: null; type: 'String!' }];
      name: 'UserFillEmail';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserFillAvatarId: {
    type: 'Boolean';
    schemaKey: 'UserFillAvatarId';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserFillAvatarIdArgs';
    returnTSName: "Mutation['UserFillAvatarId']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'avatarId'; description: null; type: 'String!' }];
      name: 'UserFillAvatarId';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserOptIn: {
    type: 'Boolean';
    schemaKey: 'UserOptIn';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserOptInArgs';
    returnTSName: "Mutation['UserOptIn']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'accept'; description: null; type: 'Boolean!' }];
      name: 'UserOptIn';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserMarkNotificationAsRead: {
    type: 'Boolean';
    schemaKey: 'UserMarkNotificationAsRead';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserMarkNotificationAsReadArgs';
    returnTSName: "Mutation['UserMarkNotificationAsRead']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'isRead'; description: null; type: 'Boolean!' },
        { name: 'notifications'; description: null; type: '[String]!' }
      ];
      name: 'UserMarkNotificationAsRead';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserDeleteNotification: {
    type: 'String';
    schemaKey: 'UserDeleteNotification';
    entityName: 'String';
    isList: false;
    argsTSName: 'MutationUserDeleteNotificationArgs';
    returnTSName: "Mutation['UserDeleteNotification']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'String';
      args: [{ name: 'notificationId'; description: null; type: 'String!' }];
      name: 'UserDeleteNotification';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserDeleteManyNotification: {
    type: '[String]';
    schemaKey: 'UserDeleteManyNotification';
    entityName: 'String';
    isList: true;
    argsTSName: 'MutationUserDeleteManyNotificationArgs';
    returnTSName: "Mutation['UserDeleteManyNotification']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[String]';
      args: [{ name: 'notificationIds'; description: null; type: '[String!]' }];
      name: 'UserDeleteManyNotification';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserRegistration: {
    type: 'UserRegistrationPayload';
    schemaKey: 'UserRegistration';
    entityName: 'UserRegistrationPayload';
    isList: false;
    argsTSName: 'MutationUserRegistrationArgs';
    returnTSName: "Mutation['UserRegistration']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UserRegistrationPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UserRegistrationTypeInput!';
        }
      ];
      name: 'UserRegistration';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserBlockComments: {
    type: 'Boolean';
    schemaKey: 'UserBlockComments';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserBlockCommentsArgs';
    returnTSName: "Mutation['UserBlockComments']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'userId'; description: null; type: 'String!' },
        { name: 'isBlocked'; description: null; type: 'Boolean' },
        { name: 'materialId'; description: null; type: 'String' }
      ];
      name: 'UserBlockComments';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ChangeToUserTaNarede: {
    type: 'UserTaNaRede';
    schemaKey: 'ChangeToUserTaNarede';
    entityName: 'UserTaNaRede';
    isList: false;
    argsTSName: '{}';
    returnTSName: "Mutation['ChangeToUserTaNarede']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'UserTaNaRede';
      args: [];
      name: 'ChangeToUserTaNarede';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserChangePassword: {
    type: 'Boolean';
    schemaKey: 'UserChangePassword';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserChangePasswordArgs';
    returnTSName: "Mutation['UserChangePassword']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'newPassword'; description: null; type: 'String!' }];
      name: 'UserChangePassword';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserResetPassword: {
    type: 'Boolean';
    schemaKey: 'UserResetPassword';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUserResetPasswordArgs';
    returnTSName: "Mutation['UserResetPassword']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'user'; description: null; type: 'String!' },
        { name: 'domain'; description: null; type: 'String' }
      ];
      name: 'UserResetPassword';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FileCreateOne: {
    type: 'CreateOneFilePayload';
    schemaKey: 'FileCreateOne';
    entityName: 'CreateOneFilePayload';
    isList: false;
    argsTSName: 'MutationFileCreateOneArgs';
    returnTSName: "Mutation['FileCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneFilePayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneFileInput!' }
      ];
      name: 'FileCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FileRemoveById: {
    type: 'RemoveByIdFilePayload';
    schemaKey: 'FileRemoveById';
    entityName: 'RemoveByIdFilePayload';
    isList: false;
    argsTSName: 'MutationFileRemoveByIdArgs';
    returnTSName: "Mutation['FileRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdFilePayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'FileRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FileUpdateById: {
    type: 'UpdateByIdFilePayload';
    schemaKey: 'FileUpdateById';
    entityName: 'UpdateByIdFilePayload';
    isList: false;
    argsTSName: 'MutationFileUpdateByIdArgs';
    returnTSName: "Mutation['FileUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdFilePayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdFileInput!' }
      ];
      name: 'FileUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MaterialCreateOne: {
    type: 'CreateOneMaterialPayload';
    schemaKey: 'MaterialCreateOne';
    entityName: 'CreateOneMaterialPayload';
    isList: false;
    argsTSName: 'MutationMaterialCreateOneArgs';
    returnTSName: "Mutation['MaterialCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneMaterialPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneMaterialInput!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'MaterialCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MaterialUpdateOne: {
    type: 'UpdateOneMaterialPayload';
    schemaKey: 'MaterialUpdateOne';
    entityName: 'UpdateOneMaterialPayload';
    isList: false;
    argsTSName: 'MutationMaterialUpdateOneArgs';
    returnTSName: "Mutation['MaterialUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneMaterialPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneMaterialInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneMaterialInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneMaterialInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'MaterialUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MaterialUpdateById: {
    type: 'UpdateByIdMaterialPayload';
    schemaKey: 'MaterialUpdateById';
    entityName: 'UpdateByIdMaterialPayload';
    isList: false;
    argsTSName: 'MutationMaterialUpdateByIdArgs';
    returnTSName: "Mutation['MaterialUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdMaterialPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdMaterialInput!' },
        { name: 'filter'; description: null; type: 'filter' },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'MaterialUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MaterialRemoveOne: {
    type: 'RemoveOneMaterialPayload';
    schemaKey: 'MaterialRemoveOne';
    entityName: 'RemoveOneMaterialPayload';
    isList: false;
    argsTSName: 'MutationMaterialRemoveOneArgs';
    returnTSName: "Mutation['MaterialRemoveOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveOneMaterialPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveOneMaterialInput';
        },
        { name: 'sort'; description: null; type: 'SortRemoveOneMaterialInput' }
      ];
      name: 'MaterialRemoveOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommunicationCreateOne: {
    type: 'CreateOneMaterialPayload';
    schemaKey: 'CommunicationCreateOne';
    entityName: 'CreateOneMaterialPayload';
    isList: false;
    argsTSName: 'MutationCommunicationCreateOneArgs';
    returnTSName: "Mutation['CommunicationCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'CreateOneMaterialPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneMaterialInput!' }
      ];
      name: 'CommunicationCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommunicationUpdateOne: {
    type: 'UpdateOneMaterialPayload';
    schemaKey: 'CommunicationUpdateOne';
    entityName: 'UpdateOneMaterialPayload';
    isList: false;
    argsTSName: 'MutationCommunicationUpdateOneArgs';
    returnTSName: "Mutation['CommunicationUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'UpdateOneMaterialPayload';
      args: [
        { name: 'communicationId'; description: null; type: 'String!' },
        { name: 'record'; description: null; type: 'UpdateOneMaterialInput' }
      ];
      name: 'CommunicationUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommunicationApproveChannel: {
    type: 'Boolean';
    schemaKey: 'CommunicationApproveChannel';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationCommunicationApproveChannelArgs';
    returnTSName: "Mutation['CommunicationApproveChannel']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'CommunicationApproveChannel';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommunicationDisapproveChannel: {
    type: 'Boolean';
    schemaKey: 'CommunicationDisapproveChannel';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationCommunicationDisapproveChannelArgs';
    returnTSName: "Mutation['CommunicationDisapproveChannel']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'recordId'; description: null; type: 'String!' },
        { name: 'message'; description: null; type: 'String!' }
      ];
      name: 'CommunicationDisapproveChannel';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommunicationRemoveApprover: {
    type: 'Boolean';
    schemaKey: 'CommunicationRemoveApprover';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationCommunicationRemoveApproverArgs';
    returnTSName: "Mutation['CommunicationRemoveApprover']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'communicationId'; description: null; type: 'String!' },
        { name: 'userId'; description: null; type: 'String!' }
      ];
      name: 'CommunicationRemoveApprover';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailFillTemplate: {
    type: 'String!';
    schemaKey: 'EmailFillTemplate';
    entityName: 'String';
    isList: false;
    argsTSName: 'MutationEmailFillTemplateArgs';
    returnTSName: "Mutation['EmailFillTemplate']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'String!';
      args: [
        { name: 'template'; description: null; type: 'EmailTemplateNames' },
        {
          name: 'props';
          description: null;
          type: 'MaterialCommunicationItemsConfigTemplatePropsInput';
        }
      ];
      name: 'EmailFillTemplate';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'mutation';
  };

  EmailMarkAsRead: {
    type: 'Boolean';
    schemaKey: 'EmailMarkAsRead';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationEmailMarkAsReadArgs';
    returnTSName: "Mutation['EmailMarkAsRead']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Marca um email como lido pelo destinatario informado pelo token recebido no e-mail';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'token'; description: null; type: 'String!' }];
      name: 'EmailMarkAsRead';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  SendEmailFront: {
    type: 'Boolean';
    schemaKey: 'SendEmailFront';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationSendEmailFrontArgs';
    returnTSName: "Mutation['SendEmailFront']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'subject'; description: null; type: 'String' },
        { name: 'text'; description: null; type: 'String' },
        { name: 'attach'; description: null; type: '[String]' },
        { name: 'categories'; description: null; type: '[String]' }
      ];
      name: 'SendEmailFront';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MaterialSendTestEmail: {
    type: 'Boolean!';
    schemaKey: 'MaterialSendTestEmail';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationMaterialSendTestEmailArgs';
    returnTSName: "Mutation['MaterialSendTestEmail']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean!';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'MaterialSendTestEmail';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'mutation';
  };

  MaterialLikeToggle: {
    type: 'Boolean';
    schemaKey: 'MaterialLikeToggle';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationMaterialLikeToggleArgs';
    returnTSName: "Mutation['MaterialLikeToggle']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'MaterialLikeToggle';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UpdateDeteachedAndLatestVideo: {
    type: 'Boolean';
    schemaKey: 'UpdateDeteachedAndLatestVideo';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationUpdateDeteachedAndLatestVideoArgs';
    returnTSName: "Mutation['UpdateDeteachedAndLatestVideo']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'idDetachedVideo'; description: null; type: 'String' },
        { name: 'idLatestVideo1'; description: null; type: 'String' },
        { name: 'idLatestVideo2'; description: null; type: 'String' },
        { name: 'idLatestVideo3'; description: null; type: 'String' }
      ];
      name: 'UpdateDeteachedAndLatestVideo';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  NotificationCreateOne: {
    type: 'CreateOneNotificationPayload';
    schemaKey: 'NotificationCreateOne';
    entityName: 'CreateOneNotificationPayload';
    isList: false;
    argsTSName: 'MutationNotificationCreateOneArgs';
    returnTSName: "Mutation['NotificationCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneNotificationPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneNotificationInput!';
        }
      ];
      name: 'NotificationCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  NotificationCreateMany: {
    type: 'CreateManyNotificationPayload';
    schemaKey: 'NotificationCreateMany';
    entityName: 'CreateManyNotificationPayload';
    isList: false;
    argsTSName: 'MutationNotificationCreateManyArgs';
    returnTSName: "Mutation['NotificationCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyNotificationPayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyNotificationInput!]!';
        }
      ];
      name: 'NotificationCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  NotificationUpdateOne: {
    type: 'UpdateOneNotificationPayload';
    schemaKey: 'NotificationUpdateOne';
    entityName: 'UpdateOneNotificationPayload';
    isList: false;
    argsTSName: 'MutationNotificationUpdateOneArgs';
    returnTSName: "Mutation['NotificationUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneNotificationPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneNotificationInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneNotificationInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneNotificationInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'NotificationUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  NotificationRemoveById: {
    type: 'RemoveByIdNotificationPayload';
    schemaKey: 'NotificationRemoveById';
    entityName: 'RemoveByIdNotificationPayload';
    isList: false;
    argsTSName: 'MutationNotificationRemoveByIdArgs';
    returnTSName: "Mutation['NotificationRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdNotificationPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'NotificationRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CategoryCreateOne: {
    type: 'CreateOneCategoryPayload';
    schemaKey: 'CategoryCreateOne';
    entityName: 'CreateOneCategoryPayload';
    isList: false;
    argsTSName: 'MutationCategoryCreateOneArgs';
    returnTSName: "Mutation['CategoryCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneCategoryPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneCategoryInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CategoryCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CategoryUpdateOne: {
    type: 'UpdateOneCategoryPayload';
    schemaKey: 'CategoryUpdateOne';
    entityName: 'UpdateOneCategoryPayload';
    isList: false;
    argsTSName: 'MutationCategoryUpdateOneArgs';
    returnTSName: "Mutation['CategoryUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneCategoryPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneCategoryInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneCategoryInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneCategoryInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'CategoryUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CategoryRemoveById: {
    type: 'RemoveByIdCategoryPayload';
    schemaKey: 'CategoryRemoveById';
    entityName: 'RemoveByIdCategoryPayload';
    isList: false;
    argsTSName: 'MutationCategoryRemoveByIdArgs';
    returnTSName: "Mutation['CategoryRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdCategoryPayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CategoryRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CategoryCreateMany: {
    type: 'CreateManyCategoryPayload';
    schemaKey: 'CategoryCreateMany';
    entityName: 'CreateManyCategoryPayload';
    isList: false;
    argsTSName: 'MutationCategoryCreateManyArgs';
    returnTSName: "Mutation['CategoryCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyCategoryPayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyCategoryInput!]!';
        },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'CategoryCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  WebPushSubscribe: {
    type: 'Boolean';
    schemaKey: 'WebPushSubscribe';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationWebPushSubscribeArgs';
    returnTSName: "Mutation['WebPushSubscribe']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Adiciona uma "PushSubscriptionJSON" ao cadastro do usuario.';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'subscription'; description: null; type: 'JSON!' }];
      name: 'WebPushSubscribe';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  WebPushSendNotification: {
    type: '[String]';
    schemaKey: 'WebPushSendNotification';
    entityName: 'String';
    isList: true;
    argsTSName: 'MutationWebPushSendNotificationArgs';
    returnTSName: "Mutation['WebPushSendNotification']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Envia uma web push notification para uma lista de userIds';
      deprecationReason: null;
      type: '[String]';
      args: [
        { name: 'userIds'; description: null; type: '[String]!' },
        { name: 'notificationOptions'; description: null; type: 'JSON!' },
        { name: 'sync'; description: null; type: 'Boolean' }
      ];
      name: 'WebPushSendNotification';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PinCreateOne: {
    type: 'CreateOnePinPayload';
    schemaKey: 'PinCreateOne';
    entityName: 'CreateOnePinPayload';
    isList: false;
    argsTSName: 'MutationPinCreateOneArgs';
    returnTSName: "Mutation['PinCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOnePinPayload';
      args: [{ name: 'record'; description: null; type: 'CreateOnePinInput!' }];
      name: 'PinCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PinRemoveOne: {
    type: 'RemoveOnePinPayload';
    schemaKey: 'PinRemoveOne';
    entityName: 'RemoveOnePinPayload';
    isList: false;
    argsTSName: 'MutationPinRemoveOneArgs';
    returnTSName: "Mutation['PinRemoveOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveOnePinPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveOnePinInput';
        },
        { name: 'sort'; description: null; type: 'SortRemoveOnePinInput' }
      ];
      name: 'PinRemoveOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PinRemoveMany: {
    type: 'RemoveManyPinPayload';
    schemaKey: 'PinRemoveMany';
    entityName: 'RemoveManyPinPayload';
    isList: false;
    argsTSName: 'MutationPinRemoveManyArgs';
    returnTSName: "Mutation['PinRemoveMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ';
      deprecationReason: null;
      type: 'RemoveManyPinPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveManyPinInput!';
        }
      ];
      name: 'PinRemoveMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ShareCreateOne: {
    type: 'CreateOneSharePayload';
    schemaKey: 'ShareCreateOne';
    entityName: 'CreateOneSharePayload';
    isList: false;
    argsTSName: 'MutationShareCreateOneArgs';
    returnTSName: "Mutation['ShareCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneSharePayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneShareInput!' }
      ];
      name: 'ShareCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ShareRemoveOne: {
    type: 'RemoveOneSharePayload';
    schemaKey: 'ShareRemoveOne';
    entityName: 'RemoveOneSharePayload';
    isList: false;
    argsTSName: 'MutationShareRemoveOneArgs';
    returnTSName: "Mutation['ShareRemoveOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveOneSharePayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveOneShareInput';
        },
        { name: 'sort'; description: null; type: 'SortRemoveOneShareInput' }
      ];
      name: 'ShareRemoveOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ShareRemoveMe: {
    type: 'Boolean!';
    schemaKey: 'ShareRemoveMe';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationShareRemoveMeArgs';
    returnTSName: "Mutation['ShareRemoveMe']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove o usuário atual de um compartilhamento.';
      deprecationReason: null;
      type: 'Boolean!';
      args: [{ name: 'shareId'; description: null; type: 'String' }];
      name: 'ShareRemoveMe';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'mutation';
  };

  CommentCreateOne: {
    type: 'CreateOneCommentPayload';
    schemaKey: 'CommentCreateOne';
    entityName: 'CreateOneCommentPayload';
    isList: false;
    argsTSName: 'MutationCommentCreateOneArgs';
    returnTSName: "Mutation['CommentCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneCommentPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneCommentInput!' }
      ];
      name: 'CommentCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommentUpdateOne: {
    type: 'UpdateOneCommentPayload';
    schemaKey: 'CommentUpdateOne';
    entityName: 'UpdateOneCommentPayload';
    isList: false;
    argsTSName: 'MutationCommentUpdateOneArgs';
    returnTSName: "Mutation['CommentUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneCommentPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneCommentInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneCommentInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneCommentInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'CommentUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommentRemoveById: {
    type: 'RemoveByIdCommentPayload';
    schemaKey: 'CommentRemoveById';
    entityName: 'RemoveByIdCommentPayload';
    isList: false;
    argsTSName: 'MutationCommentRemoveByIdArgs';
    returnTSName: "Mutation['CommentRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdCommentPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'CommentRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CommentLikeToggle: {
    type: 'Boolean';
    schemaKey: 'CommentLikeToggle';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationCommentLikeToggleArgs';
    returnTSName: "Mutation['CommentLikeToggle']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'CommentLikeToggle';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventLiveCreateOne: {
    type: 'CreateOneEventLivePayload';
    schemaKey: 'EventLiveCreateOne';
    entityName: 'CreateOneEventLivePayload';
    isList: false;
    argsTSName: 'MutationEventLiveCreateOneArgs';
    returnTSName: "Mutation['EventLiveCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneEventLivePayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneEventLiveInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'EventLiveCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventLiveRemoveById: {
    type: 'RemoveByIdEventLivePayload';
    schemaKey: 'EventLiveRemoveById';
    entityName: 'RemoveByIdEventLivePayload';
    isList: false;
    argsTSName: 'MutationEventLiveRemoveByIdArgs';
    returnTSName: "Mutation['EventLiveRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdEventLivePayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'EventLiveRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventLiveUpdateOne: {
    type: 'UpdateOneEventLivePayload';
    schemaKey: 'EventLiveUpdateOne';
    entityName: 'UpdateOneEventLivePayload';
    isList: false;
    argsTSName: 'MutationEventLiveUpdateOneArgs';
    returnTSName: "Mutation['EventLiveUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneEventLivePayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneEventLiveInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneEventLiveInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneEventLiveInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'EventLiveUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventLiveCloseRoom: {
    type: 'Boolean';
    schemaKey: 'EventLiveCloseRoom';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationEventLiveCloseRoomArgs';
    returnTSName: "Mutation['EventLiveCloseRoom']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'eventId'; description: null; type: 'String!' }];
      name: 'EventLiveCloseRoom';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EventLiveLikeToggle: {
    type: 'Boolean';
    schemaKey: 'EventLiveLikeToggle';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationEventLiveLikeToggleArgs';
    returnTSName: "Mutation['EventLiveLikeToggle']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: null;
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'EventLiveLikeToggle';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BannerRemoveById: {
    type: 'RemoveByIdBannerPayload';
    schemaKey: 'BannerRemoveById';
    entityName: 'RemoveByIdBannerPayload';
    isList: false;
    argsTSName: 'MutationBannerRemoveByIdArgs';
    returnTSName: "Mutation['BannerRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdBannerPayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'BannerRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BannerCreateOne: {
    type: 'CreateOneBannerPayload';
    schemaKey: 'BannerCreateOne';
    entityName: 'CreateOneBannerPayload';
    isList: false;
    argsTSName: 'MutationBannerCreateOneArgs';
    returnTSName: "Mutation['BannerCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneBannerPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneBannerInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'BannerCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BannerUpdateOne: {
    type: 'UpdateOneBannerPayload';
    schemaKey: 'BannerUpdateOne';
    entityName: 'UpdateOneBannerPayload';
    isList: false;
    argsTSName: 'MutationBannerUpdateOneArgs';
    returnTSName: "Mutation['BannerUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneBannerPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneBannerInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneBannerInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneBannerInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'BannerUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsPageView: {
    type: 'Boolean';
    schemaKey: 'AnalyticsPageView';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationAnalyticsPageViewArgs';
    returnTSName: "Mutation['AnalyticsPageView']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'title'; description: null; type: 'String!' },
        { name: 'host'; description: null; type: 'String' },
        { name: 'route'; description: null; type: 'String' }
      ];
      name: 'AnalyticsPageView';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AnalyticsTrackEvent: {
    type: 'Boolean';
    schemaKey: 'AnalyticsTrackEvent';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationAnalyticsTrackEventArgs';
    returnTSName: "Mutation['AnalyticsTrackEvent']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'event'; description: null; type: 'EnumAnalyticsEvent!' },
        { name: 'kind'; description: null; type: 'EnumAnalyticsParentKind!' },
        { name: 'recordId'; description: null; type: 'String' },
        { name: 'entityName'; description: null; type: 'String' },
        { name: 'label'; description: null; type: 'String' }
      ];
      name: 'AnalyticsTrackEvent';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FolderCreateOne: {
    type: 'CreateOneFolderPayload';
    schemaKey: 'FolderCreateOne';
    entityName: 'CreateOneFolderPayload';
    isList: false;
    argsTSName: 'MutationFolderCreateOneArgs';
    returnTSName: "Mutation['FolderCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneFolderPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneFolderInput!' }
      ];
      name: 'FolderCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FolderRemoveOne: {
    type: 'RemoveOneFolderPayload';
    schemaKey: 'FolderRemoveOne';
    entityName: 'RemoveOneFolderPayload';
    isList: false;
    argsTSName: 'MutationFolderRemoveOneArgs';
    returnTSName: "Mutation['FolderRemoveOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveOneFolderPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveOneFolderInput';
        },
        { name: 'sort'; description: null; type: 'SortRemoveOneFolderInput' }
      ];
      name: 'FolderRemoveOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FolderRemoveMany: {
    type: 'RemoveManyFolderPayload';
    schemaKey: 'FolderRemoveMany';
    entityName: 'RemoveManyFolderPayload';
    isList: false;
    argsTSName: 'MutationFolderRemoveManyArgs';
    returnTSName: "Mutation['FolderRemoveMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ';
      deprecationReason: null;
      type: 'RemoveManyFolderPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveManyFolderInput!';
        }
      ];
      name: 'FolderRemoveMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FolderUpdateOne: {
    type: 'UpdateOneFolderPayload';
    schemaKey: 'FolderUpdateOne';
    entityName: 'UpdateOneFolderPayload';
    isList: false;
    argsTSName: 'MutationFolderUpdateOneArgs';
    returnTSName: "Mutation['FolderUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneFolderPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneFolderInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneFolderInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneFolderInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'FolderUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RecentsActivitiesCreateOne: {
    type: 'CreateOneRecentsActivitiesPayload';
    schemaKey: 'RecentsActivitiesCreateOne';
    entityName: 'CreateOneRecentsActivitiesPayload';
    isList: false;
    argsTSName: 'MutationRecentsActivitiesCreateOneArgs';
    returnTSName: "Mutation['RecentsActivitiesCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneRecentsActivitiesPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneRecentsActivitiesInput!';
        }
      ];
      name: 'RecentsActivitiesCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserWordsBlackListCreateOne: {
    type: 'CreateOneUserWordsBlackListPayload';
    schemaKey: 'UserWordsBlackListCreateOne';
    entityName: 'CreateOneUserWordsBlackListPayload';
    isList: false;
    argsTSName: 'MutationUserWordsBlackListCreateOneArgs';
    returnTSName: "Mutation['UserWordsBlackListCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneUserWordsBlackListPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneUserWordsBlackListInput!';
        }
      ];
      name: 'UserWordsBlackListCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ImportUsersPlanCreateOne: {
    type: 'CreateOneImportUsersPlanPayload';
    schemaKey: 'ImportUsersPlanCreateOne';
    entityName: 'CreateOneImportUsersPlanPayload';
    isList: false;
    argsTSName: 'MutationImportUsersPlanCreateOneArgs';
    returnTSName: "Mutation['ImportUsersPlanCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneImportUsersPlanPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneImportUsersPlanInput!';
        },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'ImportUsersPlanCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ImportUsersPlanUpdateOne: {
    type: 'UpdateOneImportUsersPlanPayload';
    schemaKey: 'ImportUsersPlanUpdateOne';
    entityName: 'UpdateOneImportUsersPlanPayload';
    isList: false;
    argsTSName: 'MutationImportUsersPlanUpdateOneArgs';
    returnTSName: "Mutation['ImportUsersPlanUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneImportUsersPlanPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneImportUsersPlanInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneImportUsersPlanInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneImportUsersPlanInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'ImportUsersPlanUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  UserHistoryBlockCreateOne: {
    type: 'CreateOneUserHistoryBlockPayload';
    schemaKey: 'UserHistoryBlockCreateOne';
    entityName: 'CreateOneUserHistoryBlockPayload';
    isList: false;
    argsTSName: 'MutationUserHistoryBlockCreateOneArgs';
    returnTSName: "Mutation['UserHistoryBlockCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneUserHistoryBlockPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneUserHistoryBlockInput!';
        },
        { name: 'sort'; description: null; type: 'sort' }
      ];
      name: 'UserHistoryBlockCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailStatsCreateMany: {
    type: 'CreateManyEmailStatsPayload';
    schemaKey: 'EmailStatsCreateMany';
    entityName: 'CreateManyEmailStatsPayload';
    isList: false;
    argsTSName: 'MutationEmailStatsCreateManyArgs';
    returnTSName: "Mutation['EmailStatsCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyEmailStatsPayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyEmailStatsInput!]!';
        }
      ];
      name: 'EmailStatsCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailStatsCreateOne: {
    type: 'CreateOneEmailStatsPayload';
    schemaKey: 'EmailStatsCreateOne';
    entityName: 'CreateOneEmailStatsPayload';
    isList: false;
    argsTSName: 'MutationEmailStatsCreateOneArgs';
    returnTSName: "Mutation['EmailStatsCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneEmailStatsPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneEmailStatsInput!' }
      ];
      name: 'EmailStatsCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailUnsubscribeCreateMany: {
    type: 'CreateManyEmailUnsubscribePayload';
    schemaKey: 'EmailUnsubscribeCreateMany';
    entityName: 'CreateManyEmailUnsubscribePayload';
    isList: false;
    argsTSName: 'MutationEmailUnsubscribeCreateManyArgs';
    returnTSName: "Mutation['EmailUnsubscribeCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyEmailUnsubscribePayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyEmailUnsubscribeInput!]!';
        }
      ];
      name: 'EmailUnsubscribeCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  EmailUnsubscribeCreateOne: {
    type: 'CreateOneEmailUnsubscribePayload';
    schemaKey: 'EmailUnsubscribeCreateOne';
    entityName: 'CreateOneEmailUnsubscribePayload';
    isList: false;
    argsTSName: 'MutationEmailUnsubscribeCreateOneArgs';
    returnTSName: "Mutation['EmailUnsubscribeCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneEmailUnsubscribePayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneEmailUnsubscribeInput!';
        }
      ];
      name: 'EmailUnsubscribeCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  HomebannerCreateOne: {
    type: 'CreateOneHomebannerPayload';
    schemaKey: 'HomebannerCreateOne';
    entityName: 'CreateOneHomebannerPayload';
    isList: false;
    argsTSName: 'MutationHomebannerCreateOneArgs';
    returnTSName: "Mutation['HomebannerCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneHomebannerPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneHomebannerInput!' }
      ];
      name: 'HomebannerCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  HomebannerUpdateById: {
    type: 'UpdateByIdHomebannerPayload';
    schemaKey: 'HomebannerUpdateById';
    entityName: 'UpdateByIdHomebannerPayload';
    isList: false;
    argsTSName: 'MutationHomebannerUpdateByIdArgs';
    returnTSName: "Mutation['HomebannerUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdHomebannerPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdHomebannerInput!';
        }
      ];
      name: 'HomebannerUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PlaceRemoveById: {
    type: 'RemoveByIdPlacePayload';
    schemaKey: 'PlaceRemoveById';
    entityName: 'RemoveByIdPlacePayload';
    isList: false;
    argsTSName: 'MutationPlaceRemoveByIdArgs';
    returnTSName: "Mutation['PlaceRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdPlacePayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'PlaceRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PlaceCreateOne: {
    type: 'CreateOnePlacePayload';
    schemaKey: 'PlaceCreateOne';
    entityName: 'CreateOnePlacePayload';
    isList: false;
    argsTSName: 'MutationPlaceCreateOneArgs';
    returnTSName: "Mutation['PlaceCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOnePlacePayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOnePlaceInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'PlaceCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PlaceUpdateById: {
    type: 'UpdateByIdPlacePayload';
    schemaKey: 'PlaceUpdateById';
    entityName: 'UpdateByIdPlacePayload';
    isList: false;
    argsTSName: 'MutationPlaceUpdateByIdArgs';
    returnTSName: "Mutation['PlaceUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdPlacePayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdPlaceInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'PlaceUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PlaceUpdateOne: {
    type: 'UpdateOnePlacePayload';
    schemaKey: 'PlaceUpdateOne';
    entityName: 'UpdateOnePlacePayload';
    isList: false;
    argsTSName: 'MutationPlaceUpdateOneArgs';
    returnTSName: "Mutation['PlaceUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOnePlacePayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOnePlaceInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOnePlaceInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOnePlaceInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'PlaceUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PlacesBaseUpload: {
    type: 'JSON';
    schemaKey: 'PlacesBaseUpload';
    entityName: 'JSON';
    isList: false;
    argsTSName: 'MutationPlacesBaseUploadArgs';
    returnTSName: "Mutation['PlacesBaseUpload']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'JSON';
      args: [
        { name: 'fileId'; description: null; type: 'String!' },
        { name: 'operations'; description: null; type: '[String!]!' }
      ];
      name: 'PlacesBaseUpload';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ContractCreateOne: {
    type: 'CreateOneContractPayload';
    schemaKey: 'ContractCreateOne';
    entityName: 'CreateOneContractPayload';
    isList: false;
    argsTSName: 'MutationContractCreateOneArgs';
    returnTSName: "Mutation['ContractCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneContractPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneContractInput!' }
      ];
      name: 'ContractCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ContractCreateMany: {
    type: 'CreateManyContractPayload';
    schemaKey: 'ContractCreateMany';
    entityName: 'CreateManyContractPayload';
    isList: false;
    argsTSName: 'MutationContractCreateManyArgs';
    returnTSName: "Mutation['ContractCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyContractPayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyContractInput!]!';
        }
      ];
      name: 'ContractCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ContractUpdateOne: {
    type: 'UpdateOneContractPayload';
    schemaKey: 'ContractUpdateOne';
    entityName: 'UpdateOneContractPayload';
    isList: false;
    argsTSName: 'MutationContractUpdateOneArgs';
    returnTSName: "Mutation['ContractUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneContractPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneContractInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneContractInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneContractInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'ContractUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ContractUpdateById: {
    type: 'UpdateByIdContractPayload';
    schemaKey: 'ContractUpdateById';
    entityName: 'UpdateByIdContractPayload';
    isList: false;
    argsTSName: 'MutationContractUpdateByIdArgs';
    returnTSName: "Mutation['ContractUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdContractPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdContractInput!' }
      ];
      name: 'ContractUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ContractRemoveById: {
    type: 'RemoveByIdContractPayload';
    schemaKey: 'ContractRemoveById';
    entityName: 'RemoveByIdContractPayload';
    isList: false;
    argsTSName: 'MutationContractRemoveByIdArgs';
    returnTSName: "Mutation['ContractRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdContractPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'ContractRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductCreateOne: {
    type: 'CreateOneProductPayload';
    schemaKey: 'ProductCreateOne';
    entityName: 'CreateOneProductPayload';
    isList: false;
    argsTSName: 'MutationProductCreateOneArgs';
    returnTSName: "Mutation['ProductCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneProductPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneProductInput!' }
      ];
      name: 'ProductCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductUpdateOne: {
    type: 'UpdateOneProductPayload';
    schemaKey: 'ProductUpdateOne';
    entityName: 'UpdateOneProductPayload';
    isList: false;
    argsTSName: 'MutationProductUpdateOneArgs';
    returnTSName: "Mutation['ProductUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneProductPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneProductInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneProductInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneProductInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'ProductUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductCreateMany: {
    type: 'CreateManyProductPayload';
    schemaKey: 'ProductCreateMany';
    entityName: 'CreateManyProductPayload';
    isList: false;
    argsTSName: 'MutationProductCreateManyArgs';
    returnTSName: "Mutation['ProductCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyProductPayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyProductInput!]!';
        }
      ];
      name: 'ProductCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductUpdateById: {
    type: 'UpdateByIdProductPayload';
    schemaKey: 'ProductUpdateById';
    entityName: 'UpdateByIdProductPayload';
    isList: false;
    argsTSName: 'MutationProductUpdateByIdArgs';
    returnTSName: "Mutation['ProductUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdProductPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdProductInput!' }
      ];
      name: 'ProductUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductRemoveById: {
    type: 'RemoveByIdProductPayload';
    schemaKey: 'ProductRemoveById';
    entityName: 'RemoveByIdProductPayload';
    isList: false;
    argsTSName: 'MutationProductRemoveByIdArgs';
    returnTSName: "Mutation['ProductRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdProductPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'ProductRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductBaseUpload: {
    type: 'JSON';
    schemaKey: 'ProductBaseUpload';
    entityName: 'JSON';
    isList: false;
    argsTSName: 'MutationProductBaseUploadArgs';
    returnTSName: "Mutation['ProductBaseUpload']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'JSON';
      args: [
        { name: 'fileId'; description: null; type: 'String!' },
        { name: 'operations'; description: null; type: '[String!]!' }
      ];
      name: 'ProductBaseUpload';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  SupplierCreateOne: {
    type: 'CreateOneSupplierPayload';
    schemaKey: 'SupplierCreateOne';
    entityName: 'CreateOneSupplierPayload';
    isList: false;
    argsTSName: 'MutationSupplierCreateOneArgs';
    returnTSName: "Mutation['SupplierCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneSupplierPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneSupplierInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'SupplierCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  SupplierUpdateOne: {
    type: 'UpdateOneSupplierPayload';
    schemaKey: 'SupplierUpdateOne';
    entityName: 'UpdateOneSupplierPayload';
    isList: false;
    argsTSName: 'MutationSupplierUpdateOneArgs';
    returnTSName: "Mutation['SupplierUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneSupplierPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneSupplierInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneSupplierInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneSupplierInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'SupplierUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  SupplierUpdateById: {
    type: 'UpdateByIdSupplierPayload';
    schemaKey: 'SupplierUpdateById';
    entityName: 'UpdateByIdSupplierPayload';
    isList: false;
    argsTSName: 'MutationSupplierUpdateByIdArgs';
    returnTSName: "Mutation['SupplierUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdSupplierPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdSupplierInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'SupplierUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  SupplierRemoveById: {
    type: 'RemoveByIdSupplierPayload';
    schemaKey: 'SupplierRemoveById';
    entityName: 'RemoveByIdSupplierPayload';
    isList: false;
    argsTSName: 'MutationSupplierRemoveByIdArgs';
    returnTSName: "Mutation['SupplierRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdSupplierPayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'SupplierRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  TablePriceCreateOne: {
    type: 'CreateOneTablePricePayload';
    schemaKey: 'TablePriceCreateOne';
    entityName: 'CreateOneTablePricePayload';
    isList: false;
    argsTSName: 'MutationTablePriceCreateOneArgs';
    returnTSName: "Mutation['TablePriceCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneTablePricePayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneTablePriceInput!' }
      ];
      name: 'TablePriceCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  TablePriceUpdateOne: {
    type: 'UpdateOneTablePricePayload';
    schemaKey: 'TablePriceUpdateOne';
    entityName: 'UpdateOneTablePricePayload';
    isList: false;
    argsTSName: 'MutationTablePriceUpdateOneArgs';
    returnTSName: "Mutation['TablePriceUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneTablePricePayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneTablePriceInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneTablePriceInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneTablePriceInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'TablePriceUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  TablePriceRemoveById: {
    type: 'RemoveByIdTablePricePayload';
    schemaKey: 'TablePriceRemoveById';
    entityName: 'RemoveByIdTablePricePayload';
    isList: false;
    argsTSName: 'MutationTablePriceRemoveByIdArgs';
    returnTSName: "Mutation['TablePriceRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdTablePricePayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'TablePriceRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PricingTableCreateOne: {
    type: 'CreateOnePricingTablePayload';
    schemaKey: 'PricingTableCreateOne';
    entityName: 'CreateOnePricingTablePayload';
    isList: false;
    argsTSName: 'MutationPricingTableCreateOneArgs';
    returnTSName: "Mutation['PricingTableCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOnePricingTablePayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOnePricingTableInput!';
        }
      ];
      name: 'PricingTableCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PricingTableRemoveById: {
    type: 'RemoveByIdPricingTablePayload';
    schemaKey: 'PricingTableRemoveById';
    entityName: 'RemoveByIdPricingTablePayload';
    isList: false;
    argsTSName: 'MutationPricingTableRemoveByIdArgs';
    returnTSName: "Mutation['PricingTableRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdPricingTablePayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'PricingTableRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PricingTableUpdateById: {
    type: 'UpdateByIdPricingTablePayload';
    schemaKey: 'PricingTableUpdateById';
    entityName: 'UpdateByIdPricingTablePayload';
    isList: false;
    argsTSName: 'MutationPricingTableUpdateByIdArgs';
    returnTSName: "Mutation['PricingTableUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdPricingTablePayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdPricingTableInput!';
        }
      ];
      name: 'PricingTableUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  LegalTextCreateOne: {
    type: 'CreateOneLegalTextPayload';
    schemaKey: 'LegalTextCreateOne';
    entityName: 'CreateOneLegalTextPayload';
    isList: false;
    argsTSName: 'MutationLegalTextCreateOneArgs';
    returnTSName: "Mutation['LegalTextCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneLegalTextPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneLegalTextInput!' }
      ];
      name: 'LegalTextCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  LegalTextUpdateById: {
    type: 'UpdateByIdLegalTextPayload';
    schemaKey: 'LegalTextUpdateById';
    entityName: 'UpdateByIdLegalTextPayload';
    isList: false;
    argsTSName: 'MutationLegalTextUpdateByIdArgs';
    returnTSName: "Mutation['LegalTextUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdLegalTextPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdLegalTextInput!' }
      ];
      name: 'LegalTextUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  LegalTextRemoveById: {
    type: 'RemoveByIdLegalTextPayload';
    schemaKey: 'LegalTextRemoveById';
    entityName: 'RemoveByIdLegalTextPayload';
    isList: false;
    argsTSName: 'MutationLegalTextRemoveByIdArgs';
    returnTSName: "Mutation['LegalTextRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdLegalTextPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'LegalTextRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DeviceCreateOne: {
    type: 'CreateOneDevicePayload';
    schemaKey: 'DeviceCreateOne';
    entityName: 'CreateOneDevicePayload';
    isList: false;
    argsTSName: 'MutationDeviceCreateOneArgs';
    returnTSName: "Mutation['DeviceCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneDevicePayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneDeviceInput!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'DeviceCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DeviceUpdateOne: {
    type: 'UpdateOneDevicePayload';
    schemaKey: 'DeviceUpdateOne';
    entityName: 'UpdateOneDevicePayload';
    isList: false;
    argsTSName: 'MutationDeviceUpdateOneArgs';
    returnTSName: "Mutation['DeviceUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneDevicePayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneDeviceInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneDeviceInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneDeviceInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'DeviceUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DeviceRemoveById: {
    type: 'RemoveByIdDevicePayload';
    schemaKey: 'DeviceRemoveById';
    entityName: 'RemoveByIdDevicePayload';
    isList: false;
    argsTSName: 'MutationDeviceRemoveByIdArgs';
    returnTSName: "Mutation['DeviceRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdDevicePayload';
      args: [
        { name: '_id'; description: null; type: 'MongoID!' },
        { name: 'filter'; description: null; type: 'filter' }
      ];
      name: 'DeviceRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DeleteDevice: {
    type: 'Device';
    schemaKey: 'DeleteDevice';
    entityName: 'Device';
    isList: false;
    argsTSName: 'MutationDeleteDeviceArgs';
    returnTSName: "Mutation['DeleteDevice']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Marca um device como deletado';
      deprecationReason: null;
      type: 'Device';
      args: [{ name: 'recordId'; description: null; type: 'String!' }];
      name: 'DeleteDevice';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DeviceBaseUpload: {
    type: 'JSON';
    schemaKey: 'DeviceBaseUpload';
    entityName: 'JSON';
    isList: false;
    argsTSName: 'MutationDeviceBaseUploadArgs';
    returnTSName: "Mutation['DeviceBaseUpload']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'JSON';
      args: [{ name: 'fileId'; description: null; type: 'String!' }];
      name: 'DeviceBaseUpload';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DigitalBrochureCreateOne: {
    type: 'CreateOneDigitalBrochurePayload';
    schemaKey: 'DigitalBrochureCreateOne';
    entityName: 'CreateOneDigitalBrochurePayload';
    isList: false;
    argsTSName: 'MutationDigitalBrochureCreateOneArgs';
    returnTSName: "Mutation['DigitalBrochureCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneDigitalBrochurePayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneDigitalBrochureInput!';
        }
      ];
      name: 'DigitalBrochureCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DigitalBrochureUpdateOne: {
    type: 'UpdateOneDigitalBrochurePayload';
    schemaKey: 'DigitalBrochureUpdateOne';
    entityName: 'UpdateOneDigitalBrochurePayload';
    isList: false;
    argsTSName: 'MutationDigitalBrochureUpdateOneArgs';
    returnTSName: "Mutation['DigitalBrochureUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneDigitalBrochurePayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneDigitalBrochureInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneDigitalBrochureInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneDigitalBrochureInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'DigitalBrochureUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DigitalBrochureRemoveById: {
    type: 'RemoveByIdDigitalBrochurePayload';
    schemaKey: 'DigitalBrochureRemoveById';
    entityName: 'RemoveByIdDigitalBrochurePayload';
    isList: false;
    argsTSName: 'MutationDigitalBrochureRemoveByIdArgs';
    returnTSName: "Mutation['DigitalBrochureRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdDigitalBrochurePayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'DigitalBrochureRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BrochureUpdateThumbnail: {
    type: '[BrochureUpdateThumbnailPayload]!';
    schemaKey: 'BrochureUpdateThumbnail';
    entityName: 'BrochureUpdateThumbnailPayload';
    isList: false;
    argsTSName: 'MutationBrochureUpdateThumbnailArgs';
    returnTSName: "Mutation['BrochureUpdateThumbnail']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[BrochureUpdateThumbnailPayload]!';
      args: [
        {
          name: 'filter';
          description: null;
          type: 'FilterFindManyDigitalBrochureInput!';
        },
        { name: 'density'; description: null; type: 'Int'; defaultValue: 150 }
      ];
      name: 'BrochureUpdateThumbnail';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'mutation';
  };

  CampaignCreateOne: {
    type: 'CreateOneCampaignPayload';
    schemaKey: 'CampaignCreateOne';
    entityName: 'CreateOneCampaignPayload';
    isList: false;
    argsTSName: 'MutationCampaignCreateOneArgs';
    returnTSName: "Mutation['CampaignCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneCampaignPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneCampaignInput!' }
      ];
      name: 'CampaignCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CampaignUpdateOne: {
    type: 'UpdateOneCampaignPayload';
    schemaKey: 'CampaignUpdateOne';
    entityName: 'UpdateOneCampaignPayload';
    isList: false;
    argsTSName: 'MutationCampaignUpdateOneArgs';
    returnTSName: "Mutation['CampaignUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneCampaignPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneCampaignInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneCampaignInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneCampaignInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'CampaignUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  CampaignRemoveById: {
    type: 'RemoveByIdCampaignPayload';
    schemaKey: 'CampaignRemoveById';
    entityName: 'RemoveByIdCampaignPayload';
    isList: false;
    argsTSName: 'MutationCampaignRemoveByIdArgs';
    returnTSName: "Mutation['CampaignRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdCampaignPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'CampaignRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  LayoutCreateOne: {
    type: 'CreateOneLayoutPayload';
    schemaKey: 'LayoutCreateOne';
    entityName: 'CreateOneLayoutPayload';
    isList: false;
    argsTSName: 'MutationLayoutCreateOneArgs';
    returnTSName: "Mutation['LayoutCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneLayoutPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneLayoutInput!' }
      ];
      name: 'LayoutCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  LayoutCreateMany: {
    type: 'CreateManyLayoutPayload';
    schemaKey: 'LayoutCreateMany';
    entityName: 'CreateManyLayoutPayload';
    isList: false;
    argsTSName: 'MutationLayoutCreateManyArgs';
    returnTSName: "Mutation['LayoutCreateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Creates Many documents with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateManyLayoutPayload';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateManyLayoutInput!]!';
        }
      ];
      name: 'LayoutCreateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  LayoutUpdateOne: {
    type: 'UpdateOneLayoutPayload';
    schemaKey: 'LayoutUpdateOne';
    entityName: 'UpdateOneLayoutPayload';
    isList: false;
    argsTSName: 'MutationLayoutUpdateOneArgs';
    returnTSName: "Mutation['LayoutUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneLayoutPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneLayoutInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneLayoutInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneLayoutInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'LayoutUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  LayoutUpdateById: {
    type: 'UpdateByIdLayoutPayload';
    schemaKey: 'LayoutUpdateById';
    entityName: 'UpdateByIdLayoutPayload';
    isList: false;
    argsTSName: 'MutationLayoutUpdateByIdArgs';
    returnTSName: "Mutation['LayoutUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdLayoutPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdLayoutInput!' }
      ];
      name: 'LayoutUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  LayoutRemoveById: {
    type: 'RemoveByIdLayoutPayload';
    schemaKey: 'LayoutRemoveById';
    entityName: 'RemoveByIdLayoutPayload';
    isList: false;
    argsTSName: 'MutationLayoutRemoveByIdArgs';
    returnTSName: "Mutation['LayoutRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdLayoutPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'LayoutRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  LayoutAssociateCodeTM: {
    type: 'Boolean';
    schemaKey: 'LayoutAssociateCodeTM';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationLayoutAssociateCodeTmArgs';
    returnTSName: "Mutation['LayoutAssociateCodeTM']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        {
          name: 'items';
          description: null;
          type: '[LayoutAssociateCodeItemInput]';
        }
      ];
      name: 'LayoutAssociateCodeTM';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RegionalBudgetMarkAsViewed: {
    type: 'Boolean';
    schemaKey: 'RegionalBudgetMarkAsViewed';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationRegionalBudgetMarkAsViewedArgs';
    returnTSName: "Mutation['RegionalBudgetMarkAsViewed']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'regionalBudgetId'; description: null; type: 'String!' }];
      name: 'RegionalBudgetMarkAsViewed';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BudgetMarkAsFinished: {
    type: 'Boolean';
    schemaKey: 'BudgetMarkAsFinished';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationBudgetMarkAsFinishedArgs';
    returnTSName: "Mutation['BudgetMarkAsFinished']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [{ name: 'budgetId'; description: null; type: 'String!' }];
      name: 'BudgetMarkAsFinished';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RegionalBudgetParseItems: {
    type: 'RegionalBudgetParseItemsPayload';
    schemaKey: 'RegionalBudgetParseItems';
    entityName: 'RegionalBudgetParseItemsPayload';
    isList: false;
    argsTSName: 'MutationRegionalBudgetParseItemsArgs';
    returnTSName: "Mutation['RegionalBudgetParseItems']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionalBudgetParseItemsPayload';
      args: [
        { name: 'regionalBudgetId'; description: null; type: 'String!' },
        { name: 'parentBudgetId'; description: null; type: 'String!' },
        {
          name: 'items';
          description: null;
          type: '[ProductionParsedItemsInput]';
        }
      ];
      name: 'RegionalBudgetParseItems';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RegionalBudgetUpdateProductionItems: {
    type: 'RegionalBudget';
    schemaKey: 'RegionalBudgetUpdateProductionItems';
    entityName: 'RegionalBudget';
    isList: false;
    argsTSName: 'MutationRegionalBudgetUpdateProductionItemsArgs';
    returnTSName: "Mutation['RegionalBudgetUpdateProductionItems']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'RegionalBudget';
      args: [
        { name: 'regionalBudgetId'; description: null; type: 'String!' },
        {
          name: 'productionItems';
          description: null;
          type: '[ProductionItemInput]';
        }
      ];
      name: 'RegionalBudgetUpdateProductionItems';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductionCreateOne: {
    type: 'CreateOneProductionPayload';
    schemaKey: 'ProductionCreateOne';
    entityName: 'CreateOneProductionPayload';
    isList: false;
    argsTSName: 'MutationProductionCreateOneArgs';
    returnTSName: "Mutation['ProductionCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneProductionPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneProductionInput!' }
      ];
      name: 'ProductionCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductionUpdateById: {
    type: 'UpdateByIdProductionPayload';
    schemaKey: 'ProductionUpdateById';
    entityName: 'UpdateByIdProductionPayload';
    isList: false;
    argsTSName: 'MutationProductionUpdateByIdArgs';
    returnTSName: "Mutation['ProductionUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdProductionPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdProductionInput!';
        }
      ];
      name: 'ProductionUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductionRemoveById: {
    type: 'RemoveByIdProductionPayload';
    schemaKey: 'ProductionRemoveById';
    entityName: 'RemoveByIdProductionPayload';
    isList: false;
    argsTSName: 'MutationProductionRemoveByIdArgs';
    returnTSName: "Mutation['ProductionRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdProductionPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'ProductionRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductionArchiveItem: {
    type: 'Production';
    schemaKey: 'ProductionArchiveItem';
    entityName: 'Production';
    isList: false;
    argsTSName: 'MutationProductionArchiveItemArgs';
    returnTSName: "Mutation['ProductionArchiveItem']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: "Marca um item como arquivado para que nao apareca mais na listagem na pagina 'Logística' para o fornecedor";
      deprecationReason: null;
      type: 'Production';
      args: [
        { name: 'recordId'; description: null; type: 'String!' },
        {
          name: 'archive';
          description: null;
          type: 'Boolean';
          defaultValue: true;
        }
      ];
      name: 'ProductionArchiveItem';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  ProductionParseItems: {
    type: 'ProductionParseItemsPayload';
    schemaKey: 'ProductionParseItems';
    entityName: 'ProductionParseItemsPayload';
    isList: false;
    argsTSName: 'MutationProductionParseItemsArgs';
    returnTSName: "Mutation['ProductionParseItems']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'ProductionParseItemsPayload';
      args: [
        {
          name: 'items';
          description: null;
          type: '[ProductionParsedItemsInput]';
        }
      ];
      name: 'ProductionParseItems';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BudgetCreateOne: {
    type: 'CreateOneBudgetPayload';
    schemaKey: 'BudgetCreateOne';
    entityName: 'CreateOneBudgetPayload';
    isList: false;
    argsTSName: 'MutationBudgetCreateOneArgs';
    returnTSName: "Mutation['BudgetCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneBudgetPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneBudgetInput!' }
      ];
      name: 'BudgetCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BudgetUpdateById: {
    type: 'UpdateByIdBudgetPayload';
    schemaKey: 'BudgetUpdateById';
    entityName: 'UpdateByIdBudgetPayload';
    isList: false;
    argsTSName: 'MutationBudgetUpdateByIdArgs';
    returnTSName: "Mutation['BudgetUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdBudgetPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdBudgetInput!' }
      ];
      name: 'BudgetUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  BudgetRemoveById: {
    type: 'RemoveByIdBudgetPayload';
    schemaKey: 'BudgetRemoveById';
    entityName: 'RemoveByIdBudgetPayload';
    isList: false;
    argsTSName: 'MutationBudgetRemoveByIdArgs';
    returnTSName: "Mutation['BudgetRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdBudgetPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'BudgetRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  SupplierDeliveryUpdateStatus: {
    type: 'Boolean';
    schemaKey: 'SupplierDeliveryUpdateStatus';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationSupplierDeliveryUpdateStatusArgs';
    returnTSName: "Mutation['SupplierDeliveryUpdateStatus']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean';
      args: [
        { name: 'productionId'; description: null; type: 'String!' },
        {
          name: 'records';
          description: null;
          type: '[SupplierDeliveryUpdateStatusInput]!';
        }
      ];
      name: 'SupplierDeliveryUpdateStatus';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  InventorySync: {
    type: '[InventoryItem]';
    schemaKey: 'InventorySync';
    entityName: 'InventoryItem';
    isList: true;
    argsTSName: 'MutationInventorySyncArgs';
    returnTSName: "Mutation['InventorySync']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Sincroniza o estoque com nos dados vindos do xlsx (parse do xlsx feito no front). Remove todo o estoque existente no local informado e sincroniza.';
      deprecationReason: null;
      type: '[InventoryItem]';
      args: [
        { name: 'depositPaths'; description: null; type: '[String]!' },
        { name: 'records'; description: null; type: '[InventorySyncProduct]!' }
      ];
      name: 'InventorySync';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  InventoryWithDrawCreateOne: {
    type: 'CreateOneInventoryWithDrawPayload';
    schemaKey: 'InventoryWithDrawCreateOne';
    entityName: 'CreateOneInventoryWithDrawPayload';
    isList: false;
    argsTSName: 'MutationInventoryWithDrawCreateOneArgs';
    returnTSName: "Mutation['InventoryWithDrawCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneInventoryWithDrawPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneInventoryWithDrawInput!';
        }
      ];
      name: 'InventoryWithDrawCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  InventoryCreateWithDraw: {
    type: '[InventoryWithDraw]';
    schemaKey: 'InventoryCreateWithDraw';
    entityName: 'InventoryWithDraw';
    isList: true;
    argsTSName: 'MutationInventoryCreateWithDrawArgs';
    returnTSName: "Mutation['InventoryCreateWithDraw']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: '[InventoryWithDraw]';
      args: [
        {
          name: 'records';
          description: null;
          type: '[CreateOneInventoryWithDrawInput]';
        }
      ];
      name: 'InventoryCreateWithDraw';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DeliveryOrderCreateOne: {
    type: 'CreateOneDeliveryOrderPayload';
    schemaKey: 'DeliveryOrderCreateOne';
    entityName: 'CreateOneDeliveryOrderPayload';
    isList: false;
    argsTSName: 'MutationDeliveryOrderCreateOneArgs';
    returnTSName: "Mutation['DeliveryOrderCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneDeliveryOrderPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneDeliveryOrderInput!';
        }
      ];
      name: 'DeliveryOrderCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DeliveryOrderUpdateById: {
    type: 'UpdateByIdDeliveryOrderPayload';
    schemaKey: 'DeliveryOrderUpdateById';
    entityName: 'UpdateByIdDeliveryOrderPayload';
    isList: false;
    argsTSName: 'MutationDeliveryOrderUpdateByIdArgs';
    returnTSName: "Mutation['DeliveryOrderUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdDeliveryOrderPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdDeliveryOrderInput!';
        }
      ];
      name: 'DeliveryOrderUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DeliveryOrderRemoveById: {
    type: 'RemoveByIdDeliveryOrderPayload';
    schemaKey: 'DeliveryOrderRemoveById';
    entityName: 'RemoveByIdDeliveryOrderPayload';
    isList: false;
    argsTSName: 'MutationDeliveryOrderRemoveByIdArgs';
    returnTSName: "Mutation['DeliveryOrderRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdDeliveryOrderPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'DeliveryOrderRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MetadataCreateOne: {
    type: 'CreateOneMetadataPayload';
    schemaKey: 'MetadataCreateOne';
    entityName: 'CreateOneMetadataPayload';
    isList: false;
    argsTSName: 'MutationMetadataCreateOneArgs';
    returnTSName: "Mutation['MetadataCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneMetadataPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneMetadataInput!' }
      ];
      name: 'MetadataCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MetadataUpdateOne: {
    type: 'UpdateOneMetadataPayload';
    schemaKey: 'MetadataUpdateOne';
    entityName: 'UpdateOneMetadataPayload';
    isList: false;
    argsTSName: 'MutationMetadataUpdateOneArgs';
    returnTSName: "Mutation['MetadataUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneMetadataPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateOneMetadataInput!' },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneMetadataInput';
        },
        { name: 'sort'; description: null; type: 'SortUpdateOneMetadataInput' },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'MetadataUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MetadataRemoveById: {
    type: 'RemoveByIdMetadataPayload';
    schemaKey: 'MetadataRemoveById';
    entityName: 'RemoveByIdMetadataPayload';
    isList: false;
    argsTSName: 'MutationMetadataRemoveByIdArgs';
    returnTSName: "Mutation['MetadataRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdMetadataPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'MetadataRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MetadataUpdateById: {
    type: 'UpdateByIdMetadataPayload';
    schemaKey: 'MetadataUpdateById';
    entityName: 'UpdateByIdMetadataPayload';
    isList: false;
    argsTSName: 'MutationMetadataUpdateByIdArgs';
    returnTSName: "Mutation['MetadataUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdMetadataPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdMetadataInput!' }
      ];
      name: 'MetadataUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PositivatorCreateOne: {
    type: 'CreateOnePositivatorPayload';
    schemaKey: 'PositivatorCreateOne';
    entityName: 'CreateOnePositivatorPayload';
    isList: false;
    argsTSName: 'MutationPositivatorCreateOneArgs';
    returnTSName: "Mutation['PositivatorCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOnePositivatorPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOnePositivatorInput!';
        }
      ];
      name: 'PositivatorCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PositivatorUpdateOne: {
    type: 'UpdateOnePositivatorPayload';
    schemaKey: 'PositivatorUpdateOne';
    entityName: 'UpdateOnePositivatorPayload';
    isList: false;
    argsTSName: 'MutationPositivatorUpdateOneArgs';
    returnTSName: "Mutation['PositivatorUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOnePositivatorPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOnePositivatorInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOnePositivatorInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOnePositivatorInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'PositivatorUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PositivatorUpdateMany: {
    type: 'UpdateManyPositivatorPayload';
    schemaKey: 'PositivatorUpdateMany';
    entityName: 'UpdateManyPositivatorPayload';
    isList: false;
    argsTSName: 'MutationPositivatorUpdateManyArgs';
    returnTSName: "Mutation['PositivatorUpdateMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ';
      deprecationReason: null;
      type: 'UpdateManyPositivatorPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateManyPositivatorInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateManyPositivatorInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateManyPositivatorInput';
        },
        { name: 'skip'; description: null; type: 'Int' },
        { name: 'limit'; description: null; type: 'Int'; defaultValue: 1000 }
      ];
      name: 'PositivatorUpdateMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PositivatorRemoveById: {
    type: 'RemoveByIdPositivatorPayload';
    schemaKey: 'PositivatorRemoveById';
    entityName: 'RemoveByIdPositivatorPayload';
    isList: false;
    argsTSName: 'MutationPositivatorRemoveByIdArgs';
    returnTSName: "Mutation['PositivatorRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdPositivatorPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'PositivatorRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PositivatorRemoveMany: {
    type: 'RemoveManyPositivatorPayload';
    schemaKey: 'PositivatorRemoveMany';
    entityName: 'RemoveManyPositivatorPayload';
    isList: false;
    argsTSName: 'MutationPositivatorRemoveManyArgs';
    returnTSName: "Mutation['PositivatorRemoveMany']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation. ';
      deprecationReason: null;
      type: 'RemoveManyPositivatorPayload';
      args: [
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterRemoveManyPositivatorInput!';
        }
      ];
      name: 'PositivatorRemoveMany';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PositivatorNotificationCreateOne: {
    type: 'Notification';
    schemaKey: 'PositivatorNotificationCreateOne';
    entityName: 'Notification';
    isList: false;
    argsTSName: 'MutationPositivatorNotificationCreateOneArgs';
    returnTSName: "Mutation['PositivatorNotificationCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Notification';
      args: [
        {
          name: 'positivedPercentageInterval';
          description: null;
          type: 'PositivatorNumberIntervalInput';
        },
        { name: 'regionItems'; description: null; type: '[String]!' },
        { name: 'channels'; description: null; type: '[EnumPlaceType]!' },
        { name: 'text'; description: null; type: 'String!' },
        { name: 'schedule'; description: null; type: 'Date!' }
      ];
      name: 'PositivatorNotificationCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PositivatorItemCreateOne: {
    type: 'CreateOnePositivatorItemPayload';
    schemaKey: 'PositivatorItemCreateOne';
    entityName: 'CreateOnePositivatorItemPayload';
    isList: false;
    argsTSName: 'MutationPositivatorItemCreateOneArgs';
    returnTSName: "Mutation['PositivatorItemCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOnePositivatorItemPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOnePositivatorItemInput!';
        }
      ];
      name: 'PositivatorItemCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PositivatorItemUpdateById: {
    type: 'UpdateByIdPositivatorItemPayload';
    schemaKey: 'PositivatorItemUpdateById';
    entityName: 'UpdateByIdPositivatorItemPayload';
    isList: false;
    argsTSName: 'MutationPositivatorItemUpdateByIdArgs';
    returnTSName: "Mutation['PositivatorItemUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdPositivatorItemPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdPositivatorItemInput!';
        }
      ];
      name: 'PositivatorItemUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PositivatorItemRemoveById: {
    type: 'RemoveByIdPositivatorItemPayload';
    schemaKey: 'PositivatorItemRemoveById';
    entityName: 'RemoveByIdPositivatorItemPayload';
    isList: false;
    argsTSName: 'MutationPositivatorItemRemoveByIdArgs';
    returnTSName: "Mutation['PositivatorItemRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdPositivatorItemPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'PositivatorItemRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DeviceNotificationMarkAsRead: {
    type: 'DeviceNotification';
    schemaKey: 'DeviceNotificationMarkAsRead';
    entityName: 'DeviceNotification';
    isList: false;
    argsTSName: 'MutationDeviceNotificationMarkAsReadArgs';
    returnTSName: "Mutation['DeviceNotificationMarkAsRead']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DeviceNotification';
      args: [{ name: '_id'; description: null; type: 'String!' }];
      name: 'DeviceNotificationMarkAsRead';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DeviceNotificationMarkAsSolved: {
    type: 'DeviceNotification';
    schemaKey: 'DeviceNotificationMarkAsSolved';
    entityName: 'DeviceNotification';
    isList: false;
    argsTSName: 'MutationDeviceNotificationMarkAsSolvedArgs';
    returnTSName: "Mutation['DeviceNotificationMarkAsSolved']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'DeviceNotification';
      args: [
        { name: 'notificationId'; description: null; type: 'String!' },
        { name: 'devicesIds'; description: null; type: '[String!]!' }
      ];
      name: 'DeviceNotificationMarkAsSolved';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MerchanRequestTempPassword: {
    type: 'Boolean!';
    schemaKey: 'MerchanRequestTempPassword';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationMerchanRequestTempPasswordArgs';
    returnTSName: "Mutation['MerchanRequestTempPassword']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean!';
      args: [
        { name: 'email'; description: null; type: 'String!' },
        { name: 'CPF'; description: null; type: 'String' },
        { name: 'CNPJ'; description: null; type: 'String' }
      ];
      name: 'MerchanRequestTempPassword';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'mutation';
  };

  MerchanVerifyTempCode: {
    type: 'Boolean!';
    schemaKey: 'MerchanVerifyTempCode';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationMerchanVerifyTempCodeArgs';
    returnTSName: "Mutation['MerchanVerifyTempCode']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean!';
      args: [
        { name: 'code'; description: null; type: 'String!' },
        { name: 'CPF'; description: null; type: 'String' },
        { name: 'CNPJ'; description: null; type: 'String' }
      ];
      name: 'MerchanVerifyTempCode';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'mutation';
  };

  MerchanChangePassword: {
    type: 'Boolean!';
    schemaKey: 'MerchanChangePassword';
    entityName: 'Boolean';
    isList: false;
    argsTSName: 'MutationMerchanChangePasswordArgs';
    returnTSName: "Mutation['MerchanChangePassword']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'Boolean!';
      args: [
        { name: 'CPF'; description: null; type: 'String' },
        { name: 'CNPJ'; description: null; type: 'String' },
        { name: 'oldPassword'; description: null; type: 'String!' },
        { name: 'newPassword'; description: null; type: 'String!' }
      ];
      name: 'MerchanChangePassword';
      isDeprecated: false;
    };
    isNonNull: true;
    kind: 'mutation';
  };

  StructuralCreateOne: {
    type: 'CreateOneStructuralPayload';
    schemaKey: 'StructuralCreateOne';
    entityName: 'CreateOneStructuralPayload';
    isList: false;
    argsTSName: 'MutationStructuralCreateOneArgs';
    returnTSName: "Mutation['StructuralCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneStructuralPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneStructuralInput!' }
      ];
      name: 'StructuralCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  StructuralUpdateOne: {
    type: 'UpdateOneStructuralPayload';
    schemaKey: 'StructuralUpdateOne';
    entityName: 'UpdateOneStructuralPayload';
    isList: false;
    argsTSName: 'MutationStructuralUpdateOneArgs';
    returnTSName: "Mutation['StructuralUpdateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateOneStructuralPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateOneStructuralInput!';
        },
        {
          name: 'filter';
          description: 'Filter by fields';
          type: 'FilterUpdateOneStructuralInput';
        },
        {
          name: 'sort';
          description: null;
          type: 'SortUpdateOneStructuralInput';
        },
        { name: 'skip'; description: null; type: 'Int' }
      ];
      name: 'StructuralUpdateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  StructuralRemoveById: {
    type: 'RemoveByIdStructuralPayload';
    schemaKey: 'StructuralRemoveById';
    entityName: 'RemoveByIdStructuralPayload';
    isList: false;
    argsTSName: 'MutationStructuralRemoveByIdArgs';
    returnTSName: "Mutation['StructuralRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdStructuralPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'StructuralRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  StructuralUpdateById: {
    type: 'UpdateByIdStructuralPayload';
    schemaKey: 'StructuralUpdateById';
    entityName: 'UpdateByIdStructuralPayload';
    isList: false;
    argsTSName: 'MutationStructuralUpdateByIdArgs';
    returnTSName: "Mutation['StructuralUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdStructuralPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'UpdateByIdStructuralInput!';
        }
      ];
      name: 'StructuralUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  StructuralEditStatus: {
    type: 'StructuralEditStatusPayload';
    schemaKey: 'StructuralEditStatus';
    entityName: 'StructuralEditStatusPayload';
    isList: false;
    argsTSName: 'MutationStructuralEditStatusArgs';
    returnTSName: "Mutation['StructuralEditStatus']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: '';
      deprecationReason: null;
      type: 'StructuralEditStatusPayload';
      args: [
        { name: 'projectId'; description: null; type: 'String' },
        { name: 'stage'; description: null; type: 'EnumStructuralStage' },
        { name: 'status'; description: null; type: 'ProjectStageStatus' },
        { name: 'data'; description: null; type: 'JSON' },
        {
          name: 'isComment';
          description: 'A alteração é um comentário?';
          type: 'Boolean';
        },
        {
          name: 'isFile';
          description: 'É um upload de arquivo?';
          type: 'Boolean';
        }
      ];
      name: 'StructuralEditStatus';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  DigitalBrochureAnalyticsCreateOne: {
    type: 'CreateOneDigitalBrochureAnalyticsPayload';
    schemaKey: 'DigitalBrochureAnalyticsCreateOne';
    entityName: 'CreateOneDigitalBrochureAnalyticsPayload';
    isList: false;
    argsTSName: 'MutationDigitalBrochureAnalyticsCreateOneArgs';
    returnTSName: "Mutation['DigitalBrochureAnalyticsCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneDigitalBrochureAnalyticsPayload';
      args: [
        {
          name: 'record';
          description: null;
          type: 'CreateOneDigitalBrochureAnalyticsInput!';
        }
      ];
      name: 'DigitalBrochureAnalyticsCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AreaLojaCreateOne: {
    type: 'CreateOneAreaLojaPayload';
    schemaKey: 'AreaLojaCreateOne';
    entityName: 'CreateOneAreaLojaPayload';
    isList: false;
    argsTSName: 'MutationAreaLojaCreateOneArgs';
    returnTSName: "Mutation['AreaLojaCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneAreaLojaPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneAreaLojaInput!' }
      ];
      name: 'AreaLojaCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AreaLojaUpdateById: {
    type: 'UpdateByIdAreaLojaPayload';
    schemaKey: 'AreaLojaUpdateById';
    entityName: 'UpdateByIdAreaLojaPayload';
    isList: false;
    argsTSName: 'MutationAreaLojaUpdateByIdArgs';
    returnTSName: "Mutation['AreaLojaUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdAreaLojaPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdAreaLojaInput!' }
      ];
      name: 'AreaLojaUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  AreaLojaRemoveById: {
    type: 'RemoveByIdAreaLojaPayload';
    schemaKey: 'AreaLojaRemoveById';
    entityName: 'RemoveByIdAreaLojaPayload';
    isList: false;
    argsTSName: 'MutationAreaLojaRemoveByIdArgs';
    returnTSName: "Mutation['AreaLojaRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdAreaLojaPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'AreaLojaRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RegraMapaCreateOne: {
    type: 'CreateOneRegraMapaPayload';
    schemaKey: 'RegraMapaCreateOne';
    entityName: 'CreateOneRegraMapaPayload';
    isList: false;
    argsTSName: 'MutationRegraMapaCreateOneArgs';
    returnTSName: "Mutation['RegraMapaCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneRegraMapaPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneRegraMapaInput!' }
      ];
      name: 'RegraMapaCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RegraMapaUpdateById: {
    type: 'UpdateByIdRegraMapaPayload';
    schemaKey: 'RegraMapaUpdateById';
    entityName: 'UpdateByIdRegraMapaPayload';
    isList: false;
    argsTSName: 'MutationRegraMapaUpdateByIdArgs';
    returnTSName: "Mutation['RegraMapaUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdRegraMapaPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdRegraMapaInput!' }
      ];
      name: 'RegraMapaUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  RegraMapaRemoveById: {
    type: 'RemoveByIdRegraMapaPayload';
    schemaKey: 'RegraMapaRemoveById';
    entityName: 'RemoveByIdRegraMapaPayload';
    isList: false;
    argsTSName: 'MutationRegraMapaRemoveByIdArgs';
    returnTSName: "Mutation['RegraMapaRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdRegraMapaPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'RegraMapaRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FormatoCreateOne: {
    type: 'CreateOneFormatoPayload';
    schemaKey: 'FormatoCreateOne';
    entityName: 'CreateOneFormatoPayload';
    isList: false;
    argsTSName: 'MutationFormatoCreateOneArgs';
    returnTSName: "Mutation['FormatoCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneFormatoPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneFormatoInput!' }
      ];
      name: 'FormatoCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FormatoUpdateById: {
    type: 'UpdateByIdFormatoPayload';
    schemaKey: 'FormatoUpdateById';
    entityName: 'UpdateByIdFormatoPayload';
    isList: false;
    argsTSName: 'MutationFormatoUpdateByIdArgs';
    returnTSName: "Mutation['FormatoUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdFormatoPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdFormatoInput!' }
      ];
      name: 'FormatoUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  FormatoRemoveById: {
    type: 'RemoveByIdFormatoPayload';
    schemaKey: 'FormatoRemoveById';
    entityName: 'RemoveByIdFormatoPayload';
    isList: false;
    argsTSName: 'MutationFormatoRemoveByIdArgs';
    returnTSName: "Mutation['FormatoRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdFormatoPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'FormatoRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PecaMapaCreateOne: {
    type: 'CreateOnePecaMapaPayload';
    schemaKey: 'PecaMapaCreateOne';
    entityName: 'CreateOnePecaMapaPayload';
    isList: false;
    argsTSName: 'MutationPecaMapaCreateOneArgs';
    returnTSName: "Mutation['PecaMapaCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOnePecaMapaPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOnePecaMapaInput!' }
      ];
      name: 'PecaMapaCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PecaMapaUpdateById: {
    type: 'UpdateByIdPecaMapaPayload';
    schemaKey: 'PecaMapaUpdateById';
    entityName: 'UpdateByIdPecaMapaPayload';
    isList: false;
    argsTSName: 'MutationPecaMapaUpdateByIdArgs';
    returnTSName: "Mutation['PecaMapaUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdPecaMapaPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdPecaMapaInput!' }
      ];
      name: 'PecaMapaUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  PecaMapaRemoveById: {
    type: 'RemoveByIdPecaMapaPayload';
    schemaKey: 'PecaMapaRemoveById';
    entityName: 'RemoveByIdPecaMapaPayload';
    isList: false;
    argsTSName: 'MutationPecaMapaRemoveByIdArgs';
    returnTSName: "Mutation['PecaMapaRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdPecaMapaPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'PecaMapaRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MapaPDVCreateOne: {
    type: 'CreateOneMapaPDVPayload';
    schemaKey: 'MapaPDVCreateOne';
    entityName: 'CreateOneMapaPDVPayload';
    isList: false;
    argsTSName: 'MutationMapaPdvCreateOneArgs';
    returnTSName: "Mutation['MapaPDVCreateOne']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Create one document with mongoose defaults, setters, hooks and validation';
      deprecationReason: null;
      type: 'CreateOneMapaPDVPayload';
      args: [
        { name: 'record'; description: null; type: 'CreateOneMapaPDVInput!' }
      ];
      name: 'MapaPDVCreateOne';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MapaPDVUpdateById: {
    type: 'UpdateByIdMapaPDVPayload';
    schemaKey: 'MapaPDVUpdateById';
    entityName: 'UpdateByIdMapaPDVPayload';
    isList: false;
    argsTSName: 'MutationMapaPdvUpdateByIdArgs';
    returnTSName: "Mutation['MapaPDVUpdateById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it.';
      deprecationReason: null;
      type: 'UpdateByIdMapaPDVPayload';
      args: [
        { name: 'record'; description: null; type: 'UpdateByIdMapaPDVInput!' }
      ];
      name: 'MapaPDVUpdateById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };

  MapaPDVRemoveById: {
    type: 'RemoveByIdMapaPDVPayload';
    schemaKey: 'MapaPDVRemoveById';
    entityName: 'RemoveByIdMapaPDVPayload';
    isList: false;
    argsTSName: 'MutationMapaPdvRemoveByIdArgs';
    returnTSName: "Mutation['MapaPDVRemoveById']";
    isMutation: true;
    isQuery: false;
    isSubscription: false;
    field: {
      description: 'Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document.';
      deprecationReason: null;
      type: 'RemoveByIdMapaPDVPayload';
      args: [{ name: '_id'; description: null; type: 'MongoID!' }];
      name: 'MapaPDVRemoveById';
      isDeprecated: false;
    };
    isNonNull: false;
    kind: 'mutation';
  };
}
