"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DEFAULT_VALUE = '000';

exports.incrementStringNumber = function (numBase) {
  var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;

  if (!numBase) {
    return DEFAULT_VALUE;
  }

  var num = parseInt(numBase, 10) + 1;
  var complement = new Array(length + 1).join('0');
  return "".concat(complement).concat(num).substr(length * -1);
};