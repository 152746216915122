"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * retorna um objeto contendo "promise", "resolve", e "reject"
 * a promise será resolvida assim que resolve ou reject forem chamados
 */

exports.hope = function () {
  var self = {};
  self.resolved = false;
  self.promise = new Promise(function (success, error) {
    self.resolve = function () {
      self.resolved = true;
      success.apply(void 0, arguments);
    };

    self.reject = function () {
      self.resolved = true;
      error.apply(void 0, arguments);
    };
  });
  var started = false;

  self.once = function (cb) {
    if (started) return self.promise;
    started = true;
    return cb().then(function (r) {
      self.resolve(r);
      return r;
    }).catch(function (err) {
      self.reject(err);
      return err;
    });
  };

  return self;
};