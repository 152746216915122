"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DEFAULT_VALUE = 'AAA';

exports.incrementString = function (stringBase) {
  if (!stringBase) {
    return DEFAULT_VALUE;
  }

  var stringNum = parseInt(stringBase, 36) + 1;
  var result = stringNum.toString(36).replace(/0/g, 'a');
  return result.toUpperCase();
};