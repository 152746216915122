"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isArrayEmpty(arr) {
  if (Array.isArray(arr)) return arr.length === 0 ? true : false;
  return false;
}

exports.isArrayEmpty = isArrayEmpty;