"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var DeviceActionsProvider_1 = require("./DeviceActionsProvider");

var DeviceProvider_1 = require("./DeviceProvider");

var graphql_clientgen_1 = require("graphql-clientgen");

var DataProviders_1 = require("../../DataProviders/DataProviders");

exports.DataProviders = function (props) {
  var device = props.device;
  return React.createElement(DataProviders_1.ComponentsGraphqlStoreContext.Consumer, null, function (value) {
    return React.createElement(DeviceActionsProvider_1.DeviceActionsProvider, {
      store: value
    }, React.createElement(DeviceProvider_1.DeviceProvider, {
      device: device
    }, props.children));
  });
};

function useClientStore() {
  return React.useContext(DataProviders_1.ComponentsGraphqlStoreContext);
}

exports.useClientStore = useClientStore;
exports.useClient = graphql_clientgen_1.useClientFactory(useClientStore);