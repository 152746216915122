"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isInputFieldConfig(field) {
  return field && field.name && field.label && !field.list && !field.custom && !field.loadable;
}

exports.isInputFieldConfig = isInputFieldConfig;

exports.isLoadableConfig = function (entry) {
  return entry && entry.loadable;
};

exports.isCustomComponentConfig = function (entry) {
  return entry && entry.component;
};

exports.isInlineFieldList = function (entry) {
  return entry && Array.isArray(entry.list);
};

exports.isCustomComponentFieldConfig = function (entry) {
  if (typeof entry !== 'object') return false;
  return !!Object.prototype.hasOwnProperty.call(entry, 'custom');
};