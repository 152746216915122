"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var calculateAge_1 = require("../calculateAge");

var getBRDate_1 = require("../getBRDate");
/**
 * verifica se uma data é valida - se fornecida a "idade" minima em anos, também é verificado
 * @param value ex: '25/08/1989'
 * @param minAge idade minima
 */


exports.validateDate = function (value, minAge) {
  var message = 'Data inválida';
  if (!value || typeof value !== 'string') return message;
  var date = getBRDate_1.getBRDate(value);

  if (!date) {
    return message;
  }

  var age = calculateAge_1.calculateAge(date);
  if (age > 1000) return message;

  if (minAge && age < minAge) {
    return "A idade m\xEDnima \xE9 ".concat(minAge, " anos");
  }
};