"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var graphql_clientgen_1 = require("graphql-clientgen");

exports.DigitalBrochureActionsContext = React.createContext({});
var Provider = exports.DigitalBrochureActionsContext.Provider;

exports.DigitalBrochureActionsProvider = function (props) {
  var children = props.children,
      store = props.store;
  var deleteDigitalBrochure = graphql_clientgen_1.fetchGraphql('DigitalBrochureRemoveById', {}, store);

  var deleteAct = function deleteAct(record, cb) {
    return deleteDigitalBrochure({
      variables: {
        _id: record.recordId
      },
      afterMutate: /^DigitalBrochure/
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  return React.createElement(Provider, {
    value: {
      delete: deleteAct
    }
  }, children);
};

function useDigitalBrochureActions() {
  return React.useContext(exports.DigitalBrochureActionsContext);
}

exports.useDigitalBrochureActions = useDigitalBrochureActions;