"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var TypeCard;

(function (TypeCard) {
  TypeCard[TypeCard["LARGE"] = 1] = "LARGE";
  TypeCard[TypeCard["MEDIUM1"] = 2] = "MEDIUM1";
  TypeCard[TypeCard["MEDIUM2"] = 3] = "MEDIUM2";
  TypeCard[TypeCard["SMALL"] = 4] = "SMALL";
  TypeCard[TypeCard["BANNER"] = 5] = "BANNER";
  TypeCard[TypeCard["SMALL2"] = 6] = "SMALL2";
  TypeCard[TypeCard["BANNER_FOOTER"] = 7] = "BANNER_FOOTER";
})(TypeCard = exports.TypeCard || (exports.TypeCard = {}));

exports.TypeCardDescription = {
  1: 'Card Grande',
  2: 'Card Médio 1',
  3: 'Card Médio 2',
  4: 'Card Pequeno 1',
  5: 'Banner',
  6: 'Card Pequeno 2',
  7: 'Banner Rodapé'
};