"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var isBrowser_1 = require("../isBrowser");

exports.useOnce = function (cb, serverOnly) {
  if (serverOnly && isBrowser_1.isBrowser()) return; // eslint-disable-next-line react-hooks/rules-of-hooks

  var called = React.useRef(false);

  if (!called.current) {
    called.current = true;
    return cb();
  }
};