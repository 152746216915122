"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.isBrowser = function () {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
};

exports.onBrowser = function (browserCB, serverCB) {
  if (exports.isBrowser()) {
    return typeof browserCB === 'function' ? browserCB() : undefined;
  } else {
    return typeof serverCB === 'function' ? serverCB() : undefined;
  }
};