"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function nonNullValues(object) {
  if (typeof object !== 'object') {
    throw new Error("".concat(object, " n\xE3o \xE9 um objeto v\xE1lido"));
  }

  var keys = Object.keys(object);
  var errors = '';
  var t = {};
  keys.forEach(function (k) {
    var value = object[k];

    if (value === null || value === undefined) {
      errors += "".concat(k, ": valor \"").concat(value, "\" \xE9 inv\xE1lido.");
    }

    t[k] = value;
  });

  if (errors) {
    console.trace(errors);
    throw new Error(errors);
  }

  return t;
}

exports.nonNullValues = nonNullValues;