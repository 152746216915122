"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.formatCurrency = function (value) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$locale = _ref.locale,
      locale = _ref$locale === void 0 ? 'pt-BR' : _ref$locale,
      _ref$currency = _ref.currency,
      currency = _ref$currency === void 0 ? 'BRL' : _ref$currency,
      _ref$minimumFractionD = _ref.minimumFractionDigits,
      minimumFractionDigits = _ref$minimumFractionD === void 0 ? 2 : _ref$minimumFractionD;

  if (typeof value !== 'number' || isNaN(value)) {
    return 'invalid value';
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minimumFractionDigits
  }).format(value);
};