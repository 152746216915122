"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validatePhone = function (value) {
  return ((value || '').match(/\d/g) || []).length !== 10 ? 'Telefone inválido.' : '';
};

exports.validateCellPhone = function (value) {
  var length = ((value || '').match(/\d/g) || []).length;
  return length !== 11 ? 'Celular inválido.' : '';
};