"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

function renderLineDefault(c, index) {
  return React.createElement("span", {
    key: index
  }, c);
}

exports.nl2br = function (text, renderLine) {
  if (typeof text !== 'string') return text;
  var parts = (text || '').split('\n');
  var last = parts.length - 1;
  var render = renderLine || renderLineDefault;
  return parts.map(function (el, index) {
    return render(React.createElement(React.Fragment, null, el, index !== last && React.createElement("br", null)), index);
  });
};