"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function validateAddress(value) {
  var invalid;
  invalid = value.length < 5;
  if (!value.match(/[0-9]/)) invalid = true;
  if (!value.match(/[a-z]/gim)) invalid = true;
  if (!value.match(/ /)) invalid = true;
  return invalid ? 'Endereço inválido' : '';
}

exports.validateAddress = validateAddress;