"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function emailMask(email) {
  var maskedEmail = email.replace(/([^@\.])/g, '*').split('');
  var previous = '';
  var i;

  for (i = 0; i < maskedEmail.length; i++) {
    if (i <= 1 || previous == '.' || previous == '@') {
      maskedEmail[i] = email[i];
    }

    previous = email[i];
  }

  return maskedEmail.join('');
}

exports.emailMask = emailMask;