"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var antd_1 = require("antd");

var Button_1 = require("../Button/Button");

function ButtonUpload(props) {
  var children = props.children,
      accept = props.accept,
      onChange = props.onChange;
  var inputRef = react_1.useRef(null);
  var inputEl = inputRef.current;
  return react_1.default.createElement(Button_1.Button, {
    onClick: function onClick() {
      return inputEl.click();
    }
  }, react_1.default.createElement("input", {
    ref: inputRef,
    type: "file",
    style: {
      display: 'none'
    },
    accept: accept,
    multiple: false,
    onChange: onChange
  }), react_1.default.createElement(antd_1.Icon, {
    type: "upload"
  }), children);
}

exports.default = ButtonUpload;
exports.ButtonUpload = ButtonUpload;