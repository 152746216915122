"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/** O tipo de uso do material. Ex: "video_poster" */

var EnumFileKind;

(function (EnumFileKind) {
  EnumFileKind["CardThumb"] = "card_thumb";
  EnumFileKind["VideoMp4"] = "video_mp4";
  EnumFileKind["VideoThumb"] = "video_thumb";
  EnumFileKind["VideoBanner"] = "video_banner";
  EnumFileKind["Pdf"] = "pdf";
  EnumFileKind["Notification"] = "notification";
  EnumFileKind["Mailing"] = "mailing";
  EnumFileKind["NotificationImage"] = "notification_image";
  EnumFileKind["AnyImage"] = "any_image";
  EnumFileKind["AnyDoc"] = "any_doc";
  EnumFileKind["StructuralFiles"] = "structural_files";
})(EnumFileKind = exports.EnumFileKind || (exports.EnumFileKind = {}));