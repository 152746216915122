"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function formatCPF(CPF) {
  if (!CPF.toString()) return '';
  var cpf = CPF.toString().replace(/\D/gim, '');

  if (cpf.toString().length < 11) {
    cpf = cpf.padStart(11, '0');
  }

  var result = cpf.toString() || '';
  return result;
}

exports.formatCPF = formatCPF;