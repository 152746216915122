"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.IncentiveCampaignContext = React.createContext({});
var Provider = exports.IncentiveCampaignContext.Provider;

exports.IncentiveCampaignProvider = function (props) {
  var _a;

  var children = props.children,
      incentiveCampaign = props.incentiveCampaign;

  if (!((_a = incentiveCampaign) === null || _a === void 0 ? void 0 : _a._id)) {
    console.error("IncentiveCampaignProvider: incentiveCampaign not provided in props \"".concat(JSON.stringify(props, null, 2), "\""));
    return null;
  }

  return React.createElement(Provider, {
    value: {
      incentiveCampaign: incentiveCampaign
    }
  }, children);
};

function useIncentiveCampaignContext() {
  var _a;

  var context = React.useContext(exports.IncentiveCampaignContext);

  if (!((_a = context.incentiveCampaign) === null || _a === void 0 ? void 0 : _a._id)) {
    console.error("useIncentiveCampaignContext: invalid incentiveCampaign in context:", context);
    return null;
  }

  return context;
}

exports.useIncentiveCampaignContext = useIncentiveCampaignContext;