"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function verdade(data) {
  return (Array.isArray(data) ? data : []).filter(function (item) {
    return !!item;
  });
}

exports.verdade = verdade;