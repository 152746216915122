"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var graphql_clientgen_1 = require("graphql-clientgen");

exports.MaterialActionsContext = React.createContext({});
var Provider = exports.MaterialActionsContext.Provider;

exports.MaterialActionsProvider = function (props) {
  var children = props.children,
      store = props.store,
      uploadUrl = props.uploadUrl;
  var deletePin = graphql_clientgen_1.fetchGraphql('PinRemoveOne', {}, store);
  var fetchPin = graphql_clientgen_1.fetchGraphql('PinCreateOne', {}, store);
  var fetchLike = graphql_clientgen_1.fetchGraphql('MaterialLikeToggle', {}, store);
  var fetchShare = graphql_clientgen_1.fetchGraphql('ShareCreateOne', {}, store);
  var fetchSearchUser = graphql_clientgen_1.fetchGraphql('UserSearch', {}, store);
  var fetchCreateFile = graphql_clientgen_1.fetchGraphql('FileCreateOne', {}, store);
  var fetchUpdateFile = graphql_clientgen_1.fetchGraphql('FileUpdateById', {}, store);
  var fetchFindFile = graphql_clientgen_1.fetchGraphql('FileFindById', {}, store);
  var fetchFiles = graphql_clientgen_1.fetchGraphql('FileFindByIds', {}, store);
  var fetchRegions = graphql_clientgen_1.fetchGraphql('RegionFindMany', {}, store);
  var fetchRoleGroups = graphql_clientgen_1.fetchGraphql('RoleGroupFindMany', {}, store);
  var fetchChannels = graphql_clientgen_1.fetchGraphql('ChannelFindMany', {}, store);
  var fetchSearchUserByIds = graphql_clientgen_1.fetchGraphql('UserFindByIds', {}, store);
  var fetchSearchUserByEmails = graphql_clientgen_1.fetchGraphql('UserFindByEmails', {}, store);

  var unPin = function unPin(record, cb, notReload) {
    return deletePin({
      variables: {
        filter: record
      }
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      if (!notReload) window.location.reload();
      return ctx;
    });
  };

  var onPin = function onPin(record, cb) {
    return fetchPin({
      variables: {
        record: record
      }
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  var onLike = function onLike(record, cb) {
    return fetchLike({
      variables: {
        record: record
      }
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  var onShare = function onShare(record, cb) {
    return fetchShare({
      variables: {
        record: Object.assign(Object.assign({}, record), {
          kind: 'material'
        })
      }
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  var onSearchUser = function onSearchUser(variables, cb) {
    return fetchSearchUser({
      variables: variables,
      cache: true
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  var onSearchUserByIds = function onSearchUserByIds(record, cb) {
    return fetchSearchUserByIds({
      variables: record
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
    return [];
  };

  var onSearchUserByEmails = function onSearchUserByEmails(record, cb) {
    return fetchSearchUserByEmails({
      variables: record,
      appendToFragment: "\n      _id\n      name\n      email\n    "
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
    return [];
  };

  var onFileCreate = function onFileCreate(record, cb) {
    return fetchCreateFile({
      variables: {
        record: record
      }
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  var onFileUpdate = function onFileUpdate(record, cb) {
    return fetchUpdateFile({
      variables: {
        record: record
      }
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  var onFileFind = function onFileFind(_id, cb) {
    return fetchFindFile({
      variables: _id
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  var onFileFindMany = function onFileFindMany(_ids, cb) {
    return fetchFiles({
      variables: _ids
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  return React.createElement(Provider, {
    value: {
      deletePin: unPin,
      pin: onPin,
      share: onShare,
      like: onLike,
      searchUser: onSearchUser,
      createFile: onFileCreate,
      findFile: onFileFind,
      findManyFile: onFileFindMany,
      uploadUrl: uploadUrl,
      fetchRegions: fetchRegions,
      fetchRoleGroups: fetchRoleGroups,
      fetchChannels: fetchChannels,
      updateFile: onFileUpdate,
      searchUserByIds: onSearchUserByIds,
      searchUserByEmails: onSearchUserByEmails
    }
  }, children);
};

function useMaterialActions() {
  return React.useContext(exports.MaterialActionsContext);
}

exports.useMaterialActions = useMaterialActions;