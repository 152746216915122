"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function download(content, filename, isFromUrl) {
  var encodedUri = isFromUrl ? null : encodeURI(content);
  var link = document.createElement('a');
  link.setAttribute('href', isFromUrl ? content : encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named.
}

exports.download = download;