"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validateName = function () {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var invalid = !name;

  if (name.length < 4) {
    invalid = true;
  }

  if (name.length > 500) {
    invalid = true;
  }

  if (name.match(exports.NAME_VALIDATION_REGEX)) {
    invalid = true;
  } // if (!name.match(/ [^ ]/)) {
  //   invalid = true;
  // }


  return invalid ? 'Nome completo inválido' : '';
};

exports.NAME_VALIDATION_REGEX = /[$+,:;=?@#|<>*()%!]/g;