"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var theme_1 = require("@digi-tim-19/theme");

var styled_components_1 = require("styled-components");

exports.defaultTheme = Object.assign(Object.assign({}, theme_1.colors), {
  baseInputHeight: 34,
  primaryButton: theme_1.colors.red,
  primaryButtonActive: theme_1.colors.blue
});

exports.ThemeProvider = function (props) {
  return React.createElement(styled_components_1.ThemeProvider, {
    theme: Object.assign(Object.assign({}, exports.defaultTheme), props.theme)
  }, React.createElement(React.Fragment, null, props.children));
};