"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var graphql_clientgen_1 = require("graphql-clientgen");

exports.DeviceActionsContext = React.createContext({});
var Provider = exports.DeviceActionsContext.Provider;

exports.DeviceActionsProvider = function (props) {
  var children = props.children,
      store = props.store;
  var deleteDevice = graphql_clientgen_1.fetchGraphql('DeleteDevice', {}, store);

  var deleteAct = function deleteAct(record, cb) {
    return deleteDevice({
      variables: {
        recordId: record.recordId
      },
      afterMutate: /^Device/
    }).then(function (ctx) {
      if (ctx.errors) {
        cb && cb(ctx.errors.join());
      } else {
        cb && cb(null, ctx.result);
      }

      return ctx;
    });
  };

  return React.createElement(Provider, {
    value: {
      delete: deleteAct
    }
  }, children);
};

function useDeviceActions() {
  return React.useContext(exports.DeviceActionsContext);
}

exports.useDeviceActions = useDeviceActions;