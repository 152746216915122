"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_redux_1 = require("react-redux");

var React = __importStar(require("react"));

exports.useBindAction = function () {
  var redux = React.useContext(react_redux_1.ReactReduxContext);
  return function (fn) {
    if (typeof fn !== 'function') {
      throw new Error("invalid action ".concat(fn));
    }

    return function () {
      return redux.store.dispatch(fn.apply(void 0, arguments));
    };
  };
}; // M == mapped resulting state


exports.useRedux = function () {
  var stateMapperFn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {
    return {};
  };
  var state = react_redux_1.useSelector(stateMapperFn, react_redux_1.shallowEqual);
  var bindAction = exports.useBindAction();
  return [state, bindAction];
};