"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isNumber(number) {
  return typeof number === 'number' && !isNaN(number) ? true : false;
}

exports.isNumber = isNumber;