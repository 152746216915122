"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var __1 = require("..");

exports.usePublico = function () {
  var _a, _b, _c;

  var context = __1.useSelecionarPublico();

  var availableAtRegions = (_a = context.state.regions) === null || _a === void 0 ? void 0 : _a.filter(function (item) {
    if (item.toUpperCase() === 'HQ' || item.toUpperCase() === 'THQ') return true;else return !!item.match(/^_BR_(\w\w)_(\d\d)_/);
  });
  var availableAtRoleGroups = (_b = context.state.channels) === null || _b === void 0 ? void 0 : _b.filter(function (item) {
    return item.includes(':') && !item.includes('-');
  });
  var availableAtChannels = (_c = context.state.channels) === null || _c === void 0 ? void 0 : _c.filter(function (item, _index, arr) {
    var isChannel = isOnlyChannel(item);

    if (isChannel) {
      var itemsSelected = arr.filter(function (el) {
        return el.indexOf(item) === 0;
      });
      var allItemsChannel = context.state.allChannels.filter(function (el) {
        return el.indexOf(item) === 0;
      });
      return allItemsChannel.length === itemsSelected.length;
    }

    return isChannel;
  });
  return {
    availableAtRoleGroups: availableAtRoleGroups,
    availableAtChannels: availableAtChannels,
    availableAtRegions: availableAtRegions
  };
};

function isOnlyChannel(item) {
  var _a;

  return !item.includes(':') && !((_a = item) === null || _a === void 0 ? void 0 : _a.includes('sub-')) && !item.includes('Selecionar Todos');
}