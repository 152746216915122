"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.MaterialContext = React.createContext({});
var Provider = exports.MaterialContext.Provider;

exports.MaterialProvider = function (props) {
  var _a;

  var children = props.children,
      material = props.material;

  if (!((_a = material) === null || _a === void 0 ? void 0 : _a._id)) {
    console.error("MaterialProvider: material not provided in props \"".concat(JSON.stringify(props, null, 2), "\""));
    return null;
  }

  return React.createElement(Provider, {
    value: {
      material: material
    }
  }, children);
};

function useMaterialContext() {
  var _a;

  var context = React.useContext(exports.MaterialContext);

  if (!((_a = context.material) === null || _a === void 0 ? void 0 : _a._id)) {
    console.error("useMaterialContext: invalid material in context:", context);
    return null;
  }

  return context;
}

exports.useMaterialContext = useMaterialContext;