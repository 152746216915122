"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // import * as Integrations from '@sentry/integrations';

var env_1 = require("./env");

if (!env_1.isDev()) {
  try {
    require('@sentry/browser').init({
      dsn: 'https://7e8c356798e648bfb49311cf92e94ab0@sentry.io/2308586',
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      integrations: [// new Integrations.CaptureConsole({
        //   levels: ['error']
        // })
      ]
    });
  } catch (e) {
    console.error(e);
  }
}